<template>
  <v-card class="pb-1 text-center">
    <v-alert border="top" colored-border color="secondary" class="pa-0 mb-3" />

    <v-card class="ma-1" outlined>
      <v-icon class="mt-2" color="primary" size="30">{{ descripcion.icon }}</v-icon>
      <v-card-subtitle class="pa-0">{{ descripcion.subtitle }}</v-card-subtitle>
      <v-skeleton-loader type="list-item-avatar-three-line"></v-skeleton-loader>  
    </v-card>
  </v-card>
</template>

<script>
export default {
  name: 'cargandoAvisos',
  props: {
    tipo: { type: String },
  },
  data () {
    return {
    }
  },
  computed: {
    descripcion() {
      const informacion = {};

      switch (this.tipo) {
        case "actividadesPendientes":
          informacion.subtitle = 'Buscando actividades por vencer...'
          informacion.icon = 'mdi-format-list-text'  
          break;
      }

      return informacion;
    }
  }

}
</script>