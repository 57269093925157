import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"persistent":"","width":"500"},model:{value:(_vm.mostrar),callback:function ($$v) {_vm.mostrar=$$v},expression:"mostrar"}},[_c(VCard,[_c(VToolbar,{attrs:{"dense":"","flat":"","dark":"","tile":"","color":"primary"}},[_c(VToolbarTitle,[_vm._v("Seleccionar examen para aplicar")])],1),_c(VCardText,{staticClass:"pt-2"},[_vm._l((_vm.examenes),function(examen){return _c(VCard,{key:examen._id,attrs:{"color":"#eceff1","flat":"","tile":""}},[_c('examen-item',{attrs:{"examen":examen},on:{"clickAction":_vm.examenSeleccionado}})],1)}),_c('div',{staticClass:"d-flex justify-end pt-2"},[_c(VBtn,{attrs:{"color":"secondary","outlined":"","small":""},on:{"click":function($event){return _vm.$emit('cancelar')}}},[_c('span',[_vm._v("Cancelar")])])],1)],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }