<template>
  <v-dialog v-model="show" persistent width="500">
    <v-card>
      <v-toolbar dense dark flat color="error darken-2">
        <v-toolbar-title>
          <span>Confirmar</span>
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text class="mt-3">
        <p>
          Si quitas la planeación académica de este curso, se eliminarán todos
          los detalles que hayas modificado dentro de la misma. Los datos de la
          planeación original se conservarán.
        </p>
        <p>
          <strong>Una vez realizada la acción, no se puede revertir.</strong>
        </p>
        <div class="d-flex justify-end">
          <v-btn
            color="primary"
            outlined
            small
            class="mr-2"
            @click="$emit('close')"
          >
            <span>Cancelar</span>
          </v-btn>
          <v-btn
            color="error darken-2"
            small
            :loading="loading"
            @click="quitarPlaneacion()"
          >
            <v-icon small class="mr-2">mdi-delete</v-icon>
            <span>Confirmar eliminación</span>
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { quitarPlaneacionAcademicaService } from "./configuracion.service";

export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    tipoCurso: {
      type: String,
    },
    idCurso: {
      type: String,
    },
  },

  data() {
    return {
      loading: false,
    };
  },

  methods: {
    async quitarPlaneacion() {
      try {
        this.loading = true;
        const serverResponse = await quitarPlaneacionAcademicaService(
          this.tipoCurso,
          this.idCurso
        );
        this.loading = false;

        if (!serverResponse.ok) {
          this.$systemErrorMessage(serverResponse.mensaje);
        } else {
          this.$emit("planeacionRemoved");
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
  },
};
</script>
