<template>
  <v-dialog v-model="mostrar" persistent width="600">
    <v-card :loading="loading">
      <v-toolbar dense color="primary" dark flat>
        <v-toolbar-title>{{
          item ? "Modificar respuesta" : "Agregar respuesta"
        }}</v-toolbar-title>
      </v-toolbar>
      <v-card-text class="pt-3 pb-0 px-4">
        <v-row dense>
          <v-col cols="12">
            <v-text-field
              v-model.trim="respuesta"
              label="Respuesta:"
              outlined
              dense
              :disabled="loading"
              :error-messages="respuestaErrors"
              @input="validarRespuesta()"
              @blur="validarRespuesta()"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model.trim="pista"
              label="Pista (opcional):"
              outlined
              dense
              :disabled="loading"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model.trim="textoElegido"
              label="Retroalimentación seleccionado:"
              outlined
              dense
              hint="Opcional. Aparecerá al finalizar si la respuesta fue seleccionada"
              :disabled="loading"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model.trim="textoNoElegido"
              label="Retroalimentación no seleccionado:"
              outlined
              dense
              hint="Opcional. Aparecerá al finalizar si la respuesta no fue seleccionada"
              :disabled="loading"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          small
          color="primary"
          outlined
          :disabled="loading"
          @click="$emit('cancelar')"
          >Cancelar</v-btn
        >
        <v-btn
          small
          color="primary"
          :disabled="loading || !formularioValido"
          :loading="loading"
          @click="guardarRespuesta()"
          >Guardar</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import {
  agregarMultiplechoiceRespuestaService,
  modificarMultiplechoiceRespuestaService,
} from "./multiplechoice.service.js";

export default {
  mixins: [validationMixin],

  props: {
    mostrar: { type: Boolean, default: false },
    modificar: { type: Boolean, default: false },
    idMultiplechoice: { type: String, default: null },
    item: { type: Object, default: () => null },
  },

  computed: {
    formularioValido() {
      if (this.respuestaErrors.length > 0) return false;
      return true;
    },
  },

  validations: {
    respuesta: { required },
  },

  data: () => ({
    loading: false,
    respuesta: "",
    pista: "",
    textoElegido: "",
    textoNoElegido: "",
    respuestaErrors: [],
    pistaErrors: [],
  }),

  mounted() {
    if (this.item !== null) {
      this.respuesta = this.item.text;
      this.pista = this.item.tipsAndFeedback.tip;
      this.textoElegido = this.item.tipsAndFeedback.chosenFeedback;
      this.textoNoElegido = this.item.tipsAndFeedback.notChosenFeedback;
    }
  },

  methods: {
    validarRespuesta() {
      this.respuestaErrors = [];
      !this.$v.respuesta.required &&
        this.respuestaErrors.push("Campo requerido.");
    },
    limpiarRespuesta() {
      const mapaAcentos = {
        á: "a",
        é: "e",
        í: "i",
        ó: "o",
        ú: "u",
        Á: "A",
        É: "E",
        Í: "I",
        Ó: "O",
        Ú: "U",
      };
      this.respuesta = this.respuesta.replace(
        /[áéíóúÁÉÍÓÚ]/g,
        function (letra) {
          return mapaAcentos[letra];
        }
      );

      this.respuesta = this.respuesta
        .replace(/[^\w\s]/gi, "")
        .replace(/\s/g, "");
      this.respuesta = this.respuesta.toUpperCase();
    },

    async guardarRespuesta() {
      this.validarRespuesta();
      if (!this.formularioValido) return;
      try {
        this.loading = true;
        const data = {
          text: this.respuesta,
          tip: this.pista,
          chosenFeedback: this.textoElegido,
          notChosenFeedback: this.textoNoElegido,
        };
        const serverResponse = this.modificar
          ? await modificarMultiplechoiceRespuestaService(
              this.idMultiplechoice,
              this.item._id,
              data
            )
          : await agregarMultiplechoiceRespuestaService(
              this.idMultiplechoice,
              data
            );
        this.loading = false;
        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else {
          this.modificar
            ? this.$emit("respuestaModificada", serverResponse)
            : this.$emit("respuestaAgregada", serverResponse);
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
  },
};
</script>
