<template>
  <div>
    <v-list>
      <v-list-item
        v-for="(interactivo, index) in interactivos"
        :key="interactivo._id"
      >
        <v-list-item-icon>
          <v-badge color="secondary" :content="index + 1" left overlap>
            <v-icon color="primary">mdi-puzzle</v-icon>
          </v-badge>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            <router-link :to="`interactivos/${interactivo.tipo}/${interactivo._id}`" class="link">
              {{ interactivo.nombre }}
            </router-link>
          </v-list-item-title>
          <v-list-item-subtitle>
            {{ tiposInteractivo[interactivo.tipo] }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <EditorInteractivo />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import EditorInteractivo from "./editorInteractivo.vue";
import {tiposInteractivosNombre} from './interactivos.service';

export default {
  name: "listaInteractivos",
  components: {
    EditorInteractivo,
  },
  props: {
    loading: { type: Boolean, default: () => false },
    interactivos: { type: Array, default: () => [] },
    pagination: { type: Object, default: () => {} },
  },
  computed: {
    ...mapGetters(["sessionToken", "role", "documentsURL", "app"]),
    esAdmin() {
      return (
        this.app == "campus" ||
        this.role == "admin" ||
        this.role == "profesor" ||
        this.role == "master"
      );
    },
  },
  data() {
    return {
      tiposInteractivo: tiposInteractivosNombre
    };
  },
  methods: {},
};
</script>
