import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('global-mensajeDialog',{attrs:{"mostrar":_vm.mostrar,"title":"Seleccionar área","noClose":true,"loading":_vm.loading}},[_c(VContainer,{attrs:{"slot":"content"},slot:"content"},[_c(VRow,{attrs:{"dense":""}},[_c(VCol,{attrs:{"cols":"12"}},[_c(VSelect,{attrs:{"items":_vm.areasEncuesta,"item-value":"_id","item-text":"nombre","label":"Área","outlined":"","dense":"","disabled":_vm.loading,"no-data-text":"No hay áreas de encuesta disponibles"},model:{value:(_vm.areaSeleccionada),callback:function ($$v) {_vm.areaSeleccionada=$$v},expression:"areaSeleccionada"}})],1)],1)],1),_c('template',{slot:"footer"},[_c(VSpacer),_c('global-secondaryButton',{attrs:{"texto":"Cancelar","disabled":_vm.loading},on:{"clicked":function($event){return _vm.$emit('cancelar')}}}),_c('global-primaryButton',{staticClass:"rightBtn-alert",attrs:{"texto":"Guardar","loading":_vm.loading},on:{"clicked":function($event){return _vm.submit()}}})],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }