<template>
  <div>
    <v-list-item
      dense
      @mouseover="puedeModificar ? (modificarIcon = true) : null"
      @mouseout="modificarIcon = false"
    >
      <v-list-item-icon>
        <v-btn
          v-if="modificarIcon && !vistaSeguimiento"
          fab
          x-small
          color="primary"
          @click="modificarInstrumento = true"
        >
          <v-icon small>mdi-pencil</v-icon>
        </v-btn>
        <v-icon v-else color="primary">mdi-table</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>Instrumento de evaluación</v-list-item-title>
        <v-list-item-subtitle>
          <span v-if="!instrumento">No asignado</span>
          <span v-else>
            <v-btn text x-small @click="verInstrumento">{{
              instrumento.nombre
            }}</v-btn>
          </span>
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <modificarInstrumentoActividad
      v-if="modificarInstrumento"
      :mostrar="modificarInstrumento"
      :tipoCurso="tipoCurso"
      :idCurso="idCurso"
      :idActividad="idActividad"
      :instrumento="instrumento"
      :tipoInstrumento="tipoInstrumento"
      @cancelar="modificarInstrumento = false"
      @instrumentoModificado="instrumentoModificado"
    />

    <!-- VER INSTRUMENTO -->
    <verInstrumentoActividad
      v-if="mostrarInstrumento"
      :mostrar="mostrarInstrumento"
      :instrumento="instrumento"
      :tipoInstrumento="tipoInstrumento"
      @cancelar="mostrarInstrumento = false"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import modificarInstrumentoActividad from "./modificarInstrumentoActividad.vue";
import verInstrumentoActividad from "../../../instrumentos/actividad/instrumentoActividad.view.vue";

export default {
  name: "instrumentoActividad",
  props: {
    tipo: Number,
    tipoCurso: String,
    idCurso: String,
    idActividad: String,
    instrumento: [Object, null],
    tipoInstrumento: String,
    permisoEscritura: { type: Boolean, default: false },
  },
  components: { modificarInstrumentoActividad, verInstrumentoActividad },
  computed: {
    ...mapGetters(["vistaSeguimiento"]),
    puedeModificar() {
      return this.permisoEscritura && !this.vistaSeguimiento && this.tipo != 4;
    },
  },
  data() {
    return {
      modificarIcon: false,
      modificarInstrumento: false,
      mostrarInstrumento: false,
    };
  },
  created() {},
  methods: {
    instrumentoModificado(data) {
      this.modificarInstrumento = false;
      this.$emit("instrumentoModificado", data);
    },
    verInstrumento() {
      this.mostrarInstrumento = true;
    },
  },
};
</script>
