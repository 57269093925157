<template>
  <div>
    <v-card-title>
      <v-btn
        icon
        :disabled="hayModuloPrevio"
        @click="moduloAnterior()"
        color="primary"
      >
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
      <v-spacer></v-spacer> {{ modulo.nombre }} <v-spacer></v-spacer>
      <v-btn
        icon
        :disabled="hayModuloSiguiente"
        @click="moduloPosterior()"
        color="primary"
      >
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
    </v-card-title>
    <v-divider class="mx-3"></v-divider>
    <v-container>
        <div v-if="permisoEscritura && !vistaSeguimiento">
          <v-toolbar dense flat>
            <span v-if="!modulo.descripcion && !edicionHabilitada">
              <i>No se ha capturado descripción</i>
            </span>
            <v-spacer></v-spacer>
            <v-tooltip v-if="permisoEscritura && !modulo.transversal" top>
              <template v-slot:activator="{ on }">
                <button style="float: right" v-on="on" @click="edicionHabilitada = true">
                  <v-icon medium style="margin-right: 5px">
                    mdi-square-edit-outline
                  </v-icon>
                </button>
              </template>
              <span>Modificar descripción</span>
            </v-tooltip>
          </v-toolbar>
        </div>
        <div v-if="edicionHabilitada">
          <editor-texto
            ref="editorTexto"
            :texto="modulo.descripcion"
            app="empresa"
            :contenido="
              tipoCurso == 'campus' ? 'cursoCampus' : 'cursoPlantilla'
            "
            :idContenido="idCurso"
          ></editor-texto>
          <div style="text-align: right">
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              class="margen"
              outlined
              small
              :loading="loading"
              @click="edicionHabilitada = false"
            >
              Cancelar
            </v-btn>
            <v-btn
              color="primary"
              class="margen"
              dark
              small
              :loading="loading"
              @click="modificarDescripcion()"
            >
              Guardar
            </v-btn>
          </div>
        </div>
        <div v-else v-html="modulo.descripcion"></div>
      </v-container>
    <v-container>
      <v-row>
        <v-col v-if="modulo.contieneClases" md="6" cols="12">
          <clasesModuloComponent
            :idModulo="modulo._id"
            :permisoEscritura="permisoEscritura"
          />
        </v-col>
        <v-col v-if="modulo.contieneActividades" md="6" cols="12">
          <actividadesModuloComponent
            :idModulo="modulo._id"
            :transversal="modulo.transversal"
            :permisoEscritura="permisoEscritura"
          />
        </v-col>
        <v-col v-if="modulo.contieneRecursos" md="6" cols="12">
          <recursosModuloComponent
            :idModulo="modulo._id"
            :permisoEscritura="permisoEscritura"
          />
        </v-col>
        <v-col
          v-if="modulo.contieneExamenes"
          md="6"
          cols="12"
          :permisoEscritura="permisoEscritura"
        >
          <examenesModuloComponent
            :idModulo="modulo._id"
            :permisoEscritura="permisoEscritura"
          />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import clasesModuloComponent from "./clases/clasesModulo.vue";
import actividadesModuloComponent from "./actividades/actividadesModulo.vue";
import recursosModuloComponent from "./recursos/recursosModulo.vue";
import examenesModuloComponent from "./examenAplicaciones/aplicacionesModulo.vue";
import editorTexto from "../../contenidos/editorTexto.vue";
import { modificarDescripcionModuloService } from "./modulos.service";
import { mapGetters } from "vuex";

export default {
  name: "contenidoModulo",
  props: {
    modulo: Object,
    moduloSiguiente: String,
    moduloPrevio: String,
    idCurso: String,
    tipoCurso: String,
    tipoModulo: String,
    permisoEscritura: Boolean,
    porcentajeAvance: Number,
    avanceAutomatico: Boolean,
  },
  components: {
    clasesModuloComponent,
    actividadesModuloComponent,
    recursosModuloComponent,
    examenesModuloComponent,
    "editor-texto": editorTexto,
  },
  computed: {
    ...mapGetters(["sessionToken", "app", "vistaSeguimiento", , "sesionCursoAlumno"]),
    hayModuloSiguiente(){
      let existe = true;
      if ((this.moduloSiguiente != null && !this.sesionCursoAlumno)  || 
          (this.moduloSiguiente != null && this.sesionCursoAlumno && !this.avanceAutomatico) ||
          (this.moduloSiguiente != null && this.avanceAutomatico && this.porcentajeAvance != null && this.porcentajeAvance == 100)) {
        existe = false;
      }
      return existe;
    }
  },
  watch: {
  },
  data() {
    return {
      loading: false,
      // hayModuloSiguiente: true,
      hayModuloPrevio: true,
      edicionHabilitada: false,
      idModulo: this.$route.params.idModulo,
    };
  },
  created() {
    if (this.sessionToken) this.idModulo = this.modulo._id;
    
    if (this.moduloPrevio != null) {
      this.hayModuloPrevio = false;
    }
  },
  methods: {
    moduloPosterior() {
      this.$router.push(
        `/curso/${this.tipoCurso}/${this.idCurso}/tipo/${this.tipoModulo}/${this.moduloSiguiente}`
      );
    },
    moduloAnterior() {
      this.$router.push(
        `/curso/${this.tipoCurso}/${this.idCurso}/tipo/${this.tipoModulo}/${this.moduloPrevio}`
      );
    },
    async modificarDescripcion() {
      try {
        this.loading = true;
        const data = {
          descripcion: this.$refs.editorTexto.obtenerTexto(),
        };

        const serverResponse = await modificarDescripcionModuloService(
          data,
          this.tipoCurso,
          this.idCurso,
          this.modulo._id
        );
        this.loading = false;

        if (!serverResponse.ok) {
          this.$systemErrorMessage(serverResponse.mensaje);
          this.edicionHabilitada = false;
        } else {
          this.$emit("descripcionModificada", serverResponse.descripcion);
          this.edicionHabilitada = false;
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
  },
};
</script>

<style>
.margen {
  margin: 5px !important;
}
</style>
