import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VFadeTransition } from 'vuetify/lib/components/transitions';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.loading)?_c('div',{staticClass:"d-flex justify-center"},[_c('span',{staticClass:"caption text--secondary"},[_vm._v("Cargando imágenes...")])]):(_vm.imagenes.length < 1)?_c('div',{staticClass:"d-flex justify-center"},[_c('span',{staticClass:"caption text--secondary"},[_vm._v("No se han subido imágenes aún")])]):_c(VRow,{staticClass:"listado",staticStyle:{"width":"100%"},attrs:{"justify":"start"}},_vm._l((_vm.imagenes),function(imagen,index){return _c(VCol,{key:imagen,attrs:{"cols":"6","md":"4","lg":"3"}},[_c(VCard,{staticClass:"image-card",staticStyle:{"cursor":"pointer"},on:{"mouseover":function($event){_vm.indexHover = index},"mouseenter":function($event){_vm.indexHover = index},"mouseleave":function($event){_vm.indexHover = null}}},[_c(VFadeTransition,[(_vm.indexHover === index)?_c('div',{staticClass:"image-buttons d-flex"},[_c(VBtn,{attrs:{"fab":"","color":"error","small":""},on:{"click":function($event){return _vm.abrirEliminacion(imagen)}}},[_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-delete")])],1)],1):_vm._e()]),_c(VImg,{attrs:{"src":`${_vm.imageURL}/${imagen}`,"aspect-ratio":"1","min-width":"100%","min-height":"100","max-height":"250"},on:{"click":function($event){return _vm.agregarImagen(imagen)}}})],1)],1)}),1),(_vm.mostrarEliminacionDialog)?_c('eliminacion-dialog',{attrs:{"mostrar":_vm.mostrarEliminacionDialog,"tipoContenido":_vm.contenido,"urlImagen":_vm.imagenParaEliminar,"idContenido":_vm.idContenido},on:{"cancelar":function($event){return _vm.cerrarEliminacion()},"imagenEliminada":_vm.imagenEliminada}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }