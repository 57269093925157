import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.loading)?_c(VContainer,[_c(VSkeletonLoader,{attrs:{"type":"article"}})],1):(!!_vm.modulo && !!_vm.planeacion)?_c(VContainer,{class:{ 'pa-0': _vm.idModulo }},[_c(VCard,[_c(VToolbar,{staticClass:"text-h6",attrs:{"dense":"","color":_vm.idModulo ? 'primary' : 'secondary',"dark":"","flat":""}},[_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){return _vm.goBack()}}},[_c(VIcon,[_vm._v("mdi-arrow-left")])],1),_c(VSpacer),_c('span',[_vm._v(_vm._s(_vm.planeacion.nombre))]),_c(VSpacer),_c('planeacionModuloMenu',{attrs:{"idPlaneacion":_vm.planeacion._id,"modulo":_vm.modulo,"modificarDescripcion":true},on:{"moduloSaved":_vm.moduloSaved}})],1),_c(VCardTitle,[_c(VBtn,{attrs:{"icon":"","color":"primary","disabled":!_vm.previousModulo},on:{"click":function($event){return _vm.changeModulo(_vm.previousModulo)}}},[_c(VIcon,[_vm._v("mdi-chevron-left")])],1),_c(VSpacer),_c('span',[_vm._v(_vm._s(_vm.modulo.nombre))]),_c(VSpacer),_c(VBtn,{attrs:{"icon":"","color":"primary","disabled":!_vm.nextModulo},on:{"click":function($event){return _vm.changeModulo(_vm.nextModulo)}}},[_c(VIcon,[_vm._v("mdi-chevron-right")])],1)],1),_c(VDivider,{staticClass:"mx-3"}),_c(VCardText,[(_vm.modulo.descripcion)?_c('div',[_c('p',{domProps:{"innerHTML":_vm._s(_vm.modulo.descripcion)}})]):_vm._e(),_c('div',{staticClass:"mt-4"},[_c('moduloElementos',{attrs:{"elementos":_vm.modulo.elementos,"idPlaneacion":_vm.idPlaneacion,"idModulo":_vm.idModulo,"planeacion":_vm.planeacion}})],1)])],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }