<template>
  <v-dialog v-model="show" persistent width="500">
    <v-card>
      <v-toolbar dense flat color="primary" dark>
        <v-toolbar-title>Editar planeación</v-toolbar-title>
      </v-toolbar>
      <v-card-text class="pt-3 pb-3 px-4">
        <v-form v-model="isFormValid" ref="form" lazy-validation>
          <v-row dense>
            <v-col cols="12">
              <v-text-field
                v-model="form.nombre"
                label="Nombre de la planeación"
                outlined
                dense
                :rules="[$rules.required]"
                @keypress.prevent.enter="submit()"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <div class="d-flex justify-end">
                <v-btn
                  color="primary"
                  outlined
                  small
                  class="mr-2"
                  @click="$emit('close')"
                >
                  <span>Cancelar</span>
                </v-btn>
                <v-btn
                  color="primary"
                  small
                  :disabled="!isFormValid"
                  :loading="loading"
                  @click="submit()"
                >
                  <span>Guardar</span>
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { updatePlaneacion } from "./planeacionesAcademicas.service.js";

export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    planeacion: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      form: {
        nombre: "",
      },
      isFormValid: false,
      loading: false,
    };
  },

  mounted() {
    this.setCurrentData();
  },

  methods: {
    setCurrentData() {
      this.form.nombre = this.planeacion.nombre;
    },

    async submit() {
      this.loading = true;
      await this.$refs.form.validate();
      if (!this.isFormValid) return;

      try {
        const serverResponse = await updatePlaneacion(
          this.planeacion._id,
          this.form
        );

        if (!serverResponse.ok) {
          this.$systemErrorMessage(serverResponse.mensaje);
          return;
        }

        this.$emit("planeacionSaved", serverResponse.planeacion);
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
