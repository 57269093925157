import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSubheader } from 'vuetify/lib/components/VSubheader';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{attrs:{"outlined":""}},[_c(VSubheader,{staticClass:"font-weight-bold"},[_vm._v(" Clases "),_c(VSpacer),(!_vm.loading && _vm.permisoEscritura && !_vm.vistaSeguimiento)?_c(VBtn,{attrs:{"icon":"","x-small":"","color":"primary","disabled":_vm.loading},on:{"click":function($event){_vm.agregarClase = true}}},[_c(VIcon,[_vm._v("mdi-text-box-plus-outline")])],1):_vm._e()],1),_c(VDivider,{staticClass:"mx-3"}),(_vm.loading)?_c(VCard,{staticClass:"my-2",attrs:{"flat":""}},[_c(VSkeletonLoader,{staticStyle:{"margin":"20px"},attrs:{"type":"list-item-avatar-two-line@3"}})],1):_c('div',[_c(VList,[(!_vm.sesionCursoAlumno)?_c('draggable',_vm._b({attrs:{"element":"div","ghost-class":"ghost","handle":".handle"},on:{"change":_vm.cambiarOrden},model:{value:(_vm.clases),callback:function ($$v) {_vm.clases=$$v},expression:"clases"}},'draggable',_vm.dragOptions,false),_vm._l((_vm.clases),function(clase,index){return _c('claseItemComponent',{key:`clase-${index}`,attrs:{"tipoCurso":_vm.tipoCurso,"idCurso":_vm.idCurso,"clase":clase,"tipoModulo":_vm.tipoModulo,"permisoEscritura":_vm.permisoEscritura},on:{"claseVisibleModificado":_vm.claseVisibleModificado}})}),1):_vm._l((_vm.clases),function(clase,index){return _c('claseItemAlumnoComponent',{key:`claseAlumno-${index}`,attrs:{"tipoCurso":_vm.tipoCurso,"tipoModulo":_vm.tipoModulo,"idCurso":_vm.idCurso,"clase":clase}})}),(_vm.clases.length == 0)?_c(VListItem,[_c(VListItemSubtitle,[_vm._v("No hay clases capturadas")])],1):_vm._e()],2)],1),(_vm.agregarClase && _vm.permisoEscritura)?_c('agregarClaseComponent',{attrs:{"mostrar":_vm.agregarClase,"tipoCurso":_vm.tipoCurso,"idCurso":_vm.idCurso,"idModulo":_vm.idModulo},on:{"cancelar":function($event){_vm.agregarClase = false},"claseAgregada":_vm.claseAgregada}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }