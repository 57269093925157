import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{attrs:{"outlined":""}},[_c(VCardTitle,[_vm._v("Texto "),_c(VSpacer),(_vm.permisoEscritura)?_c(VBtn,{attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.mostrarTextArea()}}},[_c(VIcon,[_vm._v("mdi-pencil-box-outline")])],1):_vm._e(),(_vm.textoValido)?_c(VBtn,{attrs:{"color":"primary","small":"","icon":""},on:{"click":function($event){return _vm.$emit('preview')}}},[_c(VIcon,[_vm._v("mdi-motion-play-outline")])],1):_vm._e()],1),_c(VDivider,{staticClass:"mx-3"}),_c(VContainer,[(!_vm.mostrarModificarTexto)?_c(VCardText,{domProps:{"innerHTML":_vm._s(_vm.textoLocal)}}):_c(VTextarea,{attrs:{"loading":_vm.loading,"disabled":_vm.loading,"outlined":"","hint":"Las palabras clave deben estar entre asteriscos. Ejemplo: Esta es una palabra *clave*","error-messages":_vm.error},model:{value:(_vm.textoModificar),callback:function ($$v) {_vm.textoModificar=$$v},expression:"textoModificar"}}),(_vm.mostrarModificarTexto)?_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","small":"","outlined":"","disabled":_vm.loading},on:{"click":function($event){_vm.mostrarModificarTexto = false}}},[_vm._v("Cancelar")]),_c(VBtn,{attrs:{"color":"primary","small":"","loading":_vm.loading},on:{"click":function($event){return _vm.modificarTexto()}}},[_vm._v("Modificar")])],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }