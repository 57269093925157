<template>
  <v-container style="padding-top: 0px;">
    <!-- Datos de calificación -->
    <v-card outlined :loading="loading">
      <v-toolbar dense color="#254b72" dark flat style="height: 45px;">
        <strong>Calificación</strong>
      </v-toolbar>

      <div v-if="loading">
        <v-skeleton-loader
          class="ma-2"
          type="list-item, list-item, actions"
        ></v-skeleton-loader>
      </div>

      <div v-else>
        <v-container class="mt-2 pb-0">
          <v-row>
            <v-col cols="2" xs="12" md="12" style="padding-bottom: 0px;">
              <v-select
                v-if="!instrumento"
                v-model="calificacionLocal"
                label="Cuantitativa:"
                :items="listaCalificaciones"
                :disabled="disableActions"
                outlined
                dense
              />
              <v-text-field
                v-else
                label="Cualitativa:"
                :value="calificacionLocal"
                :disabled="disableActions"
                readonly
                outlined
                dense
              />
            </v-col>
            <v-col v-if="!esInteractivo" cols="10" xs="12" md="12" style="padding-top: 0px">
              <v-textarea
                v-model="observacionesLocal"
                label="Retroalimentación:"
                rows="3"
                :disabled="disableActions"
                outlined
                dense
              />
            </v-col>
          </v-row>
        </v-container>

        <v-alert
          v-if="disableActions"
          class="mx-3"
          color="orange accent-1 text-center"
          dense
        >
          No se puede agregar una calificación porque no se cuenta con archivos
          o ya existe una calificación previamente registrada.
        </v-alert>

        <v-card-actions style="padding-top: 0px">
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            small
            outlined
            :disabled="loading"
            @click="$emit('cancelar')"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="primary"
            small
            dark
            :class="{ 'disable-button': loading || disableActions }"
            @click="guardar()"
          >
            <span v-if="!calificacionModificar">Agregar</span>
            <span v-else>Modificar</span>
          </v-btn>
        </v-card-actions>
      </div>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "datosCalificacion",
  props: {
    loading: { type: Boolean, default: false },
    instrumento: { type: String, default: null },
    instrumentoCalificacion: { type: Number, default: null }, // Sirve como indicador de calificación cuando es instrumento
    calificacionModificar: { type: Object, default: () => null },
    limpiarDatos: { type: Object, default: () => null },
    disableActions: { type: Boolean, default: false },
    esInteractivo: { type: Boolean, default: false },
  },

  computed: {
    disableBtn() {
      if (this.loading) return true;
      if (this.calificacionModificar === null) return true;
      return false;
    },
  },

  data() {
    return {
      calificacionLocal: 0,
      observacionesLocal: "",
      listaCalificaciones: [],
    };
  },

  watch: {
    instrumentoCalificacion(value) {
      this.calificacionLocal = value;
    },
    limpiarDatos(value) {
      if (value) this.setDataGrade();
    },
  },

  created() {
    for (let i = 100; i >= 0; i--) this.listaCalificaciones.push(i);
    this.setDataGrade();
  },

  methods: {
    setDataGrade() {
      if (this.calificacionModificar) {
        const { calificacion, observaciones } = this.calificacionModificar;
        this.calificacionLocal = calificacion;
        this.observacionesLocal = observaciones;
      } else {
        if (this.instrumento) {
          this.calificacionLocal = this.instrumentoCalificacion;
          this.observacionesLocal = "";
        } else {
          this.calificacionLocal = 100;
          this.observacionesLocal = "";
        }
      }
    },

    guardar() {
      let data = {
        calificacion: this.calificacionLocal,
        observaciones: this.observacionesLocal,
      };
      this.$emit("agregarCalificacion", data);
    },
  },
};
</script>

<style>
.disable-button {
  pointer-events: none;
  opacity: 0.2;
}
</style>
