<template>
  <div>
    <v-list-item
      dense
      @mouseover="puedeModificar && !transversal ? (modificarIcon = true) : null"
      @mouseout="modificarIcon = false"
    >
      <v-list-item-icon>
        <v-btn
          v-if="modificarIcon && !vistaSeguimiento"
          fab
          x-small
          color="primary"
          @click="modificarTipo = true"
        >
          <v-icon small>mdi-pencil</v-icon>
        </v-btn>
        <v-icon v-else color="primary">{{ tipoActividad.icon }}</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>Tipo</v-list-item-title>
        <v-list-item-subtitle>
          {{ tipoActividad.texto }}
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <modificarTipoActividad
      v-if="modificarTipo"
      :mostrar="modificarTipo"
      :tipoCurso="tipoCurso"
      :idCurso="idCurso"
      :idActividad="idActividad"
      :tipo="tipo"
      @cancelar="modificarTipo = false"
      @tipoModificado="tipoModificado"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import modificarTipoActividad from "./modificarTipoActividad.vue";

export default {
  name: "tipoActividad",
  props: {
    tipoCurso: String,
    idCurso: String,
    idActividad: String,
    tipo: Number,
    permisoEscritura: { type: Boolean, default: false },
    transversal: { type: Object, default: null },
  },
  components: { modificarTipoActividad },
  computed: {
    ...mapGetters([
      "actividadDirectaIcon",
      "actividadArchivoIcon",
      "actividadForoIcon",
      "actividadInteractivaIcon",
      "actividadTransversalIcon",
      "vistaSeguimiento",
    ]),
    puedeModificar() {
      return this.permisoEscritura && !this.vistaSeguimiento && this.tipo != 4;
    },
    tipoActividad() {
      if (this.tipo === 1)
        return {
          icon: this.actividadDirectaIcon,
          texto: "Calificación directa",
        };
      if (this.tipo === 2)
        return {
          icon: this.actividadArchivoIcon,
          texto: "Subir archivo",
        };
      if (this.tipo === 3)
        return {
          icon: this.actividadForoIcon,
          texto: "Foro",
        };
      if (this.tipo === 4)
        return {
          icon: this.actividadInteractivaIcon,
          texto: "Interactivo",
        };
        if (this.tipo === 5){
          let icon = this.actividadTransversalIcon;
          let texto = '';
          if(this.transversal.tipo == 1) texto = "Calificación directa"
          else texto = "Subir archivo"
          return { icon, texto };
        }
      return {
        icon: "mdi-alert",
        texto: "No definido",
      };
    },
  },
  data() {
    return {
      modificarIcon: false,
      modificarTipo: false,
    };
  },
  methods: {
    tipoModificado(data) {
      this.modificarTipo = false;
      this.$emit("tipoModificado", data);
    },
  },
};
</script>
