import { VBtn } from 'vuetify/lib/components/VBtn';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('datatable',{attrs:{"headers":_vm.headers,"pagination":_vm.pagination,"datos":_vm.equiposLocal,"itemsPerPage":_vm.equiposLocal.length,"totalItems":_vm.equiposLocal.length,"noDatosText":"No se han generado equipos","hideSearch":"","hidePagination":""},scopedSlots:_vm._u([{key:"table-title",fn:function(){return [(_vm.permisoEscritura && !_vm.vistaSeguimiento)?_c(VBtn,{staticClass:"agregar-equipo-btn",attrs:{"x-small":"","color":"primary","dark":""},on:{"click":function($event){_vm.editarEquipo.mostrar = true}}},[_vm._v("Agregar equipo")]):_vm._e(),(_vm.permisoEscritura && _vm.equipos.length == 0 && !_vm.vistaSeguimiento)?_c(VBtn,{staticClass:"importar-equipo-btn",attrs:{"x-small":"","color":"primary","dark":""},on:{"click":function($event){_vm.importarEquipos = true}}},[_vm._v("Importar equipos")]):_vm._e()]},proxy:true},{key:"item-equipo",fn:function({ item }){return [_c(VListItem,{staticClass:"equipo-item"},[(_vm.permisoEscritura && !_vm.vistaSeguimiento)?_c(VListItemAction,{staticClass:"equipo-menu"},[_c('equipoMenu',{attrs:{"idCurso":_vm.idCurso,"idActividad":_vm.idActividad,"idEquipo":item._id,"desdeComentario":_vm.desdeComentario},on:{"asignarAdmin":_vm.asignarAdmin,"modificarEquipo":_vm.modificarEquipo,"equipoActividadEliminado":_vm.equipoActividadEliminado}})],1):_vm._e(),_c(VListItemContent,{staticStyle:{"width":"300px"}},[_c(VListItemTitle,[_vm._v(_vm._s(item.nombre))]),_c(VListItemSubtitle,[_vm._v(_vm._s(item.admin.nombreCompleto)+" "),(item.integrantes.length > 1)?_c('span',[_vm._v(" y "+_vm._s(item.integrantes.length - 1)+" más.")]):_vm._e()])],1)],1)]}}])}),(_vm.editarEquipo.mostrar)?_c('equipoForm',{attrs:{"mostrar":_vm.editarEquipo.mostrar,"idCurso":_vm.idCurso,"idActividad":_vm.idActividad,"alumnos":_vm.alumnos,"equipos":_vm.equipos,"idEquipo":_vm.editarEquipo.equipo,"entregaActividad":_vm.entrega},on:{"cancelar":function($event){_vm.editarEquipo.equipo = null;
      _vm.editarEquipo.mostrar = false;},"equipoAgregado":_vm.equipoAgregado,"equipoModificado":_vm.equipoModificado}}):_vm._e(),(_vm.importarEquipos)?_c('equipoImport',{attrs:{"mostrar":_vm.importarEquipos,"idModulo":_vm.idModulo,"idActividad":_vm.idActividad,"entregaActividad":_vm.entrega},on:{"cancelar":function($event){_vm.importarEquipos = false},"equiposImportados":_vm.equiposImportados}}):_vm._e(),(_vm.editarAdmin.mostrar)?_c('equipoAdmin',{attrs:{"mostrar":_vm.editarAdmin.mostrar,"idCurso":_vm.idCurso,"idActividad":_vm.idActividad,"equipo":_vm.editarAdmin.equipo},on:{"cancelar":function($event){_vm.editarAdmin.mostrar = false;
      _vm.editarAdmin.equipo = null;},"adminModificado":_vm.adminModificado}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }