<template>
  <div>

    <v-card v-if="entrega > 1 && !esAlumno" class="mb-2" flat outlined>
      <v-toolbar dense flat dark color="primary">
        <v-toolbar-title>
          <span>Equipos</span>
        </v-toolbar-title>
      </v-toolbar>

      <listadoEquipos 
        :idCurso="idCurso"
        :idActividad="idActividad"
        :idModulo="idModulo"
        :entrega="entrega"
        :alumnos="alumnos"
        :equipos="equipos"
        :permisoEscritura="permisoEscritura"
        :desdeComentario="true"
        @equipoAgregado="equipoAgregado"
        @equipoModificado="equipoModificado"
        @equiposImportados="equiposImportados"
        @equipoActividadEliminado="equipoActividadEliminado"
      />
    </v-card>

    <v-card outlined :loading="loading">
      <v-toolbar dense flat dark color="primary">
        <v-toolbar-title>
          <span>Comentarios</span>
        </v-toolbar-title>
      </v-toolbar>

      <v-card v-if="loading" flat outlined>
        <v-skeleton-loader
          class="ma-2"
          type="list-item-avatar, list-item-three-line"
        ></v-skeleton-loader>
      </v-card>

      <v-card-text v-else>
        <div class="mb-3">
          <listado-comentarios
            :actualizarTipo="actualizarTipo"
            :actualizarEntregas="actualizarEntregas"
            :actualizarActividad="actualizarActividad"
            :idCurso="idCurso"
            :idActividad="idActividad"
            :entrega="entrega"
            :idClase="idClase"
            :entregaActividad="entregaActividad"
            :comentarios="comentariosLocal"
            :idEquipo="idEquipo"
            :cargarCalificacionesAlumnos="permisoEscritura"
            :permisoEscritura="permisoEscritura || vistaSeguimiento"
            :cursoArchivado="cursoArchivado"
          />
        </div>
        <agregar-comentario
          v-if="!restringirComentario && !restringirPorTiempo && puedeComentar && !vistaSeguimiento && !cursoArchivado"
          :idActividad="idActividad"
          :idClase="idClase"
        ></agregar-comentario>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Pusher from 'pusher-js';

import ListadoComentarios from "./ListadoComentarios.vue";
import listadoEquipos from "./listadoEquipos.vue";
import { obtenerComentariosActividad, obtenerComentariosClase } from "./comentarios.service";
import { ordenarObjetosAsc } from "../../helpers/ordenador";



export default {
  name: 'comentarios',
  props: {
    actualizarTipo: { type: Number, default: 0 },
    actualizarEntregas: { type: Number, default: 0 },
    actualizarActividad: { type: String, default: "" },
    idCurso: { type: String, default: null },
    idActividad: { type: String, default: null },
    descripcion: { type: String, default: null },
    entrega: { type: Number, default: 1 },
    esAlumno: {type: Boolean, default: false },
    idModulo: { type: String, default: null },
    entregaActividad: { type: Object, default: () => {} },
    idEquipo: { type: String, default: null },
    puedeComentar: {
      type: Boolean,
      default: true,
    },
    permisoEscritura: {
      type: Boolean,
      default: false,
    },
    cursoArchivado: { type: Boolean, default: false },
  },

  components: {
    "agregar-comentario": () => import("./AgregarComentario.vue"),
    "listado-comentarios": ListadoComentarios,
    listadoEquipos
  },

  data() {
    return {
      idClase: null, //# TODO: Validar de donde se obtiene
      loading: false,
      comentarios: [],
      alumnos: [],
      equipos: [],
      pusher: null,
    };
  },

  watch: {
    actualizarEntregas() {
      this.cargarComentarios();
    },
  },

  computed: {
    ...mapGetters(["userId", "vistaSeguimiento", "pusherKey", "pusherCluster"]),

    restringirComentario() {
      const esActividadSinDescripcion = this.idActividad && !this.descripcion;
      const comentarioActual = this.comentarios.find(
        (c) => c.usuario._id == this.userId
      );
      return (
        (!!comentarioActual && !this.permisoEscritura) ||
        esActividadSinDescripcion
      );
    },

    restringirPorTiempo() {
      if (
        !this.entregaActividad ||
        this.entregaActividad.abierta ||
        this.entregaActividad.entregaExt ||
        this.permisoEscritura
      )
        return false;

      const fechaActual = new Date().getTime();
      const fechaInicio = new Date(this.entregaActividad.inicio).getTime();
      const fechaFin = new Date(this.entregaActividad.fin).getTime();

      return fechaActual < fechaInicio || fechaActual > fechaFin;
    },

    comentariosLocal() {
      const comentariosResponse = [];

      for (const comentario of this.comentarios) {
        if(comentario.equipo) {
          comentario.equipo.integrantes.forEach((integrante) => {
            let { nombreCompleto, avatar } = this.alumnos.find((alumno) => alumno._id == integrante.usuario)
            integrante.nombreCompleto = nombreCompleto;
            integrante.avatar = avatar;
          });
        }
        
        comentariosResponse.push(comentario);
      }

      return comentariosResponse;
    }
  },

  async created() {
    try {
      
      this.pusher = new Pusher(this.pusherKey, { cluster: this.pusherCluster, encrypted: true },);

      const nameChannel = this.idActividad ? this.idActividad : this.idClase;

      this.pusher.subscribe(`${nameChannel}`);
      this.pusher.bind('comment-message', data => {
        this.actualizarComentarios(data.comentario);
      });
      
    } catch (error) {
      console.log(error);
      this.loading = false;
      this.$appErrorMessage();
    }
  },

  beforeDestroy() {
    const nameChannel = this.idActividad ? this.idActividad : this.idClase;
    this.pusher.unsubscribe(`${nameChannel}`);
  },
  
  async mounted() {
    await this.cargarComentarios();
  },


  methods: {
    async cargarComentarios() {
      this.loading = true;

      try {
        const serverResponse = this.idActividad
          ? await obtenerComentariosActividad({
              idActividad: this.idActividad,
              idCurso: this.idCurso,
            })
          : await obtenerComentariosClase({
              idClase: this.idClase,
              idCurso: this.idCurso,
            });
        this.loading = false;

        this.$validateResponse(serverResponse);
        if (!serverResponse.ok) this.$notificarError(serverResponse.mensaje);
        else {
          this.comentarios = serverResponse.comentarios;
          if (serverResponse.alumnos.length > 0)
            this.alumnos = ordenarObjetosAsc(serverResponse.alumnos, "nombreCompleto");
          this.equipos = serverResponse.equipos;
        }
      } catch (error) {
        console.log(error);
        this.loading = false;
        this.$appErrorMessage();
      }
    },
    actualizarComentarios(comentario) {

      const indexComentario = this.comentarios.findIndex((c) => c._id === comentario._id);
      if (indexComentario === -1) 
        this.comentarios.push(comentario);
      else 
        this.comentarios.splice(indexComentario, 1, comentario);

    },
    equipoAgregado(equipo) {
      this.equipos.push(equipo);
    },
    equipoModificado(equipo) {
      const index = this.equipos.findIndex((x) => x._id == equipo._id);
      if (index >= 0) {
        this.equipos[index].nombre = equipo.nombre;
        this.equipos[index].integrantes = equipo.integrantes;
      }
    },
    equiposImportados(equipos) {
      this.equipos = equipos;
    },
    equipoActividadEliminado(idEquipo) {
      const index = this.equipos.findIndex((x) => x._id == idEquipo);
      if (index >= 0) this.equipos.splice(index, 1);

      this.cargarComentarios();
    },
  }
    
};
</script>
