<template>
  <v-container>
    <v-card>
      <v-toolbar color="secondary" dark flat dense class="text-h6">
        <v-spacer></v-spacer>
        Planeaciones
        <v-spacer></v-spacer>
      </v-toolbar>

      <v-card-text>
        <v-row>
          <v-col cols="10" md="6">
            <selector-areas
              @change="areas = $event"
              @areaSelected="areaSelected = $event"
            ></selector-areas>
          </v-col>
          <v-col cols="2" md="6">
            <div class="d-flex justify-end">
              <v-tooltip v-if="esAdmin" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    color="primary"
                    fab
                    small
                    style="margin-left: 10px"
                    :disabled="!areas.length"
                    @click="mostrarCreacion = true"
                    ><v-icon>mdi-plus</v-icon></v-btn
                  >
                </template>
                <span>Nueva planeación</span>
              </v-tooltip>
            </div>
          </v-col>
        </v-row>
        <v-row dense class="mt-5">
          <v-col cols="12">
            <planeaciones-list
              ref="planeacionesList"
              :area="areaSelected"
            ></planeaciones-list>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <planeacion-dialog
      v-if="mostrarCreacion"
      :show="mostrarCreacion"
      :areaSelected="areaSelected"
      @close="mostrarCreacion = false"
      @planeacionSaved="planeacionSaved"
    ></planeacion-dialog>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";

import selectorAreasPlaneaciones from "./areas/selectorAreasPlaneaciones.vue";
import crearPlaneacionDialog from "./crearPlaneacionDialog.vue";
import planeacionesList from "./planeacionesList.vue";

export default {
  components: {
    "selector-areas": selectorAreasPlaneaciones,
    "planeacion-dialog": crearPlaneacionDialog,
    "planeaciones-list": planeacionesList,
  },

  data() {
    return {
      areas: [],
      areaSelected: null,
      mostrarCreacion: false,
    };
  },

  computed: {
    ...mapGetters(["app", "role"]),

    esAdmin() {
      return (
        this.app === "campus" || this.role == "admin" || this.role === "master"
      );
    },
  },

  methods: {
    planeacionSaved(planeacion) {
      this.$refs.planeacionesList.addPlaneacion(planeacion);
      this.mostrarCreacion = false;
    },
  },
};
</script>
