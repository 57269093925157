import { VBadge } from 'vuetify/lib/components/VBadge';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VList,_vm._l((_vm.interactivos),function(interactivo,index){return _c(VListItem,{key:interactivo._id},[_c(VListItemIcon,[_c(VBadge,{attrs:{"color":"secondary","content":index + 1,"left":"","overlap":""}},[_c(VIcon,{attrs:{"color":"primary"}},[_vm._v("mdi-puzzle")])],1)],1),_c(VListItemContent,[_c(VListItemTitle,[_c('router-link',{staticClass:"link",attrs:{"to":`interactivos/${interactivo.tipo}/${interactivo._id}`}},[_vm._v(" "+_vm._s(interactivo.nombre)+" ")])],1),_c(VListItemSubtitle,[_vm._v(" "+_vm._s(_vm.tiposInteractivo[interactivo.tipo])+" ")])],1)],1)}),1),_c('EditorInteractivo')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }