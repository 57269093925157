import { VApp } from 'vuetify/lib/components/VApp';
import { VCard } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VMain } from 'vuetify/lib/components/VMain';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VApp,{staticStyle:{"background-color":"#b0bec5"}},[_c(VMain,[(_vm.loading)?_c(VDialog,{attrs:{"persistent":"","max-width":"300"},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}},[_c(VCard,[_c(VToolbar,{attrs:{"dense":"","color":"primary","dark":""}},[_vm._v("Activando cuenta... ")]),_c(VProgressLinear,{attrs:{"color":"white","indeterminate":""}})],1)],1):_vm._e(),(_vm.cuentaActivada)?_c('cuentaActivadaComponent',{attrs:{"mostrar":_vm.cuentaActivada},on:{"redireccionar":_vm.redireccionar}}):_vm._e(),(_vm.errorActivacion)?_c('errorActivacionComponent',{attrs:{"mostrar":_vm.errorActivacion},on:{"redireccionar":_vm.redireccionar}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }