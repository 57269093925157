import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VMenu,{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-dots-vertical")])],1)]}}])},[_c(VList,{attrs:{"dense":""}},_vm._l((_vm.menuModulo),function(menuItem,index){return _c(VListItem,{key:index,staticClass:"text--primary",on:{"click":function($event){return menuItem.method()}}},[_c(VListItemIcon,{staticClass:"ma-0 pa-0 pt-4"},[(menuItem.icon == 'mdi-delete')?_c(VIcon,{attrs:{"small":"","color":"red darken-2"}},[_vm._v(_vm._s(menuItem.icon))]):_c(VIcon,{attrs:{"small":""}},[_vm._v(_vm._s(menuItem.icon || "mdi-pencil"))])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(menuItem.title))])],1)],1)}),1)],1),(_vm.openNombreDialog)?_c('update-nombre-dialog',{attrs:{"show":_vm.openNombreDialog,"idPlaneacion":_vm.idPlaneacion,"modulo":_vm.moduloLocal,"vaAModificarDescripcion":_vm.vaAModificarDescripcion},on:{"moduloSaved":_vm.moduloSaved,"close":function($event){_vm.openNombreDialog = false}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }