import { VCard } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VListItem,[(_vm.app == 'campus')?_c(VListItemIcon,[_c(VIcon,[_vm._v("mdi mdi-certificate")])],1):_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c(VListItemIcon,_vm._g({},on),[_c(VIcon,{on:{"click":function($event){return _vm.agregarDiploma()}}},[_vm._v("mdi mdi-certificate")])],1)]}}]),model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('span',[_vm._v("Agregar")])]),_c(VListItemContent,[_c(VListItemTitle,{on:{"mouseover":function($event){_vm.show = true},"mouseleave":function($event){_vm.show = false}}},[_vm._v("Generar diploma al finalizar")]),_vm._l((_vm.diplomasLocal),function(diploma){return _c(VListItemSubtitle,{key:diploma._id,staticStyle:{"display":"flex","align-items":"center"}},[_c('a',{on:{"click":function($event){return _vm.obtenerDiploma(diploma._id)}}},[_vm._v(_vm._s(diploma.nombre))]),_c(VSpacer,{staticStyle:{"display":"inline"}}),(_vm.app != 'campus')?_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c(VListItemIcon,_vm._g({staticStyle:{"margin":"0px 0px 0px 16px"}},on),[_c(VIcon,{on:{"click":function($event){return _vm.quitarDiploma(diploma._id)}}},[_vm._v("mdi-close")])],1)]}}],null,true)},[_c('span',[_vm._v("Quitar")])]):_vm._e()],1)})],2),(_vm.mostrarAgregar)?_c('agregarDiplomasComponent',{attrs:{"mostrar":_vm.mostrarAgregar,"tipoCurso":_vm.tipoCurso,"idCurso":_vm.idCurso,"diplomasActuales":_vm.diplomasLocal},on:{"diplomaAgregado":_vm.diplomaAgregado,"cancelar":function($event){_vm.mostrarAgregar = false}}}):_vm._e(),_c(VDialog,{attrs:{"persistent":"","max-width":"300"},model:{value:(_vm.loadingFile),callback:function ($$v) {_vm.loadingFile=$$v},expression:"loadingFile"}},[_c(VCard,[_c(VToolbar,{attrs:{"dense":"","color":"primary","dark":""}},[_vm._v("Cargando archivo... ")]),_c(VProgressLinear,{attrs:{"color":"white","indeterminate":""}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }