<template>
  <v-dialog v-model="mostrar" :max-width="300" persistent>
    <v-card>
      <v-toolbar color="primary" dark flat dense class="text-bold">
        <v-toolbar-title>Tipo de calificación</v-toolbar-title>
      </v-toolbar>
      <v-container>
        <v-select
          v-model="tipoSelect"
          outlined
          dense
          label="Tipo:"
          :disabled="loading || loading"
          :items="tiposList"
          :loading="loading"
        >
          </v-select
        >
      </v-container>
      <v-divider class="mx-3"></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          outlined
          small
          :disabled="loading"
          @click="$emit('cancelar')"
        >
          Cancelar
        </v-btn>
        <v-btn
          color="primary"
          dark
          small
          :loading="loading"
          :class="{ 'disable-btn': !esValido }"
          @click="modificarTipoCalificacion()"
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { modificarTipoCalificacionService } from "../actividad.service";
export default {
  props: {
    mostrar: Boolean,
    tipoCurso: String,
    idCurso: String,
    idActividad: String,
    tipo: Number,
    tipoCalificacion: String,
    permisoEscritura: { type: Boolean, default: false },
  },
  computed: {
    esValido() {
      return this.tipoSelect != this.tipoCalificacion;
    },
  },
  data() {
    return {
      loading: false,
      tipoSelect: null,
      tiposList: [
        {value: "maxima", text: "Máxima"},
        {value: "promedio", text: "Promedio"}
      ],
    };
  },
  mounted() {
    this.tipoSelect = this.tipoCalificacion;
  },
  methods: {
    async modificarTipoCalificacion() {
      try {
        this.loading = true;
        const data = { tipoCalificacion: this.tipoSelect };
        const serverResponse = await modificarTipoCalificacionService(
          this.tipoCurso,
          this.idCurso,
          this.idActividad,
          data
        );
        this.loading = false;
        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else {
          this.$emit("tipoCalificacionModificada", serverResponse.tipoCalificacion);
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
  },
};
</script>
