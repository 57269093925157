<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <instruccionesComponent
          :idInteractivo="interactivo._id"
          :tipoInteractivo="tipoInteractivo"
          :texto="interactivo.descripcion"
          :permisoEscritura="permisoEscritura"
          @textoModificado="descripcionModificada"
        />
      </v-col>
      <v-col cols="9" sm="12" md="9" lg="9">
        <dtwTextoComponent
          :idInteractivo="interactivo._id"
          :tipoInteractivo="tipoInteractivo"
          :permisoEscritura="permisoEscritura"
          :texto="texto"
          @textoModificado="textoModificado"
          @preview="preview = true"
        />
      </v-col>
      <v-col cols="6" sm="12" md="3" lg="3">
        <dtwDistractoresComponent
          :idInteractivo="interactivo._id"
          :tipoInteractivo="tipoInteractivo"
          :permisoEscritura="permisoEscritura"
          :distractores="distractores"
          @distractorAgregado="distractorAgregado"
          @distractorModificado="distractorModificado"
          @distractorEliminado="distractorEliminado"
        />
      </v-col>
    </v-row>

    <interactivoViewer
      v-if="preview"
      :mostrar="preview"
      :idInteractivo="interactivo._id"
      :tipoInteractivo="tipoInteractivo"
      :nombreInteractivo="interactivo.nombre"
      @cerrar="preview = false"
    />
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import dtwTextoComponent from "./dtwTexto.vue";
import dtwDistractoresComponent from "./dtwDistractores.vue";
import instruccionesComponent from "../instrucciones.vue";
import interactivoViewer from "../interactivoViewer.vue";

export default {
  name: "DragTheWordsView",
  components: {
    dtwTextoComponent,
    dtwDistractoresComponent,
    instruccionesComponent,
    interactivoViewer,
  },
  props: {
    interactivo: { type: Object, default: () => {} },
    tipoInteractivo: { type: String, default: null },
    permisoEscritura: { type: Boolean, default: false },
  },
  computed: {
    ...mapGetters(["sessionToken", "app", "role", "menu"]),
  },

  mounted() {
    this.texto = this.interactivo.texto;
    this.distractores = this.interactivo.distractores;
  },

  data() {
    return {
      loading: true,
      preview: false,
      texto: "",
      distractores: [],
    };
  },
  methods: {
    descripcionModificada(payload) {
      this.$emit("descripcionModificada", payload);
    },
    textoModificado(payload) {
      this.texto = payload.texto;
      this.$emit("logAgregado", payload.log);
    },
    distractorAgregado(payload) {
      this.distractores.push(payload.distractor);
      this.$emit("logAgregado", payload.log);
    },
    distractorModificado(payload) {
      this.distractores[payload.index] = payload.distractor;
      this.$emit("logAgregado", payload.log);
    },
    distractorEliminado(payload) {
      this.distractores.splice(payload.index, 1);
      this.$emit("logAgregado", payload.log);
    },
  },
};
</script>
