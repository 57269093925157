<template>
  <div class="">
    <v-list-item>
      <v-list-item-icon
        @mouseover="showEditButton = true"
        @mouseenter="showEditButton = true"
        @mouseleave="showEditButton = false"
      >
        <v-btn
          v-if="showEditButton && esAdmin"
          fab
          x-small
          color="primary"
          @click="showSelectorPlaneacion = true"
        >
          <v-icon small>mdi-pencil</v-icon>
        </v-btn>
        <v-icon v-else>mdi-file-chart-check-outline</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title v-if="cursoLocal.planeacionAcademica">
          <a
            href="javascript: void(0)"
            @click="
              $router.push(
                `/planeaciones/planeacion/${cursoLocal.planeacionAcademica._id}`
              )
            "
            class="text-decoration-none"
            >{{ nombrePlaneacion }}</a
          >
        </v-list-item-title>
        <v-list-item-title v-else>{{ nombrePlaneacion }}</v-list-item-title>
        <v-list-item-subtitle>Planeación Académica</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>

    <selector-planeaciones
      v-if="showSelectorPlaneacion"
      :show="showSelectorPlaneacion"
      :idCurso="curso._id"
      :tipoCurso="$route.params.tipoCurso"
      :currentPlaneacion="curso.planeacionAcademica"
      @close="showSelectorPlaneacion = false"
      @planeacionSaved="planeacionSaved"
      @planeacionRemoved="planeacionRemoved"
    ></selector-planeaciones>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  components: {
    "selector-planeaciones": () => import("./selectorPlaneacionesCurso.vue"),
  },

  props: {
    curso: Object,
  },

  data() {
    return {
      showEditButton: false,
      showSelectorPlaneacion: false,
      cursoLocal: this.curso,
    };
  },

  watch: {
    curso(v) {
      this.cursoLocal = v;
    },
  },

  computed: {
    ...mapGetters(["role", "app"]),
    esAdmin() {
      return (
        this.app == "campus" ||
        this.role == "admin" ||
        this.role == "profesor" ||
        this.role == "master"
      );
    },

    nombrePlaneacion() {
      if (this.cursoLocal && this.cursoLocal.planeacionAcademica)
        return this.cursoLocal.planeacionAcademica.nombre;
      return "No se ha definido";
    },
  },

  methods: {
    planeacionSaved(planeacion) {
      this.cursoLocal.planeacionAcademica = planeacion;
      this.showSelectorPlaneacion = false;
    },

    planeacionRemoved() {
      this.cursoLocal.planeacionAcademica = null;
      this.showSelectorPlaneacion = false;
    },
  },
};
</script>
