<template>
  <v-dialog v-model="show" persistent width="600">
    <v-card>
      <v-toolbar dense dark flat tile color="primary">
        <v-toolbar-title>
          <span>{{ elemento ? "Editar" : "Nuevo" }} elemento</span>
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text class="mt-4">
        <v-form v-model="isFormValid" ref="form" lazy-validation>
          <v-row dense>
            <v-col v-if="vaAModificar('titulo') && esTexto" cols="12">
              <v-text-field
                v-model="form.titulo"
                label="Título"
                outlined
                dense
                :rules="[$rules.required]"
              ></v-text-field>
            </v-col>
            <v-col
              v-if="vaAModificar('porcentajeVista') && esTexto"
              :cols="elemento ? 12 : 6"
            >
              <v-select
                v-model="form.porcentajeVista"
                label="Tamaño (Porcentaje)"
                :items="porcentajesList"
                outlined
                dense
                append-icon="mdi-percent"
              ></v-select>
            </v-col>
            <v-col
              v-if="vaAModificar('orientacion') && esTexto"
              :cols="elemento ? 12 : 6"
            >
              <v-select
                v-model="form.orientacion"
                label="Orientación"
                :items="orientacionesList"
                item-text="text"
                item-value="value"
                outlined
                dense
                prepend-inner-icon="mdi-phone-rotate-landscape"
              ></v-select>
            </v-col>
            <v-col v-if="!esTexto && vaAModificar('tipoSesion')" cols="12">
              <v-select
                v-model="form.tipoSesion"
                label="Modalidad de la Sesión"
                :items="tiposSesiones"
                item-text="text"
                item-value="value"
                outlined
                dense
                :rules="[$rules.required]"
              ></v-select>
            </v-col>
            <v-col v-if="!esTexto && vaAModificar('sesion')" cols="12" md="6">
              <v-autocomplete
                v-model="form.sesion"
                label="Número de Sesión"
                :items="sesionesList"
                outlined
                dense
                :rules="[$rules.required]"
              ></v-autocomplete>
            </v-col>
            <v-col v-if="!esTexto && vaAModificar('semana')" cols="12" md="6">
              <v-autocomplete
                v-model="form.semana"
                label="Semana"
                :items="semanasList"
                outlined
                dense
                :rules="[$rules.required]"
              ></v-autocomplete>
            </v-col>
            <v-col v-if="vaAModificar('texto')" cols="12">
              <span>{{ esTexto ? "Texto" : "Descripción" }}</span>
              <editor-texto
                ref="editorTexto"
                :texto="form.texto"
                class="mt-2"
              ></editor-texto>
            </v-col>
          </v-row>
        </v-form>
        <div class="mt-3 d-flex justify-end">
          <v-btn
            color="primary"
            outlined
            small
            class="mr-2"
            @click="$emit('close')"
          >
            <span>Cancelar</span>
          </v-btn>
          <v-btn
            color="primary"
            small
            :loading="loading"
            :disabled="!isFormValid"
            @click="submit()"
          >
            <v-icon small class="mr-2">mdi-content-save</v-icon>
            <span>Guardar</span>
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  editarModuloElemento,
  crearModuloElemento,
} from "./planeacionModuloElementos.service";

const arrayFromTo = (min, max) => {
  const v = [];
  for (let i = min; i <= max; i += 1) v.push(i);
  return v;
};

export default {
  components: {
    "editor-texto": () => import("../../../../contenidos/editorTexto.vue"),
  },

  props: {
    show: {
      type: Boolean,
      default: false,
    },
    tipo: {
      type: String,
    },
    elemento: {
      type: Object,
    },
    inputs: {
      type: Array,
      default: () => [],
    },
    idModulo: {
      type: String,
    },
    idPlaneacion: {
      type: String,
    },
  },

  data() {
    return {
      isFormValid: false,
      loading: false,
      form: {
        titulo: "",
        texto: "",
        porcentajeVista: 100,
        orientacion: "vertical",
        tipoSesion: null,
        sesion: null,
        semana: null,
      },
      porcentajesList: [25, 50, 100],
      orientacionesList: [
        {
          text: "Vertical",
          value: "vertical",
        },
        {
          text: "Horizontal",
          value: "horizontal",
        },
      ],
      sesionesList: arrayFromTo(1, 100),
      semanasList: arrayFromTo(1, 52),
      tiposSesiones: [
        {
          value: 1,
          text: "Presencial",
        },
        {
          value: 2,
          text: "Virtual",
        },
        {
          value: 3,
          text: "Mixta",
        },
      ],
    };
  },

  computed: {
    vaAModificar() {
      return function(input) {
        if (!this.elemento) return true;
        return this.inputs.some((i) => i == input);
      };
    },

    /**
     * Campos que solo se toman en cuenta al crear al elemento,
     * no se mandan cuando está editando.
     */
    noModuloFormData() {
      return {
        tipo: this.esTexto ? "texto" : "secuenciaDidactica",
        idModulo: this.$route.params.idModulo || this.idModulo,
        idPlaneacion: this.$route.params.idPlaneacion || this.idPlaneacion,
      };
    },

    esTexto() {
      if (this.elemento) return this.elemento.tipo == "texto";
      else return this.tipo == "texto";
    },

    /**
     * Para definir el body que se mandará conforme
     * al tipo de elemento
     */
    finalForm() {
      return {
        ...(this.esTexto && {
          titulo: this.form.titulo,
          texto: this.form.texto,
          porcentajeVista: this.form.porcentajeVista,
          orientacion: this.form.orientacion,
        }),
        ...(!this.esTexto && {
          tipoSesion: this.form.tipoSesion,
          sesion: this.form.sesion,
          semana: this.form.semana,
        }),
      };
    },
  },

  mounted() {
    if (!!this.elemento) this.setElementoData();
  },

  methods: {
    setElementoData() {
      this.form = {
        texto: this.elemento.texto,
        ...(this.esTexto && {
          titulo: this.elemento.titulo,
          porcentajeVista: this.elemento.porcentajeVista,
        }),
        ...(!this.esTexto && {
          sesion: this.elemento.sesion,
          semana: this.elemento.semana,
          tipoSesion: this.elemento.tipoSesion,
        }),
      };
    },

    async submit() {
      await this.$refs.form.validate();
      if (!this.isFormValid) return;

      const inputData = this.elemento
        ? {}
        : {
            ...this.finalForm,
            ...this.noModuloFormData,
            texto: this.$refs.editorTexto.obtenerTexto(),
          };
      this.inputs.forEach((input) => {
        inputData[input] = this.form[input];
        if (input == "texto")
          inputData.texto = this.$refs.editorTexto.obtenerTexto();
      });

      this.loading = true;

      try {
        const serverResponse = this.elemento
          ? await editarModuloElemento({
              idPlaneacion: this.elemento.planeacion,
              idModulo: this.elemento.modulo,
              idElemento: this.elemento._id,
              data: inputData,
            })
          : await crearModuloElemento(inputData);

        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else this.$emit("elementoSaved", serverResponse.elemento);
      } catch (error) {
        console.error(error);
        this.$appErrorMessage();
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
