<template>
  <div id="asistencias">
    <vc-calendar
    class="custom-calendar max-w-full"
    :masks="masks"
    :attributes="attributes"
    disable-page-swipe
    is-expanded
    >
    <template v-slot:day-content="{ day, attributes }">
        <div class="flex flex-col h-full z-10 overflow-hidden">
        <span class="day-label text-sm text-gray-900">{{ day.day }}</span>
        <span v-if="attributes && attributes.length > 2" class="dot text-center" style="color: white; font-size: small">{{attributes.length}}</span>
        <div class="flex-grow overflow-y-auto overflow-x-auto" style="cursor: pointer">
            <p
            v-for="attr in attributes"
            :key="attr.key"
            :class="attr.customData.class"
            @click="modificarAsistencia(attr)"
            >
            {{ attr.customData.title }}
            </p>
        </div>
        </div>
    </template>
    </vc-calendar>
    <modificarAsistenciaComponent 
        v-if="mostrarModificar == true"
        :mostrar="mostrarModificar"
        :idCurso="idCurso"
        :alumnos="alumnos"
        :asistenciaStatus="asistenciaStatus"
        :asistencia="asistencia"
        :permisoEscritura="permisoEscritura"
        @cerrar="mostrarModificar = false"
        @asistenciaModificada="asistenciaModificada"
        @asistenciaEliminada="asistenciaEliminada"
        @asistenciaFechaModificada="asistenciaFechaModificada"
    />
  </div>
</template>

<script>
import Vue from 'vue';
import VCalendar from 'v-calendar';
import { mapGetters } from "vuex";
import modificarAsistenciaComponent from './modificarAsistencia.vue';
import { DateTime } from "luxon";

Vue.use(VCalendar, {
  componentPrefix: 'vc',
});

export default {
  name: "asistenciasTab",
  props: {
    idCurso: String,
    alumnos: Array,
    asistenciaStatus: { type: Array, default: null },
    asistencias: { type: Array, default: null },
    permisoEscritura: { type: Boolean, default: false },
    coloresAsistencias: { type: Array, default: null },
  },
  components: {
    modificarAsistenciaComponent,
  },
  computed: {
    ...mapGetters(["sesionCursoAlumno", "sesionCursoPadre", "userId", "timezone", "vistaSeguimiento"]),
  },
  watch: {
    asistencias() {
      this.cargarAsistencias();
    },
  },
  created(){
    this.cargarAsistencias();
  },
  data() {
    return {
      loading: false,
      mostrarModificar: false,
      asistencia: Object,
      masks: {
        weekdays: 'WWW',
      },
      attributes: [],
    };
  },
  methods: {
    cargarAsistencias() {
      if(this.sesionCursoAlumno) this.cargarAsistenciasAlumno()
      else this.cargarAsistenciasGrupo()
    },
    cargarAsistenciasGrupo() {
      this.attributes = [];
      this.asistencias.forEach(asistencia => {
        let sumaAsistencias = 0;
        let designClass = '';
        asistencia.asistencia.forEach(asis => {
          if(asis.asistencia == "A" || asis.asistencia == "J" || asis.asistencia == "R") sumaAsistencias += 1
        });
        const promedio = Math.round(100/asistencia.asistencia.length * sumaAsistencias)
        let fecha = DateTime.fromISO(asistencia.cursoAsistencia.fecha, { zone: this.timezone })
        let hora = fecha.c.hour
        hora = hora + ':00'
        fecha = fecha.toISO()
        const leyenda = hora + ' - ' + promedio + '%';
        if(this.coloresAsistencias){
          if(this.coloresAsistencias.length > 0){
            this.coloresAsistencias.sort(this.comparaPorcentajes);
            for (let i = 0; i < this.coloresAsistencias.length; i++) {
              let porcentajeMinimo = this.coloresAsistencias[i].porcentajeMinimo;
              let nextPorcentajeMinimo = 0;
              if(i < this.coloresAsistencias.length - 1) {
                nextPorcentajeMinimo = this.coloresAsistencias[i+1].porcentajeMinimo
              }

              if((promedio >= porcentajeMinimo && promedio < nextPorcentajeMinimo) || (promedio >= porcentajeMinimo && i == this.coloresAsistencias.length - 1) || (promedio < porcentajeMinimo && i == 0)){
                //Determina si las letras van blancas o negras
                const tmpColor = this.coloresAsistencias[i].color.slice(1)
                const aRgbHex = tmpColor.match(/.{1,2}/g);
                const rgb = [ parseInt(aRgbHex[0], 16), parseInt(aRgbHex[1], 16), parseInt(aRgbHex[2], 16)];
                const colorText = this.contraste({r:rgb[0], g:rgb[1], b:rgb[2]});

                const nombreCssClass = `cssClassAsistencias${i}`

                // Crea una clase css
                let style = document.createElement('style');
                style.innerHTML = `.${nombreCssClass} { background: ${this.coloresAsistencias[i].color}; color: ${colorText}; border-radius: 5px !important;};`;
                document.getElementsByTagName('head')[0].appendChild(style);
                designClass = nombreCssClass
              }
            }
          }else{
            designClass = 'sinRegistroColores';
          }
        }else {
          if (promedio > 80) designClass = 'asistencia-mayor';
          else if (promedio > 60) designClass = 'asistencia-intermedia';
          else designClass = 'asistencia-menor';
        }
        let atr = {
          customData: {
            title: leyenda,
            class: designClass,
            asistencia: asistencia
          },
          dates: fecha,
        }
        this.attributes.push(atr);
        this.attributes.sort(function (a, b) {
          if (a.dates > b.dates) return 1;
          if (a.dates < b.dates) return -1;
          return 0;
        });
      });
    },
    cargarAsistenciasAlumno() {
      this.attributes = [];
      this.asistencias.forEach(asistencia => {
        let asistenciaTemp = asistencia.asistencia.find(asis => asis.usuario == this.userId);
        if(asistenciaTemp){
          let fecha = DateTime.fromISO(asistencia.cursoAsistencia.fecha, { zone: this.timezone })
          let hora = fecha.c.hour
          hora = hora + ':00'
          fecha = fecha.toISO()
          
          let asis = '';
          let designClass = '';
          if (asistenciaTemp.asistencia == "A") { designClass = 'asistencia-mayor'; asis = 'Asistencia'; }
          else if (asistenciaTemp.asistencia == "J") { designClass = 'asistencia-intermedia'; asis = 'Justificado'; }
          else if (asistenciaTemp.asistencia == "R") { designClass = 'asistencia-intermedia'; asis = 'Retardo'; }
          else { designClass = 'asistencia-menor'; asis = 'Falta'; }

          const leyenda = hora + ' - ' + asis;
          let atr = {
            customData: {
              title: leyenda,
              class: designClass,
              asistencia: asistencia
            },
            dates: fecha,
          }
          this.attributes.push(atr);
        }
      });
    },
    modificarAsistencia(attr) {
      if (!this.sesionCursoAlumno && !this.sesionCursoPadre && !this.vistaSeguimiento) {
        this.asistencia = attr;
        this.mostrarModificar = true; 
      }
    },
    asistenciaModificada(asistencia){
      this.$emit('asistenciaModificada',asistencia);
      this.mostrarModificar = false;
    },
    asistenciaEliminada(idAsistencia){
      this.$emit('asistenciaEliminada', idAsistencia);
      this.mostrarModificar = false;
    },
    asistenciaFechaModificada(asistencia){
      this.$emit('asistenciaFechaModificada',asistencia);
    },
    comparaPorcentajes(a, b) {
      return a.porcentajeMinimo - b.porcentajeMinimo;
    },
    contraste(rgb) {
      const C = [ rgb.r/255, rgb.g/255, rgb.b/255 ];

      for ( var i = 0; i < C.length; ++i ) 
      {
        if ( C[i] <= 0.03928 ) { C[i] = C[i] / 12.92 } 
        else { C[i] = Math.pow( ( C[i] + 0.055 ) / 1.055, 2.4); }
      }
      const L = 0.2126 * C[0] + 0.7152 * C[1] + 0.0722 * C[2];

      if ( L > 0.179 ) { return "black";} 
      else { return "white"; }
    }
  }
};
</script>

<style lang="postcss" scoped>
/deep/ .dot {
  height: 20px;
  width: 20px;
  background-color: #4296EB;
  border-radius: 50%;
  position: absolute;
  right: 0px !important;
}
/deep/ ::-webkit-scrollbar {
  width: 0px;
}
/deep/ ::-webkit-scrollbar-track {
  display: none;
}
/deep/ .vc-header {
    background-color: #f1f5f8;
    padding: 10px 0;
}
/deep/ .vc-weeks {
    padding: 0;
}
/deep/ .vc-weeks {
  background: #f8fafc !important;
  border: 1px solid #eaeaea !important;
}
/deep/ .vc-weekday {
    background-color: #f8fafc;
    border-bottom: 1px solid #eaeaea;
    border-top: 1px solid #eaeaea;
    padding: 5px 0;
    text-align: center;
    color: #a0aec0;
    font-size: medium;
    font-weight: bold;
    line-height: 14px;
    padding-top: 4px;
    padding-bottom: 8px;
    cursor: default;
    -webkit-user-select: none;
    user-select: none;
}
/deep/ .vc-day {
    padding: 0 5px 3px 5px;
    text-align: left;
    height: 110px;
    min-width: 32px;
    background-color: white;
    border: 1px solid #b8c2cc;
    overflow: scroll;
}
/deep/ .vc-day-dots {
    margin-bottom: 5px;
  }
/deep/ .weekday-1 {
    background-color: #eff8ff;
}
/deep/ .vc-container {
  border-radius: 0;
  width: 100%;
}
.asistencia-mayor {
  background: #4CAF50;
  color: whitesmoke;
  border-radius: 5px !important;
}
.asistencia-intermedia {
  background: #EBD559;
  color: black;
  border-radius: 5px !important;
}
.asistencia-menor {
  background: #A70101;
  color: whitesmoke;
  border-radius: 5px !important;
}
.asistencia-justificada {
  background: #1e88e5;
  color: whitesmoke;
  border-radius: 5px !important;
}
.sinRegistroColores {
  background: #0d3863;
  color: whitesmoke;
  border-radius: 5px !important;
}
</style>