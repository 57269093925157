import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"width":"500","persistent":"","transition":"dialog-bottom-transition"},model:{value:(_vm.mostrar),callback:function ($$v) {_vm.mostrar=$$v},expression:"mostrar"}},[_c(VCard,[_c(VAlert,{staticClass:"pt-6 pb-0",attrs:{"border":"top","colored-border":"","type":"error"}},[_vm._v(" ¿Está seguro de cancelar la confirmación? ")]),_c(VDivider),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","outlined":"","small":""},on:{"click":function($event){return _vm.$emit('cancelar')}}},[_vm._v("Cancelar ")]),_c(VBtn,{attrs:{"color":"danger","small":"","dark":""},on:{"click":function($event){return _vm.$emit('modificarConfirmarAviso')}}},[_vm._v("Aceptar")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }