import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VCard,{attrs:{"outlined":""}},[_c(VToolbar,{attrs:{"dense":"","flat":""}},[_c(VSpacer),_c(VToolbarTitle,{staticClass:"text-h6"},[_vm._v("Descripción")]),_c(VSpacer),_c(VTooltip,{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":"","small":"","color":"primary"},on:{"click":function($event){_vm.edit = true}}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-pencil")])],1)]}}])},[_c('span',[_vm._v("Editar descripción")])])],1),_c(VDivider,{staticClass:"mx-3"}),(_vm.edit)?_c(VCardText,[_c('editorTexto',{ref:"editorTexto",attrs:{"texto":_vm.descripcionLocal}}),_c('div',{staticClass:"d-flex justify-end mt-2"},[_c(VBtn,{staticClass:"mr-2",attrs:{"small":"","color":"primary","outlined":""},on:{"click":function($event){_vm.edit = false}}},[_vm._v("Cancelar")]),_c(VBtn,{attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.save()}}},[_c(VIcon,{staticClass:"mr-2",attrs:{"small":""}},[_vm._v(" mdi-content-save ")]),_c('span',[_vm._v(" Guardar ")])],1)],1)],1):_c(VCardText,[(_vm.descripcionCut)?_c('span',{domProps:{"innerHTML":_vm._s(_vm.descripcionCut)}}):_c(VChip,{attrs:{"label":""}},[_c(VIcon,{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("mdi-information-outline")]),_c('span',[_vm._v("No se ha agregado la descripción de esta planeación.")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }