<template>
  <v-container>
    <v-row no-gutters>
      <v-col cols="12" sm="12" md="4" lg="4">
        <tipoActividadComponent
          :tipoCurso="tipoCurso"
          :idCurso="idCurso"
          :idActividad="idActividad"
          :tipo="tipo"
          :transversal="transversal"
          :permisoEscritura="permisoEscritura"
          @tipoModificado="tipoModificado"
        />
      </v-col>
      <v-col cols="12" sm="12" md="4" lg="4">
        <instrumentoActividadComponent
          :tipo="tipo"
          :tipoCurso="tipoCurso"
          :idCurso="idCurso"
          :idActividad="idActividad"
          :instrumento="instrumento"
          :tipoInstrumento="tipoInstrumento"
          :permisoEscritura="permisoEscritura"
          @instrumentoModificado="instrumentoModificado"
        />
      </v-col>
      <v-col cols="12" sm="12" md="4" lg="4">
        <entregaActividadComponent
          :tipoCurso="tipoCurso"
          :idCurso="idCurso"
          :idActividad="idActividad"
          :tipo="tipo"
          :entrega="entrega"
          :equipos="equipos"
          :permisoEscritura="permisoEscritura"
          :transversal="transversal"
          @entregaModificada="entregaModificada"
        />
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12" sm="12" md="4" lg="4">
        <criterioActividadComponent
          :tipoCurso="tipoCurso"
          :idCurso="idCurso"
          :idActividad="idActividad"
          tipoActividad="actividad"
          :criterioEvaluacion="criterioEvaluacion"
          :permisoEscritura="permisoEscritura"
          @criterioModificado="criterioModificado"
        />
      </v-col>
      <v-col cols="12" sm="12" md="4" lg="4">
        <ponderacionActividadComponent
          :tipoCurso="tipoCurso"
          :idCurso="idCurso"
          :idActividad="idActividad"
          :ponderacion="ponderacion"
          :permisoEscritura="permisoEscritura"
          @ponderacionModificada="ponderacionModificada"
        />
      </v-col>
      <v-col cols="12" sm="12" md="4" lg="4">
        <v-list-item dense>
          <v-list-item-icon
            ><v-icon color="primary">mdi-chart-arc </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Puntos: {{ puntos }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-col>
      <v-col v-if="tipo === 4" cols="12" sm="12" md="4" lg="4">
        <intentosActividadComponent
          :tipoCurso="tipoCurso"
          :idCurso="idCurso"
          :idActividad="idActividad"
          :intentos="intentos"
          :permisoEscritura="permisoEscritura"
          @intentosModificado="intentosModificado"
        />
      </v-col>
      <v-col v-if="tipo === 4" cols="12" sm="12" md="4" lg="4">
        <tipoCalificacionActividadComponent
          v-if="tipo === 4"
          :tipoCurso="tipoCurso"
          :idCurso="idCurso"
          :idActividad="idActividad"
          :tipoCalificacion="tipoCalificacion"
          :permisoEscritura="permisoEscritura"
          @tipoCalificacionModificada="tipoCalificacionModificada"
        />
      </v-col>
      <v-col v-if="tipo === 4 && puedeVerInteractivo" cols="12" sm="12" md="4" lg="4">
        <v-btn color="primary" outlined small @click="verInteractivo = true"
          >Previsualizar</v-btn
        >
        <interactivoViewer
          v-if="tipo === 4"
          :mostrar="verInteractivo"
          :idInteractivo="interactivo._id"
          :tipoInteractivo="tipoInteractivo"
          :nombreInteractivo="interactivo.nombre"
          @cerrar="verInteractivo = false"
        />
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col v-if="tipo === 2 || (transversal && transversal.tipo === 2)" cols="12" sm="12" md="4" lg="4">
        <formatoArchivoActividadComponent
          :tipoCurso="tipoCurso"
          :idCurso="idCurso"
          :idActividad="idActividad"
          :formatoArchivo="formatoArchivo"
          :permisoEscritura="permisoEscritura"
          :transversal="transversal"
          @formatoArchivoModificado="formatoArchivoModificado"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import criterioActividadComponent from "./criterioActividad.vue";
import instrumentoActividadComponent from "./instrumentoActividad.vue";
import tipoActividadComponent from "./tipoActividad.vue";
import entregaActividadComponent from "./entregaActividad.vue";
import ponderacionActividadComponent from "./ponderacionActividad.vue";
import formatoArchivoActividadComponent from "./formatoArchivoActividad.vue";
import intentosActividadComponent from "./intentosActividad.vue";
import tipoCalificacionActividadComponent from "./tipoCalificacionActividad.vue";
import interactivoViewer from "../../../interactivos/interactivoViewer.vue";

export default {
  name: "propiedadesActividad",
  props: {
    tipoCurso: String,
    idCurso: String,
    idActividad: String,
    tipo: Number,
    instrumento: [Object, null],
    tipoInstrumento: String,
    criterioEvaluacion: [Object, null],
    entrega: {
      type: Number,
      validator: function(value) {
        return value >= 1 && value <= 10;
      },
    },
    equipos: Array,
    ponderacion: {
      type: Number,
      validator: function(value) {
        return value >= -1 && value <= 100;
      },
    },
    puntos: Number,
    interactivo: Object || null,
    tipoInteractivo: String || null,
    intentos: Number,
    tipoCalificacion: String,
    formatoArchivo: { type: Array, default: null },
    permisoEscritura: { type: Boolean, default: false },
    transversal: { type: Object, default: null },
  },
  components: {
    criterioActividadComponent,
    instrumentoActividadComponent,
    tipoActividadComponent,
    entregaActividadComponent,
    ponderacionActividadComponent,
    formatoArchivoActividadComponent,
    intentosActividadComponent,
    tipoCalificacionActividadComponent,
    interactivoViewer,
  },
  computed: {
    ...mapGetters(["app", "sesionCursoProfesor"]),
    puedeVerInteractivo(){
      if(this.app != "campus") return true;
      return this.sesionCursoProfesor;
    }
  },
  data() {
    return {
      nombreCriterio: "",
      modificarCriterioIcon: false,
      verInteractivo: false,
    };
  },
  methods: {
    criterioModificado(data) {
      this.$emit("criterioModificado", data);
    },
    ponderacionModificada(data) {
      this.$emit("ponderacionModificada", data);
    },
    tipoModificado(data) {
      this.$emit("tipoModificado", data);
    },
    entregaModificada(data) {
      this.$emit("entregaModificada", data);
    },
    intentosModificado(data) {
      this.$emit("intentosModificado", data);
    },
    tipoCalificacionModificada(data) {
      this.$emit("tipoCalificacionModificada", data);
    },
    instrumentoModificado(data) {
      this.$emit("instrumentoModificado", data);
    },
    formatoArchivoModificado(data) {
      this.$emit("formatoArchivoModificado", data);
    },
  },
};
</script>
