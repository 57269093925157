<template>
  <v-dialog v-model="mostrar" width="80%" :fullscreen="showFullscreen" persistent>
    <v-card>

      <v-toolbar dense color="primary" dark flat>
        <v-btn v-if="!calificacionModificar" :disabled="disableBtnPrev" icon @click="btnPrev">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
        <v-spacer></v-spacer>
          <strong v-if="!calificacionModificar"> Agregar calificación </strong>
          <strong v-else> Modificar calificación </strong>
        <v-spacer></v-spacer>
        <v-btn v-if="!calificacionModificar" :disabled="disableBtnNext" icon @click="btnNext">
          <v-icon>mdi-arrow-right</v-icon>
        </v-btn>
      </v-toolbar>

      <v-row no-gutters>
        
        <v-col :cols="showFullscreen ? 4 : 12">
          <!-- DATOS DE USUARIOS -->
          <div :class="{ 'cardFixed': showFullscreen }">

            <datosUsuariosComponent 
              :loading="loading"
              :alumno="alumno"
              :equipo="equipo"
            />

            <!-- DATOS CALIFICACIÓN -->
            <datosCalificacionComponent
              :loading="loading"
              :instrumento="instrumento"
              :instrumentoCalificacion="calificacionInstrumento.calificacion"
              :calificacionModificar="calificacionModificar"
              :limpiarDatos="alumno || equipo"
              :disableActions="disableActions"
              @cancelar="$emit('cancelar')"
              @agregarCalificacion="agregarCalificacion"
            />

            <!-- DATOS ARCHIVOS -->
            <datosArchivosComponent
              v-if="!showFullscreen"
              :loading="loading"
              :archivos="archivos"
              :disponible="disponible"
              @solicitarRevision="solicitarRevision"
            />

          </div>
        </v-col>

        <v-col v-if="showFullscreen" cols="8">
          <!-- INSTRUMENTO -->
          <datosArchivosComponent
            :loading="loading"
            :archivos="archivos"
            :disponible="disponible"
            @solicitarRevision="solicitarRevision"
          />
        
          <v-container>
            <v-card outlined flat>
              <v-toolbar dense color="#254b72" dark flat style="height: 45px;">
                <strong>Instrumento</strong>
              </v-toolbar>

              <listaCotejoActividadViewComponent
                v-if="tipoInstrumento === 'listaCotejo'"
                :class="{ 'disable-actions': disableActions }"
                :esCalificacion="true"
                :instrumentoActividad="instrumento"
                :selectedCalificacion="calificacionInstrumento.indicadores"
                @calificacionActualizada="calificacionActualizada"
              />

              <rubricaActividadViewComponent 
                v-if="tipoInstrumento === 'rubrica'"
                :class="{ 'disable-actions': disableActions }"
                :esCalificacion="true"
                :instrumentoActividad="instrumento"
                :selectedCalificacion="calificacionInstrumento.indicadores"
                @calificacionActualizada="calificacionActualizada"
              />
            
            </v-card>
          </v-container> 
        </v-col>

      </v-row>

    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import datosUsuariosComponent from "./datosUsuarios.vue";
import datosCalificacionComponent from "./datosCalificacion.vue";
import datosArchivosComponent from "./datosArchivos.vue";

import listaCotejoActividadViewComponent from "../../../../instrumentos/actividad/listaCotejoActividad.view.vue";
import rubricaActividadViewComponent from "../../../../instrumentos/actividad/rubricaActividad.view.vue";

import { modificarCalificacionArchivoService } from "../entregas.service";

export default {
  name: 'agregarCalificacionArchivo',

  props: {
    mostrar: Boolean,
    idCurso: { type: String, default: null },
    idActividad: { type: String, default: null },
    tipoActividad: { type: Number, default: 2 },
    instrumento: { type: String, default: null },
    tipoInstrumento: { type: String, default: null },
    disponible: { type: Object, default: () => {} },
    alumno: { type: Object, default: () => null },
    equipo: { type: Object, default: () => null },

    calificacionModificar: { type: Object, default: () => null },
    ultimoUsuario: { type: Number, default: 1 },
  },

  components: {
    datosCalificacionComponent,
    datosUsuariosComponent,
    datosArchivosComponent,
    listaCotejoActividadViewComponent,
    rubricaActividadViewComponent
  },

  computed: {
    ...mapGetters(["app"]),
    showFullscreen() {
      if (this.instrumento) return true;
      return false;
    },
    archivos() {
      if (this.alumno) return this.alumno.documentos;
      if (this.equipo) return this.equipo.documentos;
      return false;
    },
    disableBtnPrev() {
      if (this.alumno && this.alumno.numero === 1) return true;
      if (this.equipo && this.equipo.numero === 1) return true;  
      return false;
    },
    disableBtnNext() {
      if (this.alumno && this.alumno.numero === this.ultimoUsuario) return true;
      if (this.equipo && this.equipo.numero === this.ultimoUsuario) return true;
      return false;
    },
    disableActions() {
      if (this.alumno && this.alumno.documentos.length == 0) return true; 
      if (this.equipo && this.equipo.documentos.length == 0) return true;
      if (this.archivos[0].revision.calificacion != null && !this.calificacionModificar) return true;

      return false;
    }
  },

  watch: {
    alumno(value) {
      if (value && this.instrumento) this.limpiarCalificacionInstrumento();
    },
    equipo(value) {
      if (value && this.instrumento) this.limpiarCalificacionInstrumento();
    }
  },

  data () {
    return {
      loading: false,

      calificacionInstrumento: {
        calificacion: 0,
        indicadores: []
      },
    }
  },

  created() {
    if (this.instrumento) this.setDataCalificacionInstrumento();
  },

  methods: {
    setDataCalificacionInstrumento() {
      if (this.calificacionModificar) {
        this.calificacionInstrumento.calificacion = this.archivos[0].revision.calificacion;
        this.calificacionInstrumento.indicadores = this.archivos[0].revision.indicadores;
      }
    },
    // Solo aplica en caso de ser instrumento
    calificacionActualizada(data){
      this.calificacionInstrumento.calificacion = data.calificacion;
      this.calificacionInstrumento.indicadores = data.indicadores;
    },
    async agregarCalificacion(data) {
      try {

        const sendData = {
          idDocumento: this.archivos[0]._id,
          calificacion: data.calificacion,
          observaciones: data.observaciones
        };

        if (this.instrumento) sendData.indicadores = this.calificacionInstrumento.indicadores;

        if (this.alumno) sendData.idAlumno = this.alumno._id;
        if (this.equipo) sendData.idEquipo = this.equipo._id;

        this.loading = true;
        const serverResponse = await modificarCalificacionArchivoService(this.idCurso, this.idActividad, sendData);
        this.loading = false;

        if (!serverResponse.ok) {
          this.$systemErrorMessage(serverResponse.mensaje);
        } else {
          this.$emit("calificacionModificada", serverResponse.calificacion); 
        }

      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
    async solicitarRevision (data) {
      try {
        
        const sendData = {
          idDocumento: this.archivos[0]._id,
          reenvio: data.reenvio
        };

        if (this.alumno) sendData.idAlumno = this.alumno._id;
        if (this.equipo) sendData.idEquipo = this.equipo._id;

        this.loading = true;
        const serverResponse = await modificarCalificacionArchivoService(this.idCurso, this.idActividad, sendData);
        this.loading = false;

        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);

        else {
          this.$emit("calificacionModificada", serverResponse.calificacion);
          if(serverResponse.calificacion && serverResponse.calificacion.aviso)
            this.$systemErrorMessage(serverResponse.calificacion.aviso);
        }
        
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
    limpiarCalificacionInstrumento() {
      this.calificacionInstrumento.calificacion = 0;
      this.calificacionInstrumento.indicadores = [];
    },
    btnPrev() {
      let index = 0;
      if (this.alumno) index = this.alumno.numero - 1;
      if (this.equipo) index = this.equipo.numero - 1;
      this.$emit("btnCambio", index);
    },
    btnNext() {
      let index = 0;
      if (this.alumno) index = this.alumno.numero + 1;
      if (this.equipo) index = this.equipo.numero + 1;
      this.$emit("btnCambio", index);
    },
  },


}
</script>

<style>
.disable-actions {
  pointer-events: none;
  opacity: 0.6;
}

.cardFixed {
  position: fixed;
  width: 33%;
}

</style>