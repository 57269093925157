import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VListItem,{on:{"mouseover":function($event){_vm.permisoEscritura && !_vm.vistaSeguimiento ? (_vm.modificarIcon = true) : null},"mouseout":function($event){_vm.permisoEscritura ? (_vm.modificarIcon = false) : null}}},[_c(VListItemIcon,[(_vm.modificarIcon && !_vm.vistaSeguimiento)?_c(VBtn,{attrs:{"fab":"","x-small":"","color":"primary"},on:{"click":function($event){_vm.mostrarEditar = true}}},[_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-pencil")])],1):_c(VIcon,{attrs:{"color":"primary"}},[_vm._v("mdi-account-multiple-outline")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(_vm.maxAlumnos)+" alumnos")]),_c(VListItemSubtitle,[_vm._v("Registro máximo")])],1),_c('editarConfigComponent',{attrs:{"mostrar":_vm.mostrarEditar,"titulo":_vm.titulo,"subtitulo":_vm.subtitulo,"idCurso":_vm.idCurso,"tipoModificacion":_vm.tipoModificacion,"valor":_vm.maxAlumnos},on:{"valorModificado":_vm.valorModificado,"nuevoLog":_vm.nuevoLog,"cancelar":function($event){_vm.mostrarEditar = false}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }