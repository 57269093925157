<template>
  <div>
    <editar-comentario
      v-if="edicionActivada && !vistaSeguimiento && !cursoArchivado"
      :idComentario="esRespuesta ? comentario.comentario : comentario._id"
      :contenidoActual="comentario.contenido"
      :idActividad="comentario.cursoActividad"
      :idClase="idClase"
      :puedeCancelar="true"
      :idRespuestaAEditar="esRespuesta ? comentario._id : null"
      @cancelar="edicionActivada = false"
      @comentarioGuardado="comentarioEditado"
    ></editar-comentario>

    <v-card
      v-else
      outlined
      :loading="loading"
      :color="esRespuesta ? 'grey lighten-3' : null"
      :class="{
        'padding-puntos': esRespuesta && !comentario.editado,
      }"
    >
      <v-card-title
        :class="{
          'py-1': esRespuesta,
        }"
      >
        <v-list-item>
          <v-list-item-avatar v-if="!equipoSeleccionado">
            <v-avatar size="50">
              <v-icon v-if="!comentario.usuario.avatar" size="40"
                >mdi-account</v-icon
              >
              <img
                v-else
                :src="`${imageURL}/avatar/${comentario.usuario.avatar}`"
                alt="UserImage"
              />
            </v-avatar>
          </v-list-item-avatar>

          <v-list-item-content
            v-if="comentario.equipo && (equipoSeleccionado || permisoEscritura) && !cursoArchivado"
            three-line
          >
            <v-list-item-title>
              <span v-if="esComentarioDeUsuario || equipoSeleccionado">
                <v-badge color="success" dot inline>
                  <span>{{ comentario.equipo.nombre }}</span>
                </v-badge>
              </span>
              <span v-else>{{ comentario.equipo.nombre }}</span>
            </v-list-item-title>
            <v-list-item-subtitle style="width: 300px">
              {{ comentario.usuario.nombreCompleto }}
              <span v-if="comentario.equipo.integrantes.length > 1"> y {{ comentario.equipo.integrantes.length - 1 }} más.</span>
            </v-list-item-subtitle>
            <v-list-item-subtitle>
              <span>
                {{ obtenerFecha(comentario.fecha) }} (hace {{ timeAgo }})
              </span>
            </v-list-item-subtitle>
          </v-list-item-content>

          <v-list-item-content v-else>
            <v-list-item-title>
              <span v-if="esComentarioDeUsuario || equipoSeleccionado">
                <v-badge color="success" dot inline>
                  <span>{{ comentario.usuario.nombreCompleto }}</span>
                </v-badge>
              </span>
              <span v-else>{{ comentario.usuario.nombreCompleto }}</span>
            </v-list-item-title>
            <v-list-item-subtitle>
              <span>
                {{ obtenerFecha(comentario.fecha) }} (hace {{ timeAgo }})
              </span>
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action v-if="idActividad">
            <div class="d-flex">

              <cal-foro-item 
                v-if="!esRespuesta && permisoEscritura && !esComentarioDeUsuario && !cursoArchivado"
                :idCurso="idCurso"
                :idActividad="idActividad"
                :instrumento="instrumento"
                :tipoInstrumento="tipoInstrumento"
                :alumno="alumnoSeleccionado"
                :equipo="equipoSeleccionado"
                :calificaciones="calificacionesData"
                :permisoEscritura="permisoEscritura"
                @calificacionAgregada="calificacionAgregada"
                @calificacionModificada="calificacionModificada"
              />


              <v-tooltip
                v-if="
                  esComentarioDeUsuario &&
                  !comentario.editado &&
                  !vistaSeguimiento &&
                  !cursoArchivado
                "
                bottom
              >
                <template #activator="{ on, attrs }">
                  <v-btn
                    v-on="on"
                    v-bind="attrs"
                    small
                    text
                    class="mr-1"
                    @click="edicionActivada = true"
                  >
                    <v-icon small> mdi-pencil </v-icon>
                  </v-btn>
                </template>
                <span>Editar</span>
              </v-tooltip>

              <v-chip
                v-if="
                  (esComentarioDeUsuario || equipoSeleccionado) &&
                  !permisoEscritura &&
                  comentario.puntos &&
                  !esRespuesta
                "
                label
                style="margin-top: -3px; margin-right: 5px"
              >
                <span>{{ comentario.puntos }} Puntos</span>
              </v-chip>

              <puntos-respuesta
                v-if="esRespuesta"
                style="margin-top: -3px; margin-right: 5px"
                :idRespuesta="comentario._id"
                :idUsuario="comentario.usuario._id"
                :idCurso="idCurso"
                :puntos="comentario.puntos"
                :permisoEscritura="permisoEscritura"
                :esRespuestaDeUsuario="
                  esComentarioDeUsuario || esComentarioDeEquipo
                "
              ></puntos-respuesta>

              <v-btn
                class="ml-1"
                small
                :outlined="opinionActual !== 'like'"
                :color="coloresOpinion.like"
                @click="agregarOpinion('like')"
                :disabled="loadingOpinion || vistaSeguimiento || cursoArchivado"
              >
                <v-icon small class="mr-1">mdi-thumb-up</v-icon>
                <span>{{ comentario.likes.length }}</span>
              </v-btn>
              <v-btn
                small
                :outlined="opinionActual !== 'dislike'"
                :color="coloresOpinion.dislike"
                class="ml-1"
                @click="agregarOpinion('dislike')"
                :disabled="loadingOpinion || vistaSeguimiento || cursoArchivado"
              >
                <v-icon small class="mr-1">mdi-thumb-down</v-icon>
                <span>{{ comentario.dislikes.length }}</span>
              </v-btn>
            </div>
          </v-list-item-action>
          <v-list-item-action v-else>
            <div class="d-flex">
              <v-tooltip
                v-if="
                  esComentarioDeUsuario &&
                  !comentario.editado &&
                  !vistaSeguimiento &&
                  !cursoArchivado
                "
                bottom
              >
                <template #activator="{ on, attrs }">
                  <v-btn
                    v-on="on"
                    v-bind="attrs"
                    small
                    text
                    @click="edicionActivada = true"
                  >
                    <v-icon small> mdi-pencil </v-icon>
                  </v-btn>
                </template>
                <span>Editar</span>
              </v-tooltip>

              <v-btn
                small
                :outlined="opinionActual !== 'like'"
                :color="coloresOpinion.like"
                @click="agregarOpinion('like')"
                :disabled="loadingOpinion || vistaSeguimiento || cursoArchivado"
              >
                <v-icon small class="mr-1">mdi-thumb-up</v-icon>
                <span>{{ comentario.likes.length }}</span>
              </v-btn>
              <v-btn
                small
                :outlined="opinionActual !== 'dislike'"
                :color="coloresOpinion.dislike"
                class="ml-1"
                @click="agregarOpinion('dislike')"
                :disabled="loadingOpinion || vistaSeguimiento || cursoArchivado"
              >
                <v-icon small class="mr-1">mdi-thumb-down</v-icon>
                <span>{{ comentario.dislikes.length }}</span>
              </v-btn>
            </div>
          </v-list-item-action>
        </v-list-item>
      </v-card-title>

      <v-card-text :class="{ 'py-1': esRespuesta }">
        <span style="white-space: pre-wrap">{{ comentario.contenido }}</span>
        <div>
          <v-chip
            v-if="comentario.editado"
            color="grey"
            x-small
            dark
            class="mr-2"
          >
            <v-icon x-small class="mr-1">mdi-pencil</v-icon>
            <span>Editado</span>
          </v-chip>
          <v-chip
            v-if="
              (esExtemporaneo && esComentarioDeUsuario) ||
              (esExtemporaneo && permisoEscritura)
            "
            color="warning darken-1"
            x-small
            dark
          >
            <v-icon x-small class="mr-1">mdi-clock-time-eleven</v-icon>
            <span>Entrega extemporánea</span>
          </v-chip>
        </div>

        <div v-if="!esRespuesta" class="mt-2">
          <div v-if="respuestas.length">
            <v-btn
              v-if="showRespuestas"
              small
              text
              color="primary"
              @click="showRespuestas = false"
            >
              <v-icon small class="mr-2">mdi-chevron-down</v-icon>
              <span>Ocultar respuestas</span>
            </v-btn>
            <v-btn
              v-else
              small
              text
              color="primary"
              @click="showRespuestas = true"
            >
              <v-icon small class="mr-2">mdi-chevron-right</v-icon>
              <span>Ver {{ respuestas.length }} respuestas</span>
            </v-btn>
          </div>

          <div v-if="showRespuestas" class="pb-3">
            <comentario
              :esRespuesta="true"
              v-for="respuesta in respuestas"
              :key="respuesta._id"
              :comentario="respuesta"
              :idCurso="idCurso"
              :idActividad="idActividad"
              :idClase="idClase"
              :idEquipo="idEquipo"
              :permisoEscritura="permisoEscritura"
              :cursoArchivado="cursoArchivado"
              class="mt-3"
            ></comentario>
          </div>
        </div>

        <div v-if="!esRespuesta && !vistaSeguimiento && !cursoArchivado" class="mt-3 d-flex">
          <v-menu :close-on-content-click="false" v-model="showCrearRespuesta">
            <template #activator="{ on, attrs }">
              <v-btn v-on="on" v-bind="attrs" color="secondary" small outlined>
                <v-icon small class="mr-2">mdi-message</v-icon>
                <span>Responder</span>
              </v-btn>
            </template>
            <div>
              <agregar-comentario
                :idActividad="idActividad"
                :idClase="idClase"
                :idComentario="comentario._id"
              ></agregar-comentario>
            </div>
          </v-menu>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import Pusher from 'pusher-js';
import { mapGetters } from "vuex";
import { DateTime } from "luxon";

import AgregarComentario from "./AgregarComentario.vue";
import {
  obtenerRespuestasComentario,
  obtenerRespuestasComentarioClase,
  agregarOpinionARespuesta,
} from "./respuestas/comentarioRespuestas.service";
import { agregarOpinionAComentario } from "./comentarios.service";
import calForoItem from "./calForoItem.vue";

export default {
  name: "comentario",

  props: {
    actualizarActividad: { type: String, default: "" },
    idCurso: { type: String, default: null },
    idActividad: { type: String, default: null },
    instrumento: { type: String, default: null },
    tipoInstrumento: { type: String, default: null },
    entrega: { type: Number, default: 1 },
    idClase: { type: String, default: null },
    entregaActividad: { type: Object },

    comentario: { type: Object, required: true },
    calificaciones: { type: Array, default: () => [] },
    esRespuesta: { type: Boolean, default: false },

    permisoEscritura: { type: Boolean, default: false },
    cursoArchivado: { type: Boolean, default: false },
    idEquipo: { type: String, default: null },
  },

  components: {
    "agregar-comentario": AgregarComentario,
    "editar-comentario": () => import("./AgregarComentario.vue"),
    "cal-foro-item": calForoItem,
    "puntos-respuesta": () =>
      import("./respuestas/PuntosComentarioRespuesta.vue"),
  },

  data() {
    return {
      loading: false,
      respuestas: [],
      loadingOpinion: false,
      showRespuestas: false,
      edicionActivada: false,
      showCrearRespuesta: false,
      pusher: null,
    };
  },

  computed: {
    ...mapGetters(["imageURL", "userId", "vistaSeguimiento", "pusherKey", "pusherCluster"]),

    timeAgo() {
      const date = DateTime.fromISO(this.comentario.fecha).toJSDate();
      const seconds = Math.floor((new Date() - date) / 1000);

      let interval = seconds / 31536000;

      if (interval > 1) {
        return Math.floor(interval) + " años";
      }
      interval = seconds / 2592000;
      if (interval > 1) {
        return Math.floor(interval) + " meses";
      }
      interval = seconds / 86400;
      if (interval > 1) {
        return Math.floor(interval) + " días";
      }
      interval = seconds / 3600;
      if (interval > 1) {
        return Math.floor(interval) + " horas";
      }
      interval = seconds / 60;
      if (interval > 1) {
        return Math.floor(interval) + " minutos";
      }
      return Math.floor(seconds) + " segundos";
    },

    opinionActual() {
      const like = this.comentario.likes.find((e) => e.usuario === this.userId);
      const dislike = this.comentario.dislikes.find(
        (e) => e.usuario === this.userId
      );

      return like ? "like" : dislike ? "dislike" : null;
    },

    coloresOpinion() {
      const like = this.comentario.likes.find((e) => e.usuario === this.userId);
      const dislike = this.comentario.dislikes.find(
        (e) => e.usuario === this.userId
      );

      return {
        like: like ? "success darken-2" : "primary",
        dislike: dislike ? "error darken-2" : "primary",
      };
    },

    esComentarioDeUsuario() {
      return this.comentario.usuario._id == this.userId;
    },

    esComentarioDeEquipo() {
      if (this.equipoSeleccionado) return true;
      return false;
    },

    esExtemporaneo() {
      if (!this.entregaActividad || this.entregaActividad.abierta) return false;

      const fechaEntrega = new Date(this.comentario.fecha).getTime();
      const fechaInicio = new Date(this.entregaActividad.inicio).getTime();
      const fechaFin = new Date(this.entregaActividad.fin).getTime();

      return fechaEntrega < fechaInicio || fechaEntrega > fechaFin;
    },

    alumnoSeleccionado() {
      if (this.entrega === 1 && this.comentario) return this.comentario.usuario;
      return null;
    },
    equipoSeleccionado() {
      if (!this.comentario.equipo) return null;
      
      return this.comentario.equipo;
      
    },
    calificacionesData() {
      if (this.comentario) {
        if (this.entrega === 1) 
          return this.calificaciones.filter((c) => c.usuario == this.comentario.usuario._id);
        if (this.entrega > 1)
          return this.calificaciones.filter((c) => c.equipo == this.comentario.equipo._id);
      }
      return [];
    }
  },

  watch: {
    calificaciones(value) {
      this.calificaciones = value;
    },

    actualizarActividad() {
      if (!this.esRespuesta) {
        this.loadRespuestas();
      }
    },
  },

  async created() {
    try {
      
      this.pusher = new Pusher(this.pusherKey, { cluster: this.pusherCluster, encrypted: true });

      const nameChannel = this.idActividad ? this.idActividad : this.idClase;

      this.pusher.subscribe(`${nameChannel}`);
      this.pusher.bind('answer-message', data => {
        this.actualizarRespuestas(data.respuesta);
      });
      
    } catch (error) {
      console.log(error);
      this.loading = false;
      this.$appErrorMessage();
    }
  },

  beforeDestroy() {
    const nameChannel = this.idActividad ? this.idActividad : this.idClase;
    this.pusher.unsubscribe(`${nameChannel}`);
  },

  mounted() {
    if (!this.esRespuesta) {
      this.loadRespuestas();
    }
  },

  methods: {

    obtenerFecha(fecha) {
      return DateTime.fromISO(fecha).toLocaleString(DateTime.DATETIME_MED);
    },

    async loadRespuestas() {
      this.loading = true;

      try {
        const serverResponse = this.idActividad
          ? await obtenerRespuestasComentario({
              idComentario: this.comentario._id,
              idActividad: this.comentario.cursoActividad,
              idCurso: this.$route.params.idCurso,
            })
          : await obtenerRespuestasComentarioClase({
              idClase: this.idClase,
              idComentario: this.comentario._id,
              idCurso: this.idCurso,
            });
        this.loading = false;

        this.$validateResponse(serverResponse);
        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else this.respuestas = serverResponse.respuestas;
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },

    async agregarOpinion(tipoOpinion) {
      this.loadingOpinion = true;

      if (this.opinionActual == tipoOpinion) {
        this.loadingOpinion = false;
        return;
      }

      const data = {};
     if (this.idEquipo) data.idEquipo = this.idEquipo;

      try {
        const serverResponse = this.esRespuesta
          ? await agregarOpinionARespuesta({
              idRespuesta: this.comentario._id,
              idComentario: this.comentario.comentario,
              idClase: this.idClase,
              idActividad: this.idActividad,
              idCurso: this.idCurso,
              tipoOpinion,
              data
            })
          : await agregarOpinionAComentario({
              idComentario: this.comentario._id,
              idActividad: this.idActividad,
              idClase: this.idClase,
              idCurso: this.idCurso,
              tipoOpinion,
              data
            });
        this.loadingOpinion = false;

        this.$validateResponse(serverResponse);
        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else {
          if (this.opinionActual) {
            if (this.opinionActual == "like")
              this.comentario.likes = this.comentario.likes.filter(
                (e) => e.usuario !== this.userId
              );
            else
              this.comentario.dislikes = this.comentario.dislikes.filter(
                (e) => e.usuario !== this.userId
              );
          }

          if (tipoOpinion == "like")
            this.comentario.likes.push(serverResponse.opinion);
          else this.comentario.dislikes.push(serverResponse.opinion);
        }
      } catch (error) {
        console.error(error);
        this.loadingOpinion = false;
        this.$appErrorMessage();
      }
    },

    comentarioEditado() {
      this.edicionActivada = false;
    },

    actualizarRespuestas(respuesta) {
      if (respuesta.comentario == this.comentario._id) {
        const indexRespuesta = this.respuestas.findIndex((r) => r._id === respuesta._id);
        if (indexRespuesta === -1) this.respuestas.push(respuesta);
        else this.respuestas.splice(indexRespuesta, 1, respuesta);
      } 

      this.showCrearRespuesta = false;
    },
    calificacionAgregada(calificacion) {
      this.$emit("calificacionAgregada", calificacion);
    },
    calificacionModificada(calificacion) {
      this.$emit("calificacionModificada", calificacion);
    }
  },
};
</script>

<style scoped>
.v-menu__content {
  width: 100% !important;
}

.cal-directa-item {
  position: absolute;
  bottom: -5px;
  right: 70px;
}

.cal-directa-item.tiene-calificacion {
  bottom: 0px;
  right: 130px;
}

.cal-directa-item.es-equipo {
  bottom: 10px;
  right: 130px;
}
</style>
