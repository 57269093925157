<template>
  <div>
    <datatable
      :headers="headers"
      :pagination="pagination"
      :datos="alumnosLocal"
      :itemsPerPage="alumnosLocal.length"
      :totalItems="alumnosLocal.length"
      hideSearch
      hidePagination
    >
      <template #item-avatar="{ value }">
        <v-avatar color="primary" size="30">
          <v-icon color="white" v-if="!value">mdi-account</v-icon>
          <img v-else :src="`${imageURL}/avatar/${value}`" alt="UserImage" />
        </v-avatar>
      </template>

      <template #item-calificacion="{ item }">
        <div v-if="item.calificaciones && item.calificaciones.length">
          <v-chip label class="mr-3">
            <span class="text-button">{{
              parseInt(item.calificaciones[0].calificacion)
            }}</span>
          </v-chip>
          <v-btn small color="primary" @click="openDetalles(item)">
            <span>Ver intentos</span>
          </v-btn>
        </div>
        <div v-else>
          <v-chip color="amber darken-2" dark label>
            <v-icon small class="mr-2">mdi-alert</v-icon>
            <span class="text-button">Sin presentar</span>
          </v-chip>
        </div>
      </template>
    </datatable>
    <detalles-calificacion
      v-if="showDetalles"
      :show="showDetalles"
      :calificacion="calificacionSeleccionada"
      @cancelar="closeDetalles()"
    ></detalles-calificacion>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import datatable from "../../widgets/datatable.vue";
import { ordenarObjetosAsc, ordenarObjetosDesc } from "../../../helpers/ordenador";

export default {
  name: "calificacionAlumnosAplicacion",
  props: {
    idCurso: { type: String || null, default: null },
    idActividad: { type: String || null, default: null },
    tipoActividad: { type: Number, default: 1 },
    instrumento: { type: String, default: null },
    tipoInstrumento: { type: String, default: null },
    alumnos: { type: Array, default: () => [] },
    calificaciones: { type: Array, default: () => [] },
    permisoEscritura: { type: Boolean, default: false },
  },
  components: {
    datatable,
    "detalles-calificacion": () =>
      import("./detallesCalificacionAplicacion.vue"),
  },
  computed: {
    ...mapGetters(["imageURL"]),
    alumnosLocal() {
      const alumnosResponse = [];
      let numero = 1;
      for (const alumno of this.alumnos) {
        let alumnoItem = { numero: numero++, ...alumno };
        let calificaciones = this.calificaciones.filter(
          (x) => x.usuario == alumnoItem._id
        );
        alumnoItem.resultados = calificaciones.length
          ? calificaciones[0].resultados
          : [];
        alumnoItem.calificaciones = ordenarObjetosDesc(calificaciones, "fecha");
        alumnosResponse.push(alumnoItem);
      }
      return ordenarObjetosAsc(alumnosResponse, "nombreCompleto");
    },
  },
  data() {
    return {
      loading: false,
      headers: [
        {
          text: "",
          value: "numero",
          sortable: false,
          filterable: false,
          width: "30px",
        },
        {
          text: "",
          value: "avatar",
          sortable: false,
          filterable: false,
          width: "50px",
        },
        {
          text: "Nombre",
          value: "nombreCompleto",
          sortable: false,
          filterable: false,
        },
        {
          text: "",
          value: "calificacion",
          sortable: false,
          filterable: false,
          // width: "200px"
        },
      ],
      pagination: {},
      showDetalles: false,
      calificacionSeleccionada: null,
    };
  },
  methods: {
    calificacionAgregada(calificacion) {
      this.$emit("calificacionAgregada", calificacion);
    },
    calificacionModificada(calificacion) {
      this.$emit("calificacionModificada", calificacion);
    },
    openDetalles(item) {
      this.calificacionSeleccionada = item;
      this.showDetalles = true;
    },
    closeDetalles() {
      this.showDetalles = false;
      this.calificacionSeleccionada = false;
    },
  },
};
</script>
