import { getServerRequest } from "../../services/serverRequest.service";
import { store } from "@/store/store";
const httpURL = store.getters.httpURL;

export const getAvisosMasterService = async() => {
  const token = store.getters.sessionToken;
  const config = { headers: { token } };
  const url = `${httpURL}/avisosMaster`;
  return await getServerRequest(url, config);
}

export const getAvisosEscuelaService = async() => {
  const token = store.getters.sessionToken;
  const config = { headers: { token } };
  const url = `${httpURL}/avisosEscuela`;
  return await getServerRequest(url, config);
}

export const getActividadesPendientesService = async () => {
  const token = store.getters.sessionToken;
  const config = { headers: { token } };
  const url = `${httpURL}/actividadesPendientes`;
  return await getServerRequest(url, config);
}