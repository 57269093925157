const actividadDirectaIcon = 'mdi-checkbox-outline';
const actividadArchivoIcon = 'mdi-file-upload-outline';
const actividadForoIcon = 'mdi-comment-text-multiple-outline';
const actividadInteractivaIcon = 'mdi-puzzle-outline';
const actividadEncuestaIcon = 'mdi-clipboard-list-outline';
const actividadTransversalIcon = 'mdi-axis-arrow';
const examenIcon = 'mdi-format-list-checks';

const listaCotejoIcon = 'mdi-list-status';
const rubricaIcon = 'mdi-table-check';

const agregarContenidoIcon = 'mdi-text-box-plus-outline';


export default {
    actividadDirectaIcon,
    actividadArchivoIcon,
    actividadForoIcon,
    actividadInteractivaIcon,
    actividadEncuestaIcon,
    examenIcon,
    listaCotejoIcon,
    rubricaIcon,
    agregarContenidoIcon,
    actividadTransversalIcon
}