<template>
  <v-card outlined>
    <v-card-title
      >Bloques de texto
      <v-spacer />
      <v-btn
        v-if="bloques.length > 0"
        color="primary"
        small
        icon
        @click="$emit('preview')"
      >
        <v-icon>mdi-motion-play-outline</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text v-if="bloques.length === 0"
      ><i>No se han generado bloques de texto</i></v-card-text
    >
    <template v-else>
      <v-container>
        <fillblanksBloqueTexto
          v-for="(bloque, index) in bloquesLocal"
          :key="`bloque-${index}`"
          :bloque="bloque"
          :index="index"
          :idInteractivo="idInteractivo"
          :tipoInteractivo="tipoInteractivo"
          :permisoEscritura="permisoEscritura"
          :modificandoBloque="modificandoBloque"
          @modificandoBloque="setModificandoBloque"
          @bloqueModificado="bloqueModificado"
          @bloqueTextoEliminado="bloqueTextoEliminado"
        />
      </v-container>
    </template>
    <v-divider class="mx-3"></v-divider>
    <v-container>
      <v-row>
        <v-col v-if="permisoEscritura" cols="12">
          <v-spacer />
          <v-btn
            color="primary"
            small
            :disabled="modificandoBloque"
            :loading="loading"
            @click="agregarBloque()"
            >Agregar bloque</v-btn
          >
          <v-spacer />
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import fillblanksBloqueTexto from "./fillblanksBloqueTexto.vue";
import { agregarFillblanksBloqueService } from "./fillblanks.service.js";

export default {
  components: {
    fillblanksBloqueTexto,
  },
  props: {
    idInteractivo: { type: String, default: null },
    tipoInteractivo: { type: String, default: null },
    permisoEscritura: { type: Boolean, default: false },
    texto: { type: String, default: null },
    bloques: Array,
  },
  computed: {
    ...mapGetters(["sessionToken", "app", "role", "menu"]),
    bloquesLocal() {
      const bloques = this.bloques.map((bloque) => {
        return {
          texto: bloque,
          textoValido: this.textoValido(bloque),
        };
      });
      return bloques;
    },
  },

  data() {
    return {
      loading: false,
      modificandoBloque: false,
    };
  },
  methods: {
    textoValido(texto) {
      const regex = /\*\w+\*/;
      return regex.test(texto);
    },
    async agregarBloque() {
      try {
        this.loading = true;
        const serverResponse = await agregarFillblanksBloqueService(
          this.idInteractivo
        );
        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else {
          const emitData = {
            log: serverResponse.nuevoLog,
          };
          this.$emit("bloqueAgregado", emitData);
        }
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },

    setModificandoBloque(value) {
      this.modificandoBloque = value;
    },
    bloqueModificado(payload){
      this.$emit('bloqueModificado', payload);
    },
    bloqueTextoEliminado(payload){
      this.$emit('bloqueTextoEliminado', payload);
    }
  },
};
</script>
