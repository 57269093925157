<template>
  <v-dialog v-model="mostrar" :max-width="500" persistent>
    <v-card :loading="loading">
      <v-toolbar color="danger" dark dense flat class="text-h6">
        Eliminar párrafo
      </v-toolbar>

      <v-container grid-list-md>
        <b>¿Está seguro de elimiar el párrafo?</b>
        <br>
        <i>Esta acción no podrá ser revertida.</i>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            outlined
            small
            :disabled="loading"
            @click="$emit('cancelar')"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="red"
            dark
            small
            :loading="loading"
            @click="eliminarParrafo()"
          >
            Eliminar
          </v-btn>
        </v-card-actions>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { eliminarSortparagraphsParrafoService } from "./sortparagraphs.service.js";

export default {
  props: {
    mostrar: { type: Boolean, default: false },
    idInteractivo: { type: String, default: null },
    tipoInteractivo: { type: String, default: null },
    index: { type: Number },
  },
  data: () => ({
    loading: false,
  }),
  created() {},
  methods: {
    async eliminarParrafo() {
      try {
        this.loading = true;
        const serverResponse = await eliminarSortparagraphsParrafoService(
          this.idInteractivo,
          this.index
        );
        this.loading = false;

        if (!serverResponse.ok) {
          this.$systemErrorMessage(serverResponse.mensaje);
        } else {
          this.$emit("parrafoEliminado", {
            index: this.index,
            log: serverResponse.nuevoLog,
          });
          this.$emit("cancelar");
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },

    cerrarForm() {
      this.$emit("cancelar");
    },
  },
};
</script>

<style scoped>
.formularioClass {
  padding: 15px;
  padding-bottom: 0;
}
</style>
