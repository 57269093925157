import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VChip } from 'vuetify/lib/components/VChip';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('datatable',{attrs:{"datos":_vm.alumnos,"pagination":{ page: 1, itemsPerPage: _vm.alumnos.length },"page":1,"totalItems":_vm.alumnos.length,"headers":_vm.tableHeaders,"hide-search":"","hide-pagination":""},scopedSlots:_vm._u([{key:"item-avatar",fn:function({ value }){return [_c(VAvatar,{attrs:{"color":"primary","size":"30"}},[(!value)?_c(VIcon,{attrs:{"color":"white"}},[_vm._v("mdi-account")]):_c('img',{attrs:{"src":`${_vm.imageURL}/avatar/${value}`,"alt":"UserImage"}})],1)]}},{key:"item-nombreCompleto",fn:function({ item }){return [_c(VListItem,[_c(VListItemContent,[_c(VListItemTitle,[_vm._v(" "+_vm._s(item.nombreCompleto)+" "),(item.bloqueado && !_vm.sesionCursoAlumno)?_c(VChip,{attrs:{"color":"red darken-2","x-small":"","outlined":""}},[_vm._v("Bloqueado")]):_vm._e()],1),(!_vm.sesionCursoAlumno)?_c(VListItemSubtitle,[_vm._v(_vm._s(item.correo))]):_vm._e()],1)],1)]}},{key:"item-menu",fn:function({ item }){return [((_vm.sesionCursoAlumno && _vm.userId == item._id && !_vm.cursoEscuela && _vm.permisoEscritura) || (!_vm.sesionCursoAlumno && !_vm.vistaSeguimiento && _vm.permisoEscritura))?_c('integranteMenu',{attrs:{"bloqueado":item.bloqueado,"idCurso":_vm.idCurso,"idIntegrante":item._id,"nombreIntegrante":item.nombreCompleto,"cursoEscuela":_vm.cursoEscuela,"permisoEscritura":_vm.permisoEscritura},on:{"blockUsuario":_vm.blockUsuario,"integranteEliminado":_vm.eliminarIntegrante}}):_vm._e()]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }