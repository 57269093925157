<template>
  <datatable
    :loading="loading"
    :datos="actividadesLocal"
    :pagination="pagination"
    :headers="headers"
    :totalItems="actividadesLocal.length"
    hidePagination
    hideSearch
  >
    <template #item-actividad="{ item }">
      <actividadAlumnoItemComponent
        v-if="sesionCursoAlumno || sesionCursoPadre"
        :idCurso="idCurso"
        :tipoCurso="tipoCurso"
        tipoModulo="modulo"
        :actividad="item"
        :avanceAutomatico="avanceAutomatico"
        ocultarCriterio
        ocultarIcono
      />
      <actividadItemComponent
        v-else
        :idCurso="idCurso"
        :tipoCurso="tipoCurso"
        tipoModulo="modulo"
        :actividad="item"
        ocultarCriterio
        ocultarIcono
      />
    </template>

    <template #item-calificacion="{ item }">
      <span>{{ item.calificacion }}</span>
    </template>

    <template #item-modulo="{ value }">
      <span v-if="value">{{ value.nombre }}</span>
      <span v-else><i>No definido</i></span>
    </template>

    <template #item-criterio="{ value }">
      <v-list-item v-if="value" dense style="padding-left: 0px">
        <v-list-item-content>
          <v-list-item-title>{{ value.nombre }}</v-list-item-title>
          <v-list-item-subtitle
            ><i>Puntos: {{ value.puntos }}</i></v-list-item-subtitle
          >
        </v-list-item-content>
      </v-list-item>
      <span v-else><i>No definido</i></span>
    </template>

    <template #item-ponderacion="{ value }">
      <span v-if="value == -1">Promedio</span>
      <span v-else>{{ value }}%</span>
    </template>
  </datatable>
</template>

<script>
import { mapGetters } from "vuex";
import actividadItemComponent from "../modulos/actividades/actividadItem.vue";
import actividadAlumnoItemComponent from "../modulos/actividades/actividadAlumnoItem.vue";
import datatable from "../../widgets/datatable.vue";

export default {
  name: "actividadesTabla",
  components: {
    actividadItemComponent,
    actividadAlumnoItemComponent,
    datatable,
  },
  props: {
    idCurso: String,
    tipoCurso: String,
    actividades: { type: Array, default: () => [] },
    avanceAutomatico: Boolean,
  },
  computed: {
    ...mapGetters(["sessionToken", "app", "sesionCursoAlumno", "sesionCursoPadre"]),
    actividadesLocal() {
      const actividades = this.actividades.map((x) => {
        let actividad = { ...x };
        actividad.modulo = x.cursoModulo || x.cursoPlantillaModulo;
        actividad.calificacion = '';
        if(x.calificacionAlumno) {
          if(x.tipo == 3){
            let caliTmp = x.calificacionAlumno.calificacion + x.calificacionAlumno.puntosExtra;
            if(caliTmp > 100) actividad.calificacion = 100
            else if(caliTmp < 0) actividad.calificacion = 0
            else actividad.calificacion = caliTmp
          }
          else actividad.calificacion = x.calificacionAlumno.calificacion;
        }
        if(x.documentoAlumno && x.documentoAlumno.revision){
          actividad.calificacion = x.documentoAlumno.revision.calificacion ? x.documentoAlumno.revision.calificacion : ''
        }
        if(x.documentoAnterior && x.documentoAnterior.revision){
          actividad.calificacion = x.documentoAnterior.revision.calificacion ? x.documentoAnterior.revision.calificacion : ''
        }
        if(x.calificacionPromedio != null) actividad.calificacion = x.calificacionPromedio;
        if(x.tipo == 2 && x.documentos && x.documentos.length > 0) {
          let sum = 0;
          x.documentos.map((doc) => {
            sum += doc.revision.calificacion;
          });
          actividad.calificacion = Math.round(sum / x.documentos.length);

        }
        return actividad;
      });
      return actividades;
    },
  },
  data() {
    return {
      loading: false,
      pagination: {},
      headers: [
        {
          text: "Actividad",
          value: "actividad",
          sortable: false,
          filterable: false,
        },
        {
          text: "Promedio",
          value: "calificacion",
          sortable: false,
          filterable: false,
        },
        {
          text: "Módulo",
          value: "modulo",
          sortable: false,
          filterable: false,
        },
        {
          text: "Criterio",
          value: "criterio",
          sortable: false,
          filterable: false,
        },
        {
          text: "Ponderación",
          value: "ponderacion",
          sortable: false,
          filterable: false,
        },
        {
          text: "Puntos",
          value: "puntos",
          sortable: false,
          filterable: false,
        },
      ],
    };
  },
  created() {
    if(this.sesionCursoAlumno || this.sesionCursoPadre) this.headers[1].text = 'Calificación';
  },
  watch: {},
  methods: {},
};
</script>
