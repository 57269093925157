import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VCard,{class:{ 'grey lighten-4': _vm.arrastradoSobre },attrs:{"outlined":"","loading":_vm.loading,"disabled":_vm.loading},on:{"drop":function($event){$event.preventDefault();return _vm.elementoAgregado($event)},"dragover":function($event){$event.preventDefault();_vm.arrastradoSobre = true},"dragenter":function($event){$event.preventDefault();_vm.arrastradoSobre = true},"dragleave":function($event){$event.preventDefault();_vm.arrastradoSobre = false}}},[_c(VCardText,[_c(VRow,{staticClass:"d-flex flex-column",attrs:{"dense":"","align":"center","justify":"center"}},[_c(VIcon,{staticClass:"mt-5",attrs:{"size":"60","color":_vm.arrastradoSobre ? 'primary' : null}},[_vm._v(" mdi-cloud-upload ")]),_c('span',[_c('font',{attrs:{"color":_vm.arrastradoSobre ? 'primary' : null}},[_vm._v(" Arrastra aquí tu archivo ")])],1),_c('span',{staticClass:"caption text--secondary"},[_vm._v(" tamaño máximo permitido ("+_vm._s(_vm.tamanoMaximo / 1000)+"kb) ")]),_c('span',{staticClass:"caption text--secondary"},[_vm._v(" Tipos de archivo permitidos ("+_vm._s(_vm.tiposAceptados.join(" "))+") ")]),_c(VFileInput,{staticClass:"mt-5",staticStyle:{"width":"30%"},attrs:{"rules":_vm.reglasArchivo,"filled":"","dense":"","color":"primary","prepend-inner-icon":"mdi-image-outline","prepend-icon":null,"accept":_vm.tiposAceptados.join(','),"label":_vm.loading ? 'Cargando el archivo...' : 'Buscar un archivo'},on:{"change":function($event){return _vm.archivoSeleccionado($event)}}}),(_vm.loading)?_c('span',[_vm._v(" Subiendo imagen: "+_vm._s(_vm.uploadProgress)+"% ")]):_vm._e()],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }