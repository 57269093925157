<template>
  <v-card outlined>
    <v-container>
      <v-radio-group
        v-model="modoCalificacionLocal"
        column
        :disabled="loading"
        @change="modificarPropiedad('modoCalificacion')"
      >
        <v-radio label="Calificar con base a párrafos correctos" value="positions"></v-radio>
        <v-radio
          label="Calificar con base a la secuencia"
          value="transitions"
        ></v-radio>
      </v-radio-group>
      <v-checkbox
        v-model="penalizacionesLocal"
        :disabled="loading"
        label="Aplicar penalizaciones (Se descuenta un punto por cada párrafo incorrecto)"
        @change="modificarPropiedad('penalizaciones')"
      ></v-checkbox>
    </v-container>
  </v-card>
</template>

<script>
import { modificarInteractivoService } from '../interactivos.service';

export default {
  props: {
    idInteractivo: { type: String },
    tipoInteractivo: { type: String, default: null },
    permisoEscritura: { type: Boolean, default: false },
    modoCalificacion: { type: String, default: "positions" },
    penalizaciones: { type: Boolean, default: false },
  },

  mounted() {
    this.modoCalificacionLocal = this.modoCalificacion;
    this.penalizacionesLocal = this.penalizaciones;
  },
  data() {
    return {
      loading: false,
      modoCalificacionLocal: "positions",
      penalizacionesLocal: false,
    };
  },
  methods: {
    async modificarPropiedad(campo) {
      this.loading = true;
      try {
        const value =
          campo === "modoCalificacion"
            ? this.modoCalificacionLocal
            : this.penalizacionesLocal;

        const serverResponse = await modificarInteractivoService(
          this.tipoInteractivo,
          value,
          this.idInteractivo,
          campo
        );
        this.loading = false;
        this.$validateResponse(serverResponse);
        if (!serverResponse.ok) {
          this.$systemErrorMessage(serverResponse.mensaje);
          return;
        } else {
          this.$emit(`${campo}Modificado`, {
            value,
            nuevoLog: serverResponse.nuevoLog,
          });
          this.modificar = false;
        }
      } catch (error) {
        console.log(error);
        this.loading = false;
        this.$appErrorMessage();
      }
    },
  }
}
</script>