import { VAlert } from 'vuetify/lib/components/VAlert';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"width":"40%","fullscreen":_vm.instrumento?true:false,"persistent":""},model:{value:(_vm.mostrar),callback:function ($$v) {_vm.mostrar=$$v},expression:"mostrar"}},[_c(VCard,[_c(VToolbar,{staticClass:"text-h6",attrs:{"dense":"","color":"primary","dark":"","flat":""}},[_c('strong',[_vm._v("Calificación general")])]),_c(VContainer,{staticClass:"pb-0",attrs:{"fluid":""}},[(_vm.alertMessage)?_c(VAlert,{staticClass:"text-center",attrs:{"color":"orange lighten-1","dense":"","dark":""}},[_vm._v(" "+_vm._s(_vm.alertMessage)+" ")]):_vm._e()],1),_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,{attrs:{"cols":_vm.showFullscreen ? 4 : 12}},[_c('datosCalificacionComponent',{attrs:{"loading":_vm.loading,"instrumento":_vm.instrumento,"esInteractivo":_vm.esInteractivo,"instrumentoCalificacion":_vm.calificacionLocal},on:{"cancelar":function($event){return _vm.$emit('cancelar')},"agregarCalificacion":_vm.agregarCalificacion}})],1),(_vm.showFullscreen)?_c(VCol,{staticClass:"cardLeft",attrs:{"cols":"8"}},[_c(VContainer,{staticClass:"cardLeft",staticStyle:{"padding-top":"0px"}},[_c(VCard,{attrs:{"outlined":""}},[_c(VToolbar,{staticStyle:{"height":"45px"},attrs:{"dense":"","color":"#254b72","dark":"","flat":""}},[_c('strong',[_vm._v("Instrumento")])]),(_vm.tipoInstrumento === 'listaCotejo')?_c('listaCotejoActividadViewComponent',{attrs:{"esCalificacion":true,"instrumentoActividad":_vm.instrumento,"selectedCalificacion":_vm.indicadores},on:{"calificacionActualizada":_vm.calificacionActualizada}}):_vm._e(),(_vm.tipoInstrumento === 'rubrica')?_c('rubricaActividadViewComponent',{attrs:{"esCalificacion":true,"instrumentoActividad":_vm.instrumento,"selectedCalificacion":_vm.indicadores},on:{"calificacionActualizada":_vm.calificacionActualizada}}):_vm._e()],1)],1)],1):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }