<template>
  <div>
    <v-card elevation="0">
      <v-card-text>
        <div class="d-flex">
          <v-avatar size="50">
            <v-icon v-if="!userAvatar" size="40">mdi-account</v-icon>
            <img
              v-else
              :src="`${imageURL}/avatar/${userAvatar}`"
              alt="UserImage"
            />
          </v-avatar>
          <v-textarea
            v-model="comentario.contenido"
            :label="labelTextBox"
            outlined
            rows="3"
            class="ml-2"
            maxLength="600"
            counter="600"
          ></v-textarea>
        </div>
        <div class="d-flex">
          <v-spacer></v-spacer>
          <v-btn
            v-if="puedeCancelar"
            outlined
            small
            :disabled="loading"
            @click="$emit('cancelar')"
            class="mr-1"
          >
            <span>Cancelar</span>
          </v-btn>
          <v-btn
            color="primary"
            small
            :loading="loading"
            :disabled="!comentario.contenido"
            @click="guardarComentario()"
          >
            <v-icon small class="mr-2">mdi-content-save</v-icon>
            <span>Guardar</span>
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import { agregarComentario, editarComentario } from "./comentarios.service";
import {
  agregarRespuestaComentario,
  editarRespuesta,
} from "./respuestas/comentarioRespuestas.service";

export default {
  name: 'agregarComentario',
  props: {
    idActividad: {
      type: String,
    },
    idClase: {
      type: String,
    },
    idComentario: {
      type: String,
    },
    idRespuestaAEditar: {
      type: String,
    },
    contenidoActual: {
      type: String,
    },
    puedeCancelar: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      loading: false,

      comentario: {
        contenido: "",
      },
    };
  },

  computed: {
    ...mapGetters(["userAvatar", "imageURL"]),

    labelTextBox() {
      if (this.contenidoActual && this.idRespuestaAEditar)
        return "Editar respuesta";
      if (this.contenidoActual) return "Editar comentario";
      if (this.idComentario) return "Nueva respuesta";
      return "Nuevo comentario";
    },

    serviceSeleccionado() {
      if (this.contenidoActual && this.idRespuestaAEditar)
        return editarRespuesta;
      if (this.contenidoActual) return editarComentario;
      if (this.idComentario) return agregarRespuestaComentario;
      return agregarComentario;
    },

    bodySeleccionado() {
      // Se va a editar respuesta
      if (this.contenidoActual && this.idRespuestaAEditar)
        return {
          respuesta: this.comentario,
          idComentario: this.idComentario,
          idActividad: this.idActividad,
          idClase: this.idClase,
          idCurso: this.$route.params.idCurso,
          idRespuesta: this.idRespuestaAEditar,
        };

      // Se va a editar comentario
      if (this.contenidoActual)
        return {
          comentario: this.comentario,
          idComentario: this.idComentario,
          idActividad: this.idActividad,
          idClase: this.idClase,
          idCurso: this.$route.params.idCurso,
        };

      // Se va a agregar respuesta
      if (this.idComentario)
        return {
          data: this.comentario,
          idComentario: this.idComentario,
          idActividad: this.idActividad,
          idClase: this.idClase,
          idCurso: this.$route.params.idCurso,
        };

      // Se va a agregar comentario
      return {
        data: this.comentario,
        idActividad: this.idActividad,
        idClase: this.idClase,
        idCursoCampus: this.$route.params.idCurso,
      };
    },
  },

  mounted() {
    if (this.contenidoActual) this.comentario.contenido = this.contenidoActual;
  },

  methods: {
    async guardarComentario() {
      this.loading = true;

      try {
        const serverResponse = await this.serviceSeleccionado(
          this.bodySeleccionado
        );
        this.loading = false;

        this.$validateResponse(serverResponse);
        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else {
          this.limpiarFormulario();
          !this.idComentario &&
            this.$emit("comentarioGuardado", serverResponse.comentario);
          this.contenidoActual &&
            this.$emit(
              "comentarioGuardado",
              serverResponse.comentario || serverResponse.respuesta
            );
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },

    limpiarFormulario() {
      this.comentario.contenido = "";
    },
  },
};
</script>
