<template>
  <v-dialog v-model="mostrar" width="40%" :fullscreen="instrumento?true:false" persistent>
    <v-card>
      <v-toolbar dense color="primary" dark flat class="text-h6">
        <strong>Calificación general</strong>
      </v-toolbar>

      <v-container fluid class="pb-0">
        <v-alert v-if="alertMessage" color="orange lighten-1" dense dark class="text-center">
          {{ alertMessage }}
        </v-alert>
      </v-container>

      <v-row no-gutters>
        <!-- Datos calificación -->
        <v-col :cols="showFullscreen ? 4 : 12">
          <datosCalificacionComponent
            :loading="loading"
            :instrumento="instrumento"
            :esInteractivo="esInteractivo"
            :instrumentoCalificacion="calificacionLocal"
            @cancelar="$emit('cancelar')"
            @agregarCalificacion="agregarCalificacion"
          />
        </v-col>
        <!-- Datos calificación -->

        <!-- Instrumento -->
        <v-col v-if="showFullscreen" class="cardLeft" cols="8">
          <v-container class="cardLeft" style="padding-top: 0px">
            <v-card outlined>
              <v-toolbar dense color="#254b72" dark flat style="height: 45px;">
                <strong>Instrumento</strong>
              </v-toolbar>

              <listaCotejoActividadViewComponent
                v-if="tipoInstrumento === 'listaCotejo'"
                :esCalificacion="true"
                :instrumentoActividad="instrumento"
                :selectedCalificacion="indicadores"
                @calificacionActualizada="calificacionActualizada"
              />

              <rubricaActividadViewComponent
                v-if="tipoInstrumento === 'rubrica'" 
                :esCalificacion="true"
                :instrumentoActividad="instrumento"
                :selectedCalificacion="indicadores"
                @calificacionActualizada="calificacionActualizada"
              />
            </v-card>
          </v-container>
        </v-col>
        <!-- Instrumento -->
      </v-row>

    </v-card>
  </v-dialog>
</template>

<script>

import datosCalificacionComponent from "./datosCalificacion.vue";
import listaCotejoActividadViewComponent from "../../../../instrumentos/actividad/listaCotejoActividad.view.vue";
import rubricaActividadViewComponent from "../../../../instrumentos/actividad/rubricaActividad.view.vue";

import { agregarCalificacionGeneralService } from "../entregas.service";

export default {
  name: "agregarCalificacionGeneral",
  props: {
    mostrar: { type: Boolean, default: false },
    idCurso: { type: String, default: null },
    idActividad: {type: String, default: null },
    instrumento: { type: String, default: null },
    tipoInstrumento: { type: String, default: null },
    tipoActividad: { type: Number, default: null },
  },
  components: {
    datosCalificacionComponent,
    listaCotejoActividadViewComponent,
    rubricaActividadViewComponent
  },

  computed: {
    calificaciones() {
      let calificaciones = [];
      if(!this.instrumento) {
        for (let i = 100; i >= 0; i--) calificaciones.push(i);
        return calificaciones;
      }
      else return calificaciones;
    },
    showFullscreen() {
      if (this.instrumento) return true;
      return false;
    },
    alertMessage() {
      if (this.tipoActividad === 1) return 'Esta acción asignará nuevas calificaciones a los usuarios registrados.';
      if (this.tipoActividad === 2) return 'Esta acción modificará las calificaciones de los usuarios que cuenten con un documento registrado.';
      if (this.tipoActividad === 4) return 'Esta acción asignará nuevas calificaciones e intentos a los usuarios registrados.';
      return false;
    },
    esInteractivo() {
      if (this.tipoActividad === 4) return true;
      return false;
    }
  },

  data () {
    return {
      loading: false,
      calificacionLocal: null,
      observacionesLocal: null,
      indicadores: [],
    }
  },

  created() {
    this.asignarCalificacion();
  },

  methods: {
    asignarCalificacion() {
      if(!this.instrumento) this.calificacionLocal = 100;
      else this.calificacionLocal = 0;
    },
    calificacionActualizada(data){
      this.calificacionLocal = data.calificacion;
      this.indicadores = data.indicadores;
    },
    async agregarCalificacion(data) {
      try {
        let sendData = {
          calificacion: data.calificacion,
          observaciones: data.observaciones,
        }

        if(this.instrumento) sendData.indicadores = this.indicadores;
        
        this.loading = true;
        const serverResponse = await agregarCalificacionGeneralService(
          this.idCurso,
          this.idActividad,
          sendData
        );
        this.loading = false;
        if (!serverResponse.ok) this.$systemErrorMessage(serverResponse.mensaje);
        else {
          const emitData = {
            calificaciones: serverResponse.calificaciones,
            intentos: serverResponse.intentos
          }
          
          this.$emit("calificacionesAgregadas", emitData);

        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    }
  }
}

</script>