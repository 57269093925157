<template>
  <div class="text-center">
    
      <v-menu offset-x left close-delay>
        <template v-slot:activator="{ on }">


          <v-btn
            v-if="calificaciones.length === 0"
            color="orange darken-1"
            depressed
            small
            v-on="on"
          >
            <v-icon left>mdi-alert-outline</v-icon>
            Sin calificación
          </v-btn>

          <v-btn
            v-else
            color="primary"
            depressed
            v-on="on"
          >
            {{ calificacionData.calificacion }}
          </v-btn>

        </template>

        <calificacionMenuComponent 
          :disableAdd="disableAddCal"
          :disableModify="disableModifyCal"
          :disableShow="disableShowCal"
          @addScore="agregarCalificacion = true"
          @modifyScore="modificarCalificacion"
          @showScore="verDetalles = true"
        />

      </v-menu>
    

    <historialCalificacionesComponent
      v-if="verDetalles"
      :mostrar="verDetalles"
      :calificaciones="calificaciones"
      @cerrar="verDetalles = false"
    />

    <agregarCalificacionDirectaComponent 
      v-if="agregarCalificacion"
      :mostrar="agregarCalificacion"
      :idCurso="idCurso"
      :idActividad="idActividad"
      :instrumento="instrumento"
      :tipoInstrumento="tipoInstrumento"
      :alumno="alumnoLocal"
      :equipo="equipoLocal"
      :calificacionModificar="calificacionModificar"
      :ultimoUsuario="ultimoUsuario"
      @btnCambio="btnCambio"

      @cancelar="cancelarAgregarCalificacion"
      @calificacionAgregada="calificacionAgregada"
      @calificacionModificada="calificacionModificada"
    />

  </div>
</template>

<script>
import { mapGetters } from "vuex";
import historialCalificacionesComponent from "./historialCalificaciones.vue";
import calificacionMenuComponent from "./calificacionMenu.vue";
import agregarCalificacionDirectaComponent from "./calificacionEntregas/agregarCalificacionDirecta.vue";

export default {
  name: "calDirectaItem",
  props: {
    idCurso: { type: String, default: null },
    idActividad: { type: String, default: null },
    instrumento: { type: String, default: null },
    tipoInstrumento: { type: String, default: null },

    alumno: { type: Object, default: () => null },
    equipo: { type: Object, default: () => null },

    permisoEscritura: { type: Boolean, default: false },

    ultimoUsuario: { type: Number, default: null },
    nuevoUsuario: { type: Object, default: () => null },
  },
  components: {
    historialCalificacionesComponent,
    calificacionMenuComponent,
    agregarCalificacionDirectaComponent
  },

  computed: {
    ...mapGetters(["vistaSeguimiento"]),

    // Agrupa las calificaciones de alumno y/o equipo
    calificaciones() {

      if (this.alumno)
        return this.alumno.calificaciones;

      if (this.equipo)
        return this.equipo.calificaciones;

      return false;

    },

    // Muestra la última calificación
    calificacionData() {
      if (this.alumno) {
        if (this.alumno.calificaciones.length > 0) 
          return this.alumno.calificaciones[0];
      }

      if (this.equipo) {
        if (this.equipo.calificaciones.length > 0) 
          return this.equipo.calificaciones[0];
      }
      
      return null;
    },

    disableAddCal() {
      if (!this.permisoEscritura || this.vistaSeguimiento) return true;
      return false;
    },

    disableModifyCal() {

      if (this.calificacionData === null || !this.permisoEscritura || this.vistaSeguimiento) return true; 
      return false;

    },

    disableShowCal() {
      if (this.alumno) {
        return this.alumno.calificaciones.length === 0;
      }

      if (this.equipo) {
        return this.equipo.calificaciones.length === 0;
      }

      return false;
    }
  },

  watch: {
    nuevoUsuario(value) {
      if (value != null) {
        if (this.alumno) this.alumnoLocal = value;
        if (this.equipo) this.equipoLocal = value;
      }
      else {
        if (this.alumno) this.alumnoLocal = this.alumno;
        if (this.equipo) this.equipoLocal = this.equipo;
      }
    }
  },

  data() {
    return {
      loading: false,
      agregarCalificacion: false,
      verDetalles: false,
      calificacionModificar: null,
      alumnoLocal: null,
      equipoLocal: null,
    };
  },

  created() {
    if (this.alumno) this.alumnoLocal = this.alumno;
    if (this.equipo) this.equipoLocal = this.equipo;
  },

  methods: {
    modificarCalificacion() {
      let { _id, calificacion, observaciones, indicadores } = this.calificacionData;
      this.calificacionModificar = { idCalificacion: _id, calificacion, observaciones, indicadores };
      this.agregarCalificacion = true;
    },
    cancelarAgregarCalificacion () {
      this.calificacionModificar = null;
      this.agregarCalificacion = false;
      this.$emit("cancelarCambio");
    },
    calificacionAgregada(calificacion) {
      this.agregarCalificacion = true;
      this.$emit("calificacionAgregada", calificacion);
    },
    calificacionModificada(calificacion) {
      this.calificacionModificar = null;
      this.agregarCalificacion = false;
      this.$emit("calificacionModificada", calificacion);
    },
    btnCambio(newIndex) {
      this.$emit("btnCambio", newIndex)
    },
  },
};
</script>
