<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <instruccionesComponent
          titulo="Pregunta"
          campo="pregunta"
          :idInteractivo="interactivo._id"
          :tipoInteractivo="tipoInteractivo"
          :texto="interactivo.pregunta"
          editable
          :permisoEscritura="permisoEscritura"
          @textoModificado="preguntaModificada"
        />
      </v-col>

      <v-col cols="12">
        <v-card outlined :loading="loading">
          <v-card-title
            >Respuestas
            <v-spacer />
            <v-btn
              v-if="answers.length > 1 && existeRespuestaCorrecta"
              color="primary"
              small
              icon
              @click="preview = true"
            >
              <v-icon>mdi-motion-play-outline</v-icon>
            </v-btn>
            <v-btn
              v-if="permisoEscritura"
              icon
              color="primary"
              @click="agregarRespuesta()"
            >
              <v-icon>mdi-playlist-plus</v-icon></v-btn
            >
          </v-card-title>
          <v-card-text v-if="answers.length < 2">
            <i><font color="red"> Debe haber al menos dos respuestas </font></i>
          </v-card-text>
          <v-card-text v-else-if="!existeRespuestaCorrecta">
            <i>
              <font color="red">
                Debe haber al menos una respuesta correcta
              </font>
            </i>
          </v-card-text>
          <v-divider class="mx-3"></v-divider>

          <multiplechoiceRespuestasComponent
            :answers="answers"
            :permisoEscritura="permisoEscritura"
            @modificarRespuesta="modificarRespuesta"
            @modificarRespuestaCorrecta="modificarRespuestaCorrecta"
            @eliminarRespuesta="eliminarRespuestaAction"
          />
        </v-card>
      </v-col>
      <v-col cols="12">
        <multiplechoiceOpcionesComponent
          :idInteractivo="interactivo._id"
          :tipoInteractivo="tipoInteractivo"
          :permisoEscritura="permisoEscritura"
          :tipoRespuestas="interactivo.tipoRespuestas"
          :respuestasAleatorias="interactivo.respuestasAleatorias"
          :totalCorrectas="totalCorrectas"
          @tipoRespuestasModificado="tipoRespuestasModificado"
          @respuestasAleatoriasModificado="respuestasAleatoriasModificado"
        />
      </v-col>
    </v-row>
    <agregarModificarPregunta
      v-if="mostrarAgregarModificar"
      :mostrar="mostrarAgregarModificar"
      :modificar="modificar"
      :idMultiplechoice="interactivo._id"
      :item="respuestaModificar"
      @cancelar="mostrarAgregarModificar = false"
      @respuestaAgregada="respuestaAgregada"
      @respuestaModificada="respuestaModificada"
    />
    <interactivoViewer
      v-if="preview"
      :mostrar="preview"
      :idInteractivo="interactivo._id"
      :tipoInteractivo="tipoInteractivo"
      :nombreInteractivo="interactivo.nombre"
      @cerrar="preview = false"
    />
    <eliminarRespuestaComponent
      v-if="eliminarRespuesta"
      :mostrar="eliminarRespuesta"
      :item="respuestaEliminar"
      :idMultiplechoice="interactivo._id"
      @cancelar="eliminarRespuesta = false"
      @multiplechoiceRespuestaEliminada="respuestaEliminada"
    />
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import instruccionesComponent from "../instrucciones.vue";
import multiplechoiceRespuestasComponent from "./multiplechoiceRespuestas.vue";
import multiplechoiceOpcionesComponent from "./multiplechoiceOpciones.vue";
import agregarModificarPregunta from "./agregarModificarPregunta.vue";
import eliminarRespuestaComponent from "./eliminarRespuesta.vue";
import interactivoViewer from "../interactivoViewer.vue";

import { modificarMultiplechoiceRespuestaCorrectaService } from "./multiplechoice.service";

export default {
  name: "MultipleChoiseView",
  components: {
    instruccionesComponent,
    multiplechoiceRespuestasComponent,
    multiplechoiceOpcionesComponent,
    agregarModificarPregunta,
    eliminarRespuestaComponent,
    interactivoViewer,
  },
  props: {
    interactivo: { type: Object, default: () => {} },
    tipoInteractivo: { type: String, default: null },
    permisoEscritura: { type: Boolean, default: false },
  },
  computed: {
    ...mapGetters(["sessionToken", "menu"]),
    existeRespuestaCorrecta() {
      for (const answer of this.answers) {
        if (answer.correct) return true;
      }
      return false;
    },
    totalCorrectas() {
      let correctas = 0;
      this.answers.forEach((answer) => {
        if (answer.correct) correctas++;
      });
      return correctas;
    },
  },

  mounted() {
    this.answers = this.interactivo.answers;
  },

  data() {
    return {
      loading: false,
      answers: [],
      preview: false,
      mostrarAgregarModificar: false,
      modificar: false,
      respuestaModificar: null,
      eliminarRespuesta: false,
      respuestaEliminar: null,
    };
  },
  methods: {
    preguntaModificada(payload) {
      this.$emit("preguntaModificada", payload);
    },
    tipoRespuestasModificado(payload) {
      this.interactivo.tipoRespuestas = payload.value;
      this.$emit("logAgregado", payload.nuevoLog);
    },
    respuestasAleatoriasModificado(payload) {
      this.interactivo.respuestasAleatorias = payload.value;
      this.$emit("logAgregado", payload.nuevoLog);
    },
    agregarRespuesta() {
      this.modificar = false;
      this.respuestaModificar = null;
      this.mostrarAgregarModificar = true;
    },
    respuestaAgregada(payload) {
      this.$emit("logAgregado", payload.nuevoLog);
      this.answers.push(payload.answer);
      this.mostrarAgregarModificar = false;
    },
    modificarRespuesta(item) {
      this.modificar = true;
      this.respuestaModificar = item;
      this.mostrarAgregarModificar = true;
    },
    respuestaModificada(payload) {
      this.$emit("logAgregado", payload.nuevoLog);
      const respuesta = payload.answer;
      const index = this.answers.findIndex((x) => x._id === respuesta._id);
      if (index >= 0) this.answers.splice(index, 1, respuesta);
      this.mostrarAgregarModificar = false;
    },
    async modificarRespuestaCorrecta(payload) {
      this.loading = true;
      try {
        const serverResponse =
          await modificarMultiplechoiceRespuestaCorrectaService(
            this.interactivo._id,
            payload.idRespuesta,
            payload.value
          );
        this.loading = false;
        this.$validateResponse(serverResponse);
        if (!serverResponse.ok) {
          this.$systemErrorMessage(serverResponse.mensaje);
          return;
        } else {
          const index = this.answers.findIndex(
            (x) => x._id === payload.idRespuesta
          );
          if (index >= 0) {
            if (this.interactivo.tipoRespuestas === "single") {
              this.answers.forEach((answer) => {
                answer.correct = false;
              });
            }
            this.answers[index].correct = payload.value;
          }
          this.$emit("logAgregado", serverResponse.nuevoLog);
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
    eliminarRespuestaAction(respuesta) {
      this.respuestaEliminar = respuesta;
      this.eliminarRespuesta = true;
    },
    respuestaEliminada(payload) {
      this.$emit("logAgregado", payload.nuevoLog);
      const index = this.answers.findIndex(
        (x) => x._id === this.respuestaEliminar._id
      );
      if (index >= 0) this.answers.splice(index, 1);
      this.respuestaEliminar = null;
      this.eliminarRespuesta = false;
    },
  },
};
</script>
