import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VList,[_vm._l((_vm.instrumentos),function(instrumento){return _c(VListItem,{key:instrumento._id},[_c(VListItemAvatar,[_c(VAvatar,{attrs:{"color":"primary"}},[(_vm.tipo == 'listaCotejo')?_c(VIcon,{attrs:{"dark":""}},[_vm._v(_vm._s(_vm.listaCotejoIcon))]):_vm._e(),(_vm.tipo == 'rubrica')?_c(VIcon,{attrs:{"dark":""}},[_vm._v(_vm._s(_vm.rubricaIcon))]):_vm._e()],1)],1),_c(VListItemContent,[_c(VListItemTitle,[_c('router-link',{staticClass:"link",attrs:{"to":`instrumentos/${_vm.tipo}/${instrumento._id}`}},[_vm._v(_vm._s(instrumento.nombre)+" ")])],1),(_vm.app == 'escuela' || _vm.app == 'empresa')?_c(VCard,{staticStyle:{"font-size":"10.5px","margin-bottom":"5px"},attrs:{"flat":""}},[_vm._v("Área: "+_vm._s(instrumento.instrumentoArea[0].nombre))]):_vm._e(),_c(VListItemSubtitle,[_c('instrumentoStatusComponent',{attrs:{"status":instrumento.status}}),_c(VChip,{staticClass:"ml-1",attrs:{"x-small":""}},[_vm._v(_vm._s(_vm.getRolInstrumento(instrumento)))])],1)],1),(_vm.habilitado)?_c(VListItemAction,[_c(VMenu,{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c(VList,_vm._l((_vm.menuInstrumento),function(menuInstrumentoItem,index){return _c(VListItem,{key:index,on:{"click":function($event){return _vm.menuAction({
                ...menuInstrumentoItem,
                id: instrumento._id,
              })}}},[_c(VListItemTitle,[_c(VIcon,{staticClass:"mr-2",attrs:{"small":""}},[_vm._v(_vm._s(menuInstrumentoItem.icon))]),_c('span',[_vm._v(_vm._s(menuInstrumentoItem.text))])],1)],1)}),1)],1)],1):_vm._e()],1)}),(_vm.mostrarClonarInstrumento.mostrar)?_c('clonarInstrumentoComponent',{attrs:{"mostrar":_vm.mostrarClonarInstrumento.mostrar,"instrumento":_vm.mostrarClonarInstrumento.instrumento,"areas":_vm.areas,"tipo":_vm.tipo},on:{"cancelar":_vm.cerrarClonarInstrumento,"instrumentoClonado":_vm.instrumentoClonado}}):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }