<template>
  <v-list-item style="width: 100%">
    <div
      style="width: 100%"
      v-if="modulo.visible || !sesionCursoAlumno || avanceAutomatico"
    >
      <v-card v-if="loading" outlined class="my-2">
        <v-skeleton-loader type="list-item-avatar-three-line" style="margin: 20px"></v-skeleton-loader>
      </v-card>
      <v-card outlined class="my-2">
        <v-list>
          <v-list-item>
            <v-list-item-icon
              style="margin: auto"
              v-if="
                !sesionCursoAlumno &&
                tipoModulo != 'moduloProfesor' &&
                !vistaSeguimiento &&
                permisoEscritura &&
                !modulo.transversal
              "
              :class="{ handle: dragState == true }"
              @mouseover="permisoEscritura && !vistaSeguimiento ? (dragState = true) : null"
              @mouseout="permisoEscritura ? (dragState = false) : null"
            >
              <v-icon color="grey">mdi-drag</v-icon>
            </v-list-item-icon>
            <v-list-item-action
              v-if="
                tipoCurso == 'campus' &&
                !sesionCursoAlumno &&
                tipoModulo != 'moduloProfesor' &&
                !vistaSeguimiento &&
                permisoEscritura
              "
            >
              <moduloSwitchComponent
                :idModulo="modulo._id"
                :idCurso="idCurso"
                :visible="modulo.visible"
                :deshabilitado="avanceAutomatico"
                @moduloVisibleModificado="moduloVisibleModificado"
              />
            </v-list-item-action>
            <v-list-item-content style="margin-left: 10px">
              <v-list-item-title>
                <a
                  v-if="moduloHabilitado"
                  href="javascript:void(0)"
                  class="link text-h6"
                  @click="verModulo()"
                >
                  {{ modulo.nombre }}
                </a>
                <p v-else class="link text-h6">{{ modulo.nombre }}</p>
              </v-list-item-title>
              <v-list-item-subtitle>
                <genericoChipComponent
                  v-if="modulo.contieneClases"
                  :text="modulo.clases.length"
                  icon="mdi-motion-play-outline"
                />
                <genericoChipComponent
                  v-if="modulo.contieneActividades"
                  :text="modulo.actividades.length"
                  :icon="actividadArchivoIcon"
                />
                <genericoChipComponent
                  v-if="modulo.contieneRecursos"
                  :text="modulo.recursos.length"
                  icon="mdi-link-variant"
                />
                <genericoChipComponent
                  v-if="modulo.contieneExamenes"
                  :text="modulo.examenAplicaciones.length"
                  :icon="examenIcon"
                />
                <genericoChipComponent
                  v-if="tipoCurso == 'campus' && !loading"
                  :text="`${modulo.avance}%`"
                  icon="mdi-finance"
                />
              </v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action>
              <v-menu v-if="permisoEscritura && !vistaSeguimiento && !modulo.transversal" left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn small icon v-bind="attrs" v-on="on">
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <v-list dense>
                  <v-list-item @click="modificarModulo()">
                    <v-list-item-title>Modificar módulo</v-list-item-title>
                    <v-list-item-icon>
                      <v-icon small style="margin-right: 5px">
                        mdi-file-outline
                      </v-icon>
                    </v-list-item-icon>
                  </v-list-item>
                  <v-list-item @click="habilitarEliminar = true">
                    <v-list-item-title>
                      <font color="#D32F2F">Eliminar módulo</font>
                    </v-list-item-title>
                    <v-list-item-icon>
                      <v-icon
                        color="red darken-2"
                        small
                        style="margin-right: 5px"
                      >
                        mdi-trash-can-outline
                      </v-icon>
                    </v-list-item-icon>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-card>

      <agregarModuloComponent
        v-if="agregarModulo"
        :mostrar="agregarModulo"
        :tipoCurso="tipoCurso"
        :idCurso="idCurso"
        :tipo="tipoModulo"
        :editarModulo="editarModulo"
        @cancelar="(agregarModulo = false), (editarModulo = null)"
        @moduloModificado="moduloModificado"
      />

      <eliminar-modulo
        v-if="habilitarEliminar"
        :mostrar="habilitarEliminar"
        :tipoCurso="tipoCurso"
        :idCurso="idCurso"
        :idModulo="idModulo"
        :tipoModulo="tipoModulo"
        :modulo="modulo"
        @cancelar="habilitarEliminar = false"
        @moduloEliminado="moduloEliminado"
      ></eliminar-modulo>
    </div>
  </v-list-item>
</template>

<script>
import { mapGetters } from "vuex";
import moduloSwitchComponent from "./moduloSwitch.vue";
import agregarModuloComponent from "./agregarModulo.vue";
import genericoChipComponent from "../../widgets/chips/genericoChip.vue";
import {
  obtenerModuloService,
  modificarDescripcionModuloService,
} from "./modulos.service";
import { obtenerModuloProfesorService } from "./modulosProfesorCampus.service";

export default {
  name: "moduloItem",
  props: {
    tipoCurso: String,
    idCurso: String,
    idModulo: String,
    tipoModulo: String,
    cursoPlantilla: { type: String, default: null },
    permisoEscritura: { type: Boolean, default: false },
    avanceAutomatico: Boolean,
  },
  components: {
    moduloSwitchComponent,
    agregarModuloComponent,
    genericoChipComponent,
    "eliminar-modulo": () => import("./eliminarModulo.vue"),
  },
  computed: {
    ...mapGetters([
      "role",
      "app",
      "actividadArchivoIcon",
      "examenIcon",
      "sesionCursoAlumno",
      "vistaSeguimiento",
    ]),
    moduloHabilitado() {
      if (!this.avanceAutomatico) return true;
      return !(
        this.sesionCursoAlumno &&
        this.avanceModuloAnterior < 100 &&
        this.moduloPrevio != null
      );
    },
  },
  mounted() {
    this.cargarModulo();
  },
  data() {
    return {
      loading: false,
      modulo: {
        clases: [],
        actividades: [],
        recursos: [],
        examenAplicaciones: [],
        avance: 0,
      },
      agregarModulo: false,
      editarModulo: null,
      edicionHabilitada: false,
      habilitarEliminar: false,
      dragState: false,
      porcentajeAvance: 0,
      avanceModuloAnterior: 0,
      moduloPrevio: null,
    };
  },
  methods: {
    async cargarModulo() {
      try {
        this.loading = true;
        let serverResponse = null;
        if (this.cursoPlantilla && this.app == "campus") {
          serverResponse = await obtenerModuloProfesorService(
            this.tipoCurso,
            this.idCurso,
            this.idModulo
          );
        } else {
          serverResponse = await obtenerModuloService(
            this.tipoCurso,
            this.tipoModulo,
            this.idCurso,
            this.idModulo
          );
        }
        this.loading = false;
        if (!serverResponse.ok) {
          this.$systemErrorMessage(serverResponse.mensaje);
        } else {
          this.modulo = serverResponse.modulo;
          this.$emit("calcularAvanceCurso", this.modulo.avance);
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
    verModulo() {
      this.$router.push(
        `/curso/${this.tipoCurso}/${this.idCurso}/tipo/${this.tipoModulo}/${this.idModulo}`
      );
    },
    modificarModulo() {
      this.editarModulo = { ...this.modulo };
      this.agregarModulo = true;
    },
    moduloModificado(modulo) {
      this.modulo = modulo;
    },
    moduloVisibleModificado(visible) {
      this.modulo.visible = visible;
    },
    async modificarDescripcion() {
      try {
        this.loading = true;
        const data = {
          descripcion: this.$refs.editorTexto.obtenerTexto(),
        };

        const serverResponse = await modificarDescripcionModuloService(
          data,
          this.tipoCurso,
          this.idCurso,
          this.modulo._id
        );
        this.loading = false;

        if (!serverResponse.ok) {
          this.$systemErrorMessage(serverResponse.mensaje);
        } else {
          this.modulo.descripcion = serverResponse.descripcion;
          this.edicionHabilitada = false;
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
    async moduloEliminado(modulo) {
      this.$emit("moduloEliminado", modulo);
    },
  },
};
</script>

<style>
.margen {
  margin: 5px !important;
}
</style>
