import {
  putServerRequest,
  patchServerRequest,
  deleteServerRequest,
} from "@/components/globals/services/serverRequest.service";
import { store } from "../../../../../store/store";

const httpURL = store.getters.httpURL;

export const agregarFTWPalabraService = async (idFTW, palabra) => {
  const token = store.getters.sessionToken;
  const config = { headers: { token } };
  const url = `${httpURL}/interactivo/findthewords/${idFTW}/palabra`;
  return await putServerRequest(url, { palabra }, config);
};

export const modificarFTWPalabraService = async (idFTW, data) => {
  const token = store.getters.sessionToken;
  const config = { headers: { token } };
  const url = `${httpURL}/interactivo/findthewords/${idFTW}/palabra`;
  return await patchServerRequest(url, data, config);
};

export const eliminarFTWPalabraService = async (idFTW, index) => {
  const token = store.getters.sessionToken;
    const config = {
        headers: { token },
        data: { index }
    };
    const url = `${httpURL}/interactivo/findthewords/${idFTW}/palabra`;
    return await deleteServerRequest(url, config);
};

export const modificarAlineacionService = async (idFTW, campo, value) => {
  const token = store.getters.sessionToken;
  const config = { headers: { token } };
  let data = {};
  data[campo] = value;
  const url = `${httpURL}/interactivo/findthewords/${idFTW}/alineacion/${campo}`;
  return await patchServerRequest(url, data, config);
}