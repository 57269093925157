<template>
  <div>
    <v-card tile outlined>
      <v-row no-gutters>
        <v-col
          v-for="elemento in elementosLocal"
          :key="elemento._id"
          :cols="percentToCols(elemento.porcentajeVista)"
          class="ma-0 pa-0"
        >
          <modulo-elemento
            :elemento="elemento"
            :planeacion="planeacion"
            @elementoSaved="elementoSaved"
          ></modulo-elemento>
        </v-col>
      </v-row>
    </v-card>
    <div class="my-3 d-flex justify-center">
      <v-menu :close-on-content-click="false" bottom offset-y>
        <template #activator="{ on, attrs }">
          <v-btn v-on="on" v-bind="attrs" small color="primary" rounded>
            <v-icon class="mr-2">mdi-plus</v-icon>
            <span>Agregar elemento</span>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="(elementoOption, index) in moduloElementosList"
            :key="index"
            dense
            link
            @click="elementoOption.method()"
          >
            <v-icon small class="mr-2">{{ elementoOption.icon }}</v-icon>
            <span>{{ elementoOption.titulo }}</span>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>

    <elemento-dialog
      v-if="showElementoDialog"
      :show="showElementoDialog"
      :tipo="tipoElementoACrear"
      :idPlaneacion="idPlaneacion"
      :idModulo="idModulo"
      @close="showElementoDialog = false"
      @elementoSaved="elementoSaved"
    ></elemento-dialog>
  </div>
</template>

<script>
import planeacionModuloElemento from "./planeacionModuloElemento.vue";

export default {
  components: {
    "modulo-elemento": planeacionModuloElemento,
    "elemento-dialog": () => import("./moduloElementoDialog.vue"),
  },

  props: {
    elementos: {
      type: Array,
      default: () => [],
    },
    idPlaneacion: {
      type: String,
    },
    idModulo: {
      type: String,
    },
    planeacion: {
      type: Object,
    },
  },

  data() {
    return {
      elementosLocal: this.elementos,
      showElementoDialog: false,
      tipoElementoACrear: "texto",
    };
  },

  computed: {
    moduloElementosList() {
      return [
        {
          titulo: "Elemento de texto",
          icon: "mdi-text",
          method: this.openElementoDialog("texto"),
        },
        {
          titulo: "Secuencia didáctica",
          icon: "mdi-debug-step-over",
          method: this.openElementoDialog("secuenciaDidactica"),
        },
      ];
    },
  },

  methods: {
    percentToCols(percent) {
      const cols = (percent / 100) * 12;
      return cols;
    },

    elementoSaved(elemento) {
      const indexElemento = this.elementosLocal.findIndex(
        (e) => e._id == elemento._id
      );
      if (indexElemento == -1) this.elementosLocal.push(elemento);
      else this.elementosLocal.splice(indexElemento, 1, elemento);
      this.showElementoDialog = false;
    },

    openElementoDialog(tipo) {
      return () => {
        this.showElementoDialog = true;
        this.tipoElementoACrear = tipo;
      };
    },
  },
};
</script>
