import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"width":"500","persistent":""},model:{value:(_vm.mostrar),callback:function ($$v) {_vm.mostrar=$$v},expression:"mostrar"}},[_c(VCard,{attrs:{"loading":_vm.loading}},[_c(VToolbar,{attrs:{"color":"primary","dense":"","flat":"","dark":""}},[_c('strong',[_vm._v("Asignar administrador")])]),_c(VContainer,[_c(VCard,{staticClass:"mb-2",staticStyle:{"text-align":"center","font-size":"x-large"},attrs:{"outlined":"","flat":"","elevation":"0"}},[_vm._v(_vm._s(_vm.equipo.nombre))]),_c(VCard,{staticClass:"scroll-card",attrs:{"flat":"","outlined":"","elevation":"0"}},[_c(VList,_vm._l((_vm.equipo.integrantes),function(integrante,index){return _c(VListItem,{key:index},[_c(VRadioGroup,{model:{value:(_vm.adminSelected),callback:function ($$v) {_vm.adminSelected=$$v},expression:"adminSelected"}},[_c(VRadio,{key:index,attrs:{"value":index},on:{"click":function($event){return _vm.seleccionarAdmin(index)}}})],1),_c(VListItemAvatar,{staticClass:"avatar-list"},[_c(VAvatar,{attrs:{"color":"primary","size":"30"}},[(!integrante.avatar)?_c(VIcon,{attrs:{"color":"white"}},[_vm._v("mdi-account")]):_c('img',{attrs:{"src":`${_vm.imageURL}/avatar/${integrante.avatar}`,"alt":"UserImage"}})],1)],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(integrante.nombreCompleto))])],1),(_vm.adminLocal._id === integrante._id)?_c(VListItemAction,[_c(VChip,{attrs:{"outlined":"","color":"yellow darken-2","label":"","small":"","text-color":"yellow darken-2"}},[_vm._v("Administrador "),_c(VIcon,{attrs:{"right":""}},[_vm._v(" mdi-star ")])],1)],1):_vm._e()],1)}),1)],1)],1),_c(VDivider,{staticClass:"mx-3"}),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"small":"","outlined":"","color":"primary","disabled":_vm.loading},on:{"click":function($event){return _vm.cancelar()}}},[_vm._v("Cancelar ")]),_c(VBtn,{class:{
          'disable-events': !_vm.valido,
        },attrs:{"small":"","color":"primary","loading":_vm.loading},on:{"click":function($event){return _vm.asignarAdmin()}}},[_vm._v("Guardar ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }