import {
    postServerRequest,
    patchServerRequest,
    deleteServerRequest,
} from "@/components/globals/services/serverRequest.service";
import { store } from "../../../../../store/store";

const httpURL = store.getters.httpURL;

export const agregarMultiplechoiceRespuestaService = async (idMultiplechoice, data) => {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/interactivo/multiplechoice/${idMultiplechoice}/answer`;
    return await postServerRequest(url, data, config);
};

export const modificarMultiplechoiceRespuestaService = async (idMultiplechoice, idRespuesta, data) => {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/interactivo/multiplechoice/${idMultiplechoice}/answer/${idRespuesta}`;
    return await patchServerRequest(url, data, config);
};

export const modificarMultiplechoiceRespuestaCorrectaService = async (idMultiplechoice, idRespuesta, value) => {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/interactivo/multiplechoice/${idMultiplechoice}/answer/${idRespuesta}/correct`;
    return await patchServerRequest(url, { value }, config);
};

export const eliminarMultiplechoiceRespuestaService = async (idMultiplechoice, idRespuesta) => {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/interactivo/multiplechoice/${idMultiplechoice}/answer/${idRespuesta}`;
    return await deleteServerRequest(url, config);
};
