<template>
  <v-list-item>
    <v-list-item-icon v-if="!ocultarIcono">
      <v-icon color="primary">{{ icon }}</v-icon>
    </v-list-item-icon>
    <v-list-item-content>
      <v-list-item-title>
        <a
          v-if="actividad.visible && !sesionCursoPadre"
          href="javascript:void(0);"
          class="link"
          @click="verActividad()"
        >
          <span v-if="actividad.transversal">{{ actividad.transversal.nombre }}</span>
          <span v-else>{{ actividad.nombre }}</span>
        </a>
        <span v-else>{{ actividad.nombre }}</span>
      </v-list-item-title>
      <v-list-item-subtitle v-if="!ocultarCriterio">
        <span v-if="vista == 'general'"
          >Módulo: {{ actividad.cursoModulo.nombre }}.</span
        >
        <span v-if="actividad.criterio">
          {{ actividad.criterio.nombre }}
        </span>
      </v-list-item-subtitle>
      <v-list-item-subtitle>
        <p v-if="vista == 'general'">
          Ponderación: {{ actividad.ponderacion }}
        </p>
        <p v-if="vista == 'general'">Puntos: {{ actividad.puntos }}.</p>
      </v-list-item-subtitle>
      <v-list-item-subtitle>
        <actividadDisponibleChip
          v-if="
            !actividad.examenAplicacion &&
            !actividad.calificacionAlumno &&
            !actividad.documentoAlumno &&
            actividad.tipo != 1 &&
            actividad.visible
          "
          :disponible="actividad.disponible"
          :entregaExt="actividad.entregaExt"
        />
        <span v-if="actividad.tipo == 1 && !actividad.calificacionAlumno"
          ><v-chip x-small color="amber darken-2" style="color: white"
            ><v-icon left>mdi-clock</v-icon>Sin calificar</v-chip
          ></span
        >
        <span v-if="actividad.tipo == 1 && actividad.calificacionAlumno"
          ><v-chip x-small color="green darken-2" style="color: white"
            ><v-icon left>mdi-check</v-icon>Calificado</v-chip
          ></span
        >
        <span v-if="documentoActual">
          <template
            v-if="
              !documentoActual.calificacion && documentoActual.calificacion != 0
            "
          >
            <v-chip x-small color="amber darken-2" style="color: white"
              ><v-icon left>mdi-clock</v-icon>En espera de revisión</v-chip
            >
          </template>
          <template
            v-if="
              typeof documentoActual.calificacion == 'number' &&
              !documentoActual.reenvio
            "
          >
            <v-chip x-small color="green darken-2" style="color: white"
              ><v-icon left>mdi-check</v-icon>Calificado</v-chip
            >
          </template>
          <template v-if="documentoActual.reenvio">
            <v-chip x-small color="orange darken-3" style="color: white"
              ><v-icon left>mdi-alert</v-icon>Reenvío solicitado</v-chip
            >
          </template>
        </span>
        <span v-if="!actividad.visible"
          ><v-chip x-small outlined color="grey darken-2">{{
            leyenda
          }}</v-chip></span
        >
      </v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import { mapGetters } from "vuex";
import { store } from "@/store/store";
import actividadDisponibleChip from "../../../widgets/chips/actividadDisponibleChip.vue";
import { modificarActividadVisibleService } from "./actividades.service";

export default {
  name: "actividadAlumnoItem",
  props: {
    idCurso: String,
    tipoCurso: String,
    tipoModulo: String,
    actividad: Object,
    vista: { type: String, default: "modular" },
    ocultarCriterio: { type: Boolean, default: false },
    ocultarIcono: { type: Boolean, default: false },
    avanceAutomatico: { type: Boolean, default: false },
  },
  components: {
    actividadDisponibleChip,
  },
  computed: {
    ...mapGetters([
      "actividadDirectaIcon",
      "actividadArchivoIcon",
      "actividadForoIcon",
      "actividadInteractivaIcon",
      "actividadTransversalIcon",
      "examenIcon",
      "sesionCursoAlumno",
      "sesionCursoPadre",
    ]),
    icon() {
      if (this.actividad.tipo == 1) return this.actividadDirectaIcon;
      if (this.actividad.tipo == 2) return this.actividadArchivoIcon;
      if (this.actividad.tipo == 3) return this.actividadForoIcon;
      if (this.actividad.tipo == 4) return this.actividadInteractivaIcon;
      if (this.actividad.tipo == 5) return this.actividadTransversalIcon;
      return "mdi-file-outline";
    },
    documentoActual() {
      if (this.actividad.documentoAlumno)
        return this.actividad.documentoAlumno.revision;
      else return false;
    },
    documentoAnterior() {
      if (this.actividad.documentoAnterior)
        return this.actividad.documentoAnterior.revision;
      else return false;
    },
    calificacionTotal() {
      if (this.actividad.calificacionAlumno.puntosExtra)
        return (
          this.actividad.calificacionAlumno.calificacion +
          this.actividad.calificacionAlumno.puntosExtra
        );
      else return this.actividad.calificacionAlumno.calificacion;
    },
    leyenda() {
      if (!this.actividad.visible && !this.avanceAutomatico) return "Oculto";
      if (!this.actividad.visible && this.avanceAutomatico)
        return "Actividad no disponible";
      return "";
    },
  },
  data() {
    return {
      app: store.getters.app,
      loading: false,
      dragState: false,
    };
  },
  methods: {
    verActividad() {
      // if (this.vista == 'modulo')
      if (
        this.actividad.examenAplicacion &&
        this.actividad.examenAplicacion == true
      ) {
        const params = {
          idCurso: this.idCurso,
          tipoCurso: this.tipoCurso,
          idModulo: this.actividad.cursoModulo._id,
          idAplicacion: this.actividad._id,
        };
        this.$router.push({ name: "ExamenAplicacion", params });
      } else {
        this.$router.push(
          `/curso/${this.tipoCurso}/${this.idCurso}/${this.tipoModulo}/vista/${this.vista}/actividad/${this.actividad._id}`
        );
      }
    },
    async modificarVisible(idActividad) {
      if (this.permisoEscritura) {
        try {
          this.loading = true;
          const serverResponse = await modificarActividadVisibleService(
            this.idCurso,
            idActividad
          );
          this.loading = false;
          if (!serverResponse.ok) {
            this.$systemErrorMessage(serverResponse.mensaje);
          } else {
            const emitData = {
              idActividad,
              visible: serverResponse.actividad.visible,
            };
            this.$emit("actividadVisibleModificado", emitData);
          }
        } catch (error) {
          this.loading = false;
          this.$appErrorMessage();
        }
      }
    },
  },
};
</script>
<style scoped>
p {
  margin: 0px 0px 0px 15px;
  display: inline;
}
</style>
