<template>
  <div>
    <v-card flat>
      <v-list-item>
        <v-list-item-icon
          :class="{ handle: dragState == true }"
          @mouseover="dragState = true"
          @mouseout="dragState = false"
        >
          <v-icon color="grey">mdi-drag</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title @click="goToModulo()">
            <a href="javascript:void(0)" class="text-h6 primary--text link">
              {{ moduloLocal.nombre }}
            </a>
          </v-list-item-title>
        </v-list-item-content>
        <v-list-item-action>
          <modulo-menu
            :idPlaneacion="idPlaneacion"
            :modulo="moduloLocal"
            @moduloSaved="moduloSaved"
          ></modulo-menu>
        </v-list-item-action>
      </v-list-item>
    </v-card>
  </div>
</template>

<script>
import planeacionModuloMenu from "./modulo/planeacionModuloMenu.vue";

export default {
  components: {
    "modulo-menu": planeacionModuloMenu,
  },
  props: {
    modulo: {
      type: Object,
      required: true,
    },
    idPlaneacion: {
      type: String,
      required: true,
    },
    customGoToModulo: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      moduloLocal: this.modulo,
      dragState: false,
    };
  },

  methods: {
    moduloSaved(modulo) {
      this.moduloLocal = modulo;
    },

    goToModulo() {
      if (this.customGoToModulo) {
        this.$emit("goToModulo", this.moduloLocal._id);
        return;
      }
      const route = `/planeaciones/planeacion/${this.idPlaneacion}/modulo/${this.moduloLocal._id}`;
      this.$router.push(route);
    },
  },
};
</script>

<style>
.link {
  text-decoration: none;
}
</style>
