<template>
  <v-container>
    <v-skeleton-loader
      v-if="!escuelaTienePermiso"
      type="list-item-two-line@5"
    ></v-skeleton-loader>
    <v-card v-else flat>
      <v-toolbar color="secondary" dark flat class="text-h6">
        <v-spacer></v-spacer>ACTIVIDADES INTERACTIVAS<v-spacer></v-spacer>
      </v-toolbar>
      <v-container>
        <v-row>
          <v-col md="6" sm="12">
            <v-container>
              <selectorAreas
                :areas="areas"
                :menu="esAdmin"
                v-model="areaSeleccionada"
                @agregarArea="abrirEditorArea = true"
                @modificarArea="modificarAreaSeleccionada"
                @change="getSubareas(areaSeleccionada)"
              />
            </v-container>
          </v-col>
          <v-col md="6" sm="12">
            <v-container>
              <div class="d-flex">
                <selectorAreas
                  :areas="subareas"
                  :menu="esAdmin"
                  v-model="subareaSeleccionada"
                  :etiqueta="'Subárea'"
                  @agregarArea="abrirEditorSubarea = true"
                  @modificarArea="modificarSubareaSeleccionada"
                  @change="getInteractivos()"
                />

                <v-btn
                  small
                  style="margin-left: 10px"
                  icon
                  color="primary"
                  :disabled="
                    !areas.length || !subareas.length || !esAdmin || loading
                  "
                  @click="crearInteractivo()"
                >
                  <v-icon>mdi-file-document-plus</v-icon>
                </v-btn>
              </div>
            </v-container>
          </v-col>
        </v-row>

        <v-card-text v-if="!interactivos.length && !loading"
          >No se han generado interactivos.</v-card-text
        >

        <template v-else-if="loading">
          <v-card flat>
            <v-container>
              <v-skeleton-loader type="paragraph@2"></v-skeleton-loader>
            </v-container>
            <v-divider class="mx-3"></v-divider>
            <v-skeleton-loader type="list-item-two-line@5"></v-skeleton-loader>
          </v-card>
        </template>
        <template v-else-if="interactivos.length > 0">
          <v-container fluid>
            <v-col md="12" sm="12">
              <listaInteractivos :interactivos="interactivos" />
            </v-col>
          </v-container>
        </template>
        <logsComponent :logs="logs" />
      </v-container>
    </v-card>

    <editor-area
      v-if="abrirEditorArea"
      :mostrar="abrirEditorArea"
      :area="areaParaModificar"
      @cancelar="cancelarEditarArea"
      @areaGuardada="areaGuardada"
    />

    <editor-subarea
      v-if="abrirEditorSubarea"
      :mostrar="abrirEditorSubarea"
      :subarea="subareaParaModificar"
      :idArea="areaSeleccionada"
      @cancelar="cancelarEditarSubarea"
      @subareaGuardada="subareaGuardada"
    />

    <editorInteractivo
      v-if="abrirEditorInteractivo"
      :mostrar="abrirEditorInteractivo"
      :idSubarea="subareaSeleccionada"
      :idArea="areaSeleccionada"
      @cancelar="abrirEditorInteractivo = false"
      @interactivoAgregado="interactivoAgregado"
    />
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import listaInteractivos from "./listaInteractivos.vue";
import selectorAreas from "../widgets/selectorAreas.vue";
import logsComponent from "../widgets/logs.vue";
import EditorArea from "./editorAreaInteractivos.vue";
import EditorSubarea from "./editorSubareaInteractivos.vue";
import EditorInteractivo from "./editorInteractivo.vue";

import {
  getAreasService,
  getSubareasService,
  getInteractivosService,
} from "./interactivos.service";

export default {
  name: "InteractivosView",
  components: {
    listaInteractivos,
    selectorAreas,
    logsComponent,
    EditorArea,
    EditorSubarea,
    EditorInteractivo,
  },
  computed: {
    ...mapGetters(["sessionToken", "app", "role", "menu"]),

    esAdmin() {
      return (
        this.app == "campus" ||
        this.role == "admin" ||
        this.role == "master" ||
        this.role == "profesor"
      );
    },
  },
  watch: {
    sessionToken(value) {
      if (value) {
        if (this.app === "escuela") this.validarPermisoRuta();
        this.escuelaTienePermiso = true;
        this.getAreas();
      }
    },
  },
  mounted() {
    if (this.sessionToken) {
      if (this.app === "escuela" && this.menu.length > 0)
        this.validarPermisoRuta();
      this.escuelaTienePermiso = true;
      this.getAreas();
    }
  },

  data() {
    return {
      loading: false,
      escuelaTienePermiso: false,
      areas: [],
      areaSeleccionada: "",
      abrirEditorArea: false,
      editarArea: false,
      areaParaModificar: null,
      subareas: [],
      subareaSeleccionada: "",
      abrirEditorSubarea: false,
      subareaParaModificar: null,
      abrirEditorInteractivo: false,
      interactivos: [],
      logs: [],
    };
  },
  methods: {
    validarPermisoRuta() {
      const menuInteractivos = this.menu.find(
        (x) => x.title === "Interactivos"
      );
      if (menuInteractivos) {
        const itemIndex = menuInteractivos.items.findIndex(
          (x) => x.title === "Interactivos"
        );
        if (itemIndex == -1) {
          this.$router.push("/dashboard");
        } else this.escuelaTienePermiso = true;
      }
    },

    interactivoAgregado(payload) {
      this.interactivos.push(payload.interactivo);
      this.logs.unshift(payload.nuevoLog);
    },

    modificarAreaSeleccionada() {
      this.areaParaModificar = this.areas.filter(
        (x) => x._id === this.areaSeleccionada
      )[0];
      this.abrirEditorArea = true;
      this.editarArea = true;
    },

    modificarSubareaSeleccionada() {
      this.subareaParaModificar = this.subareas.filter(
        (x) => x._id === this.subareaSeleccionada
      )[0];
      this.abrirEditorSubarea = true;
    },

    cancelarEditarArea() {
      this.abrirEditorArea = false;
      this.editarArea = false;
      this.areaParaModificar = null;
    },

    cancelarEditarSubarea() {
      this.abrirEditorSubarea = false;
      this.subareaParaModificar = null;
    },

    async getAreas() {
      this.loading = true;
      try {
        const serverResponse = await getAreasService();
        this.loading = false;

        this.$validateResponse(serverResponse);

        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else if (serverResponse.areas.length) {
          this.areas = serverResponse.areas;
          this.areaSeleccionada = serverResponse.areas[0]._id;

          this.getSubareas(this.areaSeleccionada);
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
    async getSubareas(areaSeleccionada) {
      this.loading = true;
      try {
        const serverResponse = await getSubareasService(areaSeleccionada);
        this.loading = false;

        this.$validateResponse(serverResponse);

        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else {
          if (serverResponse.subareas.length > 0) {
            this.subareas = serverResponse.subareas;
            this.subareaSeleccionada = this.subareas[0]._id;

            this.getInteractivos();
          } else {
            this.subareas = [];
            this.interactivos = [];
          }
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },

    async getInteractivos() {
      this.loading = true;
      try {
        const serverResponse = await getInteractivosService(
          this.areaSeleccionada,
          this.subareaSeleccionada
        );
        this.loading = false;
        this.$validateResponse(serverResponse);
        if (!serverResponse.ok) {
          this.$systemErrorMessage(serverResponse.mensaje);
          return;
        } else {
          this.logs = serverResponse.logs;
          this.interactivos = serverResponse.interactivos;
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },

    areaGuardada(nuevaArea) {
      const areaExiste = this.areas.filter((e) => e._id === nuevaArea._id)[0];

      if (areaExiste) {
        let index = this.areas.findIndex((x) => x._id === nuevaArea._id);
        this.areas.splice(index, 1, nuevaArea);
      } else {
        this.areas.push(nuevaArea);
        this.areaSeleccionada = nuevaArea._id;
        this.getSubareas(this.areaSeleccionada);
      }

      this.abrirEditorArea = false;
      this.editarArea = false;
      this.areaParaModificar = null;
    },

    subareaGuardada(nuevaArea) {
      const areaExiste = this.subareas.filter(
        (e) => e._id === nuevaArea._id
      )[0];
      if (areaExiste) {
        let index = this.subareas.findIndex((x) => x._id === nuevaArea._id);
        this.subareas.splice(index, 1, nuevaArea);
      } else {
        this.subareas.push(nuevaArea);
        this.subareaSeleccionada = nuevaArea._id;
        this.getInteractivos(this.subareaSeleccionada);
      }
      this.abrirEditorSubarea = false;
      this.subareaParaModificar = null;
    },
    crearInteractivo() {
      this.abrirEditorInteractivo = true;
    },
  },
};
</script>
