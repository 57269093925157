import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VListItem,[_c(VListItemIcon,[(_vm.clase.visto)?_c(VIcon,{attrs:{"color":"green darken-2"}},[_vm._v("mdi-check-circle")]):_c(VIcon,{attrs:{"color":"primary"}},[_vm._v("mdi-play-circle")])],1),_c(VListItemContent,[_c(VListItemTitle,[_c('a',{staticClass:"link",attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.verClase(_vm.clase._id)}}},[_vm._v(" "+_vm._s(_vm.clase.nombre)+" ")])]),_c(VListItemSubtitle,[_c('tiempoChip',{staticStyle:{"margin-right":"5px"},attrs:{"tiempo":_vm.clase.tiempo}}),_c('genericoChip',{attrs:{"icon":"mdi-comment-text-multiple-outline","text":_vm.clase.comentarios}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }