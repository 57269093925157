<template>
  <v-list-item>
    <v-list-item-icon
      :class="{ handle: dragState == true }"
      @mouseover="permisoEscritura && !vistaSeguimiento ? (dragState = true) : null"
      @mouseout="permisoEscritura ? (dragState = false) : null"
    >
      <v-icon v-if="dragState" color="grey">mdi-drag</v-icon>
      <v-icon v-else-if="recurso.recurso.tipo == 'archivo'" color="primary">
        mdi-file-document-outline
      </v-icon>
      <v-icon v-else-if="recurso.recurso.tipo == 'enlace'" color="primary">
        mdi-link-variant
      </v-icon>
    </v-list-item-icon>
    <v-list-item-content>
      <v-list-item-title>
        <switchMiniComponent
          v-if="
            app == 'campus' &&
            permisoEscritura &&
            tipoModulo != 'moduloProfesor' &&
            !vistaSeguimiento
          "
          :active="recurso.visible"
          :loading="loading"
          @activate="modificarVisible()"
          @deactivate="modificarVisible()"
        />

        <a v-if="recurso.recurso.tipo == 'archivo'"  @click="viewerItem(recurso)"  class="ml-1 mr-1">{{ recurso.recurso.nombre }}</a> 
        <a v-else :href="url" class="link" target="_blank">
          {{ recurso.recurso.nombre }}
        </a>
        <v-icon v-if="recurso.recurso.restringir" color="primary" small>mdi-lock</v-icon>
      </v-list-item-title>
      <v-list-item-subtitle>
        {{ recurso.recurso.descripcion }}
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action>
      <recursoMenuComponent
        v-if="permisoEscritura && !vistaSeguimiento"
        :idRecurso="recurso._id"
        :recurso="recurso"
        @recursoModificado="recursoModificado"
        @eliminarRecurso="eliminarRecurso"
      />
    </v-list-item-action>

    <viewer
      v-if="verArchivo.mostrar"
      :mostrar="verArchivo.mostrar"
      :idRecurso="verArchivo.idRecurso"
      :nombreArchivo="verArchivo.nombre"
      :url="verArchivo.url"
      :tipoArchivo="verArchivo.tipo"
      :restringir="verArchivo.restringir"
      @cerrar="verArchivo.mostrar = false"
    />

    <noFile
      v-if="sinArchivo.mostrar"
      :mostrar="sinArchivo.mostrar"
      :nombreArchivo="sinArchivo.nombre"
      @cerrar="sinArchivo.mostrar = false"
    />
  </v-list-item>
</template>

<script>
import { mapGetters } from "vuex";
import switchMiniComponent from "../../../widgets/switchMini.vue";
import recursoMenuComponent from "./recursoMenu.vue";
import { modificarVisibleRecursoService } from "./recursos.service";
import { validaExistenciaService } from "../../../recursos/recursos.service";
import viewer from "../../../widgets/fileViewer.vue";
import noFile from "../../../widgets/noFile.vue";

export default {
  name: "recursoItem",
  props: {
    recurso: Object,
    tipoCurso: String,
    tipoModulo: String,
    idCurso: String,
    permisoEscritura: { type: Boolean, default: false },
  },
  components: { switchMiniComponent, recursoMenuComponent, viewer, noFile },
  created() {
    if (this.recurso.recurso.tipo == "archivo")
      this.url = `${this.documentsURL}/${this.recurso.recurso.route}`;
    else this.url = this.recurso.recurso.route;
  },
  computed: {
    ...mapGetters(["app", "documentsURL", "vistaSeguimiento"]),
  },
  data() {
    return {
      loading: false,
      url: null,
      dragState: false,
      verArchivo:{ mostrar:false, idRecurso: null, nombre:null, url:null, tipo:null, restringir: null },
      sinArchivo:{ mostrar:false, nombre:null }, 
    };
  },
  methods: {
    recursoModificado(recurso) {
      this.$emit("recursoModificado", recurso);
    },
    eliminarRecurso(idRecurso) {
      this.$emit("eliminarRecurso", idRecurso);
    },
    async modificarVisible() {
      const idRecurso = this.recurso._id;
      if (this.permisoEscritura) {
        try {
          this.loading = true;
          const serverResponse = await modificarVisibleRecursoService(
            this.idCurso,
            idRecurso
          );
          this.loading = false;
          if (!serverResponse.ok) {
            this.$systemErrorMessage(serverResponse.mensaje);
          } else {
            const emitData = {
              idRecurso,
              visible: serverResponse.recurso.visible,
            };
            this.$emit("recursoVisibleModificado", emitData);
          }
        } catch (error) {
          this.loading = false;
          this.$appErrorMessage();
        }
      }
    },
    async viewerItem(archivo) {
      try {
        const serverResponse = await validaExistenciaService({url: this.recurso.recurso.route});
        if (!serverResponse.ok)
            this.$systemErrorMessage(serverResponse.mensaje);
        else if(serverResponse.existe){
          
          this.verArchivo = {
            mostrar:true,
            idRecurso: archivo.recurso._id,
            nombre: archivo.recurso.nombre,
            url: this.url,
            tipo:archivo.recurso.type,
            restringir: archivo.recurso.restringir ? archivo.recurso.restringir : false
          }
        }
        else this.sinArchivo = { mostrar:true, nombre: this.recurso.recurso.nombre }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
  },
};
</script>
