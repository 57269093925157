import { VCard } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
import { VTabsSlider } from 'vuetify/lib/components/VTabs';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,[(!_vm.escuelaTienePermiso)?_c(VSkeletonLoader,{attrs:{"type":"list-item-two-line@5"}}):_c(VCard,[_c(VTabs,{attrs:{"centered":"","icons-and-text":"","background-color":"secondary","dark":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c(VTabsSlider,{attrs:{"color":"warning"}}),(_vm.permisoTabInstrumentos)?_c(VTab,{attrs:{"href":"#listas","to":"#listas"}},[_vm._v(" Listas cotejo "),_c(VIcon,[_vm._v(_vm._s(_vm.listaCotejoIcon))])],1):_vm._e(),(_vm.permisoTabInstrumentos)?_c(VTab,{attrs:{"href":"#rubricas","to":"#rubricas"}},[_vm._v(" Rúbricas "),_c(VIcon,[_vm._v(_vm._s(_vm.rubricaIcon))])],1):_vm._e(),(_vm.permisoTabCompartido)?_c(VTab,{attrs:{"href":"#compartidos","to":"#compartidos"}},[_vm._v(" Compartido "),_c(VIcon,[_vm._v("mdi-account-switch")])],1):_vm._e()],1),_c(VTabsItems,{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[(_vm.permisoTabInstrumentos)?_c(VTabItem,{attrs:{"value":"listas"}},[_c(VContainer,[_c('instrumentosListaComponent',{attrs:{"tipo":"listaCotejo","nuevaListaCotejo":_vm.nuevaListaCotejo}})],1)],1):_vm._e(),(_vm.permisoTabInstrumentos)?_c(VTabItem,{attrs:{"value":"rubricas"}},[_c(VContainer,[_c('instrumentosRubricaComponent',{attrs:{"tipo":"rubrica","nuevaRubrica":_vm.nuevaRubrica}})],1)],1):_vm._e(),(_vm.permisoTabCompartido)?_c(VTabItem,{attrs:{"value":"compartidos"}},[_c(VContainer,[_c('instrumentosCompartidosComponent',{on:{"instrumentoClonado":_vm.instrumentoClonado}})],1)],1):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }