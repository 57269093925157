import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSubheader } from 'vuetify/lib/components/VSubheader';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{attrs:{"outlined":""}},[_c(VSubheader,{staticClass:"font-weight-bold"},[_vm._v(" Exámenes "),_c(VSpacer),(!_vm.loading && _vm.permisoEscritura && !_vm.vistaSeguimiento)?_c(VBtn,{attrs:{"icon":"","x-small":"","color":"primary","disabled":_vm.loading},on:{"click":function($event){_vm.mostrarCreacionAplicacion = true}}},[_c(VIcon,[_vm._v("mdi-text-box-plus-outline")])],1):_vm._e()],1),_c(VDivider,{staticClass:"mx-3"}),(_vm.loading)?_c(VCard,{staticClass:"my-2",attrs:{"flat":""}},[_c(VSkeletonLoader,{staticStyle:{"margin":"20px"},attrs:{"type":"list-item-avatar-two-line@3"}})],1):_c('div',[_c(VList,[_c('draggable',_vm._b({attrs:{"element":"div","ghost-class":"ghost","handle":".handle"},on:{"change":_vm.cambiarOrdenAplicacion},model:{value:(_vm.aplicaciones),callback:function ($$v) {_vm.aplicaciones=$$v},expression:"aplicaciones"}},'draggable',_vm.dragOptions,false),_vm._l((_vm.aplicaciones),function(aplicacion){return _c('aplicacion-item',{key:aplicacion._id,attrs:{"idCurso":_vm.idCurso,"tipoCurso":_vm.tipoCurso,"aplicacion":aplicacion,"permisoEscritura":_vm.permisoEscritura},on:{"irAAplicacion":_vm.irAAplicacion}})}),1),(!_vm.aplicaciones.length)?_c(VListItem,[_c(VListItemSubtitle,[_vm._v("No hay exámenes capturados")])],1):_vm._e()],1)],1),(_vm.mostrarCreacionAplicacion)?_c('agregar-aplicacion',{attrs:{"mostrar":_vm.mostrarCreacionAplicacion,"tipoCurso":_vm.tipoCurso,"idCurso":_vm.idCurso},on:{"cancelar":function($event){_vm.mostrarCreacionAplicacion = false},"aplicacionGuardada":_vm.aplicacionGuardada}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }