<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <instruccionesComponent
          :idInteractivo="interactivo._id"
          :tipoInteractivo="tipoInteractivo"
          :texto="interactivo.descripcion"
          :permisoEscritura="permisoEscritura"
          @textoModificado="descripcionModificada"
        />
      </v-col>
      <v-col cols="12">
        <fillblanksBloquesComponent
          :idInteractivo="interactivo._id"
          :tipoInteractivo="tipoInteractivo"
          :permisoEscritura="permisoEscritura"
          :bloques="bloques"
          @bloqueAgregado="bloqueAgregado"
          @bloqueModificado="bloqueModificado"
          @bloqueTextoEliminado="bloqueTextoEliminado"
          @preview="preview = true"
        />
      </v-col>
    </v-row>

    <interactivoViewer
      v-if="preview"
      :mostrar="preview"
      :idInteractivo="interactivo._id"
      :tipoInteractivo="tipoInteractivo"
      :nombreInteractivo="interactivo.nombre"
      @cerrar="preview = false"
    />
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import fillblanksBloquesComponent from "./fillblanksBloques.vue";
import instruccionesComponent from "../instrucciones.vue";
import interactivoViewer from "../interactivoViewer.vue";

export default {
  name: "DragTheWordsView",
  components: {
    fillblanksBloquesComponent,
    instruccionesComponent,
    interactivoViewer,
  },
  props: {
    interactivo: { type: Object, default: () => {} },
    tipoInteractivo: { type: String, default: null },
    permisoEscritura: { type: Boolean, default: false },
  },
  computed: {
    ...mapGetters(["sessionToken", "app", "role", "menu"]),
  },

  mounted() {
    this.texto = this.interactivo.texto;
    this.bloques = this.interactivo.bloques;
  },

  data() {
    return {
      loading: true,
      preview: false,
      texto: "",
      bloques: [],
    };
  },
  methods: {
    descripcionModificada(payload) {
      this.$emit("descripcionModificada", payload);
    },
    textoModificado(payload) {
      this.texto = payload.texto;
      this.$emit("logAgregado", payload.log);
    },
    bloqueAgregado(payload) {
      this.bloques.push("");
      this.$emit("logAgregado", payload.log);
    },
    bloqueModificado(payload) {
      // this.bloques[payload.index] = ;
      this.bloques.splice(payload.index, 1, payload.bloque);
      this.$emit("logAgregado", payload.log);
    },
    bloqueTextoEliminado(payload) {
      this.bloques.splice(payload.index, 1);
      this.$emit("logAgregado", payload.log);
    },
  },
};
</script>
