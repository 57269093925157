<template>
  <v-card outlined>
    <v-toolbar dense flat>
      <v-subheader>Alineaciones</v-subheader>
      <v-spacer />
    </v-toolbar>
    <v-divider class="mx-3" />

    <v-list>
      
      <v-list-item
        v-for="(alineacion, index) in alineacionesLocales"
        :key="`alineacion-${index}`"
      >
        
          <v-list-item-avatar>
            <v-icon large>{{ alineacion.icon }}</v-icon>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>{{ alineacion.name }}</v-list-item-title>
          </v-list-item-content>

          <v-list-item-action>
            <v-checkbox
              v-model="alineacion.value"
              :value="!alineacion.value"
              color="primary"
              :disabled="loading"
              @change="modificarAlineacion(alineacion)"
            ></v-checkbox> 
          </v-list-item-action>
    
      </v-list-item>
    
    </v-list>

  </v-card>
</template>

<script>
import { modificarAlineacionService } from "./findthewords.service.js";

export default {
  name: 'ftwAlineaciones',
  props: {
    idInteractivo: { type: String, default: null },
    tipoInteractivo: { type: String, default: null },
    permisoEscritura: { type: Boolean, default: false },
    alineaciones: { type: Object, default: () => {} },
  },

  data() {
    return {
      loading: false,
      checkbox: false,
      alineacionesLocal: [
        {
          field: 'horizontal',
          value: false,
          name: 'Horizontal',
          icon: 'mdi-arrow-right-bold-box-outline'
        },
        {
          field: 'vertical',
          value: false,
          name: 'Vertical',
          icon: 'mdi-arrow-down-bold-box-outline'
        },
        {
          field: 'diagonal',
          value: false,
          name: 'Diagonal hacia abajo',
          icon: 'mdi-arrow-bottom-right-bold-box-outline'
        },
        {
          field: 'horizontalBack',
          value: false,
          name: 'Horizontal invertido',
          icon: 'mdi-arrow-left-bold-box-outline'
        },
        {
          field: 'verticalUp',
          value: false,
          name: 'Vertical invertido',
          icon: 'mdi-arrow-up-bold-box-outline'
        },
        {
          field: 'diagonalUp',
          value: false,
          name: 'Diagonal hacia arriba',
          icon: 'mdi-arrow-top-right-bold-box-outline'
        },
        {
          field: 'diagonalBack',
          value: false,
          name: 'Diagonal hacia abajo invertido',
          icon: 'mdi-arrow-bottom-left-bold-box-outline'
        },
        {
          field: 'diagonalUpBack',
          value: false,
          name: 'Diagonal hacia arriba invertido',
          icon: 'mdi-arrow-top-left-bold-box-outline'
        },

      ]
    }
  },

  computed: {
    alineacionesLocales () {

      this.alineacionesLocal.forEach((alineacion) => {
        alineacion.value = this.alineaciones[alineacion.field];
      });

      return this.alineacionesLocal;
    }
  },

  methods: {
    async modificarAlineacion (alineacionData) {
      try {
        this.loading = true;

        const serverResponse = await modificarAlineacionService(
          this.idInteractivo,
          alineacionData.field,
          alineacionData.value
        );
        this.loading = false;
        if (!serverResponse.ok) {
        
          this.alineacionesLocales.find(alineacion => {
            if(alineacion['field'] === alineacionData.field)
              alineacion.value = !alineacionData.value
          });
  
          this.$systemErrorMessage(serverResponse.mensaje);
        }
        else {
          const emitData = {
            alineacion: serverResponse.alineacion,
            log: serverResponse.nuevoLog
          }
          this.$emit("alineacionModificada", emitData);
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
      
    }
  }
}


</script>