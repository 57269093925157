<template>
  <div class="text-center">

    <!-- No cuenta con archivo -->
    <i v-if="!documentoActual">No se ha subido la actividad</i>

    <!-- Se tiene archivo  -->
    <v-menu v-else offset-x left close-delay>
      <template v-slot:activator="{ on }">
         <v-btn
            v-if="documentoActual.revision.calificacion === null"
            color="orange darken-2"
            depressed
            small
            outlined
            v-on="on"
            ><v-icon left>mdi-alert</v-icon>
            Sin calificar
          </v-btn>

        <!-- Se ha calificado -->
         <v-btn
            v-else
            color="primary"
            depressed
            outlined
            small
            v-on="on"
         >
          {{ documentoActual.revision.calificacion }}
         </v-btn>
         <div v-if="reenvioXAlumno">
           <p v-if="reenvioXAlumno.existeReenvio">Otro curso ha solicitado reenvío</p>
           <p v-if="reenvioXAlumno.existeNuevoArchivo">El archivo se actualizó en otro curso</p>
         </div>
      </template>

      <calificacionMenuComponent 
        :disableAdd="disableAddCal"
        :disableModify="disableModifyCal"
        :disableShow="disableShowCal"
        @addScore="agregarCalificacion = true"
        @modifyScore="modificarCalificacion"
        @showScore="verDetalles = true"
      />
    </v-menu>

    <historialCalificacionesComponent
      v-if="verDetalles"
      :mostrar="verDetalles"
      :calificaciones="calificaciones"
      @cerrar="verDetalles = false"
    />

    <agregarCalificacionArchivoComponent 
      v-if="agregarCalificacion"
      :mostrar="agregarCalificacion"
      :idCurso="idCurso"
      :idActividad="idActividad"
      :tipoActividad="tipoActividad"
      :instrumento="instrumento"
      :tipoInstrumento="tipoInstrumento"
      :disponible="disponible"
      :alumno="alumnoLocal"
      :equipo="equipoLocal"
      :calificacionModificar="calificacionModificar"
      :ultimoUsuario="ultimoUsuario"
      @btnCambio="btnCambio"

      @cancelar="cancelarAgregarCalificacion"
      @calificacionModificada="calificacionModificada"
    />

  </div>
</template>

<script>
import { mapGetters } from "vuex";
import historialCalificacionesComponent from "./historialCalificaciones.vue";
import calificacionMenuComponent from "./calificacionMenu.vue";
import agregarCalificacionArchivoComponent from "./calificacionEntregas/agregarCalificacionArchivo.vue";

export default {
  name: "calArchivoItem",
  props: {
    idCurso: { type: String, default: null },
    idActividad: { type: String, default: null },
    tipoActividad: { type: Number, default: 2 },
    instrumento: { type: String, default: null },
    tipoInstrumento: { type: String, default: null },
    disponible: { type: Object, default: () => {} },
    alumno: { type: Object, default: () => null },
    equipo: { type: Object, default: () => null },

    permisoEscritura: { type: Boolean, default: false },
    ultimoUsuario: { type: Number, default: null },
    nuevoUsuario: { type: Object, default: () => null },
  },
  components: {
    historialCalificacionesComponent,
    calificacionMenuComponent,
    agregarCalificacionArchivoComponent
  },
  computed: {
    ...mapGetters(["documentsURL", "vistaSeguimiento"]),
    documentoActual() {
      if (this.alumno) {
        if (this.alumno.documentos.length > 0) return this.alumno.documentos[0];
      }

      if (this.equipo) {
        if (this.equipo.documentos.length > 0) return this.equipo.documentos[0];
      }

      return false;
    },

    disableAddCal() {
      if (this.documentoActual.revision.calificacion != null || !this.permisoEscritura || this.vistaSeguimiento) return true;
      else return false;
    },

    disableModifyCal() {
      if (this.documentoActual.revision.calificacion === null || !this.permisoEscritura || this.vistaSeguimiento) return true;
      return false;
    },

    disableShowCal() {
      return this.calificaciones.length === 0;
    },

    calificaciones() {
      const calificaciones = [];
      if (this.alumno) {
        if (this.alumno.documentos.length > 0) {
          this.alumno.documentos.forEach((documento) => {
              if (documento.revision.calificacion != null) {
                calificaciones.push({
                  calificacion: documento.revision.calificacion,
                  fecha: documento.revision.fecha,
                  observaciones: documento.revision.observaciones,
                  nombreArchivo: documento.file.name,
                })
              }
           })
        }
      }

      if (this.equipo) {
        if (this.equipo.documentos.length > 0) {
          this.equipo.documentos.forEach((documento) => {
            if (documento.revision.calificacion != null) {
              calificaciones.push({
                  calificacion: documento.revision.calificacion,
                  fecha: documento.revision.fecha,
                  observaciones: documento.revision.observaciones,
                  nombreArchivo: documento.file.name,
              });
            }
          })
        }
      }

      return calificaciones;

    }
    
  },

  watch: {
    nuevoUsuario(value) {
      if (value != null) {
        if (this.alumno) this.alumnoLocal = value;
        if (this.equipo) this.equipoLocal = value;
      }
      else {
        if (this.alumno) this.alumnoLocal = this.alumno;
        if (this.equipo) this.equipoLocal = this.equipo;
      }
    }
  },

  data() {
    return {
      loading: false,
      agregarCalificacion: false,
      verDetalles: false,
      calificacionModificar: null,
      alumnoLocal: null,
      equipoLocal: null,
      reenvioXAlumno: null
    };
  },

  created() {
    if (this.alumno) {
      this.alumnoLocal = this.alumno;
      this.reenvioXAlumno = this.alumno.reenvioXAlumno;
    }
    if (this.equipo) {
      this.equipoLocal = this.equipo;
      this.reenvioXAlumno = this.equipo.reenvioXAlumno;
    }
  },

  methods: {
    modificarCalificacion() {
      let { calificacion, observaciones, indicadores } = this.documentoActual.revision;
      this.calificacionModificar = { idCalificacion: this.documentoActual._id, calificacion, observaciones, indicadores };
      this.agregarCalificacion = true;
    },
    cancelarAgregarCalificacion () {
      this.calificacionModificar = null;
      this.agregarCalificacion = false;
      this.$emit("cancelarCambio");
    },
    calificacionModificada(calificacion) {
      this.calificacionModificar ? this.agregarCalificacion = false : this.agregarCalificacion = true; 
      this.calificacionModificar = null;
      this.$emit("calificacionModificadaArchivo", calificacion);
    },
    btnCambio(newIndex) {
      this.$emit("btnCambio", newIndex)
    },
  },
};
</script>
