import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{attrs:{"shaped":""}},[_c(VCardTitle,[_c(VSpacer),_c('menuPerfilComponent',{on:{"cambiarImagen":function($event){_vm.cambiarImagen = true},"modificarNombre":function($event){_vm.modificarNombre = true},"modificarContrasena":function($event){_vm.modificarContrasena = true}}})],1),_c(VCol,{staticClass:"justify-center text-center"},[_c(VAvatar,{staticStyle:{"margin-top":"-30px"},attrs:{"size":"250","tile":""}},[(!_vm.userAvatar)?_c(VIcon,{attrs:{"size":"230"}},[_vm._v("mdi-account")]):_c('img',{attrs:{"src":`${_vm.imageURL}/avatar/${_vm.userAvatar}`,"alt":"UserImage"}})],1)],1),_c(VCol,{staticClass:"justify-center",attrs:{"cols":"12"}},[_c(VList,{attrs:{"dense":""}},[_c(VListItem,[_c(VListItemIcon,[_c(VIcon,{attrs:{"color":"primary"}},[_vm._v("mdi-account")])],1),_c(VListItemTitle,[_vm._v(_vm._s(_vm.nombreCompleto))])],1),_c(VListItem,[_c(VListItemIcon,[_c(VIcon,{attrs:{"color":"primary"}},[_vm._v("mdi-at")])],1),_c(VListItemTitle,[_vm._v(_vm._s(_vm.correo))])],1)],1)],1),(_vm.modificarNombre)?_c('modificarNombreComponent',{attrs:{"mostrar":_vm.modificarNombre,"nombre":_vm.nombre,"paterno":_vm.paterno,"materno":_vm.materno},on:{"cancelar":function($event){_vm.modificarNombre = false},"nombreModificado":_vm.nombreModificado}}):_vm._e(),(_vm.modificarContrasena)?_c('modificarContrasenaComponent',{attrs:{"mostrar":_vm.modificarContrasena},on:{"cancelar":function($event){_vm.modificarContrasena = false},"contrasenaModificada":_vm.contrasenaModificada}}):_vm._e(),(_vm.cambiarImagen)?_c('cambiarImagenComponent',{attrs:{"mostrar":_vm.cambiarImagen},on:{"cancelar":function($event){_vm.cambiarImagen = false}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }