import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const MainView = () =>
    import('@/components/globals/template/mainView/main.view')
const DashboardView = () =>
    import('@/components/dashboard/dashboard.view')

const ListaCursosView = () =>
    import('@/components/globals/template/cursos/listadoCursos/listadoCursos.view')
const CursoView = () =>
    import('@/components/globals/template/cursos/curso/curso.view')
const ModuloView = () =>
    import('@/components/globals/template/cursos/modulos/modulo.view')
const ClaseView = () =>
    import('@/components/globals/template/cursos/clases/clase.view')
const ActividadView = () =>
    import('@/components/globals/template/cursos/actividades/actividad.view')
const PerfilView = () =>
    import('@/components/perfil/perfil.view')
const CambioAppView = () =>
    import('@/components/globals/cambioApp/cambioApp.view')
const ExamenAplicacionView = () =>
    import('@/components/globals/template/cursos/examenAplicaciones/examenAplicacion.view')

const LoginView = () =>
    import('@/components/login/login.view')
const RestaurarPassView = () =>
    import('@/components/login/restaurarPass.view')

const RegistroView = () =>
    import('@/components/login/registro.view')

const ActivacionView = () =>
    import('@/components/login/activarCuenta.view')

const InstrumentosView = () =>
    import('@/components/globals/template/instrumentos/instrumentos.view')
const ListaCotejoView = () =>
    import('@/components/globals/template/instrumentos/listaCotejo/listaCotejo.view')
const RubricaView = () =>
    import('@/components/globals/template/instrumentos/rubrica/rubrica.view')

const EncuestasView = () =>
    import('@/components/globals/template/encuestas/encuestas.view')
const EncuestaView = () =>
    import('@/components/globals/template/encuestas/encuesta.view')
const SeccionEncuestaView = () =>
    import('@/components/globals/template/encuestas/seccionEncuesta.view')

const ExamenesView = () =>
    import('@/components/globals/template/examenes/examenes.view')
const ExamenView = () =>
    import('@/components/globals/template/examenes/examen.view')
const SeccionExamenView = () =>
    import('@/components/globals/template/examenes/examenSeccion.view')

const AplicacionExamenView = () =>
    import('@/components/globals/template/cursos/examenAplicaciones/aplicacion/aplicacionExamen.view')
const SeccionAplicacionExamenView = () =>
    import('@/components/globals/template/cursos/examenAplicaciones/aplicacion/seccion/seccionAplicacionExamen.view')

const VideosView = () =>
    import('@/components/globals/template/videos/videos.view')

const VideoView = () =>
    import('@/components/globals/template/videos/video.view')

const RecursosView = () =>
    import('@/components/globals/template/recursos/recursos.view')

const InteractivosView = () =>
    import('@/components/globals/template/interactivos/interactivos.view')
const InteractivoView = () =>
    import('@/components/globals/template/interactivos/interactivo.view')

const HorariosView = () =>
    import('@/components/horarios/horarios.view');

const ControlParentalView = () =>
    import('@/components/globals/template/controlParental/controlParental.view')

const ListaPlaneacionesView = () =>
    import('@/components/globals/template/planeaciones/listadoPlaneaciones.view')

const DetallePlaneacionView = () =>
    import('@/components/globals/template/planeaciones/planeacion.view')

const DetalleModuloView = () =>
    import('@/components/globals/template/planeaciones/planeacion/modulos/modulo/planeacionModulo.view')




export default new Router({
    mode: 'history', // https://router.vuejs.org/api/#mode
    linkActiveClass: 'open active',
    scrollBehavior: () => ({ y: 0 }),
    routes: [{
        path: '/',
        redirect: '/dashboard',
        name: 'Main',
        component: MainView,
        props: { app: 'campus' },
        children: [
            { path: 'dashboard', name: 'Dashboard', component: DashboardView },
            { path: 'perfil', name: 'Perfil', component: PerfilView },

            { path: 'cursos', name: 'Cursos', component: ListaCursosView },
            { path: 'curso/:tipoCurso/:idCurso', name: 'Curso', component: CursoView },
            { path: "/curso/:tipoCurso/:idCurso/tipo/:tipoModulo/:idModulo", name: "Modulo", component: ModuloView },
            { path: 'curso/:tipoCurso/:idCurso/:tipoModulo/clase/:idClase', name: 'Clase', component: ClaseView },
            { path: 'curso/:tipoCurso/:idCurso/:tipoModulo/vista/:tipoVista/actividad/:idActividad', name: 'Actividad', component: ActividadView },
            { path: 'curso/:tipoCurso/:idCurso/:idModulo/aplicacion/:idAplicacion', name: 'ExamenAplicacion', component: ExamenAplicacionView },
            { path: 'curso/:tipoCurso/:idCurso/:idModulo/aplicacion/:idAplicacion/examen/:idExamen', name: 'AplicacionExamen', component: AplicacionExamenView },
            { path: 'curso/:tipoCurso/:idCurso/:idModulo/aplicacion/:idAplicacion/examen/:idExamen/seccion/:idSeccion', name: 'SeccionAplicacionExamen', component: SeccionAplicacionExamenView },

            { path: 'instrumentos', name: 'instrumentos', component: InstrumentosView },
            { path: 'instrumentos/listaCotejo/:idInstrumento', name: 'listaCotejo', component: ListaCotejoView },
            { path: 'instrumentos/rubrica/:idInstrumento', name: 'rubrica', component: RubricaView },

            { path: 'planeaciones', name: 'planeaciones', component: ListaPlaneacionesView },
            { path: 'planeaciones/planeacion/:idPlaneacion', name: 'planeacionDetalle', component: DetallePlaneacionView },
            { path: 'planeaciones/planeacion/:idPlaneacion/modulo/:idModulo', name: 'planeacionModuloDetalle', component: DetalleModuloView },

            { path: 'encuestas', name: 'Encuestas', component: EncuestasView },
            { path: 'encuestas/:idEncuesta', name: 'Encuesta', component: EncuestaView },
            { path: 'encuestas/:idEncuesta/seccion/:idSeccion', name: 'Sección de encuesta', component: SeccionEncuestaView },

            { path: 'examenes', name: 'Examenes', component: ExamenesView },
            { path: 'examenes/:idExamen', name: 'Examen', component: ExamenView },
            { path: 'examenes/:idExamen/seccion/:idSeccion', name: 'SeccionExamen', component: SeccionExamenView },

            { path: 'videos', name: 'Videos', component: VideosView },
            { path: 'video/:idArea/:subarea/:idVideo', name: 'Video', component: VideoView },
            { path: 'recursos', name: 'Recursos', component: RecursosView },

            { path: "/interactivos", name: "Interactivos", component: InteractivosView },
            { path: "/interactivos/:tipoInteractivo/:idInteractivo", name: "Interactivo", component: InteractivoView },

            { path: 'examenes/presentar/:idResultado', name: 'PresentarExamen', component: AplicacionExamenView },
            { path: 'examenes/presentar/:idResultado/examen/:idExamen/seccion/:idSeccion', name: 'SeccionPresentarExamen', component: SeccionAplicacionExamenView },

            { path: 'horarios/', name: 'Horarios', component: HorariosView },
            { path: 'controlParental', name: 'controlParental', component: ControlParentalView },
        ]
    },

    { path: '/login', name: 'Login', component: LoginView },
    // { path: '/logout', name: 'Logout', component: LogoutView },
    { path: '/registro', name: 'Registro', component: RegistroView },
    { path: '/activacion/:idUsuario', name: 'Activacion', component: ActivacionView },
    { path: '/restaurarPass/:token', name: 'Restaurar password', component: RestaurarPassView },
    { path: '/cambioApp/:token', name: 'Redirección', component: CambioAppView },

    {
        //Cualquier ruta que no exista, se redireccionará al main
        path: '*',
        redirect: '/main',
        // name: 'Not found',
        // component: Page404
    }
    ]
})