<template>
  <div>
    <v-list-item
      dense
      @mouseover="puedeModificar ? (modificarIcon = true) : null"
      @mouseout="modificarIcon = false"
    >
      <v-list-item-icon>
        <v-btn
          v-if="modificarIcon && !vistaSeguimiento"
          fab
          x-small
          color="primary"
          @click="modificarIntentos = true"
        >
          <v-icon small>mdi-pencil</v-icon>
        </v-btn>
        <v-icon v-else color="primary">mdi-refresh</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>Intentos</v-list-item-title>
        <v-list-item-subtitle>
          {{ intentos }}
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>

    <modificarIntentosActividadComponent
      v-if="modificarIntentos"
      :mostrar="modificarIntentos"
      :tipoCurso="tipoCurso"
      :idCurso="idCurso"
      :idActividad="idActividad"
      :intentos="intentos"
      @cancelar="modificarIntentos = false"
      @intentosModificado="intentosModificado"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import modificarIntentosActividadComponent from "./modificarIntentosActividad.vue";

export default {
  name: "entregaActividad",
  props: {
    tipoCurso: String,
    idCurso: String,
    idActividad: String,
    intentos: Number,
    permisoEscritura: { type: Boolean, default: false },
  },
  components: { modificarIntentosActividadComponent },
  computed: {
    ...mapGetters(["vistaSeguimiento"]),
    puedeModificar() {
      return this.permisoEscritura && !this.vistaSeguimiento;
    },
  },
  data() {
    return {
      modificarIcon: false,
      modificarIntentos: false,
    };
  },
  methods: {
    intentosModificado(data) {
      this.modificarIntentos = false;
      this.$emit("intentosModificado", data);
    },
  },
};
</script>
