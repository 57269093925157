import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VContainer,{class:{ 'pt-5': _vm.app !== 'campus' }},[(!_vm.escuelaTienePermiso)?_c(VSkeletonLoader,{attrs:{"type":"list-item-two-line@5"}}):_c(VCard,{attrs:{"rounded":"lg"}},[_c(VToolbar,{staticClass:"text-h6",attrs:{"dense":"","color":"secondary","dark":"","flat":""}},[_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){return _vm.$router.push(`/examenes/${_vm.seccion.examen._id}`)}}},[_c(VIcon,[_vm._v("mdi-arrow-left")])],1),_c(VSpacer),_vm._v(" "+_vm._s(_vm.seccion ? _vm.seccion.examen.nombre : "")+" "),_c(VSpacer),(_vm.permisoEscritura)?_c(VMenu,{attrs:{"offset-y":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-dots-vertical")])],1)]}}],null,false,3221905750)},[_c(VList,_vm._l((_vm.menuSeccion),function(menuSeccionItem,index){return _c(VListItem,{key:index,on:{"click":function($event){return _vm.menuAction(menuSeccionItem)}}},[_c(VListItemTitle,[_c(VIcon,{staticClass:"mr-2",attrs:{"small":""}},[_vm._v(_vm._s(menuSeccionItem.icon))]),_c('span',[_vm._v(_vm._s(menuSeccionItem.text))])],1)],1)}),1)],1):_vm._e()],1),(_vm.loading || !_vm.seccion)?_c(VContainer,[_c('loading-seccion')],1):_c(VContainer,[_c(VCardTitle,{staticClass:"py-1 px-3"},[_c(VTooltip,{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":"","disabled":!_vm.prevSeccion},on:{"click":function($event){return _vm.$router.push(
                    `/examenes/${_vm.seccion.examen._id}/seccion/${_vm.prevSeccion}`
                  )}}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-chevron-left")])],1)]}}])},[_c('span',[_vm._v("Sección anterior")])]),_c(VSpacer),_vm._v(" "+_vm._s(_vm.seccion.nombre)+" "),_c(VSpacer),_c(VTooltip,{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":"","disabled":!_vm.nextSeccion},on:{"click":function($event){return _vm.$router.push(
                    `/examenes/${_vm.seccion.examen._id}/seccion/${_vm.nextSeccion}`
                  )}}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-chevron-right")])],1)]}}])},[_c('span',[_vm._v("Sección siguiente")])])],1),_c(VDivider,{staticClass:"mx-3 my-3"}),_c(VCardText,{staticClass:"pa-0 text--secondary"},[_c(VContainer,[_c('span',[_c('strong',[_vm._v("Tiempo de duración:")]),_vm._v(" "+_vm._s(_vm.seccion.tiempo)+" minutos")])]),_c(VContainer,[(_vm.seccion.descripcion && _vm.seccion.descripcion !== '<p></p>')?_c('div',{staticClass:"ck-content mt-2",staticStyle:{"overflow":"hidden"},domProps:{"innerHTML":_vm._s(_vm.seccion.descripcion)}}):_c('div',[_c('span',[_vm._v("No se ha capturado descripción.")])])]),_c(VContainer,[_c('examen-paginas',{attrs:{"paginas":_vm.seccion.paginas,"permisoEscritura":_vm.permisoEscritura},on:{"paginaGuardada":_vm.paginaGuardada}})],1)],1)],1)],1)],1),(_vm.mostrarModificarSeccion)?_c('modificar-seccion',{attrs:{"mostrar":_vm.mostrarModificarSeccion,"seccion":_vm.seccion},on:{"cancelar":function($event){_vm.mostrarModificarSeccion = false},"seccionGuardada":_vm.seccionGuardada}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }