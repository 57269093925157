<template>
  <div>
    <v-card elevation="0" color="grey lighten-4">
      <v-card-title>
        <span class="text-subtitle-1 font-weight-bold text--secondary"
          >Actividades</span
        >
        <v-spacer></v-spacer>
        <v-tooltip left>
          <template #activator="{ on, attrs }">
            <v-btn
              v-on="on"
              v-bind="attrs"
              fab
              x-small
              color="primary"
              @click="showSelectorActividades = true"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <span>Vincular actividad</span>
        </v-tooltip>
      </v-card-title>
      <v-card-text>
        <v-list-item v-for="actividad in actividadesLocal" :key="actividad._id">
          <v-list-item-icon>
            <v-icon color="primary">{{ iconoActividad(actividad) }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ actividad.nombre }}</v-list-item-title>
            <v-list-item-subtitle>{{
              actividad.cursoPlantillaModulo
                ? actividad.cursoPlantillaModulo.nombre
                : actividad.cursoModulo.nombre
            }}</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-tooltip left>
              <template #activator="{on, attrs}">
                <v-btn
                  v-on="on"
                  v-bind="attrs"
                  icon
                  small
                  :loading="loadingDeleteActividad == actividad._id"
                  @click="quitarActividad(actividad)"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </template>
              <span>Quitar actividad</span>
            </v-tooltip>
          </v-list-item-action>
        </v-list-item>
      </v-card-text>
    </v-card>

    <selector-actividades
      v-if="showSelectorActividades"
      :show="showSelectorActividades"
      :idPlaneacion="idPlaneacion"
      :idModulo="idModulo"
      :idElemento="idElemento"
      @close="showSelectorActividades = false"
      @actividadAgregada="actividadAgregada"
    ></selector-actividades>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { quitarActividadDeModuloElemento } from "./planeacionModuloElementos.service";

export default {
  components: {
    "selector-actividades": () => import("./selectorActividadesPlaneacion.vue"),
  },

  props: {
    moduloElemento: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      showSelectorActividades: false,
      actividadesLocal: this.moduloElemento.actividades,
      loadingDeleteActividad: null,
    };
  },

  computed: {
    ...mapGetters([
      "actividadDirectaIcon",
      "actividadArchivoIcon",
      "actividadForoIcon",
      "actividadInteractivaIcon",
      "actividadTransversalIcon",
      "examenIcon",
    ]),

    idPlaneacion() {
      return this.moduloElemento ? this.moduloElemento.planeacion : null;
    },

    idModulo() {
      return this.moduloElemento ? this.moduloElemento.modulo : null;
    },

    idElemento() {
      return this.moduloElemento ? this.moduloElemento._id : null;
    },

    iconoActividad() {
      return function(actividad) {
        if (actividad.examenAplicacion) return this.examenIcon;
        const iconosTipo = {
          1: this.actividadDirectaIcon,
          2: this.actividadArchivoIcon,
          3: this.actividadForoIcon,
          4: this.actividadInteractivaIcon,
          5: this.actividadTransversalIcon,
        };
        return iconosTipo[actividad.tipo];
      };
    },
  },

  methods: {
    actividadAgregada(actividad) {
      this.showSelectorActividades = false;
      this.actividadesLocal.push(actividad);
    },

    async quitarActividad(actividad) {
      this.loadingDeleteActividad = actividad._id;

      try {
        const serverResponse = await quitarActividadDeModuloElemento({
          idPlaneacion: this.idPlaneacion,
          idModulo: this.idModulo,
          idElemento: this.idElemento,
          idActividad: actividad._id,
        });
        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else
          this.actividadesLocal = this.actividadesLocal.filter(
            (a) => a._id != actividad._id
          );
      } catch (error) {
        console.error(error);
        this.$appErrorMessage();
      } finally {
        this.loadingDeleteActividad = null;
      }
    },
  },
};
</script>
