import { VBanner } from 'vuetify/lib/components/VBanner';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"fullscreen":""},model:{value:(_vm.mostrar),callback:function ($$v) {_vm.mostrar=$$v},expression:"mostrar"}},[_c(VCard,{staticClass:"overflow-y-auto",attrs:{"max-height":"200"}},[_c(VBanner,{staticClass:"text-bold",attrs:{"sticky":"","single-line":"","color":"primary","tile":""}},[_c(VBtn,{attrs:{"icon":"","color":"#FFF"},on:{"click":function($event){return _vm.$emit('cancelar')}}},[_c(VIcon,[_vm._v("mdi-window-close")])],1)],1),_c(VCardText,[(_vm.tipoInstrumento === 'listaCotejo' )?_c('listaCotejoViewComponent',{attrs:{"esActividad":_vm.esActividad,"instrumentoActividad":_vm.instrumento._id}}):_c('rubricaViewComponent',{attrs:{"esActividad":_vm.esActividad,"instrumentoActividad":_vm.instrumento._id}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }