<template>
  <div>
    <v-list-item
      dense
      @mouseover="puedeModificar ? (modificarIcon = true) : null"
      @mouseout="modificarIcon = false"
    >
      <v-list-item-icon>
        <v-btn
          v-if="modificarIcon && !vistaSeguimiento"
          fab
          x-small
          color="primary"
          @click="modificarEntrega = true"
        >
          <v-icon small>mdi-pencil</v-icon>
        </v-btn>
        <v-icon v-else color="primary">{{
          entrega == 1 ? "mdi-account" : "mdi-account-multiple"
        }}</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>Entrega</v-list-item-title>
        <v-list-item-subtitle>
          <span v-if="localEntrega == 1">Individual</span>
          <span v-else>Equipos de hasta {{ localEntrega }} integrantes</span>
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <modificarEntregaActividad
      v-if="modificarEntrega"
      :mostrar="modificarEntrega"
      :tipoCurso="tipoCurso"
      :idCurso="idCurso"
      :idActividad="idActividad"
      :tipo="tipo"
      :entrega="entrega"
      :totalEquipos="equipos.length"
      :transversal="transversal"
      @cancelar="modificarEntrega = false"
      @entregaModificada="entregaModificada"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import modificarEntregaActividad from "./modificarEntregaActividad.vue";
export default {
  name: "entregaActividad",
  props: {
    tipoCurso: String,
    idCurso: String,
    idActividad: String,
    tipo: Number,
    entrega: Number,
    equipos: Array,
    permisoEscritura: { type: Boolean, default: false },
    transversal: { type: Object, default: null },
  },
  components: { modificarEntregaActividad },
  computed: {
    ...mapGetters(["vistaSeguimiento"]),
    puedeModificar() {
      return (this.permisoEscritura && !this.vistaSeguimiento && this.tipo != 4 && !this.transversal);
    }
  },
  data() {
    return {
      modificarIcon: false,
      modificarEntrega: false,
      localEntrega: 1,
    };
  },
  mounted() {
    this.asignarEntrega();
  },
  methods: {
    asignarEntrega(){
      if(this.transversal) this.localEntrega = this.transversal.entrega
      else this.localEntrega =  this.entrega
    },
    entregaModificada(data) {
      this.modificarEntrega = false;
      this.$emit("entregaModificada", data);
    },
  },
};
</script>
