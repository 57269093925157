<template>
  <div>
    <v-list-item>
      <v-list-item-icon>
        <v-icon color="primary">mdi-book-open-outline</v-icon>
      </v-list-item-icon>

      <v-list-item-content>
        <v-list-item-title>{{ detallePlantilla.nombre }}</v-list-item-title>
        <v-list-item-subtitle
          >Plantilla, {{ origenPlantilla }}</v-list-item-subtitle
        >
      </v-list-item-content>
    </v-list-item>
  </div>
</template>

<script>
export default {
  props: {
    detallePlantilla: {
      type: Object,
      required: true,
    },
  },

  computed: {
    origenPlantilla() {
      return this.detallePlantilla.escuela
        ? this.detallePlantilla.escuela.nombreCorto
        : this.detallePlantilla.empresa.nombreCorto;
    },
  },
};
</script>
