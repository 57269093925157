import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
import { VTimeline } from 'vuetify/lib/components/VTimeline';
import { VTimelineItem } from 'vuetify/lib/components/VTimeline';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VTimeline,{attrs:{"dense":"","align-top":""}},[_vm._l((4),function(n){return _c(VTimelineItem,{key:n,scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c(VAvatar,{attrs:{"size":"40"}},[_c('img',{attrs:{"src":"https://i.pravatar.cc/64"}})])]},proxy:true}],null,true)},[_c('respuestaComponent')],1)}),_c(VTimelineItem,{scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c(VAvatar,{attrs:{"size":"40"}},[_c('img',{attrs:{"src":"https://i.pravatar.cc/64"}})])]},proxy:true}])},[_c(VTextarea,{attrs:{"flat":"","label":"Respuesta...","outlined":"","rows":"3"}}),_c(VBtn,{attrs:{"color":"primary","small":""}},[_vm._v("Responder")])],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }