<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <instruccionesComponent
          :idInteractivo="interactivo._id"
          :tipoInteractivo="tipoInteractivo"
          :texto="interactivo.descripcion"
          :permisoEscritura="permisoEscritura"
          @textoModificado="descripcionModificada"
        />
      </v-col>
      <v-col cols="12">
        <v-card outlined>
          <v-card-title
            >Palabras:
            <v-spacer />
            <v-btn
              v-if="crosswordItems.length > 1"
              color="primary"
              small
              icon
              @click="preview = true"
            >
              <v-icon>mdi-motion-play-outline</v-icon>
            </v-btn>
            <v-btn v-if="permisoEscritura" icon color="primary" @click="agregarItem()">
              <v-icon>mdi-playlist-plus</v-icon></v-btn
            >
          </v-card-title>
          <v-card-text v-if="crosswordItems.length === 0">
            <i>No se han agregado palabras</i>
          </v-card-text>
          <v-list>
            <v-divider class="mx-3"></v-divider>
            <div v-for="(item, index) of crosswordItems" :key="item._id">
              <v-list-item three-line>
                <v-list-item-content>
                  <v-list-item-title>{{ item.answer }}</v-list-item-title>
                  <v-list-item-subtitle>{{ item.clue }}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action v-if="permisoEscritura">
                  <v-menu offset-y left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon small v-bind="attrs" v-on="on">
                        <v-icon>mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>
                    <v-list dense>
                      <v-list-item @click="modificarItem(item)">
                        <v-list-item-title>Modificar palabra</v-list-item-title>
                        <v-list-item-icon
                          ><v-icon color="primary" small
                            >mdi-pencil</v-icon
                          ></v-list-item-icon
                        >
                      </v-list-item>
                      <v-list-item @click="eliminarItemAction(item)">
                        <v-list-item-title>Eliminar palabra</v-list-item-title>
                        <v-list-item-icon
                          ><v-icon small color="red darken-1"
                            >mdi-trash-can</v-icon
                          ></v-list-item-icon
                        >
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-list-item-action>
              </v-list-item>
              <v-divider
                v-if="index < crosswordItems.length - 1"
                class="mx-3"
              ></v-divider>
            </div>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
    <agregarModificarItem
      v-if="mostrarAgregarModificar"
      :mostrar="mostrarAgregarModificar"
      :modificar="modificar"
      :item="itemModificar"
      :idCrossword="interactivo._id"
      @cancelar="mostrarAgregarModificar = false"
      @itemAgregado="itemAgregado"
      @itemModificado="itemModificado"
    />
    <interactivoViewer
      v-if="preview"
      :mostrar="preview"
      :idInteractivo="interactivo._id"
      :tipoInteractivo="tipoInteractivo"
      :nombreInteractivo="interactivo.nombre"
      @cerrar="preview = false"
    />
    <eliminarItemComponent
      v-if="eliminarItem"
      :mostrar="eliminarItem"
      :item="itemEliminar"
      :idCrossword="interactivo._id"
      @cancelar="eliminarItem = false"
      @crosswordItemEliminado="itemEliminado"
    />
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import instruccionesComponent from "../instrucciones.vue";
import agregarModificarItem from "./agregarModificarItem.vue";
import eliminarItemComponent from "./eliminarItem.vue";
import interactivoViewer from "../interactivoViewer.vue";

export default {
  name: "CrosswordView",
  components: {
    instruccionesComponent,
    agregarModificarItem,
    eliminarItemComponent,
    interactivoViewer,
  },
  props: {
    interactivo: { type: Object, default: () => {} },
    tipoInteractivo: { type: String, default: null },
    permisoEscritura: { type: Boolean, default: false },
  },
  computed: {
    ...mapGetters(["sessionToken", "app", "role", "menu"]),
  },

  mounted() {
    this.crosswordItems = this.interactivo.items;
  },

  data() {
    return {
      loading: true,
      crosswordItems: [],
      preview: false,
      mostrarAgregarModificar: false,
      modificar: false,
      itemModificar: null,
      eliminarItem: false,
      itemEliminar: null,
    };
  },
  methods: {
    descripcionModificada(payload) {
      this.$emit("descripcionModificada", payload);
    },
    agregarItem() {
      this.modificar = false;
      this.itemModificar = null;
      this.mostrarAgregarModificar = true;
    },
    itemAgregado(payload) {
      this.$emit("logAgregado", payload.nuevoLog);
      this.crosswordItems.push(payload.crosswordItem);
      this.mostrarAgregarModificar = false;
    },
    modificarItem(item) {
      this.modificar = true;
      this.itemModificar = item;
      this.mostrarAgregarModificar = true;
    },
    itemModificado(payload) {
      this.$emit("logAgregado", payload.nuevoLog);
      const crosswordItem = payload.crosswordItem;
      const index = this.crosswordItems.findIndex(
        (x) => x._id === crosswordItem._id
      );
      if (index >= 0) this.crosswordItems.splice(index, 1, crosswordItem);
      this.mostrarAgregarModificar = false;
    },
    eliminarItemAction(item) {
      this.itemEliminar = item;
      this.eliminarItem = true;
    },
    itemEliminado(payload) {
      this.$emit("logAgregado", payload.nuevoLog);
      const index = this.crosswordItems.findIndex(
        (x) => x._id === this.itemEliminar._id
      );
      if (index >= 0) this.crosswordItems.splice(index, 1);
      this.eliminarItem = false;
    },
  },
};
</script>
