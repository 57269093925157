<template>
  <v-card :loading="loading">
    <v-container>
      <v-btn
        v-if="
          !loading && fechaDisponible && actividad.intentos > intentos.length
        "
        color="primary"
        small
        @click="realizarInteractivo = true"
        >Realizar actividad</v-btn
      >
      <v-card-text v-if="intentos.length == 0"
        >No se ha realizado la actividad</v-card-text
      >
      <template v-else>
        <v-list>
          <v-list-item>
            <v-list-item-avatar>
              <v-avatar color="indigo" size="36">
                <span class="white--text text-strong">{{
                  calificacion.calificacion
                }}</span>
              </v-avatar>
            </v-list-item-avatar>
            <v-list-item-title>Calificación</v-list-item-title>
          </v-list-item>
          <v-list-item v-for="intento in intentos" :key="intento._id">
            <v-list-item-avatar>
              <v-avatar color="secondary" size="36">
                <span class="white--text text-strong">{{
                  intento.calificacion
                }}</span>
              </v-avatar>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title
                >Intento {{ intento.numero }}</v-list-item-title
              >
              <v-list-item-subtitle>{{ intento.observaciones }}</v-list-item-subtitle>
              <v-list-item-subtitle>{{ intento.fecha }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </template>

      <interactivoViewer
        v-if="realizarInteractivo"
        :mostrar="realizarInteractivo"
        :preview="false"
        :idActividad="actividad._id"
        :idInteractivo="actividad.interactivo._id"
        :tipoInteractivo="actividad.tipoInteractivo"
        :nombreInteractivo="actividad.interactivo.nombre"
        @cerrar="cargarAvance()"
      />
    </v-container>
  </v-card>
</template>

<script>
import { DateTime } from "luxon";
import interactivoViewer from "../../../interactivos/interactivoViewer.vue";
import { intentosAlumnoActividadService } from "./calificacionesAlumno.service";

export default {
  props: {
    idCurso: { type: String, default: null },
    actividad: { type: Object, default: () => ({}) },
    idUsuario: { type: String, default: null },
  },
  components: { interactivoViewer },
  computed: {
    fechaDisponible() {
      if (this.actividad.disponible.abierta) return true;
      var fechaHoy = DateTime.now();
      var fechaInicio = DateTime.fromISO(this.actividad.disponible.inicio);
      var fechaFin = DateTime.fromISO(this.actividad.disponible.fin);

      if (fechaHoy < fechaInicio) return false;
      if (fechaHoy > fechaFin) return this.actividad.entregaExt;
      return true;
    },
  },
  data() {
    return {
      loading: true,
      realizarInteractivo: false,
      calificacion: {},
      intentos: [],
    };
  },
  mounted() {
    this.cargarAvance();
  },
  methods: {
    async cargarAvance() {
      this.realizarInteractivo = false;
      try {
        this.loading = true;
        const serverResponse = await intentosAlumnoActividadService(
          this.idCurso,
          this.actividad._id
        );
        this.loading = false;
        if (!serverResponse.ok) {
          this.$systemErrorMessage(serverResponse.mensaje);
        } else {
          this.calificacion = serverResponse.calificacion;
          this.intentos = serverResponse.intentos;
          this.intentos.forEach((intento) => {
            intento.fecha = DateTime.fromISO(intento.fecha).toLocaleString(
              DateTime.DATE_FULL
            );
          });
        }
      } catch (error) {
        console.error(error);
        this.loading = false;
        this.$appErrorMessage();
      }
    },
  },
};
</script>
