import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.entrega > 1 && !_vm.esAlumno)?_c(VCard,{staticClass:"mb-2",attrs:{"flat":"","outlined":""}},[_c(VToolbar,{attrs:{"dense":"","flat":"","dark":"","color":"primary"}},[_c(VToolbarTitle,[_c('span',[_vm._v("Equipos")])])],1),_c('listadoEquipos',{attrs:{"idCurso":_vm.idCurso,"idActividad":_vm.idActividad,"idModulo":_vm.idModulo,"entrega":_vm.entrega,"alumnos":_vm.alumnos,"equipos":_vm.equipos,"permisoEscritura":_vm.permisoEscritura,"desdeComentario":true},on:{"equipoAgregado":_vm.equipoAgregado,"equipoModificado":_vm.equipoModificado,"equiposImportados":_vm.equiposImportados,"equipoActividadEliminado":_vm.equipoActividadEliminado}})],1):_vm._e(),_c(VCard,{attrs:{"outlined":"","loading":_vm.loading}},[_c(VToolbar,{attrs:{"dense":"","flat":"","dark":"","color":"primary"}},[_c(VToolbarTitle,[_c('span',[_vm._v("Comentarios")])])],1),(_vm.loading)?_c(VCard,{attrs:{"flat":"","outlined":""}},[_c(VSkeletonLoader,{staticClass:"ma-2",attrs:{"type":"list-item-avatar, list-item-three-line"}})],1):_c(VCardText,[_c('div',{staticClass:"mb-3"},[_c('listado-comentarios',{attrs:{"actualizarTipo":_vm.actualizarTipo,"actualizarEntregas":_vm.actualizarEntregas,"actualizarActividad":_vm.actualizarActividad,"idCurso":_vm.idCurso,"idActividad":_vm.idActividad,"entrega":_vm.entrega,"idClase":_vm.idClase,"entregaActividad":_vm.entregaActividad,"comentarios":_vm.comentariosLocal,"idEquipo":_vm.idEquipo,"cargarCalificacionesAlumnos":_vm.permisoEscritura,"permisoEscritura":_vm.permisoEscritura || _vm.vistaSeguimiento,"cursoArchivado":_vm.cursoArchivado}})],1),(!_vm.restringirComentario && !_vm.restringirPorTiempo && _vm.puedeComentar && !_vm.vistaSeguimiento && !_vm.cursoArchivado)?_c('agregar-comentario',{attrs:{"idActividad":_vm.idActividad,"idClase":_vm.idClase}}):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }