<template>
  <v-card outlined>
    <v-toolbar dense flat>
      <v-subheader>Palabras</v-subheader>
      <v-spacer />
      <v-btn
        v-if="ftwCompleto"
        color="primary"
        small
        icon
        @click="$emit('preview')"
      >
        <v-icon>mdi-motion-play-outline</v-icon>
      </v-btn>
      <v-btn
        v-if="permisoEscritura"
        small
        icon
        color="primary"
        style="margin-right: 5px;"
        :disabled="agregarPalabra || modificarPalabra.mostrar"
        @click="agregarPalabra = true"
        ><v-icon>mdi-playlist-plus</v-icon></v-btn
      >
    </v-toolbar>
    <v-divider class="mx-3" />
    <v-list>

      <v-list-item
        v-for="(palabra, index) in palabras"
        :key="`dist-${index}`"
      >
        <v-list-item-action
          v-if="permisoEscritura && !modificarPalabra.mostrar"
        >
          <v-menu offset-y :disabled="loading">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" x-small dark icon v-bind="attrs" v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list dense>
              <v-list-item
                one-line
                @click="mostrarModificarPalabra(index, palabra)"
              >
                <v-list-item-title>Modificar</v-list-item-title>
              </v-list-item>
              <v-list-item one-line @click="eliminarPalabra(index)">
                <v-list-item-title>Eliminar</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-list-item-action>
        <template v-if="modificarPalabra.index !== index">
          <v-list-item-title>
            {{ palabra }}
          </v-list-item-title>
        </template>
        <template v-else-if="modificarPalabra.index === index">
          <v-list-item-title>
            <v-text-field
              v-model="modificarPalabra.palabra"
              outlined
              dense
              :disabled="loading"
            ></v-text-field>
          </v-list-item-title>
          <v-list-item-action>
            <v-btn-toggle style="margin-top: -25px; margin-right: 25px">
              <v-btn
                outlined
                color="grey"
                small
                :disabled="loading"
                @click="
                  modificarPalabra.mostrar = false;
                  modificarPalabra.index = -1;
                "
                ><v-icon color="red darken-2">mdi-close</v-icon></v-btn
              >
              <v-btn
                outlined
                color="grey"
                small
                :loading="loading"
                @click="guardarModificarPalabra()"
                ><v-icon color="green darken-3">mdi-check</v-icon></v-btn
              >
            </v-btn-toggle>
          </v-list-item-action>
        </template>
      </v-list-item>

      <v-list-item v-if="agregarPalabra">
        <v-list-item-title>
          <v-text-field v-model="nuevaPalabra" outlined dense></v-text-field>
        </v-list-item-title>
        <v-list-item-action>
          <v-btn-toggle style="margin-top: -25px; margin-right: 25px">
            <v-btn
              outlined
              color="grey"
              small
              :disabled="loading"
              @click="agregarPalabra = false"
              ><v-icon color="red darken-2">mdi-close</v-icon></v-btn
            >
            <v-btn
              outlined
              color="grey"
              small
              :loading="loading"
              @click="guardarNuevaPalabra()"
              ><v-icon color="green darken-3">mdi-check</v-icon></v-btn
            >
          </v-btn-toggle>
        </v-list-item-action>
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import {
  agregarFTWPalabraService,
  modificarFTWPalabraService,
  eliminarFTWPalabraService,
} from "./findthewords.service.js";

export default {
  name: 'ftwPalabras',
  props: {
    idInteractivo: { type: String, default: null },
    tipoInteractivo: { type: String, default: null },
    permisoEscritura: { type: Boolean, default: false },
    palabras: { type: Array, default: () => [] },
    alineaciones: { type: Object, default: () => {} },
  },
  computed: {
    ...mapGetters(["sessionToken", "app", "role", "menu"]),
    ftwCompleto () {
      
      const alineacionesCount = Object.values(this.alineaciones).reduce((count, value) => value ? count + 1 : count, 0);
      if (this.palabras.length >= 5 && alineacionesCount >= 3) return true;

      return false;
    }
  },

  watch: {
    palabras(value) {
      this.palabrasLocal = value;
    },
  },

  data() {
    return {
      loading: false,
      preview: false,
      palabrasLocal: "",
      agregarPalabra: false,
      nuevaPalabra: null,
      modificarPalabra: {
        index: -1,
        mostrar: false,
        palabra: null,
      },
    };
  },
  methods: {
    palabraModificada(payload) {
      this.$emit("palabraModificada", payload);
    },
    async guardarNuevaPalabra() {
      try {
        this.loading = true;
        const serverResponse = await agregarFTWPalabraService(
          this.idInteractivo,
          this.nuevaPalabra
        );
        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else {
          const emitData = {
            palabra: serverResponse.palabra,
            log: serverResponse.nuevoLog,
          };
          this.$emit("palabraAgregada", emitData);
          this.nuevaPalabra = null;
          this.agregarPalabra = false;
        }
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
    mostrarModificarPalabra(index, palabra) {
      this.modificarPalabra.index = index;
      this.modificarPalabra.mostrar = true;
      this.modificarPalabra.palabra = palabra;
    },
    async guardarModificarPalabra() {
      try {
        this.loading = true;
        const serverResponse = await modificarFTWPalabraService(
          this.idInteractivo,
          this.modificarPalabra
        );
        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else {
          const emitData = {
            index: this.modificarPalabra.index,
            palabra: serverResponse.palabra,
            log: serverResponse.nuevoLog,
          };
          this.$emit("palabraModificada", emitData);
          this.modificarPalabra.index = -1;
          this.modificarPalabra.mostrar = false;
        }
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
    async eliminarPalabra(index) {
      try {
        this.loading = true;
        const serverResponse = await eliminarFTWPalabraService(
          this.idInteractivo,
          index
        );
        this.loading = false;
        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else {
          const emitData = {
            index,
            log: serverResponse.nuevoLog,
          };
          this.$emit("palabraEliminada", emitData);
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
  },
};
</script>
