<template>
  <div>
    <v-list-item
      dense
      @mouseover="puedeModificar ? (modificarIcon = true) : null"
      @mouseout="modificarIcon = false"
    >
      <v-list-item-icon>
        <v-btn
          v-if="modificarIcon && !vistaSeguimiento"
          fab
          x-small
          color="primary"
          @click="modificarTipoCalificacion = true"
        >
          <v-icon small>mdi-pencil</v-icon>
        </v-btn>
        <v-icon v-else color="primary">mdi-finance</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>Calificación</v-list-item-title>
        <v-list-item-subtitle>
          <span v-if="tipoCalificacion == 'maxima'">Máxima</span>
          <span v-if="tipoCalificacion == 'promedio'">Promedio</span>
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>

    <modificarTipoCalificacionActividadComponent
      v-if="modificarTipoCalificacion"
      :mostrar="modificarTipoCalificacion"
      :tipoCurso="tipoCurso"
      :idCurso="idCurso"
      :idActividad="idActividad"
      :tipoCalificacion="tipoCalificacion"
      @cancelar="modificarTipoCalificacion = false"
      @tipoCalificacionModificada="tipoCalificacionModificada"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import modificarTipoCalificacionActividadComponent from "./modificarTipoCalificacionActividad.vue";

export default {
  name: "entregaActividad",
  props: {
    tipoCurso: String,
    idCurso: String,
    idActividad: String,
    tipoCalificacion: String,
    permisoEscritura: { type: Boolean, default: false },
  },
  components: { modificarTipoCalificacionActividadComponent },
  computed: {
    ...mapGetters(["vistaSeguimiento"]),
    puedeModificar() {
      return this.permisoEscritura && !this.vistaSeguimiento;
    },
  },
  data() {
    return {
      modificarIcon: false,
      modificarTipoCalificacion: false,
    };
  },
  methods: {
    tipoCalificacionModificada(data) {
      this.modificarTipoCalificacion = false;
      this.$emit("tipoCalificacionModificada", data);
    },
  },
};
</script>
