<template>
  <div>
    <v-menu left>
      <template #activator="{ on, attrs }">
        <v-btn v-on="on" v-bind="attrs" icon>
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>
      <v-list dense>
        <v-list-item
          v-for="(menuItem, index) in menuModulo"
          :key="index"
          class="text--primary"
          @click="menuItem.method()"
        >
          <v-list-item-icon class="ma-0 pa-0 pt-4">
            <v-icon
              v-if="menuItem.icon == 'mdi-delete'"
              small
              color="red darken-2"
              >{{ menuItem.icon }}</v-icon
            >
            <v-icon v-else small>{{ menuItem.icon || "mdi-pencil" }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ menuItem.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>

    <update-nombre-dialog
      v-if="openNombreDialog"
      :show="openNombreDialog"
      :idPlaneacion="idPlaneacion"
      :modulo="moduloLocal"
      :vaAModificarDescripcion="vaAModificarDescripcion"
      @moduloSaved="moduloSaved"
      @close="openNombreDialog = false"
    ></update-nombre-dialog>
  </div>
</template>

<script>
export default {
  components: {
    "update-nombre-dialog": () => import("../crearPlaneacionModuloDialog.vue"),
  },

  props: {
    modulo: {
      type: Object,
      required: true,
    },
    idPlaneacion: {
      type: String,
      required: true,
    },
    modificarDescripcion: {
      type: Boolean,
      required: false,
    },
  },

  data() {
    return {
      moduloLocal: this.modulo,
      openNombreDialog: false,
      vaAModificarDescripcion: false,
    };
  },

  computed: {
    menuModulo() {
      const menu = [
        {
          title: "Modificar nombre",
          method: this.openNombreEditor,
        },
        {
          title: "Modificar descripción",
          method: this.openDescripcionEditor,
        },
        {
          title: "Eliminar módulo",
          color: "error darken-3",
          icon: "mdi-delete",
          method: this.openNombreEditor,
        },
      ];
      if (!this.modificarDescripcion) menu.splice(1, 1);
      return menu;
    },
  },

  methods: {
    openNombreEditor() {
      this.vaAModificarDescripcion = false;
      this.openNombreDialog = true;
    },

    openDescripcionEditor() {
      this.vaAModificarDescripcion = true;
      this.openNombreDialog = true;
    },

    moduloSaved(modulo) {
      this.moduloLocal = modulo;
      this.openNombreDialog = false;
      this.$emit("moduloSaved", modulo);
    },
  },
};
</script>
