import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"persistent":"","width":"400"},model:{value:(_vm.mostrar),callback:function ($$v) {_vm.mostrar=$$v},expression:"mostrar"}},[_c(VCard,[_c(VToolbar,{attrs:{"color":"error","dark":"","dense":"","flat":""}},[_c(VToolbarTitle,[_vm._v("Confirmar")])],1),_c(VCardText,{staticClass:"pt-2"},[_c('span',[_vm._v("¿Está seguro de eliminar el examen? Esta acción no se puede revertir.")]),_c(VRow,{staticClass:"mt-2",attrs:{"dense":""}},[_c(VCol,{attrs:{"cols":"12"}},[_c(VBtn,{staticClass:"mr-2",attrs:{"small":"","color":"secondary","outlined":"","disabled":_vm.loading},on:{"click":function($event){return _vm.$emit('cancelar')}}},[_c('span',[_vm._v("Cancelar")])]),_c(VBtn,{attrs:{"small":"","color":"error","dark":"","loading":_vm.loading},on:{"click":function($event){return _vm.eliminarExamen()}}},[_c(VIcon,{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("mdi-delete")]),_c('span',[_vm._v("Eliminar examen")])],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }