<template>
  <v-list-item>
    <v-list-item-icon v-if="app == 'campus'"><v-icon>mdi mdi-certificate</v-icon></v-list-item-icon>
    <v-tooltip v-else v-model="show" top>
      <template v-slot:activator="{ on }">
        <v-list-item-icon v-on="on"><v-icon @click="agregarDiploma()" >mdi mdi-certificate</v-icon></v-list-item-icon>
      
      </template>
      <span>Agregar</span>
    </v-tooltip>
    <v-list-item-content>
      <v-list-item-title @mouseover="show = true" @mouseleave="show = false">Generar diploma al finalizar</v-list-item-title>
      <v-list-item-subtitle style="display: flex; align-items: center;" v-for="diploma in diplomasLocal" :key="diploma._id">
        <a @click="obtenerDiploma(diploma._id)">{{diploma.nombre}}</a>
        <v-spacer style="display: inline"></v-spacer>
        <v-tooltip top v-if="app != 'campus'">
          <template v-slot:activator="{ on }">
            <v-list-item-icon style="margin: 0px 0px 0px 16px" v-on="on"> 
              <v-icon @click="quitarDiploma(diploma._id)">mdi-close</v-icon>
            </v-list-item-icon>
          </template>
          <span>Quitar</span>
        </v-tooltip>
      </v-list-item-subtitle>
    </v-list-item-content>
    <agregarDiplomasComponent
      v-if="mostrarAgregar"
      :mostrar="mostrarAgregar"
      :tipoCurso="tipoCurso"
      :idCurso="idCurso"
      :diplomasActuales="diplomasLocal"
      @diplomaAgregado="diplomaAgregado"
      @cancelar="mostrarAgregar = false"
    />

    <v-dialog persistent v-model="loadingFile" max-width="300">
      <v-card>
        <v-toolbar dense color="primary" dark
          >Cargando archivo...
        </v-toolbar>
        <v-progress-linear color="white" indeterminate></v-progress-linear>
      </v-card>
    </v-dialog>
  </v-list-item>
</template>

<script>
import { mapGetters } from "vuex";
import agregarDiplomasComponent from "./agregarDiplomas.vue";
import { getDiplomasCursoService, getDiplomaFileService } from "../../diplomas/diplomas.service";
import { modificarDiplomasArrayService } from "./configuracion.service";

export default {
  name: "diplomas",
  props: {
    tipoCurso: String,
    idCurso: String,
    diplomas: Array,
    permisoEscritura: { type: Boolean, default: false },
  },
  components: { agregarDiplomasComponent },
  computed: {
    ...mapGetters(["app", "vistaSeguimiento"]),
    diplomasLocal(){
      return this.diplomasInfo;
    }
  },
  data() {
    return {
      loading: false,
      show: false,
      mostrarAgregar: false,
      diplomasInfo: [],
      loadingFile: false,
    };
  },
  watch: {
    diplomas(){
      this.cargarDiplomas();
    }
  },
  created() {
   this.cargarDiplomas()
  },
  methods: {
    async cargarDiplomas() {
      try {
        this.loading = true;
        const data = { diplomas: this.diplomas};
        const serverResponse = await getDiplomasCursoService(data);
        this.loading = false;

        if (!serverResponse.ok) {
          this.$systemErrorMessage(serverResponse.mensaje);
        } else {
          this.diplomasInfo = serverResponse.diplomas;
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
    async obtenerDiploma(idDiploma) {
      try {
        this.loadingFile = true;
        const serverResponse = await getDiplomaFileService(idDiploma, {origen: 'configuracion', idCurso: this.idCurso});
        const blob = new Blob([serverResponse], {type: 'application/pdf'});
        const blobURL = URL.createObjectURL(blob);
        window.open(blobURL);
        this.loadingFile = false;

      } catch (error) {
        this.loadingFile = false;
        this.$appErrorMessage();
      }
    },
    async quitarDiploma(idDiploma) {
      try {
        this.loading = true;
        const serverResponse = await modificarDiplomasArrayService( this.tipoCurso, idDiploma, this.idCurso );
        this.loading = false;

        if (!serverResponse.ok){
          this.$systemErrorMessage(serverResponse.mensaje);
        }
        else {
          let index = this.diplomasInfo.findIndex(d => d._id.toString() == idDiploma.toString());
          if (index >= 0) this.diplomasInfo.splice(index, 1);
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
    async agregarDiploma() {
      this.mostrarAgregar = true;
    },
    diplomaAgregado(diploma){
      this.diplomasInfo.push(diploma)
    }
  },
};
</script>
