import {
    postServerRequest,
    patchServerRequest,
    deleteServerRequest,
} from "@/components/globals/services/serverRequest.service";
import { store } from "../../../../../store/store";

const httpURL = store.getters.httpURL;

export const agregarCrosswordItemService = async (idCrossword, data) => {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/interactivo/crossword/${idCrossword}/item`;
    return await postServerRequest(url, data, config);
};

export const modificarCrosswordItemService = async (idCrossword, idItem, data) => {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/interactivo/crossword/${idCrossword}/item/${idItem}`;
    return await patchServerRequest(url, data, config);
};

export const eliminarCrosswordItemService = async (idCrossword, idItem) => {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/interactivo/crossword/${idCrossword}/item/${idItem}`;
    return await deleteServerRequest(url, config);
};
