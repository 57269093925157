import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VDivider),_c(VExpansionPanels,{attrs:{"accordion":"","flat":""}},[_c(VExpansionPanel,[_c(VExpansionPanelHeader,[_c('span',[_vm._v("Planeación académica")])]),_c(VExpansionPanelContent,[_c(VRow,{attrs:{"dense":""}},[_c(VCol,{attrs:{"cols":"12","md":"3","lg":"2"}},[_c(VListItem,[_c(VListItemContent,[_c(VListItemSubtitle,[_vm._v("Sesión")]),_c(VListItemTitle,[_vm._v(_vm._s(_vm.secuencia.sesion))])],1)],1)],1),_c(VCol,{attrs:{"cols":"12","md":"3","lg":"2"}},[_c(VListItem,[_c(VListItemContent,[_c(VListItemSubtitle,[_vm._v("Semana")]),_c(VListItemTitle,[_vm._v(_vm._s(_vm.secuencia.semana))])],1)],1)],1),_c(VCol,{attrs:{"cols":"12","md":"3"}},[_c(VListItem,[_c(VListItemContent,[_c(VListItemSubtitle,[_vm._v("Modalidad de la sesión")]),_c(VListItemTitle,[_vm._v(_vm._s(_vm.secuencia.tipoSesion))])],1)],1)],1),_c(VCol,{attrs:{"cols":"12"}},[_c('div',{staticClass:"pa-2",staticStyle:{"background-color":"#f3f3f3"}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.secuencia.texto)}})])])],1)],1)],1)],1),_c(VDivider)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }