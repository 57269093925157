import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSelect } from 'vuetify/lib/components/VSelect';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VSelect,{attrs:{"items":_vm.areas,"label":"Área","item-text":"nombre","item-value":"_id","disable":_vm.loading,"dense":"","outlined":""},model:{value:(_vm.areaSel),callback:function ($$v) {_vm.areaSel=$$v},expression:"areaSel"}}),_c(VSelect,{attrs:{"items":_vm.subareasLocal,"label":"Subárea","item-text":"nombre","item-value":"_id","disable":_vm.loading,"dense":"","outlined":""},model:{value:(_vm.subareaSel),callback:function ($$v) {_vm.subareaSel=$$v},expression:"subareaSel"}}),_c(VSelect,{attrs:{"items":_vm.interactivosLocal,"return-object":"","outlined":"","dense":"","label":"Interactivo:","disabled":_vm.loading},scopedSlots:_vm._u([{key:"selection",fn:function({ item }){return [_c(VListItem,{attrs:{"dense":""}},[_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(item.nombre))]),_c(VListItemSubtitle,[_vm._v(_vm._s(item.tipoTexto))])],1)],1)]}},{key:"item",fn:function({ item, on, attrs }){return [_c(VListItem,_vm._g(_vm._b({attrs:{"dense":""}},'v-list-item',attrs,false),on),[_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(item.nombre))]),_c(VListItemSubtitle,[_vm._v(_vm._s(item.tipoTexto))])],1)],1)]}}]),model:{value:(_vm.interactivoSel),callback:function ($$v) {_vm.interactivoSel=$$v},expression:"interactivoSel"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }