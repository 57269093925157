import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VContainer,{attrs:{"fluid":""}},[_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,{attrs:{"cols":"12","md":"4"}},[_c(VListItem,{attrs:{"dense":""}},[_c(VListItemAction,[_c(VCheckbox,{attrs:{"color":"primary","disabled":_vm.loading,"readonly":!_vm.permisoEscritura || _vm.vistaSeguimiento},model:{value:(_vm.examenAleatorio),callback:function ($$v) {_vm.examenAleatorio=$$v},expression:"examenAleatorio"}})],1),_c(VListItemTitle,[_vm._v("Exámenes aleatorios")])],1)],1),(_vm.esCampus)?_c(VCol,{attrs:{"cols":"12","md":"4"}},[_c(VListItem,{attrs:{"dense":""}},[_c(VListItemAction,[_c(VCheckbox,{attrs:{"color":"primary","disabled":_vm.loading,"readonly":!_vm.permisoEscritura || _vm.vistaSeguimiento},model:{value:(_vm.verResultados),callback:function ($$v) {_vm.verResultados=$$v},expression:"verResultados"}})],1),_c(VListItemTitle,[_vm._v("Ver resultados")])],1)],1):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }