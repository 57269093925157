import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,[_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,{attrs:{"cols":"12","md":"4"}},[_c('criterioActividadComponent',{attrs:{"tipoCurso":_vm.tipoCurso,"idCurso":_vm.idCurso,"idActividad":_vm.idAplicacion,"tipoActividad":"examenAplicacion","criterioEvaluacion":_vm.propiedadesLocal.criterio,"permisoEscritura":_vm.permisoEscritura},on:{"criterioModificado":_vm.criterioModificado}})],1),_c(VCol,{attrs:{"cols":"12","md":"4"}},[_c('propiedad-aplicacion',{attrs:{"nombre":"Ponderación","valor":_vm.ponderacionString,"editable":true,"icono":"mdi-file-percent-outline","permisoEscritura":_vm.permisoEscritura},on:{"editar":_vm.abrirModificacionPonderacion}})],1),_c(VCol,{attrs:{"cols":"12","md":"4"}},[_c('propiedad-aplicacion',{attrs:{"nombre":"Puntos","valor":_vm.propiedadesLocal.puntos,"noAsignadoString":"0","icono":"mdi-chart-arc","permisoEscritura":_vm.permisoEscritura}})],1),_c(VCol,{attrs:{"cols":"12","md":"4"}},[_c('propiedad-aplicacion',{attrs:{"nombre":"Máximo de intentos","valor":_vm.propiedadesLocal.intentos,"editable":true,"noAsignadoString":"0","icono":"mdi-chart-arc","permisoEscritura":_vm.permisoEscritura},on:{"editar":function($event){return _vm.abrirModificacionPropiedad(['intentos'])}}})],1),_c(VCol,{attrs:{"cols":"12","md":"4"}},[_c('propiedad-aplicacion',{attrs:{"nombre":"Modo de calificación","valor":_vm.getCalificacionString(_vm.propiedadesLocal.calificacion),"editable":true,"icono":"mdi-chart-arc","permisoEscritura":_vm.permisoEscritura},on:{"editar":function($event){return _vm.abrirModificacionPropiedad(['calificacion'])}}})],1),(_vm.esCampus)?_c(VCol,{attrs:{"cols":"12","md":"4"}},[_c('propiedad-aplicacion',{attrs:{"nombre":"Fecha de presentación","valor":_vm.getFechasString({
            inicio: _vm.propiedadesLocal.inicio,
            fin: _vm.propiedadesLocal.fin,
          }),"editable":true,"icono":"mdi-calendar-range","permisoEscritura":_vm.permisoEscritura},on:{"editar":function($event){return _vm.abrirModificacionPropiedad(['inicio', 'fin'])}}})],1):_vm._e()],1),(_vm.mostrarModificacion)?_c('modificacion-aplicacion',{attrs:{"mostrar":_vm.mostrarModificacion,"aplicacion":_vm.propiedadesLocal,"aModificar":_vm.aModificar},on:{"cancelar":function($event){return _vm.cerrarModificacion()},"aplicacionGuardada":_vm.aplicacionGuardada}}):_vm._e(),(_vm.mostrarModifcacionPonderacion)?_c('modificacion-ponderacion',{attrs:{"mostrar":_vm.mostrarModifcacionPonderacion,"idCurso":_vm.idCurso,"tipoCurso":_vm.tipoCurso,"idActividad":_vm.idAplicacion,"ponderacion":_vm.propiedadesLocal.ponderacion,"permisoEscritura":_vm.permisoEscritura},on:{"cancelar":_vm.cerrarModificacionPonderacion,"ponderacionModificada":_vm.ponderacionModificada}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }