import {
    postServerRequest,
    patchServerRequest,
    deleteServerRequest,
} from "@/components/globals/services/serverRequest.service";
import { store } from "../../../../../store/store";
import { putServerRequest } from "../../../services/serverRequest.service";

const httpURL = store.getters.httpURL;

export const agregarFillblanksBloqueService = async (idFillblanks) => {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/interactivo/fillblanks/${idFillblanks}/bloque`;
    return await putServerRequest(url, {}, config);
};

export const modificarFillblanksBloqueService = async (idFillblanks, texto, index) => {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/interactivo/fillblanks/${idFillblanks}/bloque`;
    return await patchServerRequest(url, { texto, index }, config);
};

export const eliminarFillblanksBloqueService = async (idFillblanks, index) => {
    const token = store.getters.sessionToken;
    const config = {
        headers: { token },
        data: { index }
    };
    const url = `${httpURL}/interactivo/fillblanks/${idFillblanks}/bloque`;
    return await deleteServerRequest(url, config);
};
