import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VCard,{attrs:{"elevation":"0","color":"grey lighten-4"}},[_c(VCardTitle,[_c('span',{staticClass:"text-subtitle-1 font-weight-bold text--secondary"},[_vm._v("Actividades")]),_c(VSpacer),_c(VTooltip,{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VBtn,_vm._g(_vm._b({attrs:{"fab":"","x-small":"","color":"primary"},on:{"click":function($event){_vm.showSelectorActividades = true}}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-plus")])],1)]}}])},[_c('span',[_vm._v("Vincular actividad")])])],1),_c(VCardText,_vm._l((_vm.actividadesLocal),function(actividad){return _c(VListItem,{key:actividad._id},[_c(VListItemIcon,[_c(VIcon,{attrs:{"color":"primary"}},[_vm._v(_vm._s(_vm.iconoActividad(actividad)))])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(actividad.nombre))]),_c(VListItemSubtitle,[_vm._v(_vm._s(actividad.cursoPlantillaModulo ? actividad.cursoPlantillaModulo.nombre : actividad.cursoModulo.nombre))])],1),_c(VListItemAction,[_c(VTooltip,{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function({on, attrs}){return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":"","small":"","loading":_vm.loadingDeleteActividad == actividad._id},on:{"click":function($event){return _vm.quitarActividad(actividad)}}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v("Quitar actividad")])])],1)],1)}),1)],1),(_vm.showSelectorActividades)?_c('selector-actividades',{attrs:{"show":_vm.showSelectorActividades,"idPlaneacion":_vm.idPlaneacion,"idModulo":_vm.idModulo,"idElemento":_vm.idElemento},on:{"close":function($event){_vm.showSelectorActividades = false},"actividadAgregada":_vm.actividadAgregada}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }