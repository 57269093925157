<template>
  <v-dialog v-model="mostrar" persistent width="450">
    <v-card :loading="loading">
      <v-toolbar dense color="primary" dark flat>
        <v-toolbar-title>{{
          item ? "Modificar palabra" : "Agregar palabra"
        }}</v-toolbar-title>
      </v-toolbar>
      <v-card-text class="pt-3 pb-0 px-4">
        <v-row dense>
          <v-col cols="12">
            <v-text-field
              v-model.trim="palabra"
              label="Palabra:"
              outlined
              dense
              :disabled="loading"
              :error-messages="palabraErrors"
              @input="
                limpiarPalabra();
                validarPalabra();
              "
              @blur="
                limpiarPalabra();
                validarPalabra();
              "
            ></v-text-field>
            <v-textarea
              v-model="pista"
              label="Pista:"
              outlined
              :disabled="loading"
              @input="validarPista()"
              @blur="validarPista()"
            ></v-textarea>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          small
          color="secondary"
          outlined
          :disabled="loading"
          @click="$emit('cancelar')"
          >Cancelar</v-btn
        >
        <v-btn
          small
          color="primary"
          :disabled="loading || !formularioValido"
          :loading="loading"
          @click="guardarItem()"
          >Guardar</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, maxLength } from "vuelidate/lib/validators";
import {
  agregarCrosswordItemService,
  modificarCrosswordItemService,
} from "./crossword.service.js";

export default {
  mixins: [validationMixin],

  props: {
    mostrar: { type: Boolean, default: false },
    modificar: { type: Boolean, default: false },
    idCrossword: { type: String, default: null },
    item: { type: Object, default: () => null },
  },

  computed: {
    formularioValido() {
      if (this.palabraErrors.length > 0) return false;
      if (this.pistaErrors.length > 0) return false;
      return true;
    },
  },

  validations: {
    palabra: { required, maxLength: maxLength(15) },
    pista: { required },
  },

  data: () => ({
    loading: false,
    palabra: "",
    pista: "",
    palabraErrors: [],
    pistaErrors: [],
  }),

  mounted() {
    if (this.item !== null) {
      this.palabra = this.item.answer;
      this.pista = this.item.clue;
    }
  },

  methods: {
    validarPalabra() {
      this.palabraErrors = [];
      !this.$v.palabra.required && this.palabraErrors.push("Campo requerido.");
      !this.$v.palabra.maxLength &&
        this.palabraErrors.push("Máximo 15 caracteres.");
    },
    validarPista() {
      this.pistaErrors = [];
      !this.$v.pista.required && this.pistaErrors.push("Campo requerido.");
    },
    limpiarPalabra() {
      const mapaAcentos = {
        á: "a",
        é: "e",
        í: "i",
        ó: "o",
        ú: "u",
        Á: "A",
        É: "E",
        Í: "I",
        Ó: "O",
        Ú: "U",
      };
      this.palabra = this.palabra.replace(/[áéíóúÁÉÍÓÚ]/g, function(letra) {
        return mapaAcentos[letra];
      });

      this.palabra = this.palabra.replace(/[^\w\s]/gi, "").replace(/\s/g, "");
      this.palabra = this.palabra.toUpperCase();
    },

    async guardarItem() {
      if (!this.formularioValido) return;
      try {
        this.loading = true;
        const data = {
          palabra: this.palabra,
          pista: this.pista,
        };
        const serverResponse = this.modificar
          ? await modificarCrosswordItemService(this.idCrossword, this.item._id, data)
          : await agregarCrosswordItemService(this.idCrossword, data);
        this.loading = false;
        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else {
          this.modificar
            ? this.$emit("itemModificado", serverResponse)
            : this.$emit("itemAgregado", serverResponse);
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
  },
};
</script>
