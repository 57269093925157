import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"max-width":500,"persistent":""},model:{value:(_vm.mostrar),callback:function ($$v) {_vm.mostrar=$$v},expression:"mostrar"}},[_c(VCard,[_c(VToolbar,{staticClass:"text-bold",attrs:{"color":"primary","dark":"","flat":"","dense":""}},[_c(VToolbarTitle,[_vm._v("Modificar criterio")])],1),_c(VContainer,[_c(VAlert,{attrs:{"color":"orange lighten-1","dense":"","dark":""}},[_vm._v(" Al modificar el criterio, la ponderación cambiará a \"Promedio\" ")]),_c(VSelect,{attrs:{"outlined":"","dense":"","label":"Criterio:","item-value":"_id","item-text":"nombre","disabled":_vm.loading || _vm.loading,"items":_vm.criterios,"loading":_vm.loading},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(item.nombre))]),(item.seccion)?_c(VListItemSubtitle,[_vm._v(_vm._s(item.seccion))]):_vm._e()],1)]}}]),model:{value:(_vm.criterioSelect),callback:function ($$v) {_vm.criterioSelect=$$v},expression:"criterioSelect"}})],1),_c(VDivider,{staticClass:"mx-3"}),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","outlined":"","small":"","disabled":_vm.loading},on:{"click":function($event){return _vm.$emit('cancelar')}}},[_vm._v(" Cancelar ")]),_c(VBtn,{class:{ 'disable-btn': !_vm.esValido },attrs:{"color":"primary","dark":"","small":"","loading":_vm.loading},on:{"click":function($event){return _vm.modificarCriterio()}}},[_vm._v(" Guardar ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }