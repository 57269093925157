import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"persistent":"","width":"750"},model:{value:(_vm.mostrar),callback:function ($$v) {_vm.mostrar=$$v},expression:"mostrar"}},[_c(VCard,[_c(VToolbar,{attrs:{"dense":"","flat":"","color":"primary","dark":""}},[_c(VToolbarTitle,[_vm._v("Solución")])],1),_c(VCardText,{staticClass:"pt-3"},[_c('editor-texto',{ref:"editorTexto",attrs:{"contenido":"examen","idContenido":_vm.$route.params.idExamen,"texto":_vm.solucion}}),_c('div',{staticClass:"pt-3"},[_c(VBtn,{attrs:{"disabled":_vm.loading,"loading":_vm.loading,"color":"primary"},on:{"click":function($event){return _vm.submit()}}},[_c('span',[_vm._v("Guardar solución")])]),_c(VBtn,{staticClass:"ml-2",attrs:{"disabled":_vm.loading,"color":"secondary","outlined":""},on:{"click":function($event){return _vm.$emit('cancelar')}}},[_c('span',[_vm._v("Cancelar")])])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }