import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"width":"500","persistent":""},model:{value:(_vm.mostrar),callback:function ($$v) {_vm.mostrar=$$v},expression:"mostrar"}},[_c(VCard,[_c(VToolbar,{attrs:{"color":"danger","dense":"","flat":"","dark":""}},[_c('strong',[_vm._v(" Límite alcanzado")])]),_c(VCardText,[_vm._v(" Estimado usuario, se ha alcanzado el límite de cursos en su cuenta. "),_c('br'),_c('br'),_vm._v(" Para poder generar más cursos, favor de comunicarse a soporte AGNUS para ofrecerle un plan a su medida. ")]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"disabled":_vm.loading,"color":"primary","outlined":"","small":""},on:{"click":function($event){return _vm.$emit('cancelar')}}},[_vm._v("Cerrar")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }