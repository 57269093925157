import {
  getServerRequest,
  putServerRequest,
  patchServerRequest,
} from "../../../services/serverRequest.service";
import { store } from "@/store/store";
const httpURL = store.getters.httpURL;

export async function getPlaneacionesAreas(data) {
  const token = store.getters.sessionToken;
  const config = { headers: { token } };
  const url = `${httpURL}/planeaciones/areas`;
  return await getServerRequest(url, config);
}

export const crearAreaPlaneaciones = async (data) => {
  const token = store.getters.sessionToken;
  const config = { headers: { token } };
  const url = `${httpURL}/planeaciones/areas`;

  return await putServerRequest(url, data, config);
};

export const modificarAreaPlaneaciones = async (idArea, data) => {
  const token = store.getters.sessionToken;
  const config = { headers: { token } };
  const url = `${httpURL}/planeaciones/areas/${idArea}`;

  return await patchServerRequest(url, data, config);
};
