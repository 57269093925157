import { VCard } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{attrs:{"outlined":""}},[_c(VContainer,[_c(VRadioGroup,{attrs:{"column":"","disabled":_vm.loading},on:{"change":function($event){return _vm.modificarPropiedad('modoCalificacion')}},model:{value:(_vm.modoCalificacionLocal),callback:function ($$v) {_vm.modoCalificacionLocal=$$v},expression:"modoCalificacionLocal"}},[_c(VRadio,{attrs:{"label":"Calificar con base a párrafos correctos","value":"positions"}}),_c(VRadio,{attrs:{"label":"Calificar con base a la secuencia","value":"transitions"}})],1),_c(VCheckbox,{attrs:{"disabled":_vm.loading,"label":"Aplicar penalizaciones (Se descuenta un punto por cada párrafo incorrecto)"},on:{"change":function($event){return _vm.modificarPropiedad('penalizaciones')}},model:{value:(_vm.penalizacionesLocal),callback:function ($$v) {_vm.penalizacionesLocal=$$v},expression:"penalizacionesLocal"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }