import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VMenu,{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VBtn,_vm._g(_vm._b({attrs:{"color":"primary","x-small":"","icon":""}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-dots-vertical")])],1)]}}])},[_c(VList,{attrs:{"dense":""}},[_c(VListItem,{on:{"click":function($event){return _vm.$emit('modificarCriterio')}}},[_c(VListItemIcon,[_c(VIcon,[_vm._v(" mdi-square-edit-outline ")])],1),_c(VListItemTitle,[_vm._v("Modificar criterio")])],1),_c(VListItem,{on:{"click":function($event){return _vm.$emit('eliminarCriterio')}}},[_c(VListItemIcon,[_c(VIcon,{attrs:{"color":"red darken-1"}},[_vm._v(" mdi-trash-can-outline ")])],1),_c(VListItemTitle,[_vm._v("Eliminar criterio")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }