import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VCard,{staticClass:"my-2",attrs:{"flat":""}},[_c(VSkeletonLoader,{staticStyle:{"margin":"20px"},attrs:{"type":"text"}})],1),_c(VDivider,{staticClass:"mx-3"}),_c(VCard,{staticClass:"my-2",attrs:{"flat":""}},[_c(VSkeletonLoader,{staticStyle:{"margin":"20px"},attrs:{"type":"paragraph"}})],1),_c(VContainer,[_c(VRow,[_c(VCol,{attrs:{"md":"6","cols":"12"}},[_c(VCard,{staticClass:"my-2",attrs:{"outlined":""}},[_c(VSkeletonLoader,{staticStyle:{"margin":"20px"},attrs:{"type":"list-item-avatar-two-line@3"}})],1)],1),_c(VCol,{attrs:{"md":"6","cols":"12"}},[_c(VCard,{staticClass:"my-2",attrs:{"outlined":""}},[_c(VSkeletonLoader,{staticStyle:{"margin":"20px"},attrs:{"type":"list-item-avatar-two-line@3"}})],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }