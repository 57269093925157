<template>
  <v-dialog v-model="mostrar" :max-width="500" persistent>
    <v-card>
      <v-toolbar color="primary" dark flat dense class="text-bold">
        <v-toolbar-title>Modificar Entrega</v-toolbar-title>
      </v-toolbar>
      <v-container>
        <v-alert v-if="!totalEquipos && [1, 3].find(n => n == tipo)" color="orange lighten-1" dense dark>
          Al modificar el tipo de entrega se eliminaran las calificaciones registradas.
        </v-alert>
        <v-alert v-if="!totalEquipos && tipo == 2" color="orange lighten-1" dense dark>
          Al modificar el tipo de entrega se eliminaran las calificaciones registradas junto con sus respectivas entregas.
        </v-alert>
        <v-select
          v-model="entregaSelect"
          outlined
          dense
          label="Entrega:"
          :disabled="loading || loading"
          :items="equipos"
          :loading="loading"
        >
          <template v-slot:selection="{ item }">
            <v-list-item dense>
              <v-list-item-title>{{ item.text }}</v-list-item-title>
            </v-list-item>
          </template>
          <template v-slot:item="{ item, on, attrs }">
            <v-list-item dense v-bind="attrs" v-on="on">
              <v-list-item-title>{{ item.text }}</v-list-item-title>
            </v-list-item>
          </template>
        </v-select>
        <v-alert v-if="totalEquipos > 0 && entregaSelect == 1" type="warning">
          <strong>
          Esta actividad ya cuenta con equipos, al cambiarlo a individual se eliminaran junto con sus respectivas entregas y calificaciones.
          </strong>
        </v-alert>
      </v-container>
      <v-divider class="mx-3"></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          outlined
          small
          :disabled="loading"
          @click="$emit('cancelar')"
        >
          Cancelar
        </v-btn>
        <v-btn
          color="primary"
          dark
          small
          :loading="loading"
          :class="{ 'disable-btn': !esValido }"
          @click="modificarEntrega()"
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { modificarEntregaService } from "../actividad.service";
export default {
  name: "modificarEntregaActividad",
  props: {
    mostrar: Boolean,
    tipoCurso: String,
    idCurso: String,
    idActividad: String,
    tipo: Number,
    entrega: Number,
    totalEquipos: Number,
    permisoEscritura: { type: Boolean, default: false },
    transversal: { type: Object, default: null },
  },
  computed: {
    esValido() {
      return this.entregaSelect != this.entrega;
    },
  },
  data() {
    return {
      loading: false,
      entregaSelect: null,
      equipos: [],
    };
  },
  mounted() {
    this.setTipos();
  },
  methods: {
    setTipos() {
      if(!this.transversal) this.equipos = [{ value: 1, text: "Individual" }];
      for (let i = 2; i <= 10; i++) {
        if (this.totalEquipos == 0 || this.entrega <= i)
          this.equipos.push({
            value: i,
            text: `Equipos de hasta ${i} integrantes`,
          });
      }
      this.entregaSelect = this.entrega;
    },
    async modificarEntrega() {
      try {
        this.loading = true;
        const data = { entrega: this.entregaSelect };
        const serverResponse = await modificarEntregaService(
          this.tipoCurso,
          this.idCurso,
          this.idActividad,
          data
        );
        this.loading = false;
        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else {
          const emitData = { entrega: serverResponse.entrega };
          this.$emit("entregaModificada", emitData);
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
  },
};
</script>
