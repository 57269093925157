<template>
  <v-container>
    <v-skeleton-loader
      v-if="!escuelaTienePermiso"
      type="list-item-two-line@5"
    ></v-skeleton-loader>
    <v-card v-else flat>
      <v-toolbar color="secondary" dark flat class="text-h6">
        <v-spacer></v-spacer>RECURSOS<v-spacer></v-spacer>
      </v-toolbar>
      <v-container>
        <v-row>
          <v-col md="6" sm="12">
            <v-container>
              <selectorAreas
                :areas="areas"
                :menu="esAdmin"
                v-model="areaSeleccionada"
                @agregarArea="abrirEditorArea = true"
                @modificarArea="modificarAreaSeleccionada"
                @change="getSubareas(areaSeleccionada)"
              />
            </v-container>
          </v-col>
          <v-col md="6" sm="12">
            <v-container>
              <div class="d-flex">
                <selectorAreas
                  :areas="subareas"
                  :menu="esAdmin"
                  v-model="subareaSeleccionada"
                  :etiqueta="'Subárea'"
                  @agregarArea="abrirEditorSubarea = true"
                  @modificarArea="modificarSubareaSeleccionada"
                  @change="getRecursos()"
                />
                <v-menu left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      small
                      style="margin-left: 10px"
                      icon
                      color="primary"
                      :disabled="
                        !areas.length || !subareas.length || !esAdmin || loading
                      "
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>mdi-file-document-plus</v-icon>
                    </v-btn>
                  </template>
                  <v-list dense>
                    <v-list-item @click="crearRecurso('archivo')">
                      <v-list-item-title>Agregar archivo</v-list-item-title>
                      <v-list-item-icon>
                        <v-icon small style="margin-right: 5px"
                          >mdi-paperclip</v-icon
                        >
                      </v-list-item-icon>
                    </v-list-item>
                    <v-divider class="mx-3"></v-divider>
                    <v-list-item @click="crearRecurso('enlace')">
                      <v-list-item-title>Agregar enlace</v-list-item-title>
                      <v-list-item-icon>
                        <v-icon small style="margin-right: 5px"
                          >mdi-link-variant</v-icon
                        >
                      </v-list-item-icon>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </v-container>
          </v-col>
        </v-row>

        <v-card-text v-if="!recursos.length && !loading"
          >No se han subido recursos.</v-card-text
        >

        <template v-else-if="loading">
          <v-card flat>
            <v-container>
              <v-skeleton-loader type="paragraph@2"></v-skeleton-loader>
            </v-container>
            <v-divider class="mx-3"></v-divider>
            <v-skeleton-loader type="list-item-two-line@5"></v-skeleton-loader>
          </v-card>
        </template>
        <template v-else-if="recursos.length > 0">
          <v-container fluid>
            <v-col md="12" sm="12">
              <tablaRecursos
                :loading="loading"
                :recursos="recursos"
                :pagination="pagination"
                @updatePageTable="updatePageTable"
                @paginaCambio="paginaCambio"
                @getRecursos="getRecursos"
              />
            </v-col>
          </v-container>
        </template>
        <logsComponent :logs="logs" />
      </v-container>
    </v-card>

    <editor-area
      v-if="abrirEditorArea"
      :mostrar="abrirEditorArea"
      :area="areaParaModificar"
      @cancelar="cancelarEditarArea"
      @areaGuardada="areaGuardada"
    />

    <editor-subarea
      v-if="abrirEditorSubarea"
      :mostrar="abrirEditorSubarea"
      :subarea="subareaParaModificar"
      :idArea="areaSeleccionada"
      @cancelar="cancelarEditarSubarea"
      @subareaGuardada="subareaGuardada"
    />

    <editorRecurso
      v-if="abrirEditorRecurso"
      :mostrar="abrirEditorRecurso"
      :idSubarea="subareaSeleccionada"
      :idArea="areaSeleccionada"
      :tipo="tipo"
      @cancelar="(abrirEditorRecurso = false), (modificarRecursoObject = null)"
      @recursoAgregado="recursoAgregado"
    />
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import tablaRecursos from "./tablaRecursos.vue";
import selectorAreas from "../widgets/selectorAreas.vue";
import logsComponent from "../widgets/logs.vue";
import EditorArea from "./editorAreaRecursos.vue";
import EditorSubarea from "./editorSubareaRecursos.vue";
import EditorRecurso from "./editorRecurso.vue";
import numeral from "numeral";
import {
  getAreasService,
  getSubareasService,
  getRecursosService,
} from "./recursos.service";

export default {
  name: "Recursos",
  components: {
    tablaRecursos,
    selectorAreas,
    logsComponent,
    EditorArea,
    EditorSubarea,
    EditorRecurso,
  },
  computed: {
    ...mapGetters(["sessionToken", "app", "role", "documentsURL", "menu"]),

    esAdmin() {
      return (
        this.app == "campus" ||
        this.role == "admin" ||
        this.role == "master" ||
        this.role == "profesor"
      );
    },
  },
  watch: {
    sessionToken(value) {
      if (value) {
        if (this.app === "escuela") this.validarPermisoRuta();
        this.escuelaTienePermiso = true;
        this.getAreas();
      }
    },
  },
  mounted() {
    if (this.sessionToken) {
      if (this.app === "escuela" && this.menu.length > 0)
        this.validarPermisoRuta();
      this.escuelaTienePermiso = true;
      this.getAreas();
    }
  },

  data() {
    return {
      loading: false,
      escuelaTienePermiso: false,
      elegirTipo: false,
      verArchivo: { mostrar: false, nombre: null, url: null, tipo: null },
      pagination: {
        page: 1,
        itemsPerPage: 10,
        pageStart: 0,
        pageStop: 0,
        pageCount: 0,
        itemsLength: 0,
      },
      areas: [],
      areaSeleccionada: "",
      abrirEditorArea: false,
      editarArea: false,
      areaParaModificar: null,
      recursos: [],
      subareas: [],
      subareaSeleccionada: "",
      abrirEditorSubarea: false,
      subareaParaModificar: null,
      abrirEditorRecurso: false,
      logs: [],
    };
  },
  methods: {
    viewerItem(archivo) {
      this.verArchivo = {
        mostrar: true,
        nombre: archivo.nombre,
        url: archivo.url,
        tipo: archivo.tipoArchivo,
      };
    },

    validarPermisoRuta() {
      const menuEvaluacion = this.menu.find((x) => x.title === "Evaluación");
      if (menuEvaluacion) {
        const itemIndex = menuEvaluacion.items.findIndex(
          (x) => x.title === "Recursos"
        );
        if (itemIndex == -1) {
          this.$router.push("/dashboard");
        } else this.escuelaTienePermiso = true;
      }
    },

    recursoAgregado() {
      this.limpiarPagination();
      this.getRecursos();
    },

    modificarAreaSeleccionada() {
      this.areaParaModificar = this.areas.filter(
        (x) => x._id === this.areaSeleccionada
      )[0];
      this.abrirEditorArea = true;
      this.editarArea = true;
    },

    modificarSubareaSeleccionada() {
      this.subareaParaModificar = this.subareas.filter(
        (x) => x._id === this.subareaSeleccionada
      )[0];
      this.abrirEditorSubarea = true;
    },

    cancelarEditarArea() {
      this.abrirEditorArea = false;
      this.editarArea = false;
      this.areaParaModificar = null;
    },

    cancelarEditarSubarea() {
      this.abrirEditorSubarea = false;
      this.subareaParaModificar = null;
    },

    async getAreas() {
      this.loading = true;
      try {
        const serverResponse = await getAreasService();
        this.loading = false;

        this.$validateResponse(serverResponse);

        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else if (serverResponse.areas.length) {
          this.areas = serverResponse.areas;
          this.areaSeleccionada = serverResponse.areas[0]._id;

          this.getSubareas(this.areaSeleccionada);
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
    async getSubareas(areaSeleccionada) {
      this.loading = true;
      try {
        const serverResponse = await getSubareasService(areaSeleccionada);
        this.loading = false;

        this.$validateResponse(serverResponse);

        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else {
          if (serverResponse.subareas.length > 0) {
            this.subareas = serverResponse.subareas;
            this.subareaSeleccionada = this.subareas[0]._id;

            this.limpiarPagination();
            this.getRecursos();
          } else {
            this.subareas = [];
            this.recursos = [];
          }
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },

    async getRecursos() {
      this.loading = true;
      try {
        const serverResponse = await getRecursosService(
          this.areaSeleccionada,
          this.subareaSeleccionada,
          this.pagination.itemsPerPage,
          this.pagination.page
        );
        this.loading = false;
        this.$validateResponse(serverResponse);
        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else {
          this.logs = serverResponse.logs;
          this.recursos = [];
          let numero = 1;

          for (const recurso of serverResponse.recursos) {
            let item = {
              numero: numero++,
              usuario: recurso.usuario,
              recurso: {
                _id: recurso._id,
                area: recurso.area,
                subarea: recurso.subarea,
                nombre: recurso.nombre,
                descripcion: recurso.descripcion,
                tipoRecurso: recurso.tipo,
                tipoArchivo: recurso.type,
                restringir: recurso.restringir || false,
                url:
                recurso.tipo == "archivo"
                ? `${this.documentsURL}/${recurso.route}`
                    : recurso.route,
                    ruta: recurso.route,
                  },
                  tamanio:
                recurso.tipo == "archivo"
                ? numeral(recurso.size).format("0.0b")
                : null,
                restringir: recurso.restringir || false,
                edicion: {
                _id: recurso._id,
                nombre: recurso.nombre,
                descripcion: recurso.descripcion,
                area: recurso.area,
                subarea: recurso.subarea,
                tipoRecurso: recurso.tipo,
                url: recurso.tipo == "enlace" ? recurso.route : null,
                restringir: recurso.restringir || false,
              },
            };
            this.recursos.push(item);
          }
          this.pagination.itemsLength = serverResponse.total;
          this.pagination.pageStop = serverResponse.pageStop;
          this.pagination.pageStart = serverResponse.pageStart;
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },

    areaGuardada(nuevaArea) {
      const areaExiste = this.areas.filter((e) => e._id === nuevaArea._id)[0];

      if (areaExiste) {
        let index = this.areas.findIndex((x) => x._id === nuevaArea._id);
        this.areas.splice(index, 1, nuevaArea);
      } else {
        this.areas.push(nuevaArea);
        this.areaSeleccionada = nuevaArea._id;
        this.getSubareas(this.areaSeleccionada);
      }

      this.abrirEditorArea = false;
      this.editarArea = false;
      this.areaParaModificar = null;
    },

    subareaGuardada(nuevaArea) {
      const areaExiste = this.subareas.filter(
        (e) => e._id === nuevaArea._id
      )[0];
      if (areaExiste) {
        let index = this.subareas.findIndex((x) => x._id === nuevaArea._id);
        this.subareas.splice(index, 1, nuevaArea);
      } else {
        this.subareas.push(nuevaArea);
        this.subareaSeleccionada = nuevaArea._id;
        this.getRecursos(this.subareaSeleccionada);
      }
      this.abrirEditorSubarea = false;
      this.subareaParaModificar = null;
    },

    updatePageTable(value) {
      this.pagination.pageCount += 1;
      this.pagination.page = value.page;
      this.getRecursos();
    },

    paginaCambio(pagina) {
      this.pagination.page = pagina;
      this.getRecursos();
    },

    limpiarPagination() {
      this.pagination.page = 1;
      this.pagination.pageStart = 0;
      this.pagination.pageStop = 0;
      this.pagination.pageCount = 1;
      this.pagination.itemsLength = 0;
    },
    crearRecurso(tipo) {
      this.tipo = tipo;
      this.abrirEditorRecurso = true;
    },
  },
};
</script>
