<template>
  <div>
    <v-divider></v-divider>
    <v-expansion-panels accordion flat>
      <v-expansion-panel>
        <v-expansion-panel-header>
          <span>Planeación académica</span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row dense>
            <v-col cols="12" md="3" lg="2">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-subtitle>Sesión</v-list-item-subtitle>
                  <v-list-item-title>{{ secuencia.sesion }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col cols="12" md="3" lg="2">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-subtitle>Semana</v-list-item-subtitle>
                  <v-list-item-title>{{ secuencia.semana }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col cols="12" md="3">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-subtitle
                    >Modalidad de la sesión</v-list-item-subtitle
                  >
                  <v-list-item-title>{{
                    secuencia.tipoSesion
                  }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col cols="12">
              <div class="pa-2" style="background-color: #f3f3f3;">
                <div v-html="secuencia.texto"></div>
              </div>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-divider></v-divider>
  </div>
</template>

<script>
export default {
  props: {
    secuencia: {
      type: Object,
      default: null,
    },
  },
};
</script>
