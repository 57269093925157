<template>
  <v-container>

    <!-- Datos de usuarios -->
    <v-card outlined>

      <v-toolbar dense color="#254b72" dark flat style="height: 45px;">
        <strong>Entrega</strong>
      </v-toolbar>
        <!-- CARD INDIVIDUAL -->
        <v-card v-if="alumno" outlined>
          <v-list-item>
            <v-list-item-avatar size="50" style="margin-right: 0px;">
              <v-icon size="50">
                mdi-account
              </v-icon>
            </v-list-item-avatar>

            <v-list-item-content class="text-center">
              <v-list-item-title>{{ alumno.nombreCompleto }}</v-list-item-title>
              <v-list-item-subtitle>{{ alumno.correoLimpio }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-card>
        <!-- CARD INDIVIDUAL -->

        <!-- CARD EQUIPO -->
        <v-card v-else outlined>
          <v-list-item>
            <v-list-item-avatar size="50">
              <v-icon size="40">
                mdi-account-group
              </v-icon>
            </v-list-item-avatar>

            <v-list-item-content class="text-left">
              <v-list-item-title>{{ equipo.nombre }}</v-list-item-title>
              <v-list-item-subtitle>Integrantes: {{ equipo.integrantes.length }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-divider></v-divider>

          <v-window show-arrows>
            <template v-slot:prev="{ on }">
              <v-btn
                v-on="on"
                icon
                outlined
                small
                style="background-color: #FFFF;"
              ><v-icon>mdi-arrow-left</v-icon></v-btn>
            </template>
            <template v-slot:next="{ on }">
              <v-btn
                v-on="on"
                icon
                outlined
                small
                style="background-color: #FFFF;"
              ><v-icon>mdi-arrow-right</v-icon></v-btn>
            </template>

            <v-window-item
              v-for="(integrante, index) in equipo.integrantes"
              :key="`integrante-${index}`"
            >
            
              <v-list-item>
                <v-list-item-content class="text-center">
                  <v-list-item-title>
                    <v-icon v-if="integrante.admin" color="#dfec00" small class="mr-2">mdi-star</v-icon>
                    <v-chip label small class="mr-1">{{ index + 1 }}</v-chip>
                    {{ integrante.nombreCompleto }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

            </v-window-item>
          </v-window>
        </v-card>

    </v-card>
  </v-container>
</template>

<script>
export default {
  name: 'datosUsuarios',
  props: {
    loading: { type: Boolean, default: false },
    alumno: { type: Object, default: () => null },
    equipo: { type: Object, default: () => null },
  },
  data () {
    return {}
  }
}
</script>