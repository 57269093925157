import {
  putServerRequest,
  patchServerRequest,
  postServerRequest,
  deleteServerRequest,
} from "../../../../../services/serverRequest.service";
import { store } from "@/store/store";
const httpURL = store.getters.httpURL;

export const crearModuloElemento = async (data) => {
  const token = store.getters.sessionToken;
  const config = { headers: { token } };
  const url = `${httpURL}/planeaciones/moduloElementos/crear`;

  return await putServerRequest(url, data, config);
};

export const editarModuloElemento = async ({
  idPlaneacion,
  idModulo,
  idElemento,
  data,
}) => {
  const token = store.getters.sessionToken;
  const config = { headers: { token } };
  const url = `${httpURL}/planeaciones/moduloElementos/planeacion/${idPlaneacion}/modulo/${idModulo}/elemento/${idElemento}`;

  return await patchServerRequest(url, data, config);
};

export const agregarActividadToModuloElemento = async ({
  idPlaneacion,
  idModulo,
  idElemento,
  data,
}) => {
  const token = store.getters.sessionToken;
  const config = { headers: { token } };
  const url = `${httpURL}/planeaciones/moduloElementos/planeacion/${idPlaneacion}/modulo/${idModulo}/elemento/${idElemento}/actividad`;

  return await postServerRequest(url, data, config);
};

export const quitarActividadDeModuloElemento = async ({
  idPlaneacion,
  idModulo,
  idElemento,
  idActividad,
}) => {
  const token = store.getters.sessionToken;
  const config = { headers: { token } };
  const url = `${httpURL}/planeaciones/moduloElementos/planeacion/${idPlaneacion}/modulo/${idModulo}/elemento/${idElemento}/actividad/${idActividad}`;

  return await deleteServerRequest(url, config);
};
