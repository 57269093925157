<template>
  <v-container>
    <v-card flat>
      <v-toolbar color="secondary" dark flat class="text-h6">
        <v-btn icon @click="regresar"><v-icon>mdi-arrow-left</v-icon></v-btn>
        <v-spacer />
        <span v-if="loading">Cargando...</span>
        <span v-else>{{ interactivo.nombre }}</span>
        <v-spacer />
        <v-menu v-if="permisoEscritura" offset-y left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon small v-bind="attrs" v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item @click="abrirEditorInteractivo = true">
              <v-list-item-title>Modificar nombre</v-list-item-title>
              <v-list-item-icon
                ><v-icon small>mdi-pencil</v-icon></v-list-item-icon
              >
            </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar>
      <v-skeleton-loader
        v-if="loading"
        type="list-item-two-line@5"
      ></v-skeleton-loader>
      <v-container v-else>
        <v-row>
          <v-col cols="12">
            <crosswordComponent
              v-if="tipoInteractivo === 'crossword'"
              :interactivo="interactivo"
              :tipoInteractivo="tipoInteractivo"
              :permisoEscritura="permisoEscritura"
              @descripcionModificada="interactivoModificado"
              @logAgregado="logAgregado"
            />
            <dragthewordsComponent
              v-if="tipoInteractivo === 'dragthewords'"
              :interactivo="interactivo"
              :tipoInteractivo="tipoInteractivo"
              :permisoEscritura="permisoEscritura"
              @descripcionModificada="interactivoModificado"
              @logAgregado="logAgregado"
            />
            <fillblanksComponent
              v-if="tipoInteractivo === 'fillblanks'"
              :interactivo="interactivo"
              :tipoInteractivo="tipoInteractivo"
              :permisoEscritura="permisoEscritura"
              @descripcionModificada="interactivoModificado"
              @logAgregado="logAgregado"
            />
            <multiplechoiceComponent
              v-if="tipoInteractivo === 'multiplechoice'"
              :interactivo="interactivo"
              :tipoInteractivo="tipoInteractivo"
              :permisoEscritura="permisoEscritura"
              @preguntaModificada="interactivoModificado"
              @logAgregado="logAgregado"
            />
            <sortparagraphsComponent
              v-if="tipoInteractivo === 'sortparagraphs'"
              :interactivo="interactivo"
              :tipoInteractivo="tipoInteractivo"
              :permisoEscritura="permisoEscritura"
              @descripcionModificada="interactivoModificado"
              @logAgregado="logAgregado"
            />
            <findthewordsComponent 
              v-if="tipoInteractivo === 'findthewords'"
              :interactivo="interactivo"
              :tipoInteractivo="tipoInteractivo"
              :permisoEscritura="permisoEscritura"
              @descripcionModificada="interactivoModificado"
              @logAgregado="logAgregado"
            />
          </v-col>
        </v-row>
        <usuariosInteractivo
          v-if="app !== 'campus'"
          :permisoEscritura="permisoEscritura"
          @logAgregado="logAgregado"
        />
        <logsComponent v-if="!loading" :logs="logs" />
      </v-container>
    </v-card>
    <editorInteractivo
      v-if="abrirEditorInteractivo"
      :mostrar="abrirEditorInteractivo"
      :editarInteractivo="interactivo"
      :tipoInteractivo="tipoInteractivo"
      @cancelar="abrirEditorInteractivo = false"
      @interactivoModificado="interactivoModificado"
    />
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import crosswordComponent from "./crossword/crossword.view.vue";
import dragthewordsComponent from "./dragthewords/dragthewords.view.vue";
import fillblanksComponent from "./fillblanks/fillblanks.view.vue";
import multiplechoiceComponent from "./multiplechoice/multiplechoice.view.vue";
import sortparagraphsComponent from "./sortparagraphs/sortparagraphs.view.vue";
import findthewordsComponent from "./findthewords/findthewords.view.vue";
import usuariosInteractivo from "./usuariosInteractivo.vue";
import logsComponent from "../widgets/logs.vue";
import { getInteractivoService } from "./interactivos.service";
import EditorInteractivo from "./editorInteractivo.vue";

export default {
  name: "InteractivoView",
  components: {
    crosswordComponent,
    dragthewordsComponent,
    fillblanksComponent,
    multiplechoiceComponent,
    sortparagraphsComponent,
    findthewordsComponent,
    usuariosInteractivo,
    EditorInteractivo,
    logsComponent,
  },
  computed: {
    ...mapGetters(["sessionToken", "app", "menu"]),
  },
  watch: {
    sessionToken(value) {
      if (value) {
        if (this.app === "escuela") this.validarPermisoRuta();
        this.escuelaTienePermiso = true;
        this.getInteractivo();
      }
    },
  },
  mounted() {
    this.tipoInteractivo = this.$route.params.tipoInteractivo;
    this.idInteractivo = this.$route.params.idInteractivo;
    if (this.sessionToken) {
      if (this.app === "escuela" && this.menu.length > 0)
        this.validarPermisoRuta();
      this.escuelaTienePermiso = true;
      this.getInteractivo();
    }
  },

  data() {
    return {
      loading: true,
      loadingColaboradores: false,
      loadingCompartido: false,
      permisoEscritura: true,
      tipoInteractivo: null,
      idInteractivo: null,
      escuelaTienePermiso: false,
      abrirEditorInteractivo: false,
      interactivo: {},
      logs: [],
    };
  },
  methods: {
    validarPermisoRuta() {
      const menuInteractivos = this.menu.find(
        (x) => x.title === "Interactivos"
      );
      if (menuInteractivos) {
        const itemIndex = menuInteractivos.items.findIndex(
          (x) => x.title === "Interactivos"
        );
        if (itemIndex == -1) this.$router.push("/dashboard");
      }
    },

    regresar() {
      this.$router.push("/interactivos");
    },

    async getInteractivo() {
      this.loading = true;
      try {
        const serverResponse = await getInteractivoService(
          this.tipoInteractivo,
          this.idInteractivo
        );
        this.loading = false;
        this.$validateResponse(serverResponse);
        if (!serverResponse.ok) {
          this.$systemErrorMessage(serverResponse.mensaje);
          return;
        } else {
          this.interactivo = serverResponse.interactivo;
          this.permisoEscritura = this.interactivo.permisoEscritura;
          this.logs = serverResponse.logs;
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },

    async interactivoModificado(payload) {
      if (payload.interactivo.nombre)
        this.interactivo.nombre = payload.interactivo.nombre;
      if (payload.interactivo.descripcion)
        this.interactivo.descripcion = payload.interactivo.descripcion;
      if (payload.interactivo.pregunta)
        this.interactivo.pregunta = payload.interactivo.pregunta;
      this.logs.unshift(payload.nuevoLog);
    },
    logAgregado(nuevoLog) {
      this.logs.unshift(nuevoLog);
    },
  },
};
</script>
