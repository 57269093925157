import {
  getServerRequest,
  postServerRequest,
  putServerRequest,
  patchServerRequest,
} from "@/components/globals/services/serverRequest.service";
import { store } from "../../../../store/store";

const httpURL = store.getters.httpURL;

export const tiposInteractivos = [
  { tipo: "crossword", nombre: "Crucigrama" },
  { tipo: "dragthewords", nombre: "Completar palabras" },
  { tipo: "fillblanks", nombre: "Llenar espacios" },
  { tipo: "multiplechoice", nombre: "Opción múltiple" },
  { tipo: "sortparagraphs", nombre: "Ordenar párrafos" },
  { tipo: "findthewords", nombre: "Buscar palabras" },
]

export const tiposInteractivosNombre = {
  crossword: 'Crucigrama',
  dragthewords: 'Completar palabras',
  fillblanks: 'Llenar espacios',
  multiplechoice: 'Opción múltiple',
  sortparagraphs: 'Ordenar párrafos',
  findthewords: 'Buscar palabras'
}

export const getAreasService = async (data) => {
  const token = store.getters.sessionToken;
  const config = { headers: { token } };
  const url = `${httpURL}/interactivos/areas/listado`;
  return await postServerRequest(url, { data }, config);
};

export const crearAreaService = async (data) => {
  const token = store.getters.sessionToken;
  const config = { headers: { token } };
  const url = `${httpURL}/interactivos/area`;
  return await postServerRequest(url, data, config);
}

export const modificarAreaService = async (idArea, data) => {
  const token = store.getters.sessionToken;
  const config = { headers: { token } };
  const url = `${httpURL}/interactivos/area/${idArea}`;
  return await patchServerRequest(url, data, config);
}

export const getSubareasService = async (idArea) => {

  const token = store.getters.sessionToken;
  const config = { headers: { token } };
  const url = `${httpURL}/interactivos/${idArea}/subareas/listado`;
  return await getServerRequest(url, config);
};

export const crearSubareaService = async (idArea, data) => {

  const token = store.getters.sessionToken;
  const config = { headers: { token } };
  const url = `${httpURL}/interactivos/${idArea}/subarea`;
  return await postServerRequest(url, data, config);
}

export const modificarSubareaService = async (idArea, idSubArea, data) => {
  const token = store.getters.sessionToken;
  const config = { headers: { token } };
  const url = `${httpURL}/interactivos/${idArea}/${idSubArea}`;
  return await patchServerRequest(url, data, config);
}

export const getInteractivosService = async (idArea, idSubarea) => {
  const token = store.getters.sessionToken;
  const config = { headers: { token } };
  const url = `${httpURL}/interactivos/listado/${idArea}/${idSubarea}`;
  return await getServerRequest(url, config);
};

export const agregarInteractivoService = async (data, idArea, idSubarea) => {
  const token = store.getters.sessionToken;
  const config = { headers: { token } };
  const url = `${httpURL}/interactivos/${idArea}/${idSubarea}`;
  return await postServerRequest(url, data, config);
}

export const getInteractivoService = async (tipoInteractivo, idInteractivo) => {
  const token = store.getters.sessionToken;
  const config = { headers: { token } };
  const url = `${httpURL}/interactivos/interactivo/${tipoInteractivo}/${idInteractivo}`;
  return await getServerRequest(url, config);
};

export const modificarInteractivoService = async (tipo, value, idInteractivo, campo) => {
  const token = store.getters.sessionToken;
  const config = { headers: { token } };
  let data = {};
  data[campo] = value;
  const url = `${httpURL}/interactivo/${tipo}/${campo}/${idInteractivo}`;
  return await patchServerRequest(url, data, config);
}

// colaboradores y compartido
export async function obtenerUsuariosInteractivoService(tipoInteractivo, idInteractivo, tipoUsuario) {
  const token = store.getters.sessionToken;
  const config = { headers: { token } };
  const url = `${httpURL}/interactivo/${tipoInteractivo}/usuarios/${tipoUsuario}/${idInteractivo}`;
  return await getServerRequest(url, config);
}

export async function agregarUsuarioInteractivoService(tipoInteractivo, idInteractivo, tipoUsuario, idUsuario) {
  const token = store.getters.sessionToken;
  const config = { headers: { token } };
  const data = { idUsuario };
  const url = `${httpURL}/interactivo/${tipoInteractivo}/usuarios/${tipoUsuario}/${idInteractivo}`;
  return await putServerRequest(url, data, config);
}