import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"persistent":"","width":"450"},model:{value:(_vm.mostrar),callback:function ($$v) {_vm.mostrar=$$v},expression:"mostrar"}},[_c(VCard,{attrs:{"loading":_vm.loading}},[_c(VToolbar,{attrs:{"dense":"","color":"primary","dark":"","flat":""}},[_c(VToolbarTitle,[_vm._v(_vm._s(_vm.item ? "Modificar palabra" : "Agregar palabra"))])],1),_c(VCardText,{staticClass:"pt-3 pb-0 px-4"},[_c(VRow,{attrs:{"dense":""}},[_c(VCol,{attrs:{"cols":"12"}},[_c(VTextField,{attrs:{"label":"Palabra:","outlined":"","dense":"","disabled":_vm.loading,"error-messages":_vm.palabraErrors},on:{"input":function($event){_vm.limpiarPalabra();
              _vm.validarPalabra();},"blur":function($event){_vm.limpiarPalabra();
              _vm.validarPalabra();}},model:{value:(_vm.palabra),callback:function ($$v) {_vm.palabra=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"palabra"}}),_c(VTextarea,{attrs:{"label":"Pista:","outlined":"","disabled":_vm.loading},on:{"input":function($event){return _vm.validarPista()},"blur":function($event){return _vm.validarPista()}},model:{value:(_vm.pista),callback:function ($$v) {_vm.pista=$$v},expression:"pista"}})],1)],1)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"small":"","color":"secondary","outlined":"","disabled":_vm.loading},on:{"click":function($event){return _vm.$emit('cancelar')}}},[_vm._v("Cancelar")]),_c(VBtn,{attrs:{"small":"","color":"primary","disabled":_vm.loading || !_vm.formularioValido,"loading":_vm.loading},on:{"click":function($event){return _vm.guardarItem()}}},[_vm._v("Guardar")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }