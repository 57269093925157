<template>
  <v-container :class="{ 'pa-0': idPlaneacion }">
    <v-card :loading="loadingUpdate">
      <v-toolbar
        dense
        :color="idPlaneacion ? 'primary' : 'secondary'"
        dark
        flat
        class="text-h6"
      >
        <v-btn v-if="!idPlaneacion" icon @click="goBack"
          ><v-icon>mdi-arrow-left</v-icon></v-btn
        >
        <v-spacer></v-spacer>{{ planeacion ? planeacion.nombre : ""
        }}<v-spacer></v-spacer>
        <planeacionMenu
          v-if="planeacion"
          :planeacion="planeacion"
          @planeacionSaved="planeacionSaved"
        />
      </v-toolbar>

      <div v-if="loading">
        <v-container>
          <v-skeleton-loader type="article, article"></v-skeleton-loader>
        </v-container>
      </div>
      <div v-else-if="planeacion">
        <v-container>
          <descripcionPlaneacion
            :descripcion="planeacion.descripcion"
            @actualizarPlaneacion="actualizarPlaneacion"
          ></descripcionPlaneacion>
          <planeacionModulos
            :modulos="planeacion.modulos"
            :idPlaneacion="planeacion._id"
            class="mt-3"
            :customGoToModulo="!!idPlaneacion"
            @actualizarPlaneacion="actualizarPlaneacion"
            @goToModulo="goToModulo"
          ></planeacionModulos>
          <logs-planeacion :logs="logs"></logs-planeacion>
        </v-container>
      </div>
    </v-card>
  </v-container>
</template>

<script>
import planeacionMenu from "./planeacionMenu.vue";
import descripcionPlaneacion from "./planeacion/descripcionPlaneacion.vue";
import planeacionModulos from "./planeacion/modulos/planeacionModulos.vue";
import logs from "../widgets/logs.vue";

import {
  getPlaneacion,
  updatePlaneacion,
} from "./planeacionesAcademicas.service";
import { mapGetters } from "vuex";

export default {
  props: {
    idPlaneacion: {
      type: String,
    },
  },

  components: {
    planeacionMenu,
    descripcionPlaneacion,
    planeacionModulos,
    "logs-planeacion": logs,
  },

  data() {
    return {
      loading: false,
      loadingUpdate: false,
      planeacion: null,
      logs: [],
    };
  },

  computed: {
    ...mapGetters(["sessionToken"]),
  },

  watch: {
    sessionToken(v) {
      !!v && this.cargarPlaneacion();
    },
  },

  mounted() {
    if (this.sessionToken) this.cargarPlaneacion();
  },

  methods: {
    async cargarPlaneacion() {
      this.loading = true;

      try {
        const serverResponse = await getPlaneacion(
          this.$route.params.idPlaneacion || this.idPlaneacion
        );

        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else {
          this.planeacion = serverResponse.planeacion;
          this.logs = serverResponse.logs;
        }
      } catch (error) {
        console.error(error);
        this.$appErrorMessage();
      } finally {
        this.loading = false;
      }
    },

    async actualizarPlaneacion(payload) {
      this.loadingUpdate = true;

      try {
        const serverResponse = await updatePlaneacion(
          this.$route.params.idPlaneacion || this.idPlaneacion,
          payload
        );

        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
      } catch (error) {
        console.error(error);
        this.$appErrorMessage();
      } finally {
        this.loadingUpdate = false;
      }
    },

    planeacionSaved(planeacion) {
      this.planeacion.nombre = planeacion.nombre;
    },

    goToModulo(idModulo) {
      this.$emit("goToModulo", idModulo);
    },

    goBack() {
      const { from, fromModulo } = this.$route.query;

      if (from) this.$router.replace(from);
      if (fromModulo) this.$router.go(-3);
      else this.$router.back();
    },
  },
};
</script>
