import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';

import router from './router'
import { store } from './store/store';
import Croppa from 'vue-croppa';

import './components/globals/classes/ck-content.css';

import Notificaciones from './plugins/notificaciones'
import CKEditor from '@ckeditor/ckeditor5-vue';
import '@fortawesome/fontawesome-free/css/all.css'

import ValidateResponse from './plugins/validateResponse';
import rules from './plugins/rules';

Vue.config.productionTip = false

Vue.use(Croppa);
Vue.use(Notificaciones, store)
Vue.use(ValidateResponse, router)

Vue.prototype.$rules = rules;

Vue.use(CKEditor);
new Vue({
    vuetify,
    router,
    store,
    iconfont: 'fa',
    render: h => h(App)
}).$mount('#app')