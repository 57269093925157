<template>
  <datatable
    :headers="headersLocal"
    :pagination="pagination"
    :datos="alumnosLocal"
    :itemsPerPage="alumnosLocal.length"
    :totalItems="alumnosLocal.length"
    hideSearch
    hidePagination
  >
    <template #item-avatar="{ value }">
      <v-avatar color="primary" size="30">
        <v-icon color="white" v-if="!value">mdi-account</v-icon>
        <img v-else :src="`${imageURL}/avatar/${value}`" alt="UserImage" />
      </v-avatar>
    </template>

    <template #item-calificacion="{ item }" >

      <calDirectaItem
        v-if="tipoActividad == 1 || (transversal && transversal.tipo == 1)"
        :idCurso="idCurso"
        :idActividad="idActividad"
        :instrumento="instrumento"
        :tipoInstrumento="tipoInstrumento"
        :alumno="seleccionarAlumno(item._id)"
        :permisoEscritura="permisoEscritura"
        :nuevoUsuario="nuevoUsuario"
        :ultimoUsuario="alumnosLocal.length"
        @btnCambio="btnCambio"
        @cancelarCambio="cancelarCambio"
        @calificacionAgregada="calificacionAgregada"
        @calificacionModificada="calificacionModificada"
      />

      <calArchivoItem
        v-if="tipoActividad == 2 || (transversal && transversal.tipo == 2)"
        :idCurso="idCurso"
        :idActividad="idActividad"
        :instrumento="instrumento"
        :tipoInstrumento="tipoInstrumento"
        :disponible="disponible"
        :alumno="seleccionarAlumno(item._id)"
        :nuevoUsuario="nuevoUsuario"
        :ultimoUsuario="alumnosLocal.length"
        @btnCambio="btnCambio"
        @cancelarCambio="cancelarCambio"
        :permisoEscritura="permisoEscritura"
        @calificacionModificadaArchivo="calificacionModificadaArchivo"
      />

      <calInteractivoItem
        v-if="tipoActividad == 4"
        :idCurso="idCurso"
        :idActividad="idActividad"
        :alumno="seleccionarAlumno(item._id)"
        :nombre="item.nombreCompleto"
      />

    </template>

    <template v-if="tipoActividad === 2 || (transversal && transversal.tipo === 2)" #item-documento="{ item }">
      <documentoItem
        :idCurso="idCurso"
        :idActividad="idActividad"
        :disponible="disponible"
        :documento="seleccionarDocumentoAlumno(item._id)"
      />
    </template>

  </datatable>
</template>

<script>
import { mapGetters } from "vuex";
import datatable from "../../../widgets/datatable.vue";
import calDirectaItem from "./calDirectaItem.vue";
import calArchivoItem from "./calArchivoItem.vue";
import calInteractivoItem from "./calInteractivoItem.vue";
import documentoItem from "./documentoItem.vue";
import { ordenarObjetosDesc } from "../../../../helpers/ordenador";

export default {
  name: "tablaAlumnos",
  props: {
    idCurso: { type: String, default: null },
    idActividad: { type: String, default: null },
    disponible: { type: Object, default: () => {} },
    tipoActividad: { type: Number, default: 1 },
    instrumento: { type: String, default: null },
    tipoInstrumento: { type: String, default: null },
    alumnos: { type: Array, default: () => [] },
    calificaciones: { type: Array, default: () => [] },
    intentos: { type: Array, default: () => [] },
    documentos: { type: Array, default: () => [] },
    permisoEscritura: { type: Boolean, default: false },
    transversal: { type: Object, default: null },
    reenvioXAlumno: { type: Array, default: () => [] },
  },
  components: {
    datatable,
    calDirectaItem,
    calArchivoItem,
    calInteractivoItem,
    documentoItem,
  },
  computed: {
    ...mapGetters(["imageURL"]),
    alumnosLocal() {
      const alumnosResponse = [];
      let numero = 1;
      for (const alumno of this.alumnos) {
        let alumnoItem = { numero: numero++, ...alumno };

        let calificaciones = this.calificaciones.filter((x) => x.usuario == alumnoItem._id);

        alumnoItem.documentos = this.documentos.filter((x) => x.usuario == alumnoItem._id);

        alumnoItem.calificaciones = ordenarObjetosDesc(calificaciones, "fecha");

        if(this.tipoActividad === 4)
          alumnoItem.intentos = this.intentos.filter(x=>x.usuario === alumnoItem._id);

        if(this.transversal)
          alumnoItem.reenvioXAlumno = this.reenvioXAlumno.find((x) => x.usuario == alumnoItem._id);

        alumnosResponse.push(alumnoItem);
      }
      return alumnosResponse;
    },
    headersLocal() {
      const headers = [
        {
          text: "",
          value: "numero",
          sortable: false,
          filterable: false,
          width: "0px",
        },
        {
          text: "",
          value: "avatar",
          sortable: false,
          filterable: false,
          width: "0px",
        },
        {
          text: "Nombre",
          value: "nombreCompleto",
          sortable: false,
          filterable: false,
          width: "250px"
        },
        {
          text: "Calificación",
          value: "calificacion",
          sortable: false,
          filterable: false,
          width: "500px",
          align: 'center'
        },
      ];

      if (this.tipoActividad === 2 || (this.transversal && this.transversal.tipo === 2)) {
        headers.push(
          {
            text: "Documento",
            value: "documento",
            sortable: false,
            filterable: false,
            align: 'center',
            width: "150px"
          },
        )
      }

      return headers;

    }
  },
  data() {
    return {
      loading: false,
      pagination: {
        page: 1,
        itemsPerPage: this.alumnos.length,
        pageStart: 0,
        pageStop: 0,
        pageCount: 0,
        itemsLength: 0,
      },
      nuevoUsuario: null,
    };
  },
  methods: {
    calificacionAgregada(calificacion) {
      this.$emit("calificacionAgregada", calificacion);
    },
    calificacionModificada(calificacion) {
      this.$emit("calificacionModificada", calificacion);
    },
    calificacionModificadaArchivo(calificacion) {
      this.$emit("calificacionModificadaArchivo", calificacion);
    },
    seleccionarAlumno(idAlumno) {
      return this.alumnosLocal.find((alumno) => {
        return alumno._id === idAlumno;
      });
    },
    seleccionarDocumentoAlumno(idAlumno) {
      const alumno = this.alumnosLocal.find((alumno) => {
        return alumno._id === idAlumno;
      });

      return alumno.documentos[0] || null;
    },
    cancelarCambio() {
      this.nuevoUsuario = null;
    },
    btnCambio(newIndex) {
      const nuevoAlumno =  this.alumnosLocal.find((alumno) => {
        return alumno.numero === newIndex;
      });

      this.nuevoUsuario = nuevoAlumno;
    }
  },
};
</script>
