<template>
  <v-card flat tile height="100%" :loading="loading">
    <v-row no-gutters :style="{ height: esHorizontal ? '100%' : null }">
      <v-col :cols="esHorizontal ? 5 : 12">
        <v-toolbar dense dark flat color="blue lighten-4" style="height: 100%">
          <v-menu offset-y :close-on-content-click="false">
            <template #activator="{ on, attrs }">
              <v-btn v-on="on" v-bind="attrs" color="black" icon light small>
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="(menuItem, index) in menuElemento"
                :key="index"
                dense
                link
                @click="menuItem.method()"
              >
                <v-list-item-title>
                  <v-icon small class="mr-2">{{ menuItem.icon }}</v-icon>
                  <span>{{ menuItem.titulo }}</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-toolbar-title style="min-width: 80%">
            <span
              v-if="esElementoTexto"
              class="text-body-2"
              style="color: black"
              >{{ elementoLocal.titulo }}</span
            >
            <span v-else class="text-body-2" style="color: black">
              Sesión {{ elementoLocal.sesion }} - Semana
              {{ elementoLocal.semana }} ({{
                getTipoSesion(elementoLocal.tipoSesion)
              }})
            </span>
          </v-toolbar-title>
        </v-toolbar>
      </v-col>
      <v-col
        v-if="esElementoTexto"
        :cols="esHorizontal ? 7 : 12"
        class="pa-2 px-5"
      >
        <span v-html="elementoLocal.texto"></span>
      </v-col>
      <v-col v-else :cols="esHorizontal ? 7 : 12" class="pa-2 px-5">
        <span
          v-if="!!elementoLocal.texto && elementoLocal.texto != '<p></p>'"
          v-html="elementoLocal.texto"
        ></span>
        <span v-else class="font-italic">No se ha capturado descripción.</span>
        <div v-if="esPlaneacionCurso" class="my-3">
          <actividades-elemento
            :moduloElemento="elemento"
          ></actividades-elemento>
        </div>
      </v-col>
    </v-row>

    <edicion-dialog
      v-if="showDialog"
      :show="showDialog"
      :elemento="elementoLocal"
      :inputs="dialogInputs"
      @close="showDialog = false"
      @elementoSaved="elementoSaved"
    ></edicion-dialog>
  </v-card>
</template>

<script>
import { editarModuloElemento } from "./planeacionModuloElementos.service";

export default {
  components: {
    "edicion-dialog": () => import("./moduloElementoDialog.vue"),
    "actividades-elemento": () => import("./ActividadesModuloElemento.vue"),
  },

  props: {
    elemento: {
      type: Object,
      required: true,
    },
    planeacion: {
      type: Object,
    },
  },

  data() {
    return {
      elementoLocal: this.elemento,
      showDialog: false,
      dialogInputs: [],
      loading: false,
    };
  },

  computed: {
    menuElemento() {
      const menuElementoTexto = [
        {
          titulo: "Modificar título",
          icon: "mdi-pencil",
          method: this.actualizarTitulo,
        },
        {
          titulo: "Modificar texto",
          icon: "mdi-pencil",
          method: this.actualizarTexto,
        },
        {
          titulo: "Cambiar orientación",
          icon: "mdi-phone-rotate-landscape",
          method: this.cambiarOrientacion,
        },
        {
          titulo: "Cambiar tamaño",
          icon: "mdi-arrow-split-vertical",
          method: this.actualizarTamano,
        },
      ];

      const menuElementoSecuencia = [
        {
          titulo: "Modificar sesión",
          icon: "mdi-account-multiple",
          method: this.actualizarSesion,
        },
        {
          titulo: "Modificar descripción",
          icon: "mdi-pencil",
          method: this.actualizarTexto,
        },
      ];

      return [
        ...(this.esElementoTexto ? menuElementoTexto : []),
        ...(!this.esElementoTexto ? menuElementoSecuencia : []),
        {
          titulo: "Eliminar elemento",
          icon: "mdi-delete",
          method: () => null,
        },
      ];
    },

    esHorizontal() {
      return this.elementoLocal.orientacion == "horizontal";
    },

    esElementoTexto() {
      return this.elementoLocal.tipo == "texto";
    },

    esPlaneacionCurso() {
      return (
        !!this.planeacion &&
        (!!this.planeacion.cursoPlantilla || !!this.planeacion.cursoCampus)
      );
    },
  },

  methods: {
    getTipoSesion(id) {
      return {
        1: "Presencial",
        2: "Virtual",
        3: "Mixta",
      }[Number(id)];
    },

    actualizarTitulo() {
      this.dialogInputs = ["titulo"];
      this.showDialog = true;
    },

    actualizarTexto() {
      this.dialogInputs = ["texto"];
      this.showDialog = true;
    },

    actualizarTamano() {
      this.dialogInputs = ["porcentajeVista"];
      this.showDialog = true;
    },

    cambiarOrientacion() {
      const orientacion =
        this.elementoLocal.orientacion == "vertical"
          ? "horizontal"
          : "vertical";
      this.actualizarElemento({ orientacion });
    },

    actualizarSesion() {
      this.dialogInputs = ["sesion", "tipoSesion", "semana"];
      this.showDialog = true;
    },

    async actualizarElemento(inputData) {
      this.loading = true;

      try {
        const serverResponse = await editarModuloElemento({
          idPlaneacion: this.elemento.planeacion,
          idModulo: this.elemento.modulo,
          idElemento: this.elemento._id,
          data: inputData,
        });

        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else this.elementoLocal = serverResponse.elemento;
      } catch (error) {
        console.error(error);
        this.$appErrorMessage();
      } finally {
        this.loading = false;
      }
    },

    elementoSaved(elemento) {
      this.dialogInputs = [];
      this.elementoLocal = elemento;
      this.showDialog = false;
      this.$emit("elementoSaved", elemento);
    },
  },
};
</script>
