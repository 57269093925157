import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VListItem,{attrs:{"dense":""},on:{"mouseover":function($event){_vm.permisoEscritura & !_vm.vistaSeguimiento ? (_vm.modificarIcon = true) : null},"mouseout":function($event){_vm.permisoEscritura ? (_vm.modificarIcon = false) : null}}},[_c(VListItemIcon,[(_vm.modificarIcon && !_vm.vistaSeguimiento)?_c(VBtn,{attrs:{"fab":"","x-small":"","color":"primary"},on:{"click":function($event){_vm.modificarPonderacion = true}}},[_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-pencil")])],1):_c(VIcon,{attrs:{"color":"primary"}},[_vm._v("mdi-file-percent-outline")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v("Ponderación")]),_c(VListItemSubtitle,[(_vm.ponderacion == -1)?_c('span',[_vm._v("Promedio")]):_c('span',[_vm._v(_vm._s(_vm.ponderacion)+"%")])])],1)],1),(_vm.modificarPonderacion)?_c('modificarPonderacionActividad',{attrs:{"mostrar":_vm.modificarPonderacion,"tipoCurso":_vm.tipoCurso,"idCurso":_vm.idCurso,"idActividad":_vm.idActividad,"ponderacion":_vm.ponderacion},on:{"cancelar":function($event){_vm.modificarPonderacion = false},"ponderacionModificada":_vm.ponderacionModificada}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }