import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"persistent":"","fullscreen":""},model:{value:(_vm.mostrar),callback:function ($$v) {_vm.mostrar=$$v},expression:"mostrar"}},[_c(VCard,[_c(VToolbar,{attrs:{"dense":"","color":"primary","dark":"","flat":""}},[_c(VSpacer),_c(VToolbarTitle,[_vm._v(_vm._s(_vm.nombreInteractivo))]),_c(VSpacer),_c(VBtn,{attrs:{"text":""},on:{"click":function($event){return _vm.$emit('cerrar')}}},[_vm._v("Cerrar")])],1),_c('iframe',{staticStyle:{"height":"1000px"},attrs:{"src":_vm.h5pRoute,"width":"100%"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }