import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{attrs:{"flat":""}},[_c(VToolbar,{attrs:{"color":"primary","dense":"","dark":"","flat":""}},[_c('span',[_vm._v(_vm._s(_vm.reactivo ? "Modificar reactivo" : "Nuevo reactivo"))])]),_c(VCardText,{staticClass:"py-2"},[_c(VRow,{staticClass:"pt-2",attrs:{"dense":""}},[_c(VCol,{attrs:{"cols":"6"}},[_c(VSelect,{attrs:{"items":_vm.tipos,"item-text":"texto","item-value":"valor","dense":"","outlined":"","label":"Tipo de reactivo"},model:{value:(_vm.tipo),callback:function ($$v) {_vm.tipo=$$v},expression:"tipo"}})],1),(_vm.tipo === 'opciones')?_c(VCol,{attrs:{"cols":"6"}},[_c(VSelect,{attrs:{"items":_vm.selecciones,"item-text":"texto","item-value":"valor","dense":"","outlined":"","label":"Selección"},model:{value:(_vm.seleccion),callback:function ($$v) {_vm.seleccion=$$v},expression:"seleccion"}})],1):_vm._e()],1),_c('div',[_c('editor-texto',{ref:"editorTexto",attrs:{"contenido":"examen","idContenido":_vm.$route.params.idExamen,"texto":_vm.texto}})],1),_c('div',[_c(VSubheader,{staticClass:"px-0"},[_vm._v("Respuestas "+_vm._s(_vm.tipo === "opciones" ? "" : "correctas")+":")]),_c('draggable',{attrs:{"tag":"div","handle":".handle"},on:{"change":_vm.modificarIndexRespuesta},model:{value:(_vm.respuestas),callback:function ($$v) {_vm.respuestas=$$v},expression:"respuestas"}},_vm._l((_vm.respuestas),function(respuesta,index){return _c(VListItem,{key:index},[_c(VListItemIcon,[_c(VBtn,{staticClass:"handle",staticStyle:{"cursor":"move"},attrs:{"icon":""}},[_c(VIcon,[_vm._v("mdi-dots-grid")])],1),_c(VSwitch,{staticClass:"ma-0 mt-1",attrs:{"readonly":_vm.tipo === 'abierta',"color":"success","dense":"","append-icon":"mdi-check"},model:{value:(respuesta.correcta),callback:function ($$v) {_vm.$set(respuesta, "correcta", $$v)},expression:"respuesta.correcta"}})],1),_c(VListItemContent,[_c(VListItemTitle,[_c(VTextField,{attrs:{"dense":"","placeholder":`Respuesta ${index + 1} - ${
                  (respuesta.correcta && 'Correcta') || 'Incorrecta'
                }`,"rules":[
                  (v) => !!v || 'El texto de la respuesta es requerido.',
                ]},model:{value:(respuesta.texto),callback:function ($$v) {_vm.$set(respuesta, "texto", $$v)},expression:"respuesta.texto"}})],1)],1),_c(VListItemAction,[_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){return _vm.eliminarRespuesta(index)}}},[_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-close")])],1)],1)],1)}),1),_c(VContainer,{staticClass:"px-3 py-0"},[_c(VTextField,{attrs:{"label":"Nueva respuesta","filled":"","dense":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.agregarRespuesta()}},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c(VBtn,{attrs:{"disabled":!_vm.nuevaRespuesta,"color":"secondary","elevation":"0","small":""},on:{"click":function($event){return _vm.agregarRespuesta()}}},[_c(VIcon,{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("mdi-plus")]),_c('span',[_vm._v("Agregar")])],1)]},proxy:true}]),model:{value:(_vm.nuevaRespuesta),callback:function ($$v) {_vm.nuevaRespuesta=$$v},expression:"nuevaRespuesta"}})],1)],1),_c('div',{staticClass:"pb-3",class:{ 'py-3': _vm.tipo === 'abierta' }},[_c(VBtn,{attrs:{"disabled":_vm.loading,"loading":_vm.loading,"small":"","color":"primary"},on:{"click":function($event){return _vm.guardarReactivo()}}},[_c(VIcon,{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("mdi-content-save")]),_c('span',[_vm._v("Guardar reactivo")])],1),(_vm.reactivo)?_c(VBtn,{staticClass:"ml-2",attrs:{"color":"secondary","outlined":"","small":"","disabled":_vm.loading},on:{"click":function($event){return _vm.$emit('cancelar')}}},[_vm._v("Cancelar")]):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }