<template>
  <v-card outlined>
    <v-card-title>Párrafos
      <v-spacer />
      <v-btn
        v-if="parrafos.length > 1 && !parrafosIncompletos"
        color="primary"
        small
        icon
        @click="$emit('preview')"
      >
        <v-icon>mdi-motion-play-outline</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text v-if="parrafos.length === 0"
      ><i>No se han generado párrafos</i></v-card-text
    >
    <template v-else>
      <v-container>
        <draggable 
          v-model="parrafosLocal"
          tag="div"
          ghost-class="ghost"
          handle=".handle"
          @change="cambiarOrden"
        >
          <template v-for="(parrafo, index) in parrafosLocal">
            <v-list-item :key="parrafo._id" class="item-seccion pl-0">
              <v-list-item-avatar
                v-if="permisoEscritura"
                class="mr-0"
                :class="{ handle: !loading && !modificandoParrafo }"
              >
                <v-btn icon style="cursor: move" :disabled="loading">
                  <v-icon>mdi-dots-grid</v-icon>
                </v-btn>
              </v-list-item-avatar>

              <v-list-item-content>
                <sortparagraphsParrafo
                  :parrafo="parrafo"
                  :index="index"
                  :idInteractivo="idInteractivo"
                  :tipoInteractivo="tipoInteractivo"
                  :permisoEscritura="permisoEscritura"
                  :modificandoParrafo="modificandoParrafo"
                  @modificandoParrafo="setModificandoParrafo"
                  @parrafoModificado="parrafoModificado"
                  @parrafoEliminado="parrafoEliminado" 
                />
              </v-list-item-content>
            </v-list-item>
          </template>
        
        </draggable>
      </v-container>
    </template>
    <v-divider class="mx-3"></v-divider>
    <v-container>
      <v-row>
        <v-col v-if="permisoEscritura" cols="12">
          <v-spacer />
          <v-btn
            color="primary"
            small
            :disabled="modificandoParrafo"
            :loading="loading"
            @click="agregarParrafo()"
            >Agregar párrafo</v-btn
          >
          <v-spacer />
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import draggable from "vuedraggable";
import sortparagraphsParrafo from "./sortparagraphsParrafo.vue";
import { agregarSortparagraphsParrafoService, modificarOrdenSortparagraphsParrafoService } from "./sortparagraphs.service.js";

export default {
  components: {
    draggable,
    sortparagraphsParrafo
  },
  props: {
    idInteractivo: { type: String, default: null },
    tipoInteractivo: { type: String, default: null },
    permisoEscritura: { type: Boolean, default: false },
    texto: { type: String, default: null },
    parrafos: Array,
  },
  computed: {
    ...mapGetters(["sessionToken", "app", "role", "menu"]),
    parrafosIncompletos() {
      return this.parrafosLocal.some((parrafo) => !parrafo );
    }
  },

  mounted() {
    this.parrafosLocal = this.parrafos;
  },

  data() {
    return {
      loading: false,
      modificandoParrafo: false,
      parrafosLocal: [],
    };
  },

  watch: {
    parrafos(value) {
      this.parrafosLocal = value;
    }
  },

  methods: {
    textoValido(texto) {
      return texto ? true : false;
    },
    async cambiarOrden(event) {
      this.loading = true;
      try {
        const data = { prevIndex: event.moved.oldIndex, nextIndex: event.moved.newIndex };
        const serverResponse = await modificarOrdenSortparagraphsParrafoService(
          this.idInteractivo,
          data
        );
        if (!serverResponse.ok) 
          this.$systemErrorMessage(serverResponse.mensaje);
        else {
          const emitData = {
            log: serverResponse.nuevoLog,
          };
          this.$emit("parrafoMovido", emitData);
        }
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
    async agregarParrafo() {
      try {
        this.loading = true;
        const serverResponse = await agregarSortparagraphsParrafoService(
          this.idInteractivo
        );
        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else {
          const emitData = {
            log: serverResponse.nuevoLog,
          };
          this.$emit("parrafoAgregado", emitData);
        }
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },

    setModificandoParrafo(value){
      this.modificandoParrafo = value;
    },
    parrafoModificado(payload){
      this.$emit('parrafoModificado', payload);
    },
    parrafoEliminado(payload){
      this.$emit('parrafoEliminado', payload);
    }
  }
}
</script>

<style lang="scss" scoped>
.link {
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.ghost {
  opacity: 0.5;
  background: #e0e0e0;
}
</style>