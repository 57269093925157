import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VList,{attrs:{"dense":""}},_vm._l((_vm.answers),function(item,index){return _c('div',{key:item._id},[_c(VListItem,{attrs:{"two-lines":""}},[_c(VListItemIcon,[(item.correct)?_c(VIcon,{attrs:{"color":"green darken-1"}},[_vm._v("mdi-check-circle ")]):_c(VIcon,{attrs:{"color":"red darken-1"}},[_vm._v("mdi-close-circle")])],1),_c(VListItemContent,[_c(VListItemSubtitle,[_vm._v("Respuesta:")]),_c(VListItemTitle,[_vm._v(_vm._s(item.text))])],1),(_vm.permisoEscritura)?_c(VListItemAction,[_c(VMenu,{attrs:{"offset-y":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":"","small":""}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c(VList,{attrs:{"dense":""}},[(!item.correct)?_c(VListItem,{on:{"click":function($event){return _vm.$emit('modificarRespuestaCorrecta', {
                  idRespuesta: item._id,
                  value: true,
                })}}},[_c(VListItemTitle,[_vm._v("Establecer correcta")]),_c(VListItemIcon,[_c(VIcon,{attrs:{"color":"green darken-1","small":""}},[_vm._v("mdi-check-circle")])],1)],1):_c(VListItem,{on:{"click":function($event){return _vm.$emit('modificarRespuestaCorrecta', {
                  idRespuesta: item._id,
                  value: false,
                })}}},[_c(VListItemTitle,[_vm._v("Establecer incorrecta")]),_c(VListItemIcon,[_c(VIcon,{attrs:{"color":"red darken-1","small":""}},[_vm._v("mdi-close-circle")])],1)],1),_c(VListItem,{on:{"click":function($event){return _vm.$emit('modificarRespuesta', item)}}},[_c(VListItemTitle,[_vm._v("Modificar respuesta")]),_c(VListItemIcon,[_c(VIcon,{attrs:{"color":"primary","small":""}},[_vm._v("mdi-pencil")])],1)],1),_c(VListItem,{on:{"click":function($event){return _vm.$emit('eliminarRespuesta', item)}}},[_c(VListItemTitle,[_vm._v("Eliminar respuesta")]),_c(VListItemIcon,[_c(VIcon,{attrs:{"small":"","color":"red darken-1"}},[_vm._v("mdi-trash-can")])],1)],1)],1)],1)],1):_vm._e()],1),(item.tipsAndFeedback.tip)?_c(VListItem,{attrs:{"two-lines":""}},[_c(VListItemIcon,[_c(VIcon,{attrs:{"color":"gray"}},[_vm._v("mdi-lightbulb-outline ")])],1),_c(VListItemContent,[_c(VListItemSubtitle,[_vm._v("Pista:")]),_c(VListItemTitle,[_vm._v(_vm._s(item.tipsAndFeedback.tip))])],1)],1):_vm._e(),(item.tipsAndFeedback.chosenFeedback)?_c(VListItem,{attrs:{"two-lines":""}},[_c(VListItemIcon,[_c(VIcon,{attrs:{"color":"gray"}},[_vm._v("mdi-information-box-outline ")])],1),_c(VListItemContent,[_c(VListItemSubtitle,[_vm._v("Retroalimentación seleccionado:")]),_c(VListItemTitle,[_vm._v(_vm._s(item.tipsAndFeedback.chosenFeedback))])],1)],1):_vm._e(),(item.tipsAndFeedback.notChosenFeedback)?_c(VListItem,{attrs:{"two-lines":""}},[_c(VListItemIcon,[_c(VIcon,{attrs:{"color":"gray"}},[_vm._v("mdi-information-box-outline ")])],1),_c(VListItemContent,[_c(VListItemSubtitle,[_vm._v("Retroalimentación no seleccionado:")]),_c(VListItemTitle,[_vm._v(_vm._s(item.tipsAndFeedback.notChosenFeedback))])],1)],1):_vm._e(),(index < _vm.answers.length - 1)?_c(VDivider,{staticClass:"mx-3"}):_vm._e()],1)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }