import {
  getServerRequest,
  putServerRequest,
  patchServerRequest,
} from "../../services/serverRequest.service";
import { store } from "@/store/store";
const { httpURL } = store.getters;

export const obtenerComentariosActividad = async ({ idActividad, idCurso }) => {
  const token = store.getters.sessionToken;
  const config = { headers: { token } };
  const url = `${httpURL}/curso/campus/actividad/comentarios/${idCurso}/${idActividad}`;
  return await getServerRequest(url, config);
};

export const obtenerComentariosClase = async ({ idClase, idCurso }) => {
  const token = store.getters.sessionToken;
  const config = { headers: { token } };
  const url = `${httpURL}/curso/campus/clase/comentarios/${idCurso}/${idClase}`;
  return await getServerRequest(url, config);
};

export const agregarComentario = async ({ idActividad, idClase, idCursoCampus, data }) => {
  const token = store.getters.sessionToken;
  const config = { headers: { token } };
  const tipoComentario = idActividad ? "actividad" : "clase";
  const url = `${httpURL}/curso/campus/${tipoComentario}/comentario/${idCursoCampus}/${ idActividad || idClase }`;
  return await putServerRequest(url, data, config);
};

export const agregarOpinionAComentario = async ({ idCurso, idActividad, idClase, idComentario, tipoOpinion, data }) => {
  const token = store.getters.sessionToken;
  const config = { headers: { token } };
  const url = `${httpURL}/curso/campus/${idCurso}/${ idActividad ? "actividad" : "clase" }/${ idActividad || idClase }/comentario/${idComentario}/opinion/${tipoOpinion}`;
  return await putServerRequest(url, data, config);
};

export const editarComentario = async ({ idCurso, idActividad, idClase, idComentario, comentario }) => {
  const token = store.getters.sessionToken;
  const config = { headers: { token } };
  const tipoComentario = idActividad ? "actividad" : "clase";
  const url = `${httpURL}/curso/campus/${idCurso}/${tipoComentario}/${ idActividad || idClase }/comentario/${idComentario}/editar`;
  return await patchServerRequest(url, comentario, config);
};
