import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
import { VTabsSlider } from 'vuetify/lib/components/VTabs';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,[_c(VTabs,{attrs:{"centered":"","icons-and-text":"","background-color":"secondary","dark":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c(VTabsSlider,{attrs:{"color":"warning"}}),_c(VTab,{attrs:{"href":"#cursos","to":"#cursos"}},[_vm._v(" Cursos "),_c(VIcon,[_vm._v("mdi-book-open-outline")])],1),_c(VTab,{attrs:{"href":"#compartidos","to":"#compartidos"}},[_vm._v(" Compartidos "),_c(VIcon,[_vm._v("mdi-account-switch")])],1),_c(VTab,{attrs:{"href":"#compartidos","to":"#archivo"}},[_vm._v(" Archivo "),_c(VIcon,[_vm._v("mdi-archive")])],1)],1),_c(VTabsItems,{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c(VTabItem,{attrs:{"value":"cursos"}},[_c(VContainer,[_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c('listadoCursosCampusComponent')],1)],1)],1)],1),_c(VTabItem,{attrs:{"value":"compartidos"}},[_c(VContainer,[_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c('listadoCursosCompartidosComponent')],1)],1)],1)],1),_c(VTabItem,{attrs:{"value":"archivo"}},[_c('listadoCursosArchivadosComponent')],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }