import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"width":"500px","persistent":""},model:{value:(_vm.mostrar),callback:function ($$v) {_vm.mostrar=$$v},expression:"mostrar"}},[_c(VCard,[_c(VToolbar,{staticClass:"text-h6",attrs:{"dense":"","color":"primary","dark":"","flat":""}},[_vm._v(_vm._s(_vm.titulo))]),_c(VContainer,[_c(VList,[_c(VListItem,[_c(VListItemAction,[_c(VCheckbox,{attrs:{"value":"profesor"},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v("Profesor")]),_c(VListItemSubtitle,[_vm._v("El usuario podrá crear cursos, aplicar exámenes, etc.")])],1)],1),_c(VListItem,{attrs:{"three-line":""}},[_c(VListItemAction,[_c(VCheckbox,{attrs:{"value":"alumno"},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v("Alumno")]),_c(VListItemSubtitle,[_vm._v("El usuario podrá registrarse a cursos, constestar exámenes, etc.")])],1)],1),_c(VListItem,{attrs:{"three-line":""}},[_c(VListItemAction,[_c(VCheckbox,{attrs:{"value":"padre"},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v("Control parental")]),_c(VListItemSubtitle,[_vm._v("El usuario podrá visualizar el rendimiento de otros usuarios con previo consentimiento.")])],1)],1)],1)],1),_c(VCardActions,[_c('div',{staticClass:"flex-grow-1"}),_c(VBtn,{attrs:{"color":"primary","outlined":"","small":"","loading":_vm.loading},on:{"click":function($event){return _vm.$emit('cancelar')}}},[_vm._v("Cancelar")]),_c(VBtn,{attrs:{"color":"primary","small":"","loading":_vm.loading,"disabled":_vm.selected.length == 0},on:{"click":function($event){return _vm.actualizarSesion()}}},[_vm._v("Guardar ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }