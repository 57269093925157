import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"width":"450","persistent":""},model:{value:(_vm.mostrar),callback:function ($$v) {_vm.mostrar=$$v},expression:"mostrar"}},[_c(VCard,[_c(VToolbar,{attrs:{"color":"primary","dense":"","flat":"","dark":""}},[_c(VToolbarTitle,[_vm._v("Remover encuesta")])],1),_c(VCardText,[_c('div',{staticClass:"py-2"},[_c('span',[_vm._v("Esta acción solo quitará la encuesta de la lista de compartidos.")])]),_c('div',{staticClass:"d-flex"},[_c(VSpacer),_c(VBtn,{staticClass:"mr-1",attrs:{"color":"secondary","outlined":"","small":"","disabled":_vm.loading},on:{"click":function($event){return _vm.$emit('cancelar')}}},[_vm._v("Cancelar")]),_c(VBtn,{attrs:{"small":"","color":"error","disabled":_vm.loading,"loading":_vm.loading},on:{"click":function($event){return _vm.quitarEncuesta()}}},[_c(VIcon,{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("mdi-delete")]),_c('span',[_vm._v("Eliminar")])],1)],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }