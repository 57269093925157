import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSubheader } from 'vuetify/lib/components/VSubheader';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{attrs:{"outlined":""}},[_c(VSubheader,{staticClass:"font-weight-bold"},[_vm._v("Recursos "),_c(VSpacer),(!_vm.loading && _vm.permisoEscritura && !_vm.vistaSeguimiento)?_c(VBtn,{attrs:{"x-small":"","icon":"","color":"primary","disabled":_vm.loading},on:{"click":function($event){_vm.agregarRecurso = true}}},[_c(VIcon,[_vm._v("mdi-text-box-plus-outline")])],1):_vm._e()],1),_c(VDivider,{staticClass:"mx-3"}),(_vm.loading)?_c(VCard,{staticClass:"my-2",attrs:{"flat":""}},[_c(VSkeletonLoader,{staticStyle:{"margin":"20px"},attrs:{"type":"list-item-avatar-two-line@3"}})],1):_c(VCard,{attrs:{"flat":""}},[_c(VList,[_c('draggable',_vm._b({attrs:{"element":"div","ghost-class":"ghost","handle":".handle"},on:{"change":_vm.cambiarOrden},model:{value:(_vm.recursos),callback:function ($$v) {_vm.recursos=$$v},expression:"recursos"}},'draggable',_vm.dragOptions,false),_vm._l((_vm.recursos),function(recurso,index){return _c('recursoItemComponent',{key:`recurso-${index}`,attrs:{"recurso":recurso,"tipoCurso":_vm.tipoCurso,"tipoModulo":_vm.tipoModulo,"idCurso":_vm.idCurso,"permisoEscritura":_vm.permisoEscritura},on:{"recursoModificado":_vm.recursoModificado,"recursoVisibleModificado":_vm.recursoVisibleModificado,"eliminarRecurso":_vm.eliminarRecurso}})}),1),(_vm.recursos.length == 0)?_c(VListItem,[_c(VListItemSubtitle,[_vm._v("No hay recursos capturados")])],1):_vm._e()],1)],1),(_vm.agregarRecurso)?_c('agregarRecursoComponent',{attrs:{"mostrar":_vm.agregarRecurso,"tipoCurso":_vm.tipoCurso,"idCurso":_vm.idCurso,"idModulo":_vm.idModulo,"tipo":_vm.tipo},on:{"cancelar":function($event){_vm.agregarRecurso = false},"recursoAgregado":_vm.recursoAgregado}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }