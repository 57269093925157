<template>
  <v-card outlined>
    <v-card-title
      >Texto
      <v-spacer />
      <v-btn
        v-if="permisoEscritura"
        icon
        color="primary"
        @click="mostrarTextArea()"
        ><v-icon>mdi-pencil-box-outline</v-icon></v-btn
      >
      <v-btn
        v-if="textoValido"
        color="primary"
        small
        icon
        @click="$emit('preview')"
      >
        <v-icon>mdi-motion-play-outline</v-icon>
      </v-btn>
    </v-card-title>
    <v-divider class="mx-3"></v-divider>
    <v-container>
      <v-card-text v-if="!mostrarModificarTexto" v-html="textoLocal">
      </v-card-text>
      <v-textarea
        v-else
        v-model="textoModificar"
        :loading="loading"
        :disabled="loading"
        outlined
        hint="Las palabras clave deben estar entre asteriscos. Ejemplo: Esta es una palabra *clave*"
        :error-messages="error"
      />
      <v-card-actions v-if="mostrarModificarTexto">
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          small
          outlined
          :disabled="loading"
          @click="mostrarModificarTexto = false"
          >Cancelar</v-btn
        >
        <v-btn
          color="primary"
          small
          :loading="loading"
          @click="modificarTexto()"
          >Modificar</v-btn
        >
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import { modificarDTWTextoService } from "./dragthewords.service.js";

export default {
  components: {},
  props: {
    idInteractivo: { type: String, default: null },
    tipoInteractivo: { type: String, default: null },
    permisoEscritura: { type: Boolean, default: false },
    texto: { type: String, default: null },
  },
  computed: {
    ...mapGetters(["sessionToken", "app", "role", "menu"]),
    textoValido() {
      const regex = /\*\w+\*/;
      return regex.test(this.textoLocal);
    },
  },
  watch: {
    texto(value) {
      if (value) this.textoLocal = value.replace(/\n/g, "<br>");
      else this.textoLocal = value;
    },
  },

  data() {
    return {
      loading: false,
      textoLocal: "",
      textoModificar: "",
      mostrarModificarTexto: false,
      error: "",
    };
  },
  methods: {
    mostrarTextArea() {
      this.textoModificar = this.texto;
      this.mostrarModificarTexto = true;
    },
    async modificarTexto() {
      this.error = "";
      const regex = /\*\w+\*/;
      const textoValido = regex.test(this.textoModificar);
      if (!textoValido)
        this.error = "El texto no contiene ninguna palabra clave";
      else {
        try {
          this.loading = true;
          const serverResponse = await modificarDTWTextoService(
            this.idInteractivo,
            this.textoModificar
          );
          if (!serverResponse.ok)
            this.$systemErrorMessage(serverResponse.mensaje);
          else {
            const emitData = {
              texto: this.textoModificar,
              log: serverResponse.nuevoLog,
            };
            this.$emit("textoModificado", emitData);
            this.mostrarModificarTexto = false;
          }
          this.loading = false;
        } catch (error) {
          this.loading = false;
          this.$appErrorMessage();
        }
      }
    },
  },
};
</script>
