import {
    getServerRequest,
    postServerRequest,
    patchServerRequest,
    deleteServerRequest
  } from "@/components/globals/services/serverRequest.service";
  import { store } from "../../../../store/store";
  
  const httpURL = store.getters.httpURL;
  
  export const getAreasService= async (data) => {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/diplomas/areas/listado`;
    return await postServerRequest(url, {data},config);
  };
  
  export const crearAreaService = async (data) => {
    const token = store.getters.sessionToken;
    const config = { headers: { token }};
    const url = `${httpURL}/diplomas/area`;
    return await postServerRequest(url, data, config);
  }
  
  export const modificarAreaService = async (idArea, data) => {
    const token = store.getters.sessionToken;
    const config = { headers: { token }};
    const url = `${httpURL}/diplomas/area/${idArea}`;
    return await patchServerRequest(url, data, config);
  }

  export const getDiplomasService= async (idArea, items, page) => {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/diplomas/${idArea}`;
    return await postServerRequest(url, {items, page},config);
  };

  export const getDiplomasCursoService = async (data) => {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/diplomas/curso/info`;
    return await postServerRequest(url, data,config);
  };

  export const getDiplomaFileService = async (idDiploma, data) => {
    const token = store.getters.sessionToken;
    const config = { headers: { token }, responseType: 'blob' };
    const url = `${httpURL}/diploma/file/pdf/${idDiploma}`;
    return await postServerRequest(url, data, config);
  };