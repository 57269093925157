import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,[(_vm.calificaciones.length == 0)?_c(VCardText,[_vm._v("No se ha calificado la actividad")]):_vm._l((_vm.calificacionesLocal),function(calificacion){return _c(VListItem,{key:calificacion._id},[_c(VListItemAvatar,[_c(VAvatar,{attrs:{"color":"indigo","size":"36"}},[_c('span',{staticClass:"white--text text-strong"},[_vm._v(_vm._s(calificacion.calificacion))])])],1),_c(VListItemContent,[_c(VListItemSubtitle,[_vm._v(_vm._s(calificacion.fecha))]),(calificacion.observaciones)?_c('textSubtractComponent',{attrs:{"texto":calificacion.observaciones}}):_vm._e()],1)],1)})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }