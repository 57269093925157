<template>
  <v-card outlined style="margin-top: 5px;">
    <v-card-title style="padding: 3px;">
      <v-subheader>BLOQUE {{ index + 1 }}</v-subheader>
      <v-spacer></v-spacer>
      <v-menu v-if="permisoEscritura" offset-x :disabled="loading" left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="primary"
            small
            dark
            icon
            v-bind="attrs"
            v-on="on"
            :disabled="modificandoBloque"
          >
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <v-list dense>
          <v-list-item one-line @click="mostrarEditor()">
            <v-list-item-title>Modificar</v-list-item-title>
          </v-list-item>
          <v-list-item one-line @click="eliminarBloqueTexto = true">
            <v-list-item-title>Eliminar</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-card-title>
    <v-divider class="mx-3"></v-divider>
    <div v-if="!mostrarModificarTexto">
      <v-card-text v-html="bloque.texto"></v-card-text>
      <v-card-subtitle v-if="!bloque.textoValido" style="color: red;">
        <i>El texto no cuenta con el formato válido.</i>
      </v-card-subtitle>
    </div>
    <v-container v-else>
      <editor-texto
        ref="editorTexto"
        :texto="bloque.texto"
        contenido="fillblanks"
        :idContenido="idInteractivo"
        app="app"
      ></editor-texto>
      <v-card-title>
        <v-spacer></v-spacer>
        <v-btn
          small
          outlined
          color="primary"
          style="margin-right: 10px;"
          :disabled="loading"
          @click="cerrarEditor()"
          >Cancelar</v-btn
        >
        <v-btn
          small
          color="primary"
          :loading="loading"
          @click="modificarTexto()"
          >Guardar</v-btn
        >
      </v-card-title>
    </v-container>
    <eliminarBloqueTextoComponent
      v-if="eliminarBloqueTexto"
      :mostrar="eliminarBloqueTexto"
      :tipoInteractivo="tipoInteractivo"
      :idInteractivo="idInteractivo"
      :index="index"
      @cancelar="eliminarBloqueTexto = false"
      @bloqueTextoEliminado="bloqueTextoEliminado"
    />
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import { modificarFillblanksBloqueService } from "./fillblanks.service.js";
import eliminarBloqueTextoComponent from "./eliminarBloqueTexto.vue";
import editorTexto from "../../contenidos/editorTexto.vue";

export default {
  components: { eliminarBloqueTextoComponent, editorTexto },
  props: {
    idInteractivo: { type: String, default: null },
    tipoInteractivo: { type: String, default: null },
    permisoEscritura: { type: Boolean, default: false },
    bloque: Object,
    index: Number,
    modificandoBloque: Boolean,
  },
  computed: {
    ...mapGetters(["sessionToken", "app", "role", "menu"]),
  },
  data() {
    return {
      loading: false,
      textoModificar: "",
      mostrarModificarTexto: false,
      eliminarBloqueTexto: false,
      error: "",
    };
  },
  methods: {
    mostrarEditor() {
      this.mostrarModificarTexto = true;
      this.$emit("modificandoBloque", true);
    },
    cerrarEditor() {
      this.mostrarModificarTexto = false;
      this.$emit("modificandoBloque", false);
    },
    async modificarTexto() {
      try {
        this.loading = true;
        const serverResponse = await modificarFillblanksBloqueService(
          this.idInteractivo,
          this.$refs.editorTexto.obtenerTexto(),
          this.index
        );
        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else {
          const emitData = {
            bloque: serverResponse.bloque,
            index: this.index,
            log: serverResponse.nuevoLog,
          };
          this.$emit("bloqueModificado", emitData);
          this.cerrarEditor();
        }
        this.loading = false;
      } catch (error) {
        // console.error(error);
        this.loading = false;
        this.$appErrorMessage();
      }
    },
    bloqueTextoEliminado(payload) {
      this.$emit("bloqueTextoEliminado", payload);
    },
  },
};
</script>
