import { VCard } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{attrs:{"outlined":""}},[_c(VContainer,[_c(VRadioGroup,{attrs:{"column":"","disabled":_vm.loading || _vm.totalCorrectas > 1},on:{"change":function($event){return _vm.modificarPropiedad('tipoRespuestas')}},model:{value:(_vm.tipoRespuestasLocal),callback:function ($$v) {_vm.tipoRespuestasLocal=$$v},expression:"tipoRespuestasLocal"}},[_c(VRadio,{attrs:{"label":"Sólo una respuesta correcta","value":"single"}}),_c(VRadio,{attrs:{"label":"Múltiples respuestas correctas","value":"multiple"}})],1),_c(VCheckbox,{attrs:{"disabled":_vm.loading,"label":"Mostrar respuestas aleatorias"},on:{"change":function($event){return _vm.modificarPropiedad('respuestasAleatorias')}},model:{value:(_vm.respuestasAleatoriasLocal),callback:function ($$v) {_vm.respuestasAleatoriasLocal=$$v},expression:"respuestasAleatoriasLocal"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }