import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"width":"500px","persistent":""},model:{value:(_vm.mostrar),callback:function ($$v) {_vm.mostrar=$$v},expression:"mostrar"}},[_c(VCard,{attrs:{"loading":_vm.loading}},[_c(VToolbar,{staticClass:"text-h6",attrs:{"dense":"","color":"primary","dark":"","flat":""}},[_vm._v(" Clonar instrumento ")]),_c(VCardText,{staticClass:"pt-3 pb-0 px-4"},[_c('span',[_vm._v("Selecciona el área donde donde quedará guardada la copia del instrumento:")])]),_c(VContainer,{staticStyle:{"margin-top":"10px"}},[_c(VSelect,{attrs:{"label":"Área","items":_vm.areas,"item-value":"_id","item-text":"nombre","outlined":"","dense":"","disabled":_vm.loading,"no-data-text":"No hay áreas disponibles"},model:{value:(_vm.areaSeleccionada),callback:function ($$v) {_vm.areaSeleccionada=$$v},expression:"areaSeleccionada"}})],1),_c(VSpacer),_c(VCardActions,[_c('div',{staticClass:"flex-grow-1"}),_c(VBtn,{attrs:{"color":"primary","outlined":"","small":"","disabled":_vm.loading},on:{"click":function($event){return _vm.$emit('cancelar')}}},[_vm._v("Cancelar")]),_c(VBtn,{class:{
          'disable-events':
            _vm.loading || _vm.areaSeleccionada == '',
        },attrs:{"color":"primary","dark":"","small":"","loading":_vm.loading},on:{"click":function($event){return _vm.submit()}}},[_vm._v("Guardar")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }