<template>
  <div>
    <selector-areas-general
      v-model="areaSelected"
      :areas="areas"
      :menu="esAdmin && !hideMenu"
      @agregarArea="agregarArea"
      @modificarArea="modificarArea"
    ></selector-areas-general>

    <creacion-area-dialog
      v-if="showAreaDialog"
      :mostrar="showAreaDialog"
      :area="areaAModificar"
      @cancelar="closeAreaDialog"
      @areaGuardada="areaGuardada"
    ></creacion-area-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import selectorAreas from "../../widgets/selectorAreas.vue";
import crearAreaPlaneacionesDialog from "./crearAreaPlaneacionesDialog.vue";
import { getPlaneacionesAreas } from "./planeacionesAreas.service";

export default {
  components: {
    "selector-areas-general": selectorAreas,
    "creacion-area-dialog": crearAreaPlaneacionesDialog,
  },

  props: {
    hideMenu: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      areas: [],
      areaSelected: null,
      areaAModificar: null,
      showAreaDialog: false,
    };
  },

  computed: {
    ...mapGetters(["sessionToken", "app", "role"]),

    esAdmin() {
      return (
        this.app === "campus" || this.role == "admin" || this.role === "master"
      );
    },
  },

  watch: {
    sessionToken(v) {
      !!v && this.getAreas();
    },

    areas(v) {
      this.$emit("change", v);
    },

    areaSelected(v) {
      this.$emit("areaSelected", v);
    },
  },

  mounted() {
    if (this.sessionToken) this.getAreas();
  },

  methods: {
    async getAreas() {
      this.loading = true;

      try {
        const serverResponse = await getPlaneacionesAreas();
        if (!serverResponse.ok) {
          this.$systemErrorMessage(serverResponse.mensaje);
          return;
        }

        this.areas = serverResponse.areas;
        if (this.areas.length) this.areaSelected = this.areas[0]._id;
      } catch (error) {
        this.$appErrorMessage();
      } finally {
        this.loading = false;
      }
    },

    agregarArea() {
      this.showAreaDialog = true;
    },

    modificarArea() {
      if (!this.areaSelected) return;
      this.areaAModificar = this.areas.find((a) => a._id == this.areaSelected);
      this.showAreaDialog = true;
    },

    closeAreaDialog() {
      this.areaAModificar = null;
      this.showAreaDialog = false;
    },

    areaGuardada(area) {
      const indexArea = this.areas.findIndex((a) => a._id == area._id);

      if (indexArea >= 0) this.areas.splice(indexArea, 1, area);
      else {
        this.areas.push(area);
        this.areaSelected = area._id;
      }

      this.closeAreaDialog();
    },
  },
};
</script>
