<template>
  <v-card :loading="loading" elevation="2" :height="!loading && avisosOrdenados.length != 0 ? '380' : '160'">

    <v-alert border="top" colored-border color="secondary" class="pa-0 mb-3" />
    <v-toolbar class="mx-1" elevation="0" outlined dense>
      <v-icon color="primary" class="mr-3" size="30">mdi-laptop</v-icon>
      <v-toolbar-title style="color: #0d3863;">Avisos cursos</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-chip class="elevation-1" color="primary" small><v-icon small class="mr-2">mdi-bell-alert</v-icon>{{ avisosOrdenados.length }}</v-chip>
    </v-toolbar>

    <v-card-subtitle v-if="avisosOrdenados.length === 0 && !loading">No hay avisos disponibles</v-card-subtitle>

    <v-card v-if="loading" outlined class="ma-1">
      <v-skeleton-loader
          type="list-item-avatar-three-line"
        ></v-skeleton-loader>
    </v-card>

    <v-virtual-scroll 
      v-else
      :bench="15"
      :items="avisosOrdenados"
      :item-height="115"
      :height="310"
    >
      <template #default="{ item }">

        <v-card outlined elevation="0" class="ma-1">
          
          <v-list-item class="mx-0 my-3 px-2">
            <v-list-item-avatar v-if="!item.cursoCampus.icon" style="margin: 0px 0px 0px 0px;">
              <v-icon>mdi-book-open-outline</v-icon>
            </v-list-item-avatar>
            
            <v-list-item-avatar v-else style="margin: 0px 5px 0px 0px;" size="30">
              <img :src="`${imageURL}/${item.cursoCampus.icon}`" alt="cursoImage"/>
            </v-list-item-avatar>
            
            <v-list-item-title style="margin-right: 10px;">
              <a class="text-caption link" @click="avisoSelected(item)" style="background-color: #E1F5FE; padding: 0px 5px 0px 5px;"><strong>{{ item.cursoCampus.nombre }}</strong></a>
            </v-list-item-title>
              
            <v-chip color="#90CAF9" style="width: 15%;" small><v-icon small>mdi-bullhorn</v-icon></v-chip>
            <v-btn v-if="item.avisoVisto" x-small style="height: 25px;" color="primary" class="ml-1" @click="ocultarAviso(item)" elevation="0" rounded><v-icon small>mdi-close-circle-outline</v-icon></v-btn>         
          </v-list-item>
          
          

          <v-list-item-title class="textTitle"> {{ item.titulo }} </v-list-item-title>

        </v-card>

      </template>
  </v-virtual-scroll>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import { getCursosAvisos, ocultarAvisoVisto } from "../avisos.service.js";

export default {
  name: 'cursosAvisos',
  computed: {
    ...mapGetters(["imageURL", "sessionToken"]),
    avisosOrdenados() {
      let avisos = [];
      avisos = [...this.avisos].reverse();
      return avisos;
    }
  },
  data () {
    return {
      loading: false,
      avisos: [],
    }
  },
  watch: {
    sessionToken() {
      this.cargarCursosAvisos();
    }
  },
  mounted() {
    if(this.sessionToken) this.cargarCursosAvisos();
  },
  methods: {
    async cargarCursosAvisos() {
      try {
        this.loading = true;
        const serverResponse = await getCursosAvisos();
        this.loading = false;
        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else {
          this.avisos = serverResponse.cursosAvisos;
          if(this.avisos.length != 0) this.$emit('showAvisos', true);
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
    avisoSelected(aviso) {
      this.$router.push(`/curso/campus/${aviso.cursoCampus._id}#avisos`);
    },
    async ocultarAviso(aviso) {
      try {
        this.loading = true;
        const idCurso = aviso.cursoCampus._id;
        const idAvisoVisto = aviso.avisoVisto.idAvisoVisto;
        const data = { ocultar: true };
        const serverResponse = await ocultarAvisoVisto(idCurso, idAvisoVisto, data);
        this.loading = false;
        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else {
          if(serverResponse.ocultar) {
            const index = this.avisos.findIndex((a) => a._id == aviso._id);
            if(index >= 0) this.avisos.splice(index, 1);
            this.avisos.length != 0 ? this.$emit('showAvisos', true) : this.$emit('showAvisos', false);
          }
        }
        
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    }
  }
}
</script>

<style scoped>
.textTitle {
  margin: 0px 12px 10px 16px;
  font-size: 15px;
  color: #000000;
}

</style>