<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <instruccionesComponent
          :idInteractivo="interactivo._id"
          :tipoInteractivo="tipoInteractivo"
          :texto="interactivo.descripcion"
          :permisoEscritura="permisoEscritura"
          @textoModificado="descripcionModificada"
        />
      </v-col>

      <v-col cols="12">
        <sortparagraphsParrafosComponent 
          :idInteractivo="interactivo._id"
          :tipoInteractivo="tipoInteractivo"
          :permisoEscritura="permisoEscritura"
          :parrafos="parrafos"
          @parrafoAgregado="parrafoAgregado"
          @parrafoModificado="parrafoModificado"
          @parrafoEliminado="parrafoEliminado"
          @parrafoMovido="parrafoMovido"
          @preview="preview = true"
        />
      </v-col>

      <v-col cols="12">
        <sortparagraphsOpcionesComponent
          :idInteractivo="interactivo._id"
          :tipoInteractivo="tipoInteractivo"
          :permisoEscritura="permisoEscritura"
          :modoCalificacion="interactivo.modoCalificacion"
          :penalizaciones="interactivo.penalizaciones"
          @modoCalificacionModificado="modoCalificacionModificado"
          @penalizacionesModificado="penalizacionesModificado"
        />
      </v-col>


    </v-row>

    <interactivoViewer
      v-if="preview"
      :mostrar="preview"
      :idInteractivo="interactivo._id"
      :tipoInteractivo="tipoInteractivo"
      :nombreInteractivo="interactivo.nombre"
      @cerrar="preview = false"
    />
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import instruccionesComponent from "../instrucciones.vue";
import sortparagraphsParrafosComponent from "./sortparagraphsParrafos.vue";
import sortparagraphsOpcionesComponent from "./sortparagraphsOpciones.vue";
import interactivoViewer from "../interactivoViewer.vue";

export default {
  name: 'SortTheParagraphsView',
  components: {
    instruccionesComponent,
    sortparagraphsParrafosComponent,
    sortparagraphsOpcionesComponent,
    interactivoViewer
  },
  props: {
    interactivo: { type: Object, default: () => {} },
    tipoInteractivo: { type: String, default: null },
    permisoEscritura: { type: Boolean, default: false },
  },
  computed: {
    ...mapGetters(["sessionToken", "app", "role", "menu"]),
  },

  mounted() {
    this.texto = this.interactivo.texto;
    this.parrafos = this.interactivo.parrafos;
  },

  data() {
    return {
      loading: true,
      preview: false,
      texto: "",
      parrafos: [],
    };
  },

  methods: {
    descripcionModificada(payload) {
      this.$emit("descripcionModificada", payload);
    },
    textoModificado(payload) {
      this.texto = payload.texto;
      this.$emit("logAgregado", payload.log);
    },
    parrafoAgregado(payload) {
      this.parrafos.push("");
      this.$emit("logAgregado", payload.log);
    },
    parrafoModificado(payload) {
      this.parrafos.splice(payload.index, 1, payload.parrafo);
      this.$emit("logAgregado", payload.log);
    },
    parrafoEliminado(payload) {
      this.parrafos.splice(payload.index, 1);
      this.$emit("logAgregado", payload.log);
    },
    parrafoMovido(payload) {
      this.$emit("logAgregado", payload.log);
    },
    modoCalificacionModificado(payload) {
      this.interactivo.modoCalificacion = payload.value;
      this.$emit("logAgregado", payload.nuevoLog);
    },
    penalizacionesModificado(payload) {
      this.interactivo.penalizaciones = payload.value;
      this.$emit("logAgregado", payload.nuevoLog);
    }
  }
  
}
</script>