<template>
  <v-card outlined>
    <v-card-title
      >{{ titulo }}
      <v-spacer />
      <v-btn
        v-if="permisoEscritura"
        icon
        color="primary"
        @click="modificar = true"
        ><v-icon>mdi-pencil-box-outline</v-icon></v-btn
      >
    </v-card-title>
    <v-card-text v-if="!modificar">
      <span v-if="!texto"><i>No se ha capturado pregunta</i></span>
      <span v-else>{{ texto }}</span>
    </v-card-text>
    <div v-else>
      <v-card-text>
        <v-textarea
          v-model="textoLocal"
          outlined
          :disabled="loading"
        ></v-textarea>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="primary"
          outlined
          small
          :loading="loading"
          @click="modificar = false"
          >Cancelar</v-btn
        >
        <v-btn
          color="primary"
          dark
          small
          :loading="loading"
          @click="modificarInstrucciones"
          >Modificar</v-btn
        >
      </v-card-actions>
    </div>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import { modificarInteractivoService } from "./interactivos.service";

export default {
  name: "InstruccionesInteractivo",
  components: {},
  props: {
    idInteractivo: { type: String, default: null },
    tipoInteractivo: { type: String, default: null },
    titulo: { type: String, default: "Instrucciones" },
    texto: { type: String, default: null },
    campo: { type: String, default: "descripcion" },
    editable: { type: Boolean, default: false },
    permisoEscritura: { type: Boolean, default: false },
  },
  computed: {
    ...mapGetters(["sessionToken", "app", "role", "menu"]),
  },
  watch: {
    texto(value) {
      this.textoLocal = value;
    },
  },
  mounted() {
    this.textoLocal = this.texto;
  },

  data() {
    return {
      loading: false,
      textoLocal: null,
      modificar: false,
    };
  },
  methods: {
    async modificarInstrucciones() {
      this.loading = true;
      try {
        const serverResponse = await modificarInteractivoService(
          this.tipoInteractivo,
          this.textoLocal,
          this.idInteractivo,
          this.campo
        );
        this.loading = false;
        this.$validateResponse(serverResponse);
        if (!serverResponse.ok) {
          this.$systemErrorMessage(serverResponse.mensaje);
          return;
        } else {
          this.$emit("textoModificado", serverResponse);
          this.modificar = false;
        }
      } catch (error) {
        console.log(error);
        this.loading = false;
        this.$appErrorMessage();
      }
    },
  },
};
</script>
