<template>

  <div>

    <i v-if="!documento" class="d-flex justify-center">Sin archivo</i>

    <v-list-item v-else class="text-center">

      <v-list-item-content style="width: 150px;">
        <v-list-item-title v-if="documento.revision.reenvio" class=" mb-1">
          <v-chip
            label
            color="orange darken-4"
            small
            outlined
          >
          Se ha solicitado reenvío
          </v-chip>
        </v-list-item-title>

        <v-list-item-title>
          <a href="javascript:void(0);" class="link"
            @click="mostrarArchivo()"
            >{{ documento.file.name }}
          </a>
        </v-list-item-title>

        <v-list-item-subtitle>
          {{ documentoFecha }}
        </v-list-item-subtitle>

        <v-list-item-subtitle>
          <v-chip
            class="mr-1"
            x-small
            outlined
            label
          >{{ documentoSize }}
          </v-chip>
          <v-chip
            class="ml-1"
            x-small
            outlined
            label
          >{{ documentoTipo }}
          </v-chip>
        </v-list-item-subtitle>

        <v-list-item-subtitle class="mt-1">
          <v-chip
            v-if="documentoExt"
            x-small
            color="orange darken-1"
            dark
            label
            >Entrega extemporánea</v-chip
          >
        </v-list-item-subtitle>

      </v-list-item-content>

    </v-list-item>

    <viewerComponent 
      v-if="verArchivo.mostrar"
      :mostrar="verArchivo.mostrar"
      :idRecurso="verArchivo.idRecurso"
      :nombreArchivo="verArchivo.filename"
      :url="verArchivo.url"
      :tipoArchivo="verArchivo.tipo"
      :permisos="verArchivo.permisos"
      @cerrar="cancelarVerArchivo"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { DateTime } from "luxon";
import numeral from "numeral";
import { getTypeFormatByName } from '../../../../helpers/utilerias';

import viewerComponent from "../../../widgets/fileViewer.vue";

export default {
  name: 'documentoItem',
  props: {
    idCurso: { type: String, default: null },
    idActividad: { type: String, default: null },
    disponible: { type: Object, default: () => {} },
    documento: { type: Object, default: () => null },
  },

  components: {
    viewerComponent,
  },

  computed: {
    ...mapGetters(["httpURL", "documentsURL"]),
    documentoSize() {
      return numeral(this.documento.file.size).format("0.0b");
    },
    documentoFecha() {
      return DateTime.fromISO(this.documento.fecha).toLocaleString(DateTime.DATE_FULL);
    },
    documentoTipo() {
      return getTypeFormatByName(this.documento.file.name);
    },
    documentoExt() {

      let fechaFin = !this.disponible.abierta
        ? DateTime.fromISO(this.disponible.fin)
        : null;

      let fechaDocumento = DateTime.fromISO(this.documento.fecha);
      let entregaExt = false;

      if (fechaFin) {
        entregaExt = fechaDocumento > fechaFin;
      } 

      return entregaExt;

    }
  },

  data () {
    return {
      verArchivo:{
        mostrar:false,
        idRecurso: null,
        filename: null,
        url: null,
        tipo: null,
        permisos: {
          descarga: true
        },
			}
    }
  },

  methods: {
    cancelarVerArchivo() {
      this.verArchivo.mostrar = false;
      this.verArchivo.idRecurso = null;
      this.verArchivo.filename = null;
      this.verArchivo.url = null;
      this.verArchivo.tipo = null;
    },

    async mostrarArchivo () {
      const url = `${this.documentsURL}/${this.documento.file.path}`;
      this.verArchivo.mostrar = true;
      this.verArchivo.idRecurso = this.documento._id;
      this.verArchivo.filename = this.documento.file.name;
      this.verArchivo.url = url;
      this.verArchivo.tipo = this.documento.file.type;
    },
  }
}
</script>

<style scoped>
.document-size {
  margin-left: 5px;
  font-size: 12px;
  font-style: italic;
  color: gray;
}
</style>