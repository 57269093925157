import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"persistent":"","width":"500"},model:{value:(_vm.mostrar),callback:function ($$v) {_vm.mostrar=$$v},expression:"mostrar"}},[_c(VCard,[_c(VToolbar,{attrs:{"color":"primary","dense":"","flat":"","dark":""}},[_c('strong',[_vm._v("Visto por")]),_c(VSpacer),_c(VIcon,{on:{"click":function($event){return _vm.$emit('cancelar')}}},[_vm._v("mdi-close")])],1),_c(VCardSubtitle,{staticClass:"mt-5 pl-4"},[_vm._v("Alumnos que han visto: "+_vm._s(_vm.vistos.length))]),_vm._l((_vm.vistosLocal),function(visto,index){return _c(VList,{key:index},[_c(VListItem,[_c(VListItemAvatar,{staticClass:"avatar-list"},[_c(VAvatar,{attrs:{"color":"primary","size":"30"}},[(!visto.usuario.avatar)?_c(VIcon,{attrs:{"color":"white"}},[_vm._v("mdi-account")]):_c('img',{attrs:{"src":`${_vm.imageURL}/avatar/${visto.usuario.avatar}`,"alt":"UserImage"}})],1)],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(visto.usuario.nombreCompleto))]),_c(VListItemSubtitle,[_vm._v("Visto: "+_vm._s(_vm.moment(visto.fecha).format('DD [de] MMMM [de] YYYY [a las] HH:mm')))])],1)],1),(index !== _vm.vistosLocal.length-1)?_c(VDivider):_vm._e()],1)})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }