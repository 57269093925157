<template>
  <div>
    <v-card-title>
      <v-btn
        v-if="tipoVista != 'general'"
        icon
        :disabled="actividadPrevia ? false : true"
        @click="cambioActividad(actividadPrevia)"
        color="primary"
      >
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <div v-if="transversal">{{ transversal.nombre }}</div>
      <div v-else>{{ actividad.nombre }}</div>
      <v-spacer></v-spacer>
      <v-btn
        v-if="tipoVista != 'general'"
        icon
        :disabled="actividadSiguiente ? false : true"
        @click="cambioActividad(actividadSiguiente)"
        color="primary"
      >
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
    </v-card-title>
    <v-divider class="mx-3"></v-divider>
    <v-container>
      <v-toolbar dense flat>
        <span
          v-if="
            (!actividad.descripcion && !edicionHabilitada && !transversal) ||
              (transversal && !transversal.descripcion)
          "
        >
          <i>No se ha capturado descripción</i>
        </span>
        <v-spacer></v-spacer>
        <v-tooltip
          v-if="permisoEscritura && !vistaSeguimiento && !transversal"
          top
        >
          <template v-slot:activator="{ on }">
            <button style="float: right" v-on="on" @click="habilitarEdicion()">
              <v-icon medium style="margin-right: 5px"
                >mdi-square-edit-outline</v-icon
              >
            </button>
          </template>
          <span>Modificar descripción</span>
        </v-tooltip>
      </v-toolbar>

      <secuencia-didactica
        v-if="planeacionElemento"
        :secuencia="planeacionElemento"
        class="my-3"
      ></secuencia-didactica>

      <div v-if="edicionHabilitada">
        <editor-texto
          ref="editorTexto"
          :texto="actividad.descripcion"
          app="app"
          :contenido="tipoCurso == 'campus' ? 'cursoCampus' : 'cursoPlantilla'"
          :idContenido="idCurso"
        ></editor-texto>
        <div style="text-align: right">
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            class="margen"
            outlined
            small
            :disabled="loading"
            @click="edicionHabilitada = false"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="primary"
            class="margen"
            dark
            small
            :loading="loading"
            @click="editarDescripcionActividad()"
          >
            Guardar
          </v-btn>
        </div>
      </div>
      <div v-else-if="!transversal" v-html="actividad.descripcion"></div>
      <div v-else-if="transversal" v-html="transversal.descripcion"></div>
      <v-alert
        v-if="!actividad.descripcion && actividad.tipo === 3"
        type="warning"
        dense
      >
        <span
          >Los alumnos no podrán agregar comentarios mientras el foro no tenga
          descripción</span
        >
      </v-alert>
    </v-container>
    <v-divider class="mx-3"></v-divider>
    <v-container>
      <!-- TODO poner componente de vincular recursos cuando se haya creado el repo -->
      <!-- <v-row>
        <v-col cols="12" md="12" sm="12">
          <recursosActividadComponent />
        </v-col>
      </v-row> -->
      <v-row>
        <v-col cols="12" md="12" sm="12">
          <v-card outlined>
            <propiedadesActividadComponent
              :tipoCurso="tipoCurso"
              :idCurso="idCurso"
              :idActividad="actividad._id"
              :tipo="actividad.tipo"
              :instrumento="actividad.instrumento"
              :tipoInstrumento="actividad.tipoInstrumento"
              :criterioEvaluacion="actividad.criterio"
              :entrega="actividad.entrega"
              :equipos="equipos"
              :ponderacion="actividad.ponderacion"
              :puntos="actividad.puntos"
              :interactivo="actividad.interactivo"
              :tipoInteractivo="actividad.tipoInteractivo"
              :formatoArchivo="actividad.formatoArchivo"
              :intentos="actividad.intentos"
              :tipoCalificacion="actividad.tipoCalificacion"
              :permisoEscritura="permisoEscritura"
              :transversal="transversal"
              @criterioModificado="criterioModificado"
              @ponderacionModificada="ponderacionModificada"
              @tipoModificado="tipoModificado"
              @entregaModificada="entregaModificada"
              @intentosModificado="intentosModificado"
              @tipoCalificacionModificada="tipoCalificacionModificada"
              @instrumentoModificado="instrumentoModificado"
              @formatoArchivoModificado="formatoArchivoModificado"
            />

            <template v-if="tipoCurso == 'campus'">
              <v-divider class="mx-3"></v-divider>
              <configuracionActividadComponent
                :idCurso="idCurso"
                :idActividad="actividad._id"
                :tipo="actividad.tipo"
                :entrega="actividad.entrega"
                :entregaExt="actividad.entregaExt"
                :formarEquipos="actividad.formarEquipos"
                :disponible="actividad.disponible"
                :permisoEscritura="permisoEscritura"
                @cambioExtemporaneo="cambioExtemporaneo"
                @cambioFormarEquipos="cambioFormarEquipos"
                @cambioFechaEntrega="cambioFechaEntrega"
              />
            </template>
            <template
              v-if="sesionCursoAlumno && !vistaSeguimiento && !permisoEscritura"
            >
              <v-divider class="mx-3"></v-divider>
              <calificacionAlumnoComponent
                :idCurso="idCurso"
                :actividad="actividad"
                :permisoEscritura="permisoEscritura"
                :cursoArchivado="cursoArchivado"
                :transversal="transversal"
              />
            </template>
          </v-card>
        </v-col>
        <v-col
          v-if="(tipoCurso == 'campus' || vistaSeguimiento) && !sesionCursoAlumno && actividad.tipo != 3"
          cols="12"
          md="12"
          sm="12"
        >
          <listadoEntregasComponent
            :actualizarTipo="actualizarTipo"
            :actualizarEntregas="actualizarEntregas"
            :actualizarActividad="actualizarTipoInstrumento"
            :transversal="transversal"
            @equipoAgregado="equipoAgregado"
            @equipoActividadEliminado="equipoActividadEliminado"
          />
        </v-col>

        <v-col
          v-if="
            actividad.tipo === 3 &&
              (tipoCurso == 'campus' || vistaSeguimiento) &&
              !sesionCursoAlumno
          "
          cols="12"
        >
          <comentarios-foro
            :actualizarTipo="actualizarTipo"
            :actualizarEntregas="actualizarEntregas"
            :actualizarActividad="actualizarTipoInstrumento"
            :idCurso="actividad.cursoCampus"
            :idActividad="actividad._id"
            :descripcion="actividad.descripcion"
            :entrega="actividad.entrega"
            :idModulo="actividad.cursoModulo"
            :entregaActividad="{
              ...actividad.disponible,
              entregaExt: actividad.entregaExt,
            }"
            :permisoEscritura="permisoEscritura"
            :cursoArchivado="cursoArchivado"  
          />
        </v-col>

      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import recursosActividadComponent from "./recursosActividad.vue";
import propiedadesActividadComponent from "./propiedades/propiedadesActividad.vue";
import configuracionActividadComponent from "./configuracion/configuracionActividad.vue";
import calificacionAlumnoComponent from "./calificacionesAlumno/calificacionAlumno.vue";
import listadoEntregasComponent from "./listadoEntregas/listadoEntregas.vue";
import editorTexto from "../../contenidos/editorTexto.vue";
import { modificarDescripcionActividadService } from "./actividad.service";

export default {
  name: "contenidoActividad",
  props: {
    tipoCurso: String,
    idCurso: String,
    actividad: Object,
    equipos: Array,
    tipoModulo: String,
    tipoVista: String,
    actividadSiguiente: String,
    actividadPrevia: String,
    permisoEscritura: { type: Boolean, default: false },
    cursoArchivado: { type: Boolean, default: false },
    transversal: { type: Object, default: null },
    planeacionElemento: { type: Object, default: null },
  },
  components: {
    propiedadesActividadComponent,
    configuracionActividadComponent,
    calificacionAlumnoComponent,
    listadoEntregasComponent,
    "editor-texto": editorTexto,
    "comentarios-foro": () => import("../../comentarios/Comentarios.vue"),
    "secuencia-didactica": () => import("./secuenciaDidacticaActividad.vue"),
  },
  computed: {
    ...mapGetters(["sesionCursoAlumno", "vistaSeguimiento"]),
  },
  data() {
    return {
      actualizarEntregas: 0,
      actualizarTipoInstrumento: "",
      actualizarTipo: 0,
      loading: false,
      edicionHabilitada: false,
    };
  },
  watch: {
    "actividad.tipoInstrumento"(value) {
      this.actualizarTipoInstrumento = value;
    },
    "actividad.tipo"(value) {
      this.actualizarTipo = value;
    },
  },
  methods: {
    cambioActividad(idActividad) {
      this.$router.push(
        `/curso/${this.tipoCurso}/${this.idCurso}/${this.tipoModulo}/vista/${this.tipoVista}/actividad/${idActividad}`
      );
    },
    cambioExtemporaneo(value) {
      this.$emit("cambioExtemporaneo", value);
    },
    cambioFormarEquipos(value) {
      this.$emit("cambioFormarEquipos", value);
    },
    criterioModificado(data) {
      this.$emit("criterioModificado", data);
    },
    ponderacionModificada(data) {
      this.$emit("ponderacionModificada", data);
    },
    tipoModificado(data) {
      this.$emit("tipoModificado", data);
    },
    entregaModificada(data) {
      this.actualizarEntregas++;
      this.$emit("entregaModificada", data);
    },
    instrumentoModificado(data) {
      this.$emit("instrumentoModificado", data);
    },
    formatoArchivoModificado(data) {
      this.$emit("formatoArchivoModificado", data);
    },
    cambioFechaEntrega(data) {
      this.$emit("cambioFechaEntrega", data);
    },
    equipoAgregado(equipo) {
      this.$emit("equipoAgregado", equipo);
    },
    equipoActividadEliminado(idEquipo) {
      this.$emit("equipoActividadEliminado", idEquipo);
    },
    intentosModificado(intentos) {
      this.$emit("intentosModificado", intentos);
    },
    tipoCalificacionModificada(tipoCalificacion) {
      this.$emit("tipoCalificacionModificada", tipoCalificacion);
    },
    habilitarEdicion() {
      this.edicionHabilitada = true;
    },
    async editarDescripcionActividad() {
      try {
        this.loading = true;
        const data = {
          descripcion: this.$refs.editorTexto.obtenerTexto(),
        };

        const serverResponse = await modificarDescripcionActividadService(
          this.idCurso,
          this.tipoCurso,
          this.actividad._id,
          data
        );
        this.loading = false;

        if (!serverResponse.ok) {
          this.$systemErrorMessage(serverResponse.mensaje);
        } else {
          this.actividad.descripcion = serverResponse.descripcion;
          this.edicionHabilitada = false;
        }
      } catch (error) {
        this.$appErrorMessage();
        this.loading = false;
      }
    },
  },
};
</script>
