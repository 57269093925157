<template>

  <v-card :loading="loading" elevation="2" :height="!loading && actividades.length != 0 ? '380' : '160'">

    <v-alert border="top" colored-border color="secondary" class="pa-0 mb-3" />
    <v-toolbar class="mx-1" elevation="0" outlined dense>
      <v-icon color="primary" class="mr-3" size="30">mdi-format-list-text</v-icon>
      <v-toolbar-title style="color: #0d3863;">Actividades por vencer</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-chip class="elevation-1" color="primary" small><v-icon small class="mr-2">mdi-bell-alert</v-icon>{{ actividades.length }}</v-chip>
    </v-toolbar>

    <v-card-subtitle v-if="actividades.length === 0 && !loading">No hay actividades por vencer</v-card-subtitle>
    
    <v-virtual-scroll 
      v-else
      :bench="15"
      :items="actividades"
      :item-height="120"
      :height="300"
      >
      <template #default="{ item }">

        <v-card outlined elevation="0" class="ma-1" @click="actividadSelected(item)">
          <v-card-title>
            
            <v-icon v-if="!item.icon" class="mr-2">mdi-book-open-outline</v-icon>
            <v-list-item-avatar v-else style="margin: 0px 8px 0px 0px;" size="30">
              <img :src="`${imageURL}/${item.icon}`" alt="cursoImage"/>
            </v-list-item-avatar>

            <span class="text-caption">{{ item.cursoNombre }}</span>
            <v-spacer />
            <v-chip
              small
              color="#90CAF9"
            >
              <v-icon small>
                mdi-clock
              </v-icon>
            </v-chip>
          </v-card-title>

          <v-list-item>
            <v-icon v-if="item.tipo === 1" class="mr-2">mdi-checkbox-outline</v-icon>
            <v-icon v-if="item.tipo === 2" class="mr-2">mdi-file-upload-outline</v-icon>
            <v-icon v-if="item.tipo === 3" class="mr-2">mdi-comment-text-multiple-outline</v-icon>
            <v-icon v-if="item.tipo === 4" class="mr-2">mdi-puzzle-outline</v-icon>
            <v-icon v-if="item.examenAplicacion" class="mr-2">mdi-format-list-checks</v-icon>
            <v-list-item-title>{{ item.nombre }} </v-list-item-title>
          </v-list-item>
          
        </v-card>

      </template>
  </v-virtual-scroll>
  
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';

import { getActividadesPendientesService } from "./avisosDashboard.service.js";

export default {
  name: 'actividadesPendientes',
  computed: {
    ...mapGetters(["sessionToken", "imageURL"]),
  },
  data () {
    return {
      loading: false,
      actividades: []
    }
  },
  watch: {
    sessionToken() {
      this.cargarActividadesPendientes();
    }, 
    loading(value) {
      this.$emit('cargando', value);
    }
  },
  mounted() {
    if(this.sessionToken) this.cargarActividadesPendientes();
  },
  methods: {
    async cargarActividadesPendientes() {
      try {
        this.loading = true;
        const serverResponse = await getActividadesPendientesService();
        this.loading = false;
        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else {
          this.actividades = serverResponse.actividadesPendientes;
          this.actividades.length != 0 ? this.$emit('showActividadesPendientes', true) : this.$emit('showActividadesPendientes', false);
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
    actividadSelected(item) {
      if (item.examenAplicacion) this.$router.push(`/curso/campus/${item.idCurso}/${item.cursoModulo._id}/aplicacion/${item._id}`)
      else this.$router.push(`/curso/campus/${item.idCurso}/modulo/vista/modular/actividad/${item._id}`)
    }
  }
}
</script>

<style>

.textTitle {
  text-align: left;
  font-size: 15px;
  color: #000000;
}

.textFecha {
  font-size: 13px;
  color: #424242;
}
.textAviso {
  margin-top: 10px;
  text-align: justify;
  font-size: 12px;
  color: #616161;
}
</style>