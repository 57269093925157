<template>
  <v-dialog v-model="mostrar" persistent width="450">
    <v-card :loading="loading">
      <v-toolbar dense color="primary" dark flat>
        <v-toolbar-title>{{
          subarea ? "Modificar subárea" : "Crear subárea"
        }}</v-toolbar-title>
      </v-toolbar>
      <v-card-text class="pt-3 pb-0 px-4">
        <v-row dense>
          <v-col cols="12">
            <v-text-field
              v-model="nombre"
              label="Nombre"
              outlined
              dense
              :disabled="loading"
              :error-messages="errors.nombre"
              @input="$v.nombre.$touch()"
              @blur="$v.nombre.$touch()"
              @keyup.enter="submit()"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          small
          color="secondary"
          outlined
          :disabled="loading"
          @click="$emit('cancelar')"
          >Cancelar</v-btn
        >
        <v-btn
          small
          color="primary"
          :disabled="loading || !esValido"
          :loading="loading"
          @click="submit()"
          >Guardar</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { crearSubareaService, modificarSubareaService } from "./interactivos.service";

export default {
  mixins: [validationMixin],

  props: {
    mostrar: { type: Boolean, default: false },
    subarea: { type: Object, default: () => null },
    idArea: {type: String, default: () => null}
  },

  computed: {
    errors() {
      const errors = {
        nombre: [],
      };

      if (!this.$v.nombre.$dirty) return errors;
      !this.$v.nombre.required && errors.nombre.push("Campo requerido.");

      return errors;
    },

    esValido() {
      if (this.errors.nombre.length) return false;
      return true;
    },
  },

  validations: {
    nombre: { required },
  },

  data: () => ({
    loading: false,
    nombre: "",
  }),

  watch: {
    area() {
      this.setAreaData();
    },
  },

  mounted() {
    if (this.subarea) {
      this.setAreaData();
    }
  },

  methods: {
    setAreaData() {
      if (this.subarea) {
        this.nombre = this.subarea.nombre;
      }
    },

    async submit() {
      this.$v.nombre.$touch();
      if (!this.esValido) return;

      this.loading = true;
      try {
        const data = { nombre: this.nombre };
      
        const serverResponse = this.subarea
          ? await modificarSubareaService(this.idArea, this.subarea._id, data)
          : await crearSubareaService(this.idArea, data);
        this.loading = false;
        
        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else this.$emit("subareaGuardada", serverResponse.subarea);
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
  },
};
</script>