import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"max-width":300,"persistent":""},model:{value:(_vm.mostrar),callback:function ($$v) {_vm.mostrar=$$v},expression:"mostrar"}},[_c(VCard,{attrs:{"loading":_vm.loading}},[_c(VToolbar,{staticClass:"text-h6",attrs:{"color":"primary","dark":"","dense":"","flat":""}},[_vm._v(" "+_vm._s(_vm.titulo)+" ")]),_c(VContainer,{attrs:{"grid-list-md":""}},[_c(VSelect,{attrs:{"items":_vm.items,"label":_vm.subtitulo,"disabled":_vm.loading},model:{value:(_vm.dataLocal),callback:function ($$v) {_vm.dataLocal=$$v},expression:"dataLocal"}}),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","outlined":"","small":"","disabled":_vm.loading},on:{"click":function($event){return _vm.cerrarForm()}}},[_vm._v(" Cancelar ")]),_c(VBtn,{attrs:{"color":"primary","dark":"","small":"","loading":_vm.loading},on:{"click":function($event){return _vm.modificarConfig()}}},[_vm._v(" Modificar ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }