import { getServerRequest, putServerRequest, patchServerRequest } from '../../../../services/serverRequest.service';
import { store } from '@/store/store';
const httpURL = store.getters.httpURL;

export async function obtenerActividadesService(tipoCurso, idCurso, idModulo) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/curso/${tipoCurso}/actividades/${idCurso}/${idModulo}`;
    return await getServerRequest(url, config);
}

export async function agregarActividadService(data, tipoCurso, idPlantilla, idModulo) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/curso/${tipoCurso}/actividad/${idPlantilla}/${idModulo}`;
    return await putServerRequest(url, data, config);
}

export async function cambiarOrdenActividadesService(data, tipoCurso, ids) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/curso/${tipoCurso}/actividad/mover/${ids.idCurso}/${ids.idModulo}/${ids.idActividad}`;
    return await patchServerRequest(url, data, config);
}

export async function modificarActividadVisibleService(idCurso, idActividad) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/curso/campus/actividad/visible/${idCurso}/${idActividad}`;
    return await patchServerRequest(url, {}, config);
}

export async function moverActividadService(data, tipoCurso, ids) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/curso/${tipoCurso}/modulo/cambiar/actividad/${ids.idCurso}/${ids.idModulo}/${ids.idActividad}`;
    return await patchServerRequest(url, data, config);
}

export async function obtenerInteractivosService(tipoCurso, idCurso) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/curso/${tipoCurso}/interactivos/${idCurso}/listado`;
    return await getServerRequest(url, config);
}