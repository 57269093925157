import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{attrs:{"slot":"content"},slot:"content"},[_c(VRow,{attrs:{"dense":""}},[_c(VCol,{attrs:{"cols":"12"}},[_c('editor-texto',{ref:"editorTexto",attrs:{"texto":_vm.descripcionActual,"app":"campus","contenido":"listaCotejo","idContenido":_vm.$route.params.idInstrumento}})],1),_c(VCol,{attrs:{"cols":"12"}},[_c(VCardActions,[_c('div',{staticClass:"flex-grow-1"}),_c(VBtn,{attrs:{"color":"primary","outlined":"","small":"","disabled":_vm.loading},on:{"click":function($event){return _vm.$emit('cancelar')}}},[_vm._v("Cancelar")]),_c(VBtn,{attrs:{"color":"primary","dark":"","small":"","loading":_vm.loading},on:{"click":function($event){return _vm.actualizarDescripcion()}}},[_vm._v("Modificar")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }