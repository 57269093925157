import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,[_c(VCard,[_c(VToolbar,{staticClass:"text-h6",attrs:{"color":"secondary","dark":"","flat":"","dense":""}},[_c(VSpacer),_vm._v(" Planeaciones "),_c(VSpacer)],1),_c(VCardText,[_c(VRow,[_c(VCol,{attrs:{"cols":"10","md":"6"}},[_c('selector-areas',{on:{"change":function($event){_vm.areas = $event},"areaSelected":function($event){_vm.areaSelected = $event}}})],1),_c(VCol,{attrs:{"cols":"2","md":"6"}},[_c('div',{staticClass:"d-flex justify-end"},[(_vm.esAdmin)?_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VBtn,_vm._g(_vm._b({staticStyle:{"margin-left":"10px"},attrs:{"color":"primary","fab":"","small":"","disabled":!_vm.areas.length},on:{"click":function($event){_vm.mostrarCreacion = true}}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-plus")])],1)]}}],null,false,2051979091)},[_c('span',[_vm._v("Nueva planeación")])]):_vm._e()],1)])],1),_c(VRow,{staticClass:"mt-5",attrs:{"dense":""}},[_c(VCol,{attrs:{"cols":"12"}},[_c('planeaciones-list',{ref:"planeacionesList",attrs:{"area":_vm.areaSelected}})],1)],1)],1)],1),(_vm.mostrarCreacion)?_c('planeacion-dialog',{attrs:{"show":_vm.mostrarCreacion,"areaSelected":_vm.areaSelected},on:{"close":function($event){_vm.mostrarCreacion = false},"planeacionSaved":_vm.planeacionSaved}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }