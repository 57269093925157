<template>
  <v-dialog v-model="show" persistent width="600">
    <v-card>
      <v-toolbar color="primary" dark flat dense class="text-h6">
        Seleccionar Planeación
      </v-toolbar>

      <v-card-text class="pt-3">
        <v-row>
          <v-col cols="12">
            <selector-areas
              :hideMenu="true"
              @change="areas = $event"
              @areaSelected="areaSelected = $event"
            ></selector-areas>
          </v-col>
        </v-row>
        <v-row dense class="mt-5">
          <v-col cols="12">
            <planeaciones-list
              ref="planeacionesList"
              :area="areaSelected"
              :customItemSelectionEvent="true"
              @planeacionSelected="planeacionSelected"
            ></planeaciones-list>
          </v-col>
          <v-col cols="12" class="d-flex justify-space-between pt-3">
            <div class="d-flex justify-start">
              <v-btn
                color="primary"
                outlined
                small
                class="mr-2"
                :disabled="loading"
                @click="$emit('close')"
              >
                <v-icon small class="mr-2">mdi-chevron-left</v-icon>
                <span>Cancelar</span>
              </v-btn>
              <v-btn
                color="primary"
                small
                :disabled="!form.idPlaneacion"
                :loading="loading"
                @click="guardarPlaneacion()"
              >
                <v-icon small class="mr-2">mdi-content-save</v-icon>
                <span>Guardar</span>
              </v-btn>
            </div>
            <div class="d-flex justify-end">
              <v-btn
                v-if="!!savedPlaneacion"
                color="error darken-2"
                outlined
                small
                :loading="loading"
                @click="showEliminacion = true"
              >
                <v-icon small class="mr-2">mdi-close</v-icon>
                <span>Quitar</span>
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <eliminacion-dialog
      v-if="showEliminacion"
      :show="showEliminacion"
      :idCurso="idCurso"
      :tipoCurso="tipoCurso"
      @close="showEliminacion = false"
      @planeacionRemoved="planeacionRemoved"
    ></eliminacion-dialog>
  </v-dialog>
</template>

<script>
import selectorAreasPlaneaciones from "../../planeaciones/areas/selectorAreasPlaneaciones.vue";
import planeacionesList from "../../planeaciones/planeacionesList.vue";
import { agregarPlaneacionAcademicaService } from "./configuracion.service";

export default {
  components: {
    "selector-areas": selectorAreasPlaneaciones,
    "planeaciones-list": planeacionesList,
    "eliminacion-dialog": () =>
      import("./confirmacionEliminacionPlaneacionCurso.vue"),
  },

  props: {
    show: Boolean,
    tipoCurso: String,
    idCurso: String,
    currentPlaneacion: Object,
  },

  data() {
    return {
      loading: false,
      areas: [],
      areaSelected: null,
      mostrarCreacion: false,
      savedPlaneacion: this.currentPlaneacion,
      form: {
        idPlaneacion: null,
      },
      showEliminacion: false,
    };
  },

  methods: {
    planeacionSelected(idPlaneacion) {
      this.form.idPlaneacion = idPlaneacion;
    },

    async guardarPlaneacion() {
      try {
        this.loading = true;
        const serverResponse = await agregarPlaneacionAcademicaService(
          this.tipoCurso,
          this.idCurso,
          this.form
        );
        this.loading = false;

        if (!serverResponse.ok) {
          this.$systemErrorMessage(serverResponse.mensaje);
        } else {
          this.$emit("planeacionSaved", serverResponse.planeacionCurso);
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },

    planeacionRemoved() {
      this.showEliminacion = false;
      this.$emit("planeacionRemoved");
    },
  },
};
</script>
