import { getServerRequest, deleteServerRequest, putServerRequest, patchServerRequest, postServerRequest } from '../../../services/serverRequest.service';
import { store } from '@/store/store';
const httpURL = store.getters.httpURL;

export async function obtenerModuloService(tipoCurso, tipoModulo, idCurso, idModulo) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/curso/${tipoCurso}/${tipoModulo}/modulo/${idCurso}/${idModulo}`;
    return await getServerRequest(url, config);
}

export async function obtenerModulosService(tipoCurso, tipoModulo, idCurso, idModulo) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/curso/${tipoCurso}/${tipoModulo}/modulos/${idCurso}/${idModulo}`;
    return await getServerRequest(url, config);
}

export async function obtenerModulosProfesorService(tipoCurso,idCurso) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/curso/${tipoCurso}/modulos/profesor/${idCurso}`;
    return await getServerRequest(url, config);
}

export async function agregarModuloService(data, tipoCurso, idCurso) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/curso/${tipoCurso}/modulo/${idCurso}`;
    return await putServerRequest(url, data, config);
}

export async function modificarModuloService(data, tipoCurso, idCurso, idModulo) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/curso/${tipoCurso}/modulo/${idCurso}/${idModulo}`;
    return await patchServerRequest(url, data, config);
}

export async function modificarModuloVisibleService(idCurso, idModulo) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/curso/campus/modulo/visible/${idCurso}/${idModulo}`;
    return await patchServerRequest(url, {}, config);
}

export async function modificarDescripcionModuloService(data, tipoCurso, idCurso, idModulo) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/curso/${tipoCurso}/modulo/descripcion/${idCurso}/${idModulo}`;
    return await patchServerRequest(url, data, config);
}

export async function eliminarModuloService(tipoCurso, idCurso, idModulo, tipoModulo) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/curso/${tipoCurso}/modulo/${tipoModulo}/${idCurso}/${idModulo}`;
    return await deleteServerRequest(url, config);
}

export async function modificarOrdenModuloService(data, tipoCurso, ids) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/curso/${tipoCurso}/modulo/mover/${ids.idCurso}/${ids.idModulo}`;
    return await patchServerRequest(url, data, config);
}

export async function obtenerIdsModulosService(tipoCurso, idCurso) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/curso/${tipoCurso}/modulos/ids/${idCurso}`;
    return await postServerRequest(url, {}, config);
}

export async function obtenerPorcentajeAvanceService(idCurso) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/curso/campus/porcentaje/${idCurso}`;
    return await postServerRequest(url, {}, config);
}