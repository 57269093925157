<template>
  <div>
    <v-card outlined>
      <v-toolbar dense flat>
        <v-spacer></v-spacer>
        <v-toolbar-title class="text-h6">Módulos</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-tooltip left>
          <template #activator="{ on, attrs }">
            <v-btn
              icon
              small
              color="primary"
              v-on="on"
              v-bind="attrs"
              @click="showCreacion = true"
            >
              <v-icon small>mdi-text-box-plus</v-icon>
            </v-btn>
          </template>
          <span>Agregar módulo</span>
        </v-tooltip>
      </v-toolbar>
      <v-divider class="mx-3"></v-divider>

      <v-card-text>
        <v-chip v-if="!modulosLocal.length" label>
          <v-icon small class="mr-2">mdi-information-outline</v-icon>
          <span>No se han agregado módulos.</span>
        </v-chip>
        <div v-else>
          <draggable
            v-model="modulosLocal"
            element="div"
            v-bind="dragOptions"
            ghost-class="ghost"
            handle=".handle"
            @change="cambiarOrden"
          >
            <planeacionModuloItem
              v-for="modulo in modulosLocal"
              :key="modulo._id"
              :modulo="modulo"
              :idPlaneacion="idPlaneacion"
              :customGoToModulo="customGoToModulo"
              @goToModulo="goToModulo"
            ></planeacionModuloItem>
          </draggable>
        </div>
      </v-card-text>
    </v-card>

    <crear-modulo-dialog
      v-if="showCreacion"
      :show="showCreacion"
      :idPlaneacion="idPlaneacion"
      @close="showCreacion = false"
      @moduloSaved="moduloSaved"
    ></crear-modulo-dialog>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import planeacionModuloItem from "./planeacionModuloItem.vue";
import crearPlaneacionModuloDialog from "./crearPlaneacionModuloDialog.vue";

export default {
  props: {
    modulos: {
      type: Array,
      default: () => [],
    },
    idPlaneacion: {
      type: String,
      required: true,
    },
    customGoToModulo: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    planeacionModuloItem,
    "crear-modulo-dialog": crearPlaneacionModuloDialog,
    draggable,
  },

  data() {
    return {
      showCreacion: false,
      modulosLocal: this.modulos,
      dragOptions: { animation: 100 },
    };
  },

  methods: {
    moduloSaved(modulo) {
      this.modulosLocal.push(modulo);
      this.showCreacion = false;
    },

    cambiarOrden(e) {
      const nuevoArregloModulos = this.modulosLocal.map((e) => e._id);
      this.$emit("actualizarPlaneacion", { modulos: nuevoArregloModulos });
    },

    goToModulo(idModulo) {
      this.$emit("goToModulo", idModulo);
    },
  },
};
</script>
