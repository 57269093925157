<template>
  <v-container>
    <v-card flat>
      <v-toolbar dense color="secondary" dark flat class="text-h6">
        <v-spacer></v-spacer> Configuración <v-spacer></v-spacer>
      </v-toolbar>
    </v-card>
    <v-row>
      <v-col v-if="tipoCurso == 'campus'" md="12" sm="12" cols="12">
        <configuracionCursoComponent
          :tipoCurso="tipoCurso"
          :idCurso="idCurso"
          :curso="curso"
          :permisoEscritura="permisoEscritura"
          @nuevoLog="nuevoLog"
        />
      </v-col>
      <v-col v-if="tipoCurso == 'plantilla'" md="6" sm="12" cols="12">
        <avanceAutomaticoComponent
          :tipoCurso="tipoCurso"
          :idCurso="idCurso"
          :avance="curso.avanceAutomatico"
          :permisoEscritura="permisoEscritura"
        />
        <diplomasComponent
          :tipoCurso="tipoCurso"
          :idCurso="idCurso"
          :diplomas="curso.diplomas"
          :permisoEscritura="permisoEscritura"
        />
      </v-col>
      <v-col v-if="tipoCurso == 'plantilla'" cols="12" md="6">
        <detallePlaneacionCurso :curso="curso"></detallePlaneacionCurso>
      </v-col>
      <v-col md="6" sm="12" cols="12">
        <usuariosContenidoLoading v-if="loadingColaboradores" />
        <UsuariosContenido
          v-else
          titulo="Colaboradores"
          tipo="colaboradores"
          :usuarios="colaboradores"
          :permisoEscritura="permisoEscritura"
          @agregarUsuario="abrirBuscador('colaboradores')"
          @quitarUsuario="quitarUsuarioAction"
        />
      </v-col>
      <v-col md="6" sm="12" cols="12">
        <usuariosContenidoLoading v-if="loadingCompartido" />
        <UsuariosContenido
          v-else
          titulo="Compartido"
          tipo="compartido"
          :usuarios="compartido"
          :permisoEscritura="permisoEscritura"
          @agregarUsuario="abrirBuscador('compartido')"
          @quitarUsuario="quitarUsuarioAction"
        />
      </v-col>
      <v-col md="12" sm="12" cols="12">
        <logsComponent :logs="logs" />
      </v-col>
    </v-row>

    <buscarUsuarioComponent
      v-if="agregarUsuario.mostrar"
      :loading="agregarUsuario.loading"
      :mostrar="agregarUsuario.mostrar"
      :excluir="noSearch"
      :ruta="agregarUsuario.ruta"
      :mostrarCorreo="true"
      @loadingStart="agregarUsuario.loading = true"
      @loadingStop="agregarUsuario.loading = false"
      @cerrar="agregarUsuario.mostrar = false"
      @usuarioAction="agregarUsuarioAction"
    />

    <quitarUsuarioContenido
      v-if="quitarUsuario.mostrar"
      :mostrar="quitarUsuario.mostrar"
      :usuario="quitarUsuario.usuario"
      :tipo="quitarUsuario.tipo"
      :ruta="quitarUsuario.ruta"
      @cancelar="quitarUsuario.mostrar = false"
      @usuarioEliminado="usuarioEliminado"
    />
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import configuracionCursoComponent from "./configuracionCurso.vue";
import UsuariosContenido from "../../widgets/usuarios/usuariosContenido.vue";
import buscarUsuarioComponent from "../../widgets/usuarios/buscarUsuario.vue";
import usuariosContenidoLoading from "../../widgets/usuarios/usuariosContenidoLoading.vue";
import quitarUsuarioContenido from "../../widgets/usuarios/quitarUsuarioContenido.vue";
import {
  obtenerUsuariosService,
  agregarUsuarioService,
  obtenerLogService,
} from "./configuracion.service";
import logsComponent from "../../widgets/logs.vue";
import avanceAutomaticoComponent from "./avanceAutomatico.vue";
import diplomasComponent from "./diplomas.vue";
import detallePlaneacionCurso from "./detallePlaneacionCurso.vue";

export default {
  name: "configuracionTab",
  props: {
    tipoCurso: String,
    idCurso: String,
    curso: Object,
    permisoEscritura: { type: Boolean, default: false },
  },
  components: {
    configuracionCursoComponent,
    UsuariosContenido,
    buscarUsuarioComponent,
    usuariosContenidoLoading,
    quitarUsuarioContenido,
    logsComponent,
    avanceAutomaticoComponent,
    diplomasComponent,
    detallePlaneacionCurso,
  },
  computed: {
    ...mapGetters(["sessionToken", "app"]),
    noSearch() {
      let listaCompartidos = this.compartido.map((x) => {
        return x._id;
      });
      let listaColaboradores = this.colaboradores.map((x) => {
        return x._id;
      });
      return listaCompartidos.concat(listaColaboradores);
    },
  },
  watch: {
    sessionToken() {
      this.cargarColaboradores();
      this.cargarCompartidos();
      this.cargarLogs();
    },
  },
  async mounted() {
    if (this.sessionToken) {
      this.cargarColaboradores();
      this.cargarCompartidos();
      this.cargarLogs();
    }
  },
  data() {
    return {
      loadingColaboradores: false,
      loadingCompartido: false,
      ruta: "",
      compartido: [],
      colaboradores: [],
      agregarUsuario: {
        mostrar: false,
        loading: false,
        tipo: "",
        ruta: "",
      },
      quitarUsuario: {
        mostrar: false,
        usuario: {},
        tipo: "",
        ruta: "",
      },
      logs: [],
    };
  },
  methods: {
    async cargarCompartidos() {
      try {
        this.loadingCompartido = true;
        const serverResponse = await obtenerUsuariosService(
          "compartido",
          this.tipoCurso,
          this.idCurso
        );
        this.loadingCompartido = false;
        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else this.compartido = serverResponse.usuarios;
      } catch (error) {
        this.loadingCompartido = false;
        this.$appErrorMessage();
      }
    },
    async cargarColaboradores() {
      try {
        this.loadingColaboradores = true;
        const serverResponse = await obtenerUsuariosService(
          "colaboradores",
          this.tipoCurso,
          this.idCurso
        );
        this.loadingColaboradores = false;
        if (!serverResponse.ok) {
          this.$systemErrorMessage(serverResponse.mensaje);
        } else this.colaboradores = serverResponse.usuarios;
      } catch (error) {
        this.loadingColaboradores = false;
        this.$appErrorMessage();
      }
    },
    abrirBuscador(tipo) {
      this.agregarUsuario.ruta = `buscar/usuario/${this.app}`;
      if (this.app == "escuela") this.agregarUsuario.ruta += "/profesor";
      this.agregarUsuario.tipo = tipo;
      this.agregarUsuario.mostrar = true;
    },
    async agregarUsuarioAction(idUsuario) {
      try {
        const tipo = this.agregarUsuario.tipo;
        this.agregarUsuario.loading = true;
        const serverResponse = await agregarUsuarioService(
          tipo,
          this.tipoCurso,
          this.idCurso,
          idUsuario
        );
        this.agregarUsuario.loading = false;
        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else {
          this.logs.unshift(serverResponse.nuevoLog);
          if (tipo == "colaboradores")
            this.colaboradores.push(serverResponse.usuario);
          if (tipo == "compartido")
            this.compartido.push(serverResponse.usuario);
        }
        this.agregarUsuario.mostrar = false;
      } catch (error) {
        this.agregarUsuario.loading = false;
        this.$appErrorMessage();
      }
    },
    quitarUsuarioAction(data) {
      this.quitarUsuario.usuario = data.usuario;
      this.quitarUsuario.tipo = data.tipo;
      this.quitarUsuario.ruta = `cursos/${this.tipoCurso}/usuarios/${data.tipo}/${this.idCurso}`;
      this.quitarUsuario.mostrar = true;
    },
    usuarioEliminado(data) {
      this.logs.unshift(data.serverResponse.nuevoLog);
      if (data.tipo == "colaboradores") {
        const index = this.colaboradores.findIndex(
          (x) => x._id == data.idUsuario
        );
        this.colaboradores.splice(index, 1);
      }
      if (data.tipo == "compartido") {
        const index = this.compartido.findIndex((x) => x._id == data.idUsuario);
        this.compartido.splice(index, 1);
      }
    },
    async cargarLogs() {
      try {
        const serverResponse = await obtenerLogService(
          this.tipoCurso,
          this.idCurso
        );

        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else this.logs = serverResponse.logs;
      } catch (error) {
        this.loadingCompartido = false;
        this.$appErrorMessage();
      }
    },
    nuevoLog(log) {
      this.logs.unshift(log);
    },
  },
};
</script>
