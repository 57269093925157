<template>
  <div>
    <div v-if="loading">
      <v-skeleton-loader
        v-for="i in 3"
        :key="i"
        type="list-item-two-line"
      ></v-skeleton-loader>
    </div>
    <div v-else>
      <div v-if="planeaciones.length">
        <v-list-item
          v-for="planeacion in planeaciones"
          :key="planeacion._id"
          :class="{
            selected: planeacionSelected == planeacion._id,
          }"
        >
          <v-list-item-avatar>
            <v-avatar color="primary">
              <v-icon dark>mdi-file-chart-check-outline</v-icon>
            </v-avatar>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title
              v-if="customItemSelectionEvent"
              style="cursor: pointer;"
              @click="planeacionSelected = planeacion._id"
            >
              <span class="link">{{ planeacion.nombre }} </span>
            </v-list-item-title>
            <v-list-item-title v-else>
              <router-link
                :to="`/planeaciones/planeacion/${planeacion._id}`"
                class="link"
                >{{ planeacion.nombre }}
              </router-link>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </div>
      <div v-else>
        <span>Sin planeaciones para mostrar</span>
      </div>
    </div>
  </div>
</template>

<script>
import { getPlaneacionesByArea } from "./planeacionesAcademicas.service";

export default {
  props: {
    area: {
      type: String,
    },
    customItemSelectionEvent: {
      type: Boolean,
      default: false,
    },
    currenPlaneacionSelected: {
      type: String,
    },
  },

  data() {
    return {
      loading: true,
      planeaciones: [],
      planeacionSelected: this.currenPlaneacionSelected,
    };
  },

  watch: {
    area(v) {
      !!v && this.loadPlaneaciones();
    },

    planeacionSelected(v) {
      this.$emit("planeacionSelected", v);
    },
  },

  mounted() {
    if (this.area) this.loadPlaneaciones();
    else this.loading = false;
  },

  methods: {
    async loadPlaneaciones() {
      this.loading = true;
      try {
        const serverResponse = await getPlaneacionesByArea(this.area);

        if (!serverResponse.ok) {
          this.$systemErrorMessage(serverResponse.mensaje);
          return;
        }

        this.planeaciones = serverResponse.planeaciones;
      } catch (error) {
        console.error(error);
        this.$appErrorMessage();
      } finally {
        this.loading = false;
      }
    },

    addPlaneacion(planeacion) {
      this.planeaciones.push(planeacion);

      this.planeaciones = this.planeaciones.sort((a, b) => {
        if (a.nombre > b.nombre) return 1;
        else if (a.nombre < b.nombre) return -1;
        return 0;
      });
    },
  },
};
</script>

<style scoped>
.selected {
  background-color: #eceff1;
}
</style>
