import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{attrs:{"outlined":""}},[_c(VCardTitle,[_vm._v("Bloques de texto "),_c(VSpacer),(_vm.bloques.length > 0)?_c(VBtn,{attrs:{"color":"primary","small":"","icon":""},on:{"click":function($event){return _vm.$emit('preview')}}},[_c(VIcon,[_vm._v("mdi-motion-play-outline")])],1):_vm._e()],1),(_vm.bloques.length === 0)?_c(VCardText,[_c('i',[_vm._v("No se han generado bloques de texto")])]):[_c(VContainer,_vm._l((_vm.bloquesLocal),function(bloque,index){return _c('fillblanksBloqueTexto',{key:`bloque-${index}`,attrs:{"bloque":bloque,"index":index,"idInteractivo":_vm.idInteractivo,"tipoInteractivo":_vm.tipoInteractivo,"permisoEscritura":_vm.permisoEscritura,"modificandoBloque":_vm.modificandoBloque},on:{"modificandoBloque":_vm.setModificandoBloque,"bloqueModificado":_vm.bloqueModificado,"bloqueTextoEliminado":_vm.bloqueTextoEliminado}})}),1)],_c(VDivider,{staticClass:"mx-3"}),_c(VContainer,[_c(VRow,[(_vm.permisoEscritura)?_c(VCol,{attrs:{"cols":"12"}},[_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","small":"","disabled":_vm.modificandoBloque,"loading":_vm.loading},on:{"click":function($event){return _vm.agregarBloque()}}},[_vm._v("Agregar bloque")]),_c(VSpacer)],1):_vm._e()],1)],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }