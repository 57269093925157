import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{attrs:{"outlined":""}},[_c(VCardTitle,[_vm._v("Párrafos "),_c(VSpacer),(_vm.parrafos.length > 1 && !_vm.parrafosIncompletos)?_c(VBtn,{attrs:{"color":"primary","small":"","icon":""},on:{"click":function($event){return _vm.$emit('preview')}}},[_c(VIcon,[_vm._v("mdi-motion-play-outline")])],1):_vm._e()],1),(_vm.parrafos.length === 0)?_c(VCardText,[_c('i',[_vm._v("No se han generado párrafos")])]):[_c(VContainer,[_c('draggable',{attrs:{"tag":"div","ghost-class":"ghost","handle":".handle"},on:{"change":_vm.cambiarOrden},model:{value:(_vm.parrafosLocal),callback:function ($$v) {_vm.parrafosLocal=$$v},expression:"parrafosLocal"}},[_vm._l((_vm.parrafosLocal),function(parrafo,index){return [_c(VListItem,{key:parrafo._id,staticClass:"item-seccion pl-0"},[(_vm.permisoEscritura)?_c(VListItemAvatar,{staticClass:"mr-0",class:{ handle: !_vm.loading && !_vm.modificandoParrafo }},[_c(VBtn,{staticStyle:{"cursor":"move"},attrs:{"icon":"","disabled":_vm.loading}},[_c(VIcon,[_vm._v("mdi-dots-grid")])],1)],1):_vm._e(),_c(VListItemContent,[_c('sortparagraphsParrafo',{attrs:{"parrafo":parrafo,"index":index,"idInteractivo":_vm.idInteractivo,"tipoInteractivo":_vm.tipoInteractivo,"permisoEscritura":_vm.permisoEscritura,"modificandoParrafo":_vm.modificandoParrafo},on:{"modificandoParrafo":_vm.setModificandoParrafo,"parrafoModificado":_vm.parrafoModificado,"parrafoEliminado":_vm.parrafoEliminado}})],1)],1)]})],2)],1)],_c(VDivider,{staticClass:"mx-3"}),_c(VContainer,[_c(VRow,[(_vm.permisoEscritura)?_c(VCol,{attrs:{"cols":"12"}},[_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","small":"","disabled":_vm.modificandoParrafo,"loading":_vm.loading},on:{"click":function($event){return _vm.agregarParrafo()}}},[_vm._v("Agregar párrafo")]),_c(VSpacer)],1):_vm._e()],1)],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }