import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{attrs:{"loading":_vm.loading}},[_c(VContainer,[(
        !_vm.loading && _vm.fechaDisponible && _vm.actividad.intentos > _vm.intentos.length
      )?_c(VBtn,{attrs:{"color":"primary","small":""},on:{"click":function($event){_vm.realizarInteractivo = true}}},[_vm._v("Realizar actividad")]):_vm._e(),(_vm.intentos.length == 0)?_c(VCardText,[_vm._v("No se ha realizado la actividad")]):[_c(VList,[_c(VListItem,[_c(VListItemAvatar,[_c(VAvatar,{attrs:{"color":"indigo","size":"36"}},[_c('span',{staticClass:"white--text text-strong"},[_vm._v(_vm._s(_vm.calificacion.calificacion))])])],1),_c(VListItemTitle,[_vm._v("Calificación")])],1),_vm._l((_vm.intentos),function(intento){return _c(VListItem,{key:intento._id},[_c(VListItemAvatar,[_c(VAvatar,{attrs:{"color":"secondary","size":"36"}},[_c('span',{staticClass:"white--text text-strong"},[_vm._v(_vm._s(intento.calificacion))])])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v("Intento "+_vm._s(intento.numero))]),_c(VListItemSubtitle,[_vm._v(_vm._s(intento.observaciones))]),_c(VListItemSubtitle,[_vm._v(_vm._s(intento.fecha))])],1)],1)})],2)],(_vm.realizarInteractivo)?_c('interactivoViewer',{attrs:{"mostrar":_vm.realizarInteractivo,"preview":false,"idActividad":_vm.actividad._id,"idInteractivo":_vm.actividad.interactivo._id,"tipoInteractivo":_vm.actividad.tipoInteractivo,"nombreInteractivo":_vm.actividad.interactivo.nombre},on:{"cerrar":function($event){return _vm.cargarAvance()}}}):_vm._e()],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }