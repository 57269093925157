import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VWindow } from 'vuetify/lib/components/VWindow';
import { VWindowItem } from 'vuetify/lib/components/VWindow';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,[_c(VCard,{attrs:{"outlined":""}},[_c(VToolbar,{staticStyle:{"height":"45px"},attrs:{"dense":"","color":"#254b72","dark":"","flat":""}},[_c('strong',[_vm._v("Entrega")])]),(_vm.alumno)?_c(VCard,{attrs:{"outlined":""}},[_c(VListItem,[_c(VListItemAvatar,{staticStyle:{"margin-right":"0px"},attrs:{"size":"50"}},[_c(VIcon,{attrs:{"size":"50"}},[_vm._v(" mdi-account ")])],1),_c(VListItemContent,{staticClass:"text-center"},[_c(VListItemTitle,[_vm._v(_vm._s(_vm.alumno.nombreCompleto))]),_c(VListItemSubtitle,[_vm._v(_vm._s(_vm.alumno.correoLimpio))])],1)],1)],1):_c(VCard,{attrs:{"outlined":""}},[_c(VListItem,[_c(VListItemAvatar,{attrs:{"size":"50"}},[_c(VIcon,{attrs:{"size":"40"}},[_vm._v(" mdi-account-group ")])],1),_c(VListItemContent,{staticClass:"text-left"},[_c(VListItemTitle,[_vm._v(_vm._s(_vm.equipo.nombre))]),_c(VListItemSubtitle,[_vm._v("Integrantes: "+_vm._s(_vm.equipo.integrantes.length))])],1)],1),_c(VDivider),_c(VWindow,{attrs:{"show-arrows":""},scopedSlots:_vm._u([{key:"prev",fn:function({ on }){return [_c(VBtn,_vm._g({staticStyle:{"background-color":"#FFFF"},attrs:{"icon":"","outlined":"","small":""}},on),[_c(VIcon,[_vm._v("mdi-arrow-left")])],1)]}},{key:"next",fn:function({ on }){return [_c(VBtn,_vm._g({staticStyle:{"background-color":"#FFFF"},attrs:{"icon":"","outlined":"","small":""}},on),[_c(VIcon,[_vm._v("mdi-arrow-right")])],1)]}}])},_vm._l((_vm.equipo.integrantes),function(integrante,index){return _c(VWindowItem,{key:`integrante-${index}`},[_c(VListItem,[_c(VListItemContent,{staticClass:"text-center"},[_c(VListItemTitle,[(integrante.admin)?_c(VIcon,{staticClass:"mr-2",attrs:{"color":"#dfec00","small":""}},[_vm._v("mdi-star")]):_vm._e(),_c(VChip,{staticClass:"mr-1",attrs:{"label":"","small":""}},[_vm._v(_vm._s(index + 1))]),_vm._v(" "+_vm._s(integrante.nombreCompleto)+" ")],1)],1)],1)],1)}),1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }