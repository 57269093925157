import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VRow,{attrs:{"dense":""}},[_c(VCol,{attrs:{"cols":"12"}},[_c(VTextField,{attrs:{"filled":"","dense":"","label":"Nuevo nombre","disabled":_vm.loading,"error-messages":_vm.errors.nombreNuevo},on:{"input":function($event){return _vm.$v.nombreNuevo.$touch()},"blur":function($event){return _vm.$v.nombreNuevo.$touch()}},model:{value:(_vm.nombreNuevo),callback:function ($$v) {_vm.nombreNuevo=$$v},expression:"nombreNuevo"}})],1),_c(VCol,{attrs:{"cols":"12"}},[_c('editor-texto',{ref:"editorTexto",attrs:{"texto":_vm.descripcionActual,"contenido":"encuesta","idContenido":_vm.$route.params.idEncuesta}})],1),_c(VCol,{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex"},[_c(VBtn,{staticClass:"mr-2",attrs:{"color":"secondary","outlined":"","disabled":_vm.loading},on:{"click":function($event){return _vm.$emit('cancelar')}}},[_vm._v("Cancelar")]),_c(VBtn,{attrs:{"color":"primary","loading":_vm.loading,"disabled":_vm.loading || !_vm.esValido},on:{"click":function($event){return _vm.submit()}}},[_c(VIcon,{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("mdi-content-save")]),_c('span',[_vm._v("Guardar")])],1)],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }