import {
  putServerRequest,
  patchServerRequest,
  getServerRequest,
} from "../../../../services/serverRequest.service";
import { store } from "@/store/store";
const httpURL = store.getters.httpURL;

export const obtenerModulo = async ({ idPlaneacion, idModulo }) => {
  const token = store.getters.sessionToken;
  const config = { headers: { token } };
  const url = `${httpURL}/planeaciones/modulos/planeacion/${idPlaneacion}/modulo/${idModulo}`;

  return await getServerRequest(url, config);
};

export const crearModulo = async (data) => {
  const token = store.getters.sessionToken;
  const config = { headers: { token } };
  const url = `${httpURL}/planeaciones/modulos/crear`;

  return await putServerRequest(url, data, config);
};

export const actualizarModulo = async ({ idPlaneacion, idModulo, data }) => {
  const token = store.getters.sessionToken;
  const config = { headers: { token } };
  const url = `${httpURL}/planeaciones/modulos/planeacion/${idPlaneacion}/modulo/${idModulo}`;

  return await patchServerRequest(url, data, config);
};
