import {
  putServerRequest,
  patchServerRequest,
  deleteServerRequest,
} from "@/components/globals/services/serverRequest.service";
import { store } from "../../../../../store/store";

const httpURL = store.getters.httpURL;

export const agregarSortparagraphsParrafoService = async (idSortparagraphs) => {
  const token = store.getters.sessionToken;
  const config = { headers: { token } };
  const url = `${httpURL}/interactivo/sortparagraphs/${idSortparagraphs}/parrafo`;
  return await putServerRequest(url, {}, config);
}

export const modificarOrdenSortparagraphsParrafoService = async (idSortparagraphs, data) => {
  const token = store.getters.sessionToken;
  const config = { headers: { token } };
  const url = `${httpURL}/interactivo/sortparagraphs/${idSortparagraphs}/parrafo/mover`;
  return await patchServerRequest(url, data, config);
}

export const modificarSortparagraphsParrafoService = async (idSortparagraphs, texto, index) => {
  const token = store.getters.sessionToken;
  const config = { headers: { token } };
  const url = `${httpURL}/interactivo/sortparagraphs/${idSortparagraphs}/parrafo`;
  return await patchServerRequest(url, { texto, index }, config);
};

export const eliminarSortparagraphsParrafoService = async (idSortparagraphs, index) => {
  const token = store.getters.sessionToken;
  const config = {
      headers: { token },
      data: { index }
  };
  const url = `${httpURL}/interactivo/sortparagraphs/${idSortparagraphs}/parrafo`;
  return await deleteServerRequest(url, config);
};