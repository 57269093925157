import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"persistent":"","width":"500"},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c(VCard,[_c(VToolbar,{attrs:{"dense":"","flat":"","color":"primary","dark":""}},[_c(VToolbarTitle,[_vm._v("Nueva planeación")])],1),_c(VCardText,{staticClass:"pt-3 pb-3 px-4"},[_c(VForm,{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.isFormValid),callback:function ($$v) {_vm.isFormValid=$$v},expression:"isFormValid"}},[_c(VRow,{attrs:{"dense":""}},[_c(VCol,{attrs:{"cols":"12"}},[_c(VTextField,{attrs:{"label":"Nombre de la planeación","outlined":"","dense":"","rules":[_vm.$rules.required]},on:{"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;$event.preventDefault();return _vm.submit()}},model:{value:(_vm.form.nombre),callback:function ($$v) {_vm.$set(_vm.form, "nombre", $$v)},expression:"form.nombre"}})],1),_c(VCol,{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex justify-end"},[_c(VBtn,{staticClass:"mr-2",attrs:{"color":"primary","outlined":"","small":""},on:{"click":function($event){return _vm.$emit('close')}}},[_c('span',[_vm._v("Cancelar")])]),_c(VBtn,{attrs:{"color":"primary","small":"","disabled":!_vm.isFormValid,"loading":_vm.loading},on:{"click":function($event){return _vm.submit()}}},[_c('span',[_vm._v("Agregar")])])],1)])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }