import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VList,{staticClass:"text-left",attrs:{"dense":""}},[_c(VListItem,{attrs:{"disabled":_vm.disableAdd},on:{"click":function($event){return _vm.$emit('addScore')}}},[_c(VListItemTitle,[_vm._v("Agregar calificación")]),_c(VListItemIcon,[_c(VIcon,{attrs:{"small":"","disabled":_vm.disableAdd}},[_vm._v("mdi-book-plus-outline")])],1)],1),_c(VListItem,{attrs:{"disabled":_vm.disableModify},on:{"click":function($event){return _vm.$emit('modifyScore')}}},[_c(VListItemTitle,[_vm._v("Modificar calificación")]),_c(VListItemIcon,[_c(VIcon,{attrs:{"small":"","disabled":_vm.disableModify}},[_vm._v("mdi-book-edit-outline")])],1)],1),_c(VListItem,{attrs:{"disabled":_vm.disableShow},on:{"click":function($event){return _vm.$emit('showScore')}}},[_c(VListItemTitle,[_vm._v("Ver calificaciones")]),_c(VListItemIcon,[_c(VIcon,{attrs:{"small":"","disabled":_vm.disableShow}},[_vm._v("mdi-bookmark-box-multiple-outline")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }