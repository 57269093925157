import { VAlert } from 'vuetify/lib/components/VAlert';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VCard,[_c(VCardText,[_c(VAlert,{attrs:{"type":"info","dense":"","text":""}},[_c('span',[_vm._v(" Tu calificación en este foro es de "),_c(VChip,{staticClass:"mx-1",attrs:{"label":"","color":"secondary"}},[_c('strong',[_vm._v(_vm._s(_vm.calificacion))])]),_vm._v(" puntos ")],1)]),_c('comentarios-foro',{attrs:{"idCurso":_vm.actividad.cursoCampus,"idActividad":_vm.actividad._id,"descripcion":_vm.actividad.descripcion,"entrega":_vm.actividad.entrega,"esAlumno":true,"idModulo":_vm.actividad.cursoModulo,"entregaActividad":{
          ..._vm.actividad.disponible,
          entregaExt: _vm.actividad.entregaExt,
        },"idEquipo":_vm.idEquipo,"puedeComentar":_vm.actividad.entrega == 1 ? true : _vm.esAdmin,"cursoArchivado":_vm.cursoArchivado}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }