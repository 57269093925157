import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"width":_vm.vaAModificarDescripcion ? 700 : 500},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c(VCard,[_c(VToolbar,{attrs:{"color":"primary","dark":"","flat":"","dense":""}},[_c(VToolbarTitle,[_vm._v(_vm._s(_vm.modulo ? "Actualizar módulo" : "Nuevo módulo"))])],1),_c(VCardText,{staticClass:"mt-3"},[_c(VForm,{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.isFormValid),callback:function ($$v) {_vm.isFormValid=$$v},expression:"isFormValid"}},[_c(VRow,{attrs:{"dense":""}},[(!_vm.vaAModificarDescripcion)?_c(VCol,{attrs:{"cols":"12"}},[_c(VTextField,{attrs:{"label":"Nombre del módulo","outlined":"","dense":"","rules":[_vm.$rules.required]},model:{value:(_vm.formData.nombre),callback:function ($$v) {_vm.$set(_vm.formData, "nombre", $$v)},expression:"formData.nombre"}})],1):_vm._e(),(_vm.vaAModificarDescripcion)?_c(VCol,{staticClass:"mb-3",attrs:{"cols":"12"}},[_c('editorTexto',{ref:"editorTexto",attrs:{"texto":_vm.modulo.descripcion}})],1):_vm._e()],1)],1),_c('div',{staticClass:"d-flex justify-end"},[_c(VBtn,{staticClass:"mr-2",attrs:{"small":"","color":"primary","outlined":""},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v("Cancelar")]),_c(VBtn,{attrs:{"small":"","color":"primary","disabled":!_vm.isFormValid,"loading":_vm.loading},on:{"click":function($event){return _vm.save()}}},[_c(VIcon,{staticClass:"mr-2",attrs:{"small":""}},[_vm._v(" mdi-content-save ")]),_c('span',[_vm._v(" Guardar ")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }