import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-center"},[_c(VMenu,{attrs:{"offset-x":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":"","small":"","loading":_vm.loading}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-dots-vertical")])],1)]}}])},[_c(VList,{attrs:{"dense":""}},[(_vm.permisoEscritura)?_c(VListItem,{on:{"click":_vm.blockIntegrante}},[(!_vm.bloqueado)?[_c(VListItemTitle,[_vm._v("Bloquear alumno")]),_c(VListItemIcon,[_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-lock")])],1)]:[_c(VListItemTitle,[_vm._v("Desbloquear alumno")]),_c(VListItemIcon,[_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-lock-open")])],1)]],2):_vm._e(),(!_vm.cursoEscuela)?_c(VListItem,{on:{"click":function($event){_vm.eliminarIntegrante = true}}},[_c(VListItemTitle,[_c('font',{attrs:{"color":"#D32F2F"}},[_vm._v(" "+_vm._s(_vm.leyendaEliminar)+" ")])],1),_c(VListItemIcon,[_c(VIcon,{attrs:{"color":"red darken-2","small":""}},[_vm._v("mdi-trash-can-outline")])],1)],1):_vm._e()],1)],1),(_vm.eliminarIntegrante)?_c('eliminarIntegranteComponent',{attrs:{"mostrar":_vm.eliminarIntegrante,"idCurso":_vm.idCurso,"idIntegrante":_vm.idIntegrante,"nombreIntegrante":_vm.nombreIntegrante},on:{"cancelar":function($event){_vm.eliminarIntegrante = false},"integranteEliminado":_vm.eliminar}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }