<template>
  <v-dialog v-model="show" :width="vaAModificarDescripcion ? 700 : 500">
    <v-card>
      <v-toolbar color="primary" dark flat dense>
        <v-toolbar-title>{{
          modulo ? "Actualizar módulo" : "Nuevo módulo"
        }}</v-toolbar-title>
      </v-toolbar>
      <v-card-text class="mt-3">
        <v-form v-model="isFormValid" ref="form" lazy-validation>
          <v-row dense>
            <v-col v-if="!vaAModificarDescripcion" cols="12">
              <v-text-field
                v-model="formData.nombre"
                label="Nombre del módulo"
                outlined
                dense
                :rules="[$rules.required]"
              ></v-text-field>
            </v-col>
            <v-col v-if="vaAModificarDescripcion" cols="12" class="mb-3">
              <editorTexto
                ref="editorTexto"
                :texto="modulo.descripcion"
              ></editorTexto>
            </v-col>
          </v-row>
        </v-form>
        <div class="d-flex justify-end">
          <v-btn
            small
            color="primary"
            outlined
            class="mr-2"
            @click="$emit('close')"
            >Cancelar</v-btn
          >
          <v-btn
            small
            color="primary"
            :disabled="!isFormValid"
            :loading="loading"
            @click="save()"
          >
            <v-icon small class="mr-2"> mdi-content-save </v-icon>
            <span> Guardar </span>
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { crearModulo, actualizarModulo } from "./planeacionModulos.service";
import editorTexto from "../../../contenidos/editorTexto.vue";

export default {
  components: {
    editorTexto,
  },

  props: {
    show: {
      type: Boolean,
      default: false,
    },
    vaAModificarDescripcion: {
      type: Boolean,
      default: false,
    },
    idPlaneacion: {
      type: String,
      required: true,
    },
    modulo: {
      type: Object,
      default: () => null,
    },
  },

  data() {
    return {
      loading: false,
      isFormValid: false,
      formData: {
        nombre: "",
      },
    };
  },

  mounted() {
    if (this.modulo) {
      this.formData.nombre = this.modulo.nombre;
    }
  },

  methods: {
    async save() {
      this.loading = true;
      await this.$refs.form.validate();
      if (!this.isFormValid) return;

      try {
        const inputData = {
          ...this.formData,
          idPlaneacion: this.idPlaneacion,
        };

        const serverResponse = this.modulo
          ? await actualizarModulo({
              idPlaneacion: this.idPlaneacion,
              idModulo: this.modulo._id,
              data: {
                ...this.formData,
                ...(this.vaAModificarDescripcion && {
                  descripcion: this.$refs.editorTexto.obtenerTexto(),
                }),
              },
            })
          : await crearModulo(inputData);

        if (!serverResponse.ok) {
          this.$systemErrorMessage(serverResponse.mensaje);
          return;
        }

        this.$emit("moduloSaved", serverResponse.modulo);
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
