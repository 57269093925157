<template>
  <div class="text-center">
    <v-menu offset-x left>
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon small v-bind="attrs" v-on="on">
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>
      <v-list dense>
        <v-list-item @click="showEdicion = true">
          <v-list-item-title>Modificar Nombre</v-list-item-title>
          <v-list-item-icon><v-icon small>mdi-pencil</v-icon></v-list-item-icon>
        </v-list-item>
        <v-list-item disabled>
          <v-list-item-title>
            <font color="#D32F2F"> Eliminar Planeación </font>
          </v-list-item-title>
          <v-list-item-icon
            ><v-icon color="red darken-2" small
              >mdi-trash-can-outline</v-icon
            ></v-list-item-icon
          >
        </v-list-item>
      </v-list>
    </v-menu>

    <modificacion-dialog
      v-if="showEdicion"
      :show="showEdicion"
      :planeacion="planeacion"
      @planeacionSaved="planeacionSaved"
    ></modificacion-dialog>
  </div>
</template>

<script>
export default {
  name: "planeacionMenu",

  components: {
    "modificacion-dialog": () => import("./edicionPlaneacionDialog.vue"),
  },

  props: {
    planeacion: {
      type: Object,
    },
  },
  data() {
    return {
      loading: false,
      showEdicion: false,
    };
  },
  methods: {
    planeacionSaved(planeacion) {
      this.showEdicion = false;
      this.$emit("planeacionSaved", planeacion);
    },
  },
};
</script>
