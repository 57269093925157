<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <instruccionesComponent 
          :idInteractivo="interactivo._id"
          :tipoInteractivo="tipoInteractivo"
          :texto="interactivo.descripcion"
          :permisoEscritura="permisoEscritura"
          @textoModificado="descripcionModificada"
        />
      </v-col>

      <v-col cols="6">
        <ftwPalabrasComponent 
          :idInteractivo="interactivo._id"
          :tipoInteractivo="tipoInteractivo"
          :permisoEscritura="permisoEscritura"
          :palabras="palabras"
          :alineaciones="alineaciones"
          @palabraAgregada="palabraAgregada"
          @palabraModificada="palabraModificada"
          @palabraEliminada="palabraEliminada"
          @preview="preview = true"
        />
      </v-col>

      <v-col cols="6">
        <ftwAlineacionesComponent
          :idInteractivo="interactivo._id"
          :tipoInteractivo="tipoInteractivo"
          :permisoEscritura="permisoEscritura"
          :alineaciones="alineaciones"
          @alineacionModificada="alineacionModificada"
        />
      </v-col>
    </v-row>

    <interactivoViewer
      v-if="preview"
      :mostrar="preview"
      :idInteractivo="interactivo._id"
      :tipoInteractivo="tipoInteractivo"
      :nombreInteractivo="interactivo.nombre"
      @cerrar="preview = false"
    />

  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import instruccionesComponent from "../instrucciones.vue";
import ftwPalabrasComponent from "./ftwPalabras.vue";
import ftwAlineacionesComponent from "./ftwAlineaciones.vue";
import interactivoViewer from "../interactivoViewer.vue";

export default {
  name: 'FindTheWordsView',
  components: {
    instruccionesComponent,
    ftwPalabrasComponent,
    ftwAlineacionesComponent,
    interactivoViewer
  },
  props: {
    interactivo: { type: Object, default: () => {} },
    tipoInteractivo: { type: String, default: null },
    permisoEscritura: { type: Boolean, default: false },
  },
  computed: {
    ...mapGetters(["sessionToken", "app", "role", "menu"]),
  },
  mounted() {
    this.texto = this.interactivo.texto;
    this.palabras = this.interactivo.palabras;
    this.alineaciones = this.interactivo.alineaciones;
  },

  data() {
    return {
      loading: true,
      preview: false,
      texto: "",
      palabras: [],
      alineaciones: {}
    };
  },

  methods: {
    descripcionModificada(payload) {
      this.$emit("descripcionModificada", payload);
    },
    textoModificado(payload) {
      this.texto = payload.texto;
      this.$emit("logAgregado", payload.log);
    },
    palabraAgregada(payload) {
      this.palabras.push(payload.palabra);
      this.$emit("logAgregado", payload.log);
    },
    palabraModificada(payload) {
      this.palabras[payload.index] = payload.palabra;
      this.$emit("logAgregado", payload.log);
    },
    palabraEliminada(payload) {
      this.palabras.splice(payload.index, 1);
      this.$emit("logAgregado", payload.log);
    },
    alineacionModificada(payload) {
      const key = Object.keys(payload.alineacion);
      this.alineaciones[key] = payload.alineacion[key];
      this.$emit("logAgregado", payload.log);
    }
  }

}
</script>