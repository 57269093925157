import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{attrs:{"elevation":"1"}},[_c(VToolbar,{attrs:{"flat":"","dense":""}},[_c(VListItem,{staticStyle:{"padding-left":"0px"}},[_c(VListItemContent,[_c(VListItemTitle,[_vm._v("Nombre de usuario")]),_c(VListItemSubtitle,[_vm._v("Fecha del comentario")])],1)],1),_c(VSpacer),_c('likes-chip'),_c('dislikes-chip')],1),_c(VCardText,[_vm._v("Lorem ipsum dolor sit amet, no nam oblique veritus. Commune scaevola imperdiet nec ut, sed euismod convenire principes at. Est et nobis iisque percipit, an vim zril disputando voluptatibus, vix an salutandi sententiae.")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }