import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.loading)?_c('div',_vm._l((3),function(i){return _c(VSkeletonLoader,{key:i,attrs:{"type":"list-item-two-line"}})}),1):_c('div',[(_vm.planeaciones.length)?_c('div',_vm._l((_vm.planeaciones),function(planeacion){return _c(VListItem,{key:planeacion._id,class:{
          selected: _vm.planeacionSelected == planeacion._id,
        }},[_c(VListItemAvatar,[_c(VAvatar,{attrs:{"color":"primary"}},[_c(VIcon,{attrs:{"dark":""}},[_vm._v("mdi-file-chart-check-outline")])],1)],1),_c(VListItemContent,[(_vm.customItemSelectionEvent)?_c(VListItemTitle,{staticStyle:{"cursor":"pointer"},on:{"click":function($event){_vm.planeacionSelected = planeacion._id}}},[_c('span',{staticClass:"link"},[_vm._v(_vm._s(planeacion.nombre)+" ")])]):_c(VListItemTitle,[_c('router-link',{staticClass:"link",attrs:{"to":`/planeaciones/planeacion/${planeacion._id}`}},[_vm._v(_vm._s(planeacion.nombre)+" ")])],1)],1)],1)}),1):_c('div',[_c('span',[_vm._v("Sin planeaciones para mostrar")])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }