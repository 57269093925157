import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"width":"900","persistent":""},model:{value:(_vm.mostrar),callback:function ($$v) {_vm.mostrar=$$v},expression:"mostrar"}},[_c(VCard,{attrs:{"loading":_vm.loading}},[_c(VToolbar,{attrs:{"dense":"","flat":"","color":"primary","dark":""}},[_c(VToolbarTitle,[_vm._v(_vm._s(_vm.examen ? "Modificar examen" : "Crear examen"))])],1),_c(VCardText,{staticClass:"pt-3 pb-0 px-4"},[_c(VRow,{attrs:{"dense":""}},[_c(VCol,{attrs:{"cols":"12"}},[_c(VTextField,{attrs:{"label":"Nombre","outlined":"","dense":"","disabled":_vm.loading,"error-messages":_vm.errors.nombre},on:{"input":function($event){return _vm.$v.nombre.$touch()},"blur":function($event){return _vm.$v.nombre.$touch()}},model:{value:(_vm.nombre),callback:function ($$v) {_vm.nombre=$$v},expression:"nombre"}})],1),(_vm.examen)?_c(VCol,{attrs:{"cols":"12"}},[_c('span',{staticClass:"text-subtitle-1 text--secondary"},[_vm._v("Descripción del examen:")]),_c('editor-texto',{ref:"editorTexto",attrs:{"texto":_vm.descripcion,"contenido":"examen","idContenido":_vm.examen._id}})],1):_vm._e()],1)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"small":"","color":"secondary","outlined":"","disabled":_vm.loading},on:{"click":function($event){return _vm.$emit('cancelar')}}},[_vm._v("Cancelar")]),_c(VBtn,{attrs:{"small":"","color":"primary","disabled":_vm.loading || !_vm.esValido,"loading":_vm.loading},on:{"click":function($event){return _vm.submit()}}},[_vm._v("Guardar")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }