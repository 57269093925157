import { VCard } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VCard,{attrs:{"flat":""}},[_c(VListItem,[_c(VListItemIcon,{class:{ handle: _vm.dragState == true },on:{"mouseover":function($event){_vm.dragState = true},"mouseout":function($event){_vm.dragState = false}}},[_c(VIcon,{attrs:{"color":"grey"}},[_vm._v("mdi-drag")])],1),_c(VListItemContent,[_c(VListItemTitle,{on:{"click":function($event){return _vm.goToModulo()}}},[_c('a',{staticClass:"text-h6 primary--text link",attrs:{"href":"javascript:void(0)"}},[_vm._v(" "+_vm._s(_vm.moduloLocal.nombre)+" ")])])],1),_c(VListItemAction,[_c('modulo-menu',{attrs:{"idPlaneacion":_vm.idPlaneacion,"modulo":_vm.moduloLocal},on:{"moduloSaved":_vm.moduloSaved}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }