<template>
  <v-dialog v-model="mostrar" persistent fullscreen>
    <v-card>
      <v-toolbar dense color="primary" dark flat>
        <v-spacer></v-spacer>
        <v-toolbar-title>{{ nombreInteractivo }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn text @click="$emit('cerrar')">Cerrar</v-btn>
      </v-toolbar>
      <iframe :src="h5pRoute" width="100%" style="height: 1000px"></iframe>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["httpH5pURL", "userId"]),
    h5pRoute() {
      let route = `${this.httpH5pURL}/h5p/${this.tipoInteractivo}/${this.idInteractivo}/${this.userId}`;
      if (this.preview) route += `/preview/00000`;
      else route += `/attempt/${this.idActividad}`;
      return route;
    },
  },
  props: {
    mostrar: { type: Boolean, default: false },
    preview: { type: Boolean, default: true },
    idInteractivo: { type: String, required: true },
    tipoInteractivo: { type: String, required: true },
    nombreInteractivo: { type: String, required: true },
    idActividad: { type: String, default: null },
  },

  data: () => ({
    loading: false,
  }),

  methods: {},
};
</script>
