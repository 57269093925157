<template>
  <v-dialog v-model="mostrar" :max-width="500" persistent>
    <v-card :loading="loading">
      <v-toolbar color="primary" dark flat dense class="text-h6">
        <v-toolbar-title>{{titulo}}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn fab icon x-small @click="$emit('cancelar')">
          <v-icon color="white">mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <br />

      <v-form ref="formulario" v-model="esValido" lazy-validation class="formularioClass" >
        <v-text-field
          v-model="datosFormulario.nombre"
          outlined
          dense
          label="Nombre"
          :disabled="loading"
          :rules="[reglasComunes.valorRequerido('Nombre requerido')]"
        ></v-text-field>
        <v-textarea
            v-model="datosFormulario.descripcion"
            outlined
            dense
            label="Descripción"
            :disabled="loading"
        ></v-textarea>

        <v-text-field
          v-if="tipo == 'enlace'"
          v-model="datosFormulario.url"
          outlined
          dense
          label="Enlace"
          :disabled="loading"
          :rules="[reglasComunes.valorRequerido('Url requerido')]"
       
        ></v-text-field>

        <v-checkbox
          v-if="tipo == 'archivo' && editarRecurso"
          v-model="datosFormulario.restringir"
          class="mt-0"
          :label="`Archivo restringido`"
        ></v-checkbox>

        <v-container v-if="tipo == 'archivo' && !editarRecurso">
          <v-file-input
            v-model="archivo"
            outlined
            dense
            :disabled="loading"
            placeholder="Seleccionar documento"
            prepend-icon="mdi-paperclip"
            :hint="hint"
            :persistent-hint="loading || uploading"
            :rules="[reglasComunes.valorRequerido('Archivo requerido')]"
            :error-messages="validarTamanio"
          >
          </v-file-input>
        </v-container>
        
      </v-form>

      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" outlined small :disabled="loading" @click="cerrarForm()">
          Cancelar
        </v-btn>
        <v-btn color="primary" small :loading="loading"  @click="agregarRecurso()"  :disabled=" !esValido || datosFormulario.nombre == '' " >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { agregarRecursoEnlaceService, agregarRecursoArchivoService, modificarRecursoService } from "./recursos.service";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

export default {
  name: "editorRecurso",
  props:{
    mostrar: { type: Boolean, default: false},
    tipo: { type: String, default: () => "" },
    idArea: { type: String, default: () => "" },
    idSubarea: { type: String, default: () => "" },
    editarRecurso: {type: Object, default: () => null}
  },
  mixins: [validationMixin],
  computed: {
    datosFormulario() {
      let nombre = this.editarRecurso ? this.editarRecurso.nombre : "";
      let descripcion = this.editarRecurso ? this.editarRecurso.descripcion : "";
      let url = null;
      let restringir = this.editarRecurso ? this.editarRecurso.restringir : false;
      if (this.tipo == 'enlace') {
          url = this.editarRecurso ? this.editarRecurso.url : null;
          return { nombre, descripcion, url };
      }else{
          return { nombre, descripcion, restringir };
      }
    },
    titulo(){
      let titulo = this.editarRecurso ? 'Modificar recurso ' : 'Agregar recurso de tipo ' + this.tipo;
      return titulo;
    },
    validarTamanio(){
      if(this.archivo){
        const tamanio = this.archivo.size;
        const maxFileSizeBytes = 20*1024*1024;
        if (tamanio > maxFileSizeBytes) return 'El archivo no debe exceder los 20 MB'
      }
      return ''
    },
  },
  validations: {
    datosFormulario:{
    nombre: { required },
    url:  { required},
    archivo: { required },
    }
  },
 
  data: () => ({
    loading: false,
    esValido: true,
    archivo: null,
    uploading: false,
    uploadingProgress: 0,
    hint: "",
    reglasComunes: {
      valorRequerido(textoParaMostrar) {
        return (v) => !!v || textoParaMostrar;
      },
    },
  }),
  methods: {
    async agregarRecurso() {
      if (this.$refs.formulario.validate()) {
        try {
          const data = {
              nombre: this.datosFormulario.nombre,
              descripcion: this.datosFormulario.descripcion,
              url: this.datosFormulario.url,
              tipo: this.tipo
            };
          let serverResponse = null;
          let mensaje = ''

          if(this.editarRecurso){
            this.loading = true;
            data.restringir = this.datosFormulario.restringir;
            serverResponse = await modificarRecursoService(data, this.editarRecurso._id);
            mensaje = "recursoModificado";
          }
          else{
            if (this.tipo == 'enlace') {
              this.loading = true;
              serverResponse = await agregarRecursoEnlaceService( data, this.idArea, this.idSubarea );
              }
            else {
              this.loading = true;
              this.hint = "Subiendo... 0%";
              this.uploading = true;
              const archivo = new FormData();
              archivo.append("nombre", this.datosFormulario.nombre);
              archivo.append("descripcion", this.datosFormulario.descripcion);
              archivo.append("tipo", this.tipo);
              archivo.append("file", this.archivo);

             const uploadHandler = {
                onUploadProgress: (progressEvent) => {
                  this.uploadingProgress = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total
                  );
                  this.hint = `Subiendo... ${this.uploadingProgress}%`;
                  if (this.uploadingProgress >= 98) {
                    this.uploading = false;
                    this.loading = true;
                    this.hint = `Procesando...`;
                  }
                },
              };
              serverResponse = await agregarRecursoArchivoService( archivo, uploadHandler, this.idArea, this.idSubarea );
            }
            this.uploading = false;
            mensaje = "recursoAgregado";
         }
          this.loading = false;
          if (!serverResponse.ok){
            this.$systemErrorMessage(serverResponse.mensaje);
          }
          else {
            this.$emit(mensaje, serverResponse.recurso);
            this.$emit("cancelar");
          }
        } catch (error) {
          this.uploading = false;
          this.loading = false;
          this.$appErrorMessage();
        }
      }
    },

    cerrarForm() {
      this.$refs.formulario.reset();
      this.$emit("cancelar");
    },
  },
};
</script>

<style scoped>
.formularioClass {
  padding: 15px;
  padding-bottom: 0;
}
</style>