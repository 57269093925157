import { VChip } from 'vuetify/lib/components/VChip';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(!_vm.documento)?_c('i',{staticClass:"d-flex justify-center"},[_vm._v("Sin archivo")]):_c(VListItem,{staticClass:"text-center"},[_c(VListItemContent,{staticStyle:{"width":"150px"}},[(_vm.documento.revision.reenvio)?_c(VListItemTitle,{staticClass:"mb-1"},[_c(VChip,{attrs:{"label":"","color":"orange darken-4","small":"","outlined":""}},[_vm._v(" Se ha solicitado reenvío ")])],1):_vm._e(),_c(VListItemTitle,[_c('a',{staticClass:"link",attrs:{"href":"javascript:void(0);"},on:{"click":function($event){return _vm.mostrarArchivo()}}},[_vm._v(_vm._s(_vm.documento.file.name)+" ")])]),_c(VListItemSubtitle,[_vm._v(" "+_vm._s(_vm.documentoFecha)+" ")]),_c(VListItemSubtitle,[_c(VChip,{staticClass:"mr-1",attrs:{"x-small":"","outlined":"","label":""}},[_vm._v(_vm._s(_vm.documentoSize)+" ")]),_c(VChip,{staticClass:"ml-1",attrs:{"x-small":"","outlined":"","label":""}},[_vm._v(_vm._s(_vm.documentoTipo)+" ")])],1),_c(VListItemSubtitle,{staticClass:"mt-1"},[(_vm.documentoExt)?_c(VChip,{attrs:{"x-small":"","color":"orange darken-1","dark":"","label":""}},[_vm._v("Entrega extemporánea")]):_vm._e()],1)],1)],1),(_vm.verArchivo.mostrar)?_c('viewerComponent',{attrs:{"mostrar":_vm.verArchivo.mostrar,"idRecurso":_vm.verArchivo.idRecurso,"nombreArchivo":_vm.verArchivo.filename,"url":_vm.verArchivo.url,"tipoArchivo":_vm.verArchivo.tipo,"permisos":_vm.verArchivo.permisos},on:{"cerrar":_vm.cancelarVerArchivo}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }