<template>
  <div>
    <v-select
      v-model="areaSel"
      :items="areas"
      label="Área"
      item-text="nombre"
      item-value="_id"
      :disable="loading"
      dense
      outlined
    />
    <v-select
      v-model="subareaSel"
      :items="subareasLocal"
      label="Subárea"
      item-text="nombre"
      item-value="_id"
      :disable="loading"
      dense
      outlined
    />
    <v-select
      v-model="interactivoSel"
      :items="interactivosLocal"
      return-object
      outlined
      dense
      label="Interactivo:"
      :disabled="loading"
    >
      <template v-slot:selection="{ item }">
        <v-list-item dense>
          <v-list-item-content>
            <v-list-item-title>{{ item.nombre }}</v-list-item-title>
            <v-list-item-subtitle>{{ item.tipoTexto }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>
      <template v-slot:item="{ item, on, attrs }">
        <v-list-item dense v-bind="attrs" v-on="on">
          <v-list-item-content>
            <v-list-item-title>{{ item.nombre }}</v-list-item-title>
            <v-list-item-subtitle>{{ item.tipoTexto }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-select>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { obtenerInteractivosService } from "./actividades.service";
import { tiposInteractivosNombre } from "../../../interactivos/interactivos.service";

export default {
  props: {
    value: Object || null,
    tipoCurso: String,
    idCurso: String,
    idModulo: String,
  },
  computed: {
    ...mapGetters(["app"]),
  },
  watch: {
    loading(value) {
      if (value) this.$emit("loadingEnabled");
      else this.$emit("loadingDisabled");
    },
    areaSel(value) {
      let subareas = [];
      if (value) subareas = this.subareas.filter((x) => x.area === value);
      if (subareas.length > 0) this.subareaSel = subareas[0]._id;
      this.subareasLocal = subareas;
    },
    subareaSel(value) {
      let interactivos = [];
      if (value)
        interactivos = this.interactivos.filter((x) => x.subarea === value);
      if (interactivos.length > 0) {
        this.interactivoSel = interactivos[0];
        this.$emit("saveEnabled");
      } else {
        this.interactivoSel = null;
        this.$emit("saveDisabled");
      }
      this.interactivosLocal = interactivos;
    },
    interactivoSel(value) {
      const interactivo = {
        _id: value._id,
        tipo: value.tipo,
      };
      this.$emit("input", interactivo || null);
    },
  },
  data: () => ({
    loading: false,
    areas: [],
    subareas: [],
    subareasLocal: [],
    interactivos: [],
    interactivosLocal: [],
    areaSel: null,
    subareaSel: null,
    interactivoSel: null,
  }),
  mounted() {
    this.cargarInteractivos();
  },
  methods: {
    async cargarInteractivos() {
      try {
        this.loading = true;
        const serverResponse = await obtenerInteractivosService(
          this.tipoCurso,
          this.idCurso
        );
        this.loading = false;

        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else {
          this.areas = serverResponse.areas;
          if (this.areas.length > 0) this.areaSel = this.areas[0]._id;
          this.subareas = serverResponse.subareas;
          this.interactivos = serverResponse.interactivos;
          this.interactivos.forEach((interactivo) => {
            interactivo.tipoTexto = tiposInteractivosNombre[interactivo.tipo];
          });
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
  },
};
</script>

<style scoped>
.formularioClass {
  padding: 15px;
  padding-bottom: 0;
}
</style>
