<template>
  <v-dialog v-model="show" persistent width="500">
    <v-card :loading="loading">
      <v-toolbar dense dark flat color="primary">
        <v-toolbar-title>
          <span>Vincular actividad</span>
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text class="mt-5">
        <v-row dense>
          <v-col cols="12">
            <v-select
              v-model="moduloSelected"
              outlined
              dense
              label="Módulo del curso"
              :items="modulosCurso"
              item-text="nombre"
              item-value="_id"
            ></v-select>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col
            cols="12"
            v-for="actividad in actividadesModulo"
            :key="actividad._id"
          >
            <v-list-item
              link
              :disabled="!!actividad.moduloElemento"
              @click="addActividad(actividad)"
            >
              <v-list-item-icon>
                <v-icon color="primary">{{ iconoActividad(actividad) }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ actividad.nombre }}</v-list-item-title>
                <v-list-item-subtitle v-if="actividad.moduloElemento"
                  >Ya se usa en un elemento de la
                  planeación</v-list-item-subtitle
                >
              </v-list-item-content>
              <v-list-item-action>
                <v-progress-circular
                  v-if="actividadLoading == actividad._id"
                  indeterminate
                  color="primary"
                ></v-progress-circular>
              </v-list-item-action>
            </v-list-item>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12">
            <v-btn
              color="primary"
              outlined
              dense
              block
              small
              @click="$emit('close')"
            >
              <span>Cancelar</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { getActividadesPlaneacion } from "../../../planeacionesAcademicas.service";
import { agregarActividadToModuloElemento } from "./planeacionModuloElementos.service";

export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    idPlaneacion: {
      type: String,
      required: true,
    },
    idModulo: {
      type: String,
      required: true,
    },
    idElemento: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      actividades: [],
      moduloSelected: "",
      actividadLoading: null,
    };
  },

  computed: {
    ...mapGetters([
      "actividadDirectaIcon",
      "actividadArchivoIcon",
      "actividadForoIcon",
      "actividadInteractivaIcon",
      "actividadTransversalIcon",
      "examenIcon",
    ]),

    modulosCurso() {
      const modulos = [];

      this.actividades.forEach((actividad) => {
        const moduloActividad =
          actividad.cursoPlantillaModulo || actividad.cursoModulo;
        if (!modulos.find((modulo) => modulo._id == moduloActividad._id))
          modulos.push(moduloActividad);
      });

      return modulos;
    },

    actividadesModulo() {
      if (!this.moduloSelected) return [];
      return this.actividades.filter(
        (actividad) =>
          (actividad.cursoPlantillaModulo &&
            actividad.cursoPlantillaModulo._id == this.moduloSelected) ||
          (actividad.cursoModulo &&
            actividad.cursoModulo._id == this.moduloSelected)
      );
    },

    iconoActividad() {
      return function(actividad) {
        if (actividad.examenAplicacion) return this.examenIcon;
        const iconosTipo = {
          1: this.actividadDirectaIcon,
          2: this.actividadArchivoIcon,
          3: this.actividadForoIcon,
          4: this.actividadInteractivaIcon,
          5: this.actividadTransversalIcon,
        };
        return iconosTipo[actividad.tipo];
      };
    },
  },

  mounted() {
    this.loadActividades();
  },

  methods: {
    async loadActividades() {
      this.loading = true;

      try {
        const serverResponse = await getActividadesPlaneacion(
          this.idPlaneacion
        );

        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else this.actividades = serverResponse.actividadesPlaneacion;
        if (this.actividades.length)
          this.moduloSelected = this.modulosCurso[0]._id;
      } catch (error) {
        console.error(error);
        this.$appErrorMessage();
      } finally {
        this.loading = false;
      }
    },

    async addActividad(actividad) {
      this.actividadLoading = actividad._id;

      try {
        const data = {
          actividad: actividad._id,
        };
        const args = {
          idPlaneacion: this.idPlaneacion,
          idModulo: this.idModulo,
          idElemento: this.idElemento,
          data,
        };
        const serverResponse = await agregarActividadToModuloElemento(args);

        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else this.$emit("actividadAgregada", actividad);
      } catch (error) {
        console.error(error);
        this.$appErrorMessage();
      } finally {
        this.actividadLoading = null;
      }
    },
  },
};
</script>
