<template>
  <v-dialog v-model="mostrar" :max-width="500" persistent>
    <v-card :loading="loading">
      <v-toolbar color="primary" dark flat dense class="text-h6">
        <v-toolbar-title>{{ titulo }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn fab icon x-small @click="$emit('cancelar')">
          <v-icon color="white">mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <br />

      <v-form
        ref="formulario"
        v-model="esValido"
        lazy-validation
        class="formularioClass"
      >
        <v-select
          v-model="tipo"
          label="Tipo:"
          :items="tiposList"
          item-value="tipo"
          item-text="nombre"
          dense
          outlined
          :disabled="loading || editarInteractivo !== null"
        >
        </v-select>
        <v-text-field
          v-model="datosFormulario.nombre"
          outlined
          dense
          label="Nombre"
          :disabled="loading"
          :rules="[reglasComunes.valorRequerido('Nombre requerido')]"
        ></v-text-field>
      </v-form>

      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          outlined
          small
          :disabled="loading"
          @click="cerrarForm()"
        >
          Cancelar
        </v-btn>
        <v-btn
          color="primary"
          small
          :loading="loading"
          @click="agregarInteractivo()"
          :disabled="!esValido || datosFormulario.nombre == ''"
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  tiposInteractivos,
  agregarInteractivoService,
  modificarInteractivoService,
} from "./interactivos.service";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

export default {
  name: "editorInteractivo",
  props: {
    mostrar: { type: Boolean, default: false },
    idArea: { type: String, default: () => "" },
    idSubarea: { type: String, default: () => "" },
    tipoInteractivo: { type: String, default: () => "" },
    editarInteractivo: { type: Object, default: () => null },
  },
  mixins: [validationMixin],
  computed: {
    datosFormulario() {
      let nombre = this.editarInteractivo ? this.editarInteractivo.nombre : "";
      return { nombre };
    },
    titulo() {
      let titulo = this.editarInteractivo
        ? "Modificar interactivo "
        : "Agregar interactivo";
      return titulo;
    },
  },
  validations: {
    datosFormulario: {
      nombre: { required },
    },
  },
  mounted() {
    this.tiposList = tiposInteractivos;
    this.tipo = this.tiposList[0].tipo;
  },
  data: () => ({
    loading: false,
    esValido: true,
    tiposList: [],
    tipo: null,
    reglasComunes: {
      valorRequerido(textoParaMostrar) {
        return (v) => !!v || textoParaMostrar;
      },
    },
  }),
  methods: {
    async agregarInteractivo() {
      if (this.$refs.formulario.validate()) {
        try {
          let serverResponse = null;
          let mensaje = "";

          if (this.editarInteractivo) {
            this.loading = true;
            serverResponse = await modificarInteractivoService(
              this.tipoInteractivo,
              this.datosFormulario.nombre,
              this.editarInteractivo._id,
              "nombre"
            );
            mensaje = "interactivoModificado";
          } else {
            const data = {
              tipo: this.tipo,
              nombre: this.datosFormulario.nombre,
            };
            this.loading = true;
            serverResponse = await agregarInteractivoService(
              data,
              this.idArea,
              this.idSubarea
            );
            mensaje = "interactivoAgregado";
          }
          this.loading = false;
          if (!serverResponse.ok) {
            this.$systemErrorMessage(serverResponse.mensaje);
          } else {
            this.$emit(mensaje, serverResponse);
            this.$emit("cancelar");
          }
        } catch (error) {
          this.uploading = false;
          this.loading = false;
          this.$appErrorMessage();
        }
      }
    },

    cerrarForm() {
      this.$refs.formulario.reset();
      this.$emit("cancelar");
    },
  },
};
</script>

<style scoped>
.formularioClass {
  padding: 15px;
  padding-bottom: 0;
}
</style>
