<template>
  <v-card :loading="loading" elevation="2" :height="!loading && avisosOrdenados.length != 0 ? '380' : '160'">

    <v-alert border="top" colored-border color="secondary" class="pa-0 mb-3" />
    <v-toolbar class="mx-1" elevation="0" outlined dense>
      <v-icon color="primary" class="mr-3" size="30">mdi-school</v-icon>
      <v-toolbar-title style="color: #0d3863;">Avisos escuela</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-chip class="elevation-1" color="primary" small><v-icon small class="mr-2">mdi-bell-alert</v-icon>{{ avisosOrdenados.length }}</v-chip>
    </v-toolbar>


    <v-card-subtitle v-if="avisosOrdenados.length === 0 && !loading">No hay avisos disponibles</v-card-subtitle>

    <v-card v-if="loading" outlined class="ma-1">
      <v-skeleton-loader
          type="list-item-avatar-three-line"
        ></v-skeleton-loader>
    </v-card>

    <v-virtual-scroll 
      v-else
      :bench="15"
      :items="avisosOrdenados"
      :item-height="130"
      :height="300"
    >
      <template #default="{ item }">

        <v-card outlined elevation="0" class="ma-1">
          <v-card-title>
              <v-icon
                class="mr-2"
                small
              >
               mdi-clock
              </v-icon>
              <span class="text-caption">{{ moment(item.fechaCreacion).locale('es').format('DD [de] MMMM [de] YYYY') }}</span>
              <v-spacer />
              <v-chip color="#90CAF9" small><v-icon small>mdi-bullhorn</v-icon></v-chip>
          </v-card-title>
          
          <v-card-text>
            <div class="textTitle">{{ item.titulo }}</div>
            <div class="textAviso">{{ item.descripcion }}</div>
          </v-card-text>
        </v-card>

      </template>
    </v-virtual-scroll>


  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment-timezone';
import { getAvisosEscuelaService } from "./avisosDashboard.service.js";

export default {
  name: 'avisosEscuela',
  computed: {
    ...mapGetters(["sessionToken"]),
    avisosOrdenados() {
      let avisos = [];
      avisos = [...this.avisos].reverse();
      return avisos;
    }
  },
  data () {
    return {
      loading: false,
      avisos: []
    }
  },
  watch: {
    sessionToken() {
      this.cargarAvisosEscuela();
    }
  },
  mounted() {
    if(this.sessionToken) this.cargarAvisosEscuela();
  },
  created() {
    this.moment = moment;
  },
  methods: {
    async cargarAvisosEscuela() {
      try {
        this.loading = true;

        const serverResponse = await getAvisosEscuelaService();
        this.loading = false;
        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else {
          this.avisos = serverResponse.avisos;
          if(this.avisos.length != 0) this.$emit('showAvisosEscuela');
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    }
  }
}
</script>

<style scoped>

.textTitle {
  text-align: left;
  font-size: 15px;
  color: #000000;
}

.textAviso {
  margin-top: 5px;
  text-align: left;
  font-size: 12px;
  color: #616161;
}
</style>