import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"persistent":"","width":"500"},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c(VCard,[_c(VToolbar,{attrs:{"dense":"","dark":"","flat":"","color":"error darken-2"}},[_c(VToolbarTitle,[_c('span',[_vm._v("Confirmar")])])],1),_c(VCardText,{staticClass:"mt-3"},[_c('p',[_vm._v(" Si quitas la planeación académica de este curso, se eliminarán todos los detalles que hayas modificado dentro de la misma. Los datos de la planeación original se conservarán. ")]),_c('p',[_c('strong',[_vm._v("Una vez realizada la acción, no se puede revertir.")])]),_c('div',{staticClass:"d-flex justify-end"},[_c(VBtn,{staticClass:"mr-2",attrs:{"color":"primary","outlined":"","small":""},on:{"click":function($event){return _vm.$emit('close')}}},[_c('span',[_vm._v("Cancelar")])]),_c(VBtn,{attrs:{"color":"error darken-2","small":"","loading":_vm.loading},on:{"click":function($event){return _vm.quitarPlaneacion()}}},[_c(VIcon,{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("mdi-delete")]),_c('span',[_vm._v("Confirmar eliminación")])],1)],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }