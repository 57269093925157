<template>
  <v-dialog v-model="mostrar" :max-width="500" persistent>
    <v-card :loading="loading">
      <v-toolbar color="primary" dark flat dense class="text-h6">
        <v-toolbar-title>Agregar actividad</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn fab icon x-small @click="$emit('cancelar')">
          <v-icon color="white">mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <br />

      <v-form
        ref="formulario"
        v-model="esValido"
        lazy-validation
        class="formularioClass"
      >
        <v-select
          v-model="tipoSelect"
          outlined
          dense
          label="Tipo:"
          :disabled="loading"
          :items="tipos"
        >
          <template v-slot:selection="{ item }">
            <v-list-item dense>
              <v-list-item-icon>
                <v-icon color="primary">{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ item.text }}</v-list-item-title>
            </v-list-item>
          </template>
          <template v-slot:item="{ item, on, attrs }">
            <v-list-item dense v-bind="attrs" v-on="on">
              <v-list-item-icon>
                <v-icon color="primary">{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ item.text }}</v-list-item-title>
            </v-list-item>
          </template>
        </v-select>

        <agregarInteractivoComponent
          v-if="esInteractivo"
          v-model="interactivo"
          :tipoCurso="tipoCurso"
          :idCurso="idCurso"
          :idModulo="idModulo"
          @loadingEnabled="loading = true"
          @loadingDisabled="loading = false"
          @saveEnabled="esValido = true"
          @saveDisabled="esValido = false"
        />

        <v-text-field
          v-else
          v-model="nombre"
          outlined
          dense
          label="Nombre"
          :disabled="loading"
          :rules="[reglasComunes.valorRequerido('Nombre requerido')]"
        ></v-text-field>

        <v-select
          v-model="criterio"
          outlined
          dense
          label="Criterio:"
          item-value="_id"
          item-text="nombre"
          :disabled="loading || criteriosLoading"
          :items="criterios"
          :loading="criteriosLoading"
        >
          <template v-slot:item="{ item }">
            <v-list-item-content>
              <v-list-item-title>{{ item.nombre }}</v-list-item-title>
              <v-list-item-subtitle v-if="item.seccion">{{
                item.seccion
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </template>
        </v-select>
      </v-form>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          outlined
          small
          :disabled="loading"
          @click="cerrarForm()"
        >
          Cancelar
        </v-btn>
        <v-btn
          color="primary"
          dark
          small
          :loading="loading"
          :class="{ 'disable-btn': !esValido }"
          @click="agregarActividad()"
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { agregarActividadService } from "./actividades.service";
import { obtenerEvaluacionCursoService } from "../../encuadre/evaluacion/evaluacion.service";
import agregarInteractivoComponent from "./agregarInteractivo.vue";

export default {
  props: {
    mostrar: Boolean,
    tipoCurso: String,
    idCurso: String,
    idModulo: String,
  },
  components: {
    agregarInteractivoComponent,
  },
  computed: {
    ...mapGetters([
      "actividadDirectaIcon",
      "actividadArchivoIcon",
      "actividadForoIcon",
      "actividadInteractivaIcon",
    ]),
    esInteractivo() {
      return this.tipoSelect === 4;
    },
  },
  data: () => ({
    loading: false,
    criteriosLoading: false,
    nombre: "",
    interactivo: null,
    criterio: null,
    criterios: [],
    tipoSelect: null,
    tipos: [],
    esValido: true,
    reglasComunes: {
      valorRequerido(textoParaMostrar) {
        return (v) => !!v || textoParaMostrar;
      },
    },
  }),
  mounted() {
    this.setTipos();
    this.cargarEvaluacion();
  },
  methods: {
    setTipos() {
      this.tipos = [
        {
          value: 1,
          text: "Calificación directa",
          icon: this.actividadDirectaIcon,
        },
        { value: 2, text: "Subir archivo", icon: this.actividadArchivoIcon },
        { value: 3, text: "Foro", icon: this.actividadForoIcon },
        { value: 4, text: "Interactivo", icon: this.actividadInteractivaIcon },
      ];
      this.tipoSelect = 1;
    },
    async cargarEvaluacion() {
      try {
        this.criterios = [{ _id: null, nombre: "Ninguno", seccion: "" }];
        this.criteriosLoading = true;
        const serverResponse = await obtenerEvaluacionCursoService(
          this.tipoCurso,
          this.idCurso
        );
        this.criteriosLoading = false;

        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else {
          serverResponse.secciones.forEach((seccion) => {
            const criteriosValidos = seccion.criterios.filter(
              (x) => !x.asistencias.requerido
            );
            const criterios = criteriosValidos.map((criterio) => {
              return {
                _id: criterio._id,
                nombre: criterio.nombre,
                seccion: seccion.nombre,
              };
            });
            this.criterios.push(...criterios);
          });
        }
      } catch (error) {
        this.criteriosLoading = false;
        this.$appErrorMessage();
      }
    },
    async agregarActividad() {
      if (this.$refs.formulario.validate()) {
        try {
          this.loading = true;
          const data = {
            tipo: this.tipoSelect,
            nombre: this.nombre,
            interactivo: this.interactivo,
            criterio: this.criterio,
          };

          const serverResponse = await agregarActividadService(
            data,
            this.tipoCurso,
            this.idCurso,
            this.idModulo
          );
          this.loading = false;

          if (!serverResponse.ok)
            this.$systemErrorMessage(serverResponse.mensaje);
          else this.$emit("actividadAgregada", serverResponse.actividad);
        } catch (error) {
          this.loading = false;
          this.$appErrorMessage();
        }
      }
    },
    cerrarForm() {
      this.$refs.formulario.reset();
      this.$emit("cancelar");
    },
  },
};
</script>

<style scoped>
.formularioClass {
  padding: 15px;
  padding-bottom: 0;
}
</style>
