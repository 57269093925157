import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VDialog,{attrs:{"max-width":500,"persistent":""},model:{value:(_vm.mostrar),callback:function ($$v) {_vm.mostrar=$$v},expression:"mostrar"}},[_c(VCard,[_c(VToolbar,{staticClass:"text-h6",attrs:{"color":"primary","dark":"","dense":"","flat":""}},[_vm._v(" Archivo no disponible "),_c(VSpacer),_c(VBtn,{attrs:{"fab":"","icon":"","x-small":""},on:{"click":function($event){return _vm.$emit('cerrar')}}},[_c(VIcon,{attrs:{"color":"white"}},[_vm._v("mdi-close")])],1)],1),_c(VContainer,{attrs:{"grid-list-md":""}},[_c('i',[_vm._v("El documento ")]),_c('b',[_vm._v(_vm._s(_vm.nombreArchivo))]),_c('i',[_vm._v(" ya no se encuentra disponible.")])]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.$emit('cerrar')}}},[_vm._v("Aceptar")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }