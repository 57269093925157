<template>
  <v-dialog v-model="mostrar" :max-width="300" persistent>
    <v-card>
      <v-toolbar color="primary" dark flat dense class="text-bold">
        <v-toolbar-title>Modificar intentos</v-toolbar-title>
      </v-toolbar>
      <v-container>
        <v-select
          v-model="intentosSelect"
          outlined
          dense
          label="Intentos:"
          :disabled="loading || loading"
          :items="intentosList"
          :loading="loading"
        >
          <template v-slot:selection="{ item }">
            <v-list-item dense>
              <v-list-item-title>{{ item }} intentos</v-list-item-title>
            </v-list-item>
          </template>
          <template v-slot:item="{ item, on, attrs }">
            <v-list-item dense v-bind="attrs" v-on="on">
              <v-list-item-title>{{ item }} intentos</v-list-item-title>
            </v-list-item>
          </template> </v-select
        >
      </v-container>
      <v-divider class="mx-3"></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          outlined
          small
          :disabled="loading"
          @click="$emit('cancelar')"
        >
          Cancelar
        </v-btn>
        <v-btn
          color="primary"
          dark
          small
          :loading="loading"
          :class="{ 'disable-btn': !esValido }"
          @click="modificarIntentos()"
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { modificarIntentosService } from "../actividad.service";
export default {
  name: "modificarIntentosActividad",
  props: {
    mostrar: Boolean,
    tipoCurso: String,
    idCurso: String,
    idActividad: String,
    tipo: Number,
    intentos: Number,
    permisoEscritura: { type: Boolean, default: false },
  },
  computed: {
    esValido() {
      return this.intentosSelect != this.intentos;
    },
  },
  data() {
    return {
      loading: false,
      intentosSelect: null,
      intentosList: [1, 2, 3, 4, 5],
    };
  },
  mounted() {
    this.intentosSelect = this.intentos;
  },
  methods: {
    async modificarIntentos() {
      try {
        this.loading = true;
        const data = { intentos: this.intentosSelect };
        const serverResponse = await modificarIntentosService(
          this.tipoCurso,
          this.idCurso,
          this.idActividad,
          data
        );
        this.loading = false;
        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else {
          this.$emit("intentosModificado", serverResponse.intentos);
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
  },
};
</script>
