<template>
  <v-dialog v-model="mostrar" width="500" persistent transition="dialog-bottom-transition">
    <v-card>
    <v-toolbar color="danger" dense flat dark class="text-h6">Eliminar palabra</v-toolbar>

    <v-container>
        <b>¿Está seguro de eliminar la palabra?</b> <br>
        <v-card-title style="justify-content: center;">{{item.palabra}}</v-card-title>
        <i>Esta acción afectará a todas las actividades que utilizan este recurso de manera irreversible.</i>
    </v-container>
    <v-divider></v-divider>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn 
        color="primary" 
        outlined 
        small 
        :disabled="loading" 
        @click="$emit('cancelar')"
      >Cancelar
      </v-btn>
      <v-btn 
        color="danger" 
        small
        dark
        :loading="loading" 
        @click="eliminar()">Eliminar</v-btn>
    </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { eliminarCrosswordItemService } from "./crossword.service";

export default {
  props: {
    mostrar: { type: Boolean, default: false },
    idCrossword: { type: String, default: null },
    item: { type: Object, default: () => null },
  },
  data() {
    return {
      loading: false,
    }
  },
  methods: {
    async eliminar() {
      try {
        this.loading = true;

        const serverResponse = await eliminarCrosswordItemService(this.idCrossword, this.item._id);
        this.loading = false;
        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else {
          this.$emit("crosswordItemEliminado", serverResponse);
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    }
  }
}
</script>