import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{attrs:{"outlined":""}},[_c(VCardTitle,[_vm._v(_vm._s(_vm.titulo)+" "),_c(VSpacer),(_vm.permisoEscritura)?_c(VBtn,{attrs:{"icon":"","color":"primary"},on:{"click":function($event){_vm.modificar = true}}},[_c(VIcon,[_vm._v("mdi-pencil-box-outline")])],1):_vm._e()],1),(!_vm.modificar)?_c(VCardText,[(!_vm.texto)?_c('span',[_c('i',[_vm._v("No se ha capturado pregunta")])]):_c('span',[_vm._v(_vm._s(_vm.texto))])]):_c('div',[_c(VCardText,[_c(VTextarea,{attrs:{"outlined":"","disabled":_vm.loading},model:{value:(_vm.textoLocal),callback:function ($$v) {_vm.textoLocal=$$v},expression:"textoLocal"}})],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","outlined":"","small":"","loading":_vm.loading},on:{"click":function($event){_vm.modificar = false}}},[_vm._v("Cancelar")]),_c(VBtn,{attrs:{"color":"primary","dark":"","small":"","loading":_vm.loading},on:{"click":_vm.modificarInstrucciones}},[_vm._v("Modificar")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }