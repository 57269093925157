import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VCard,{staticClass:"my-2",attrs:{"loading":_vm.loading,"outlined":""}},[_c(VList,{attrs:{"subheader":""}},[_c(VSubheader,[_vm._v(" "+_vm._s(_vm.titulo)+" "),_c(VSpacer),(_vm.permisoEscritura && !_vm.vistaSeguimiento)?_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c(VBtn,_vm._g({attrs:{"color":"primary","icon":"","x-small":""},on:{"click":function($event){return _vm.$emit('agregarUsuario')}}},on),[_c(VIcon,[_vm._v("mdi-account-plus")])],1)]}}],null,false,3376163259)},[_c('span',[_vm._v("Agregar usuario")])]):_vm._e()],1),_c(VDivider,{staticClass:"mx-1"}),(_vm.usuarios.length == 0)?_c(VListItem,[_c(VListItemContent,[_c(VListItemSubtitle,[_vm._v("No se han asignado usuarios")])],1)],1):_vm._e(),_vm._l((_vm.usuarios),function(usuario,index){return [(index > 0)?_c(VDivider,{key:index,staticClass:"mx-2"}):_vm._e(),_c(VListItem,{key:usuario._id},[_c(VListItemAvatar,[(usuario.avatar)?_c('img',{attrs:{"src":`${_vm.imageURL}/avatar/${usuario.avatar}`}}):_c(VIcon,{attrs:{"size":"45"}},[_vm._v("mdi-account-circle")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(usuario.nombreCompleto))]),_c(VListItemSubtitle,[_vm._v(_vm._s(usuario.correo))])],1),(_vm.permisoEscritura)?_c(VListItemAction,[_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c(VBtn,_vm._g({attrs:{"icon":"","x-small":"","color":"red darken-1"},on:{"click":function($event){return _vm.$emit('quitarUsuario', { usuario, tipo: _vm.tipo })}}},on),[_c(VIcon,[_vm._v("mdi-close-circle")])],1)]}}],null,true)},[_c('span',[_vm._v("Quitar usuario")])])],1):_vm._e()],1)]})],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }