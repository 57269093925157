import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"width":"370","persistent":""},model:{value:(_vm.mostrar),callback:function ($$v) {_vm.mostrar=$$v},expression:"mostrar"}},[_c(VCard,[_c(VToolbar,{attrs:{"color":"primary","dense":"","flat":"","dark":""}},[_c('strong',[_vm._v("Importar curso")])]),_c(VContainer,[_c(VTextField,{staticClass:"separador",attrs:{"dense":"","outlined":"","label":"Código","disabled":_vm.loading,"error-messages":_vm.codigoErrors},on:{"input":function($event){return _vm.$v.codigo.$touch()},"blur":function($event){return _vm.$v.codigo.$touch()}},model:{value:(_vm.codigo),callback:function ($$v) {_vm.codigo=$$v},expression:"codigo"}}),_c(VSelect,{attrs:{"dense":"","outlined":"","messages":"La plantilla se copiará en el área seleccionada","items":_vm.areaPlantillas,"item-value":"_id","item-text":"nombre","label":"Área de plantilla","error":_vm.areaSel ? false : true,"disabled":_vm.loading},model:{value:(_vm.areaSel),callback:function ($$v) {_vm.areaSel=$$v},expression:"areaSel"}})],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"disabled":_vm.loading,"color":"primary","outlined":"","small":""},on:{"click":function($event){return _vm.$emit('cancelar')}}},[_vm._v(" Cancelar ")]),_c(VBtn,{class:{ 'disable-btn': _vm.codigoErrors.length > 0 || !_vm.codigo },attrs:{"loading":_vm.loading,"color":"primary","small":""},on:{"click":function($event){return _vm.importarPlantilla()}}},[_vm._v(" Importar ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }