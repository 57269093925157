import { VCard } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{attrs:{"outlined":""}},[_c(VToolbar,{attrs:{"dense":"","flat":""}},[_c(VSubheader,[_vm._v("Alineaciones")]),_c(VSpacer)],1),_c(VDivider,{staticClass:"mx-3"}),_c(VList,_vm._l((_vm.alineacionesLocales),function(alineacion,index){return _c(VListItem,{key:`alineacion-${index}`},[_c(VListItemAvatar,[_c(VIcon,{attrs:{"large":""}},[_vm._v(_vm._s(alineacion.icon))])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(alineacion.name))])],1),_c(VListItemAction,[_c(VCheckbox,{attrs:{"value":!alineacion.value,"color":"primary","disabled":_vm.loading},on:{"change":function($event){return _vm.modificarAlineacion(alineacion)}},model:{value:(alineacion.value),callback:function ($$v) {_vm.$set(alineacion, "value", $$v)},expression:"alineacion.value"}})],1)],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }