import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VListItem,{attrs:{"dense":""},on:{"mouseover":function($event){_vm.puedeModificar ? (_vm.modificarIcon = true) : null},"mouseout":function($event){_vm.modificarIcon = false}}},[_c(VListItemIcon,[(_vm.modificarIcon && !_vm.vistaSeguimiento)?_c(VBtn,{attrs:{"fab":"","x-small":"","color":"primary"},on:{"click":function($event){_vm.modificarIntentos = true}}},[_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-pencil")])],1):_c(VIcon,{attrs:{"color":"primary"}},[_vm._v("mdi-refresh")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v("Intentos")]),_c(VListItemSubtitle,[_vm._v(" "+_vm._s(_vm.intentos)+" ")])],1)],1),(_vm.modificarIntentos)?_c('modificarIntentosActividadComponent',{attrs:{"mostrar":_vm.modificarIntentos,"tipoCurso":_vm.tipoCurso,"idCurso":_vm.idCurso,"idActividad":_vm.idActividad,"intentos":_vm.intentos},on:{"cancelar":function($event){_vm.modificarIntentos = false},"intentosModificado":_vm.intentosModificado}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }