import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"max-width":500,"persistent":""},model:{value:(_vm.mostrar),callback:function ($$v) {_vm.mostrar=$$v},expression:"mostrar"}},[_c(VCard,[_c(VToolbar,{staticClass:"text-bold",attrs:{"color":"primary","dark":"","flat":"","dense":""}},[_c(VToolbarTitle,[_vm._v("Modificar Tipo")])],1),_c(VContainer,[(_vm.tipo == 1)?_c(VAlert,{attrs:{"color":"orange lighten-1","dense":"","dark":""}},[_vm._v(" Al modificar el tipo también se eliminaran las calificaciones registradas. ")]):_vm._e(),(_vm.tipo == 2)?_c(VAlert,{attrs:{"color":"orange lighten-1","dense":"","dark":""}},[_vm._v(" Al modificar el tipo también se eliminaran las calificaciones registradas junto con sus respectivas entregas. ")]):_vm._e(),_c(VSelect,{attrs:{"outlined":"","dense":"","label":"Tipo:","disabled":_vm.loading || _vm.loading,"items":_vm.tipos,"loading":_vm.loading},scopedSlots:_vm._u([{key:"selection",fn:function({ item }){return [_c(VListItem,{attrs:{"dense":""}},[_c(VListItemIcon,[_c(VIcon,{attrs:{"color":"primary"}},[_vm._v(_vm._s(item.icon))])],1),_c(VListItemTitle,[_vm._v(_vm._s(item.text))])],1)]}},{key:"item",fn:function({ item, on, attrs }){return [_c(VListItem,_vm._g(_vm._b({attrs:{"dense":""}},'v-list-item',attrs,false),on),[_c(VListItemIcon,[_c(VIcon,{attrs:{"color":"primary"}},[_vm._v(_vm._s(item.icon))])],1),_c(VListItemTitle,[_vm._v(_vm._s(item.text))])],1)]}}]),model:{value:(_vm.tipoSelect),callback:function ($$v) {_vm.tipoSelect=$$v},expression:"tipoSelect"}})],1),_c(VDivider,{staticClass:"mx-3"}),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","outlined":"","small":"","disabled":_vm.loading},on:{"click":function($event){return _vm.$emit('cancelar')}}},[_vm._v(" Cancelar ")]),_c(VBtn,{class:{ 'disable-btn': !_vm.esValido },attrs:{"color":"primary","dark":"","small":"","loading":_vm.loading},on:{"click":function($event){return _vm.modificarTipo()}}},[_vm._v(" Guardar ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }