import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,[_c(VCard,{attrs:{"outlined":""}},[_c(VListItem,[_c(VListItemAvatar,[_c(VIcon,{attrs:{"x-large":"","color":"primary"}},[_vm._v("mdi-card-text-outline")])],1),_c(VListItemContent,[_c(VListItemTitle,{staticClass:"title",staticStyle:{"white-space":"normal"}},[_vm._v(" Descripción ")])],1),(_vm.permisoEscritura && !_vm.vistaSeguimiento)?_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c(VBtn,_vm._g({staticClass:"title",attrs:{"x-small":"","icon":"","color":"primary","disabled":_vm.loading},on:{"click":function($event){_vm.edicionHabilitada = true}}},on),[_c(VIcon,[_vm._v("mdi-square-edit-outline")])],1)]}}],null,false,3814168758)},[_c('span',[_vm._v("Modificar descripción")])]):_vm._e()],1),_c(VDivider,{staticClass:"mx-3"}),_c(VContainer,[(_vm.edicionHabilitada)?_c('div',[_c('editor-texto',{ref:"editorTexto",attrs:{"texto":_vm.descripcion,"app":_vm.app,"contenido":_vm.tipoCursoCompleto,"idContenido":_vm.idCurso}}),_c('div',{staticStyle:{"text-align":"right"}},[_c(VSpacer),_c(VBtn,{staticClass:"margen",attrs:{"color":"primary","outlined":"","small":"","disabled":_vm.loading},on:{"click":function($event){_vm.edicionHabilitada = false}}},[_vm._v(" Cancelar ")]),_c(VBtn,{staticClass:"margen",attrs:{"color":"primary","dark":"","small":"","loading":_vm.loading},on:{"click":function($event){return _vm.modificarDescripcion()}}},[_vm._v(" Guardar ")])],1)],1):[(!_vm.descripcion)?_c('div',[_c('i',[_vm._v("No se ha capturado una descripción")])]):_c('div',{domProps:{"innerHTML":_vm._s(_vm.descripcion)}})]],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }