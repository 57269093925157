import {
  getServerRequest,
  putServerRequest,
  patchServerRequest,
} from "../../services/serverRequest.service";
import { store } from "@/store/store";
const httpURL = store.getters.httpURL;

export const getPlaneacion = async (idPlaneacion) => {
  const token = store.getters.sessionToken;
  const config = { headers: { token } };
  const url = `${httpURL}/planeaciones/planeacion/${idPlaneacion}`;

  return await getServerRequest(url, config);
};

export const getPlaneacionesByArea = async (idArea) => {
  const token = store.getters.sessionToken;
  const config = { headers: { token } };
  const url = `${httpURL}/planeaciones/byArea/${idArea}`;

  return await getServerRequest(url, config);
};

export const crearPlaneacion = async (data) => {
  const token = store.getters.sessionToken;
  const config = { headers: { token } };
  const url = `${httpURL}/planeaciones/crear`;

  return await putServerRequest(url, data, config);
};

export const updatePlaneacion = async (idPlaneacion, data) => {
  const token = store.getters.sessionToken;
  const config = { headers: { token } };
  const url = `${httpURL}/planeaciones/planeacion/${idPlaneacion}`;

  return await patchServerRequest(url, data, config);
};

export const getActividadesPlaneacion = async (idPlaneacion) => {
  const token = store.getters.sessionToken;
  const config = { headers: { token } };
  const url = `${httpURL}/planeaciones/planeacion/${idPlaneacion}/actividades`;

  return await getServerRequest(url, config);
};
