import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VCard,{attrs:{"tile":"","outlined":""}},[_c(VRow,{attrs:{"no-gutters":""}},_vm._l((_vm.elementosLocal),function(elemento){return _c(VCol,{key:elemento._id,staticClass:"ma-0 pa-0",attrs:{"cols":_vm.percentToCols(elemento.porcentajeVista)}},[_c('modulo-elemento',{attrs:{"elemento":elemento,"planeacion":_vm.planeacion},on:{"elementoSaved":_vm.elementoSaved}})],1)}),1)],1),_c('div',{staticClass:"my-3 d-flex justify-center"},[_c(VMenu,{attrs:{"close-on-content-click":false,"bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VBtn,_vm._g(_vm._b({attrs:{"small":"","color":"primary","rounded":""}},'v-btn',attrs,false),on),[_c(VIcon,{staticClass:"mr-2"},[_vm._v("mdi-plus")]),_c('span',[_vm._v("Agregar elemento")])],1)]}}])},[_c(VList,_vm._l((_vm.moduloElementosList),function(elementoOption,index){return _c(VListItem,{key:index,attrs:{"dense":"","link":""},on:{"click":function($event){return elementoOption.method()}}},[_c(VIcon,{staticClass:"mr-2",attrs:{"small":""}},[_vm._v(_vm._s(elementoOption.icon))]),_c('span',[_vm._v(_vm._s(elementoOption.titulo))])],1)}),1)],1)],1),(_vm.showElementoDialog)?_c('elemento-dialog',{attrs:{"show":_vm.showElementoDialog,"tipo":_vm.tipoElementoACrear,"idPlaneacion":_vm.idPlaneacion,"idModulo":_vm.idModulo},on:{"close":function($event){_vm.showElementoDialog = false},"elementoSaved":_vm.elementoSaved}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }