import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,[_c(VContainer,{staticClass:"d-flex justify-space-between align-center mx-y px-0 my-3"},[_c('span',{staticClass:"subtitle-1"},[_vm._v("Secciones de la encuesta")]),(_vm.permisoEscritura)?_c(VBtn,{attrs:{"color":"primary","outlined":"","small":"","disabled":_vm.loading,"loading":_vm.loading},on:{"click":_vm.crearSeccion}},[_c(VIcon,{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("mdi-plus")]),_c('span',[_vm._v("Agregar sección")])],1):_vm._e()],1),(_vm.seccionesLocal && !_vm.seccionesLocal.length)?_c('span',{staticClass:"text--secondary"},[_vm._v("No se han agregado secciones a esta encuesta.")]):_c(VList,[_c('draggable',{attrs:{"tag":"div","ghost-class":"ghost","handle":".handle"},on:{"change":_vm.cambiarOrden},model:{value:(_vm.seccionesLocal),callback:function ($$v) {_vm.seccionesLocal=$$v},expression:"seccionesLocal"}},[_vm._l((_vm.seccionesLocal),function(seccion){return [_c(VListItem,{key:seccion._id,staticClass:"item-seccion pl-0"},[_c(VListItemAvatar,{staticClass:"mr-0",class:{ handle: !_vm.loading }},[_c(VBtn,{staticStyle:{"cursor":"move"},attrs:{"icon":"","disabled":_vm.loading}},[_c(VIcon,[_vm._v("mdi-dots-grid")])],1)],1),_c(VListItemContent,[_c(VListItemTitle,[_c('router-link',{staticClass:"link",attrs:{"to":`${_vm.$route.fullPath}/seccion/${seccion._id}`}},[_vm._v(_vm._s(seccion.nombre)+" ")])],1),_c(VListItemSubtitle,[_vm._v("Reactivos: "+_vm._s(seccion && seccion.preguntas ? seccion.preguntas.length : "0"))])],1)],1)]})],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }