import { VCard } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
import { VTabsSlider } from 'vuetify/lib/components/VTabs';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,[(!_vm.escuelaTienePermiso)?_c(VSkeletonLoader,{attrs:{"type":"list-item-two-line@5"}}):_c(VCard,[(_vm.app === 'campus')?_c(VTabs,{attrs:{"centered":"","icons-and-text":"","background-color":"secondary","dark":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c(VTabsSlider,{attrs:{"color":"warning"}}),_c(VTab,{attrs:{"href":"#listado","to":"#listado"}},[_vm._v(" Encuestas "),_c(VIcon,[_vm._v("mdi-clipboard-list-outline")])],1),_c(VTab,{attrs:{"href":"#compartidos","to":"#compartido"}},[_vm._v(" Compartido "),_c(VIcon,[_vm._v("mdi-account-switch")])],1)],1):_c(VToolbar,{staticClass:"text-h6",attrs:{"dense":"","color":"secondary","dark":"","flat":""}},[_c(VSpacer),_c(VIcon,{staticClass:"mr-2"},[_vm._v("mdi-clipboard-list-outline")]),_c('span',[_vm._v("Encuestas")]),_c(VSpacer)],1),_c(VTabsItems,{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c(VTabItem,{attrs:{"value":"listado"}},[_c(VContainer,[_c('listado-encuestas')],1)],1),(_vm.app === 'campus')?_c(VTabItem,{attrs:{"value":"compartido"}},[_c('encuestas-compartidas')],1):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }