import { VCard } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VCard,{attrs:{"flat":""}},[_c(VList,[_vm._l((_vm.alumnosPromedio),function(item,index){return [_c(VListItem,{key:`item-${index}`},[_c(VListItemIcon,{staticClass:"numero-lista"},[_c('strong',[_vm._v(_vm._s(index+1))])]),(!_vm.sesionCursoAlumno && !_vm.sesionCursoPadre)?_c(VListItemAvatar,{staticClass:"avatar-lista"},[(item.alumno.avatar)?_c('img',{attrs:{"src":`${_vm.imageURL}/icon/${item.alumno.avatar}`}}):_c(VIcon,{attrs:{"size":"45"}},[_vm._v("mdi-account-circle")])],1):_vm._e(),_c(VListItemContent,[(!_vm.sesionCursoAlumno && !_vm.sesionCursoPadre)?_c(VListItemTitle,[_vm._v(_vm._s(item.alumno.nombreCompleto))]):_c(VListItemTitle,[_vm._v(_vm._s(item.periodo.text))])],1),(!_vm.loading)?_c(VListItemAction,{on:{"click":function($event){return _vm.verAsistenciasMes(item.promedio.asistenciasMes)}}},[_c(VChip,{staticStyle:{"width":"60px"},attrs:{"small":"","outlined":"","label":"","color":item.promedio.color}},[_vm._v(" "+_vm._s(item.promedio.asistencias)+" / "+_vm._s(item.promedio.total)+" ")])],1):_vm._e(),(!_vm.loading)?_c(VListItemAction,{staticStyle:{"padding-right":"50px"}},[_c(VProgressCircular,{attrs:{"value":item.promedio.promedio,"color":item.promedio.color}},[_c('span',{staticStyle:{"font-size":"10px"}},[_vm._v(_vm._s(item.promedio.promedio))])])],1):_vm._e(),(_vm.loading)?_c(VListItemAction,{staticStyle:{"padding-right":"50px"}},[_c(VProgressCircular,{attrs:{"indeterminate":"","color":"grey"}},[_c('span',{staticStyle:{"font-size":"10px"}},[_vm._v("SC")])])],1):_vm._e()],1),_c(VDivider,{key:index,staticClass:"mx-3"})]})],2)],1),(_vm.mostrarAsistencias == true)?_c('asistenciasAlumnoMesComponent',{attrs:{"mostrar":_vm.mostrarAsistencias,"asistenciasMes":_vm.asistenciasMes},on:{"cerrar":function($event){_vm.mostrarAsistencias = false}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }