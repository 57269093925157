import { render, staticRenderFns } from "./documentoItem.vue?vue&type=template&id=131359bc&scoped=true"
import script from "./documentoItem.vue?vue&type=script&lang=js"
export * from "./documentoItem.vue?vue&type=script&lang=js"
import style0 from "./documentoItem.vue?vue&type=style&index=0&id=131359bc&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "131359bc",
  null
  
)

export default component.exports