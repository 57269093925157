<template>
  <div>
    <v-card outlined>
      <v-toolbar dense flat>
        <v-spacer></v-spacer>
        <v-toolbar-title class="text-h6">Descripción</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-tooltip left>
          <template #activator="{ on, attrs }">
            <v-btn icon small color="primary" v-on="on" v-bind="attrs" @click="edit = true">
              <v-icon small>mdi-pencil</v-icon>
            </v-btn>
          </template>
          <span>Editar descripción</span>
        </v-tooltip>
      </v-toolbar>
      <v-divider class="mx-3"></v-divider>
      <v-card-text v-if="edit">
        <editorTexto ref="editorTexto" :texto="descripcionLocal"></editorTexto>
        <div class="d-flex justify-end mt-2">
          <v-btn
            small
            color="primary"
            outlined
            class="mr-2"
            @click="edit = false"
            >Cancelar</v-btn
          >
          <v-btn small color="primary" @click="save()">
            <v-icon small class="mr-2"> mdi-content-save </v-icon>
            <span> Guardar </span>
          </v-btn>
        </div>
      </v-card-text>
      <v-card-text v-else>
        <span v-if="descripcionCut" v-html="descripcionCut"></span>
        <v-chip v-else label>
          <v-icon small class="mr-2">mdi-information-outline</v-icon>
          <span>No se ha agregado la descripción de esta planeación.</span>
        </v-chip>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import editorTexto from "../../contenidos/editorTexto.vue";

export default {
  components: {
    editorTexto,
  },

  props: {
    descripcion: {
      type: String,
    },
  },

  data() {
    return {
      edit: false,
      descripcionLocal: this.descripcion,
    };
  },

  computed: {
    descripcionCut() {
      return !!this.descripcionLocal && this.descripcionLocal != "<p></p>"
        ? this.descripcionLocal
        : "";
    },
  },

  methods: {
    save() {
      const descripcion = this.$refs.editorTexto.obtenerTexto();
      const payload = { descripcion };
      this.$emit("actualizarPlaneacion", payload);
      this.descripcionLocal = descripcion;
      this.edit = false;
    },
  },
};
</script>
