import { VChip } from 'vuetify/lib/components/VChip';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VListItem,[(!_vm.ocultarIcono)?_c(VListItemIcon,[_c(VIcon,{attrs:{"color":"primary"}},[_vm._v(_vm._s(_vm.icon))])],1):_vm._e(),_c(VListItemContent,[_c(VListItemTitle,[(_vm.actividad.visible && !_vm.sesionCursoPadre)?_c('a',{staticClass:"link",attrs:{"href":"javascript:void(0);"},on:{"click":function($event){return _vm.verActividad()}}},[(_vm.actividad.transversal)?_c('span',[_vm._v(_vm._s(_vm.actividad.transversal.nombre))]):_c('span',[_vm._v(_vm._s(_vm.actividad.nombre))])]):_c('span',[_vm._v(_vm._s(_vm.actividad.nombre))])]),(!_vm.ocultarCriterio)?_c(VListItemSubtitle,[(_vm.vista == 'general')?_c('span',[_vm._v("Módulo: "+_vm._s(_vm.actividad.cursoModulo.nombre)+".")]):_vm._e(),(_vm.actividad.criterio)?_c('span',[_vm._v(" "+_vm._s(_vm.actividad.criterio.nombre)+" ")]):_vm._e()]):_vm._e(),_c(VListItemSubtitle,[(_vm.vista == 'general')?_c('p',[_vm._v(" Ponderación: "+_vm._s(_vm.actividad.ponderacion)+" ")]):_vm._e(),(_vm.vista == 'general')?_c('p',[_vm._v("Puntos: "+_vm._s(_vm.actividad.puntos)+".")]):_vm._e()]),_c(VListItemSubtitle,[(
          !_vm.actividad.examenAplicacion &&
          !_vm.actividad.calificacionAlumno &&
          !_vm.actividad.documentoAlumno &&
          _vm.actividad.tipo != 1 &&
          _vm.actividad.visible
        )?_c('actividadDisponibleChip',{attrs:{"disponible":_vm.actividad.disponible,"entregaExt":_vm.actividad.entregaExt}}):_vm._e(),(_vm.actividad.tipo == 1 && !_vm.actividad.calificacionAlumno)?_c('span',[_c(VChip,{staticStyle:{"color":"white"},attrs:{"x-small":"","color":"amber darken-2"}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-clock")]),_vm._v("Sin calificar")],1)],1):_vm._e(),(_vm.actividad.tipo == 1 && _vm.actividad.calificacionAlumno)?_c('span',[_c(VChip,{staticStyle:{"color":"white"},attrs:{"x-small":"","color":"green darken-2"}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-check")]),_vm._v("Calificado")],1)],1):_vm._e(),(_vm.documentoActual)?_c('span',[(
            !_vm.documentoActual.calificacion && _vm.documentoActual.calificacion != 0
          )?[_c(VChip,{staticStyle:{"color":"white"},attrs:{"x-small":"","color":"amber darken-2"}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-clock")]),_vm._v("En espera de revisión")],1)]:_vm._e(),(
            typeof _vm.documentoActual.calificacion == 'number' &&
            !_vm.documentoActual.reenvio
          )?[_c(VChip,{staticStyle:{"color":"white"},attrs:{"x-small":"","color":"green darken-2"}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-check")]),_vm._v("Calificado")],1)]:_vm._e(),(_vm.documentoActual.reenvio)?[_c(VChip,{staticStyle:{"color":"white"},attrs:{"x-small":"","color":"orange darken-3"}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-alert")]),_vm._v("Reenvío solicitado")],1)]:_vm._e()],2):_vm._e(),(!_vm.actividad.visible)?_c('span',[_c(VChip,{attrs:{"x-small":"","outlined":"","color":"grey darken-2"}},[_vm._v(_vm._s(_vm.leyenda))])],1):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }