<template>
  <v-dialog v-model="mostrar" :max-width="1000" persistent>
    <v-card :loading="loading">
      <v-toolbar color="primary" dark dense flat class="text-h6">
          <v-toolbar-title>Agregar diplomas</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
            <v-btn fab icon x-small @click="cerrarForm()">
                <v-icon color="white">mdi-close</v-icon>
            </v-btn>
            </v-toolbar-items>
      </v-toolbar>

      <v-container>
        <v-row>
          <v-col md="12" sm="12">
            <v-container>
              <selectorAreas
                :areas="areas"
                :menu="false"
                v-model="areaSeleccionada"
                @change="getDiplomas()"
              />
            </v-container>
          </v-col>
        </v-row>

        <v-card-text v-if="!diplomas.length && !loading"
          >No se han subido diplomas.</v-card-text
        >

        <template v-else-if="loading">
          <v-card flat>
            <v-container>
              <v-skeleton-loader type="paragraph@2"></v-skeleton-loader>
            </v-container>
            <v-divider class="mx-3"></v-divider>
            <v-skeleton-loader type="list-item-two-line@5"></v-skeleton-loader>
          </v-card>
        </template>
        <template v-else-if="diplomas.length > 0">
          <v-container fluid>
            <v-col md="12" sm="12">
              <tablaDiplomas
                :loading="loading"
                :datos="diplomasLocal"
                :pagination="pagination"
                :page="pagination.page"
                :itemsPerPage="10"
                :totalItems="pagination.itemsLength"
                :headers="headers"
                hideSearch
                @update-page="updatePageTable"
                @pagina-change="paginaCambio"  
              >
                <template #item-diploma="{ value }" >
                  <div @click="agregarDiploma(value)" style="cursor:pointer">
                    <v-icon color="primary">mdi mdi-certificate</v-icon>
                    <a style="pointer-events: none;">{{ value.nombre }} </a>

                    <v-list-item-subtitle  style="margin-left: 15px; font-family:Arial;" v-if="value.descripcion && value.descripcion != '' && !value.vermas" >
                      {{value.descripcion.substring(0,100)}}<a v-if="verMas(value.descripcion)" @click="value.vermas=true" class="link">... ver más</a>
                    </v-list-item-subtitle  >
                      
                    <v-card-text class="mx-auto text-justify read-more-target" style="font-family:Arial;" max-width="700" v-if="value.vermas">
                      {{value.descripcion}}<a @click="value.vermas=false" class="link"> Ver menos</a>
                    </v-card-text>
                  </div>
                </template>
                <template #item-usuario="{ value }" >
                  <h5 style="text-align: center"> {{ value }}</h5>
                </template>      
              </tablaDiplomas>
            </v-col>
          </v-container>
        </template>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>

import { mapGetters } from "vuex";
import { modificarDiplomasArrayService } from "./configuracion.service";
import tablaDiplomas from "../../widgets/datatable.vue";
import selectorAreas from "../../widgets/selectorAreas.vue";
import { getAreasService, getDiplomasService } from "../../diplomas/diplomas.service";

export default {
  props: {
    tipoCurso: String,
    mostrar: Boolean,
    idCurso: String,
    diplomasActuales: Array,
  },
  components: {
    tablaDiplomas,
    selectorAreas,
  },
  computed: {
    ...mapGetters(["sessionToken", "app" ]),
    diplomasLocal(){
      let diplomasIds = this.diplomasActuales.map((d) => {return d._id});
      let diplomas = this.diplomas.filter((d) => !diplomasIds.includes(d.diploma._id))
      return diplomas;
    }
  },
  data: () => ({
    loading: false,
    items:[],
    dataLocal: [],
    pagination: {
        page: 1,
        itemsPerPage: 10,
        pageStart: 0,
        pageStop: 0,
        pageCount: 0,
        itemsLength: 0,
      },
    areas: [],
    areaSeleccionada: "",
    diplomas: [],
    headers: [
        { text: "", value: "numero", sortable: false, filterable: false, width: "30px"},
        { text: "Nombre", value: "diploma", sortable: false, filterable: false }
      ],
  }),
  created() {
    if (this.sessionToken) {
      this.getAreas();
    }
  },
  methods: {
    verMas(descripcion){
      return descripcion != null & descripcion.length > 50 ? true : false
    },
    async getAreas() {
      this.loading = true;
      try {
        const serverResponse = await getAreasService();
        this.loading = false;

        this.$validateResponse(serverResponse);

        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else if (serverResponse.areas.length) {
          this.areas = serverResponse.areas;
          this.areaSeleccionada = serverResponse.areas[0]._id;
          this.logs = serverResponse.logs;
          await this.getDiplomas();
        }else{
          this.areas = [];
          this.diplomas = [];
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
    async getDiplomas() {
      try {
        this.loading = true;
        const serverResponse = await getDiplomasService(this.areaSeleccionada, this.pagination.itemsPerPage, this.pagination.page);
        this.loading = false;
        this.$validateResponse(serverResponse);
        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else {
          let numero = 1;
          for (const diploma of serverResponse.diplomas) {
            let item = {
              numero: numero++,
              diploma: {
                _id: diploma._id,
                area: diploma.area,
                nombre: diploma.nombre,
                descripcion: diploma.descripcion,
                empresa: diploma.empresa,
                escuela: diploma.escuela,
                archivo: diploma.archivo,
                ruta: diploma.ruta
              }
            };
            this.diplomas.push(item);
          }
          this.pagination.itemsLength = serverResponse.total;
          this.pagination.pageStop = serverResponse.pageStop;
          this.pagination.pageStart = serverResponse.pageStart;
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
    updatePageTable(value) {
      this.pagination.pageCount += 1;
      this.pagination.page = value.page;
      this.getRecursos();
    },

    paginaCambio(pagina) {
      this.pagination.page = pagina;
      this.getRecursos();
    },

    limpiarPagination() {
      this.pagination.page = 1;
      this.pagination.pageStart = 0;
      this.pagination.pageStop = 0;
      this.pagination.pageCount = 1;
      this.pagination.itemsLength = 0;
    },
    async agregarDiploma(diploma) {
      try {
        this.loading = true;
        const serverResponse = await modificarDiplomasArrayService( this.tipoCurso, diploma._id, this.idCurso );
        this.loading = false;

        if (!serverResponse.ok){
          this.$systemErrorMessage(serverResponse.mensaje);
        }
        else {
          this.$emit('diplomaAgregado', diploma);
          this.$emit("cancelar");
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
    cerrarForm() {
      this.$emit("cancelar");
    },
  },
};
</script>