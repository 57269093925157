import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VCard,{attrs:{"outlined":""}},[_c(VToolbar,{attrs:{"dense":"","flat":""}},[_c(VSpacer),_c(VToolbarTitle,{staticClass:"text-h6"},[_vm._v("Módulos")]),_c(VSpacer),_c(VTooltip,{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":"","small":"","color":"primary"},on:{"click":function($event){_vm.showCreacion = true}}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-text-box-plus")])],1)]}}])},[_c('span',[_vm._v("Agregar módulo")])])],1),_c(VDivider,{staticClass:"mx-3"}),_c(VCardText,[(!_vm.modulosLocal.length)?_c(VChip,{attrs:{"label":""}},[_c(VIcon,{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("mdi-information-outline")]),_c('span',[_vm._v("No se han agregado módulos.")])],1):_c('div',[_c('draggable',_vm._b({attrs:{"element":"div","ghost-class":"ghost","handle":".handle"},on:{"change":_vm.cambiarOrden},model:{value:(_vm.modulosLocal),callback:function ($$v) {_vm.modulosLocal=$$v},expression:"modulosLocal"}},'draggable',_vm.dragOptions,false),_vm._l((_vm.modulosLocal),function(modulo){return _c('planeacionModuloItem',{key:modulo._id,attrs:{"modulo":modulo,"idPlaneacion":_vm.idPlaneacion,"customGoToModulo":_vm.customGoToModulo},on:{"goToModulo":_vm.goToModulo}})}),1)],1)],1)],1),(_vm.showCreacion)?_c('crear-modulo-dialog',{attrs:{"show":_vm.showCreacion,"idPlaneacion":_vm.idPlaneacion},on:{"close":function($event){_vm.showCreacion = false},"moduloSaved":_vm.moduloSaved}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }