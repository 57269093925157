
<template>
<div>
    <v-dialog v-if="noDisponible" v-model="mostrar" :max-width="500" persistent> 
        <v-card >
          <v-toolbar color="primary" dark dense flat class="text-h6">
           Vista previa no disponible
            <v-spacer></v-spacer>
              <v-btn fab icon @click="$emit('cerrar')" x-small><v-icon color="white">mdi-close</v-icon> </v-btn>
          </v-toolbar>
            <v-container grid-list-md>
                <i>El archivo </i><b>{{ nombreArchivo }}</b><i> no puede ser previsualizado.</i>
            </v-container>
            <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn small color="secondary" outlined @click="$emit('cerrar')">Cancelar</v-btn>
            <v-btn small color="primary" @click="download()">Descargar</v-btn>
        </v-card-actions>
        </v-card>
    </v-dialog>
    <v-dialog v-else v-model="mostrar" fullscreen hide-overlay >
        <v-card  :class="{ImgSize: visualizador == 'img', ImgSize: visualizador == 'video', Size: visualizador != 'img'}">
            <v-toolbar color="primary" dark class="text-h6">
               <v-btn v-if="!restringir" text @click="download()"> 
                <v-icon small style="padding-right: 5px;">mdi-file-download-outline</v-icon>
                Descargar
               </v-btn> 
               <v-spacer></v-spacer>
               <v-toolbar-title>{{nombreArchivo}}</v-toolbar-title>
               <v-spacer></v-spacer>
               <v-btn text @click="$emit('cerrar')">Cerrar</v-btn>
            </v-toolbar>
            <img v-if="visualizador == 'img'" class="viewer-img" :src="url"/>
            <iframe v-if="visualizador == 'google'" class="viewer-doc" :src="`https://docs.google.com/gview?url=${this.url}&embedded=true`"></iframe>
            <iframe v-if="visualizador == 'pdf'" class="viewer-doc" :src="`${this.httpH5pURL}/pdf/${this.idRecurso}`" ></iframe>
            <div class="viewer-video">
                <video-player
                    v-if="visualizador == 'video'"
                    ref="videoPlayer"
                    :options="playerOptions"
                    :playsinline="true"
                />
            </div>
        </v-card> 
    </v-dialog>
</div>
</template>

<script>
import { mapGetters } from "vuex";
import "video.js/dist/video-js.css";
import { videoPlayer } from "vue-video-player";
export default {
    name: 'fileViewer',
    props: {
        mostrar: { type: Boolean, default: false },
        idRecurso: { type: String, default: () => '' },
        tipoArchivo: { type: String, default: () => ''},
        nombreArchivo: { type: String, default: () => '' },
        url: { type: String, default: () => '' },
        restringir: { type: Boolean, default: false },
    },
    components: { videoPlayer },
    computed: {
        ...mapGetters(["httpH5pURL"]),
    },
    data() {
        return {
            visualizador:'',
            noDisponible:false,
            playerOptions: {
                responsive: true,
                muted: false,
                language: "es",
                playbackRates: [0.5, 1.0, 1.5, 2.0],
                sources: [{
                    type: "video/mp4",
                    src: "https://www.youtube.com/watch?v=fEa84OPxLY4&ab_channel=AGNUS",
                    }],
            },  
      
        };
    },
    
    created(){
        if(this.tipoArchivo.includes('application/pdf')) this.visualizador = 'pdf'
        else if(this.tipoArchivo.includes('image')) this.visualizador = 'img'
        else if(this.tipoArchivo.includes('vnd.openxmlformats-officedocument')) this.visualizador = 'google'
        else if(this.tipoArchivo.includes('video')){
            this.visualizador = 'video'; 
            this.playerOptions.sources[0].src = this.url;
        }         
        else this.noDisponible = true;
    },

    methods:{
        async download() {
            try {
                const url = this.url;
                const link = document.createElement("a");
                link.href = url;
                link.target = "_blank";
                link.setAttribute("download", '');

                link.click();
                link.remove();

                this.$emit('cerrar');
                
            } catch (error) {
                this.loading = false;
                this.$appErrorMessage();
            }
        }
    }
}
</script>

<style scoped>
    .ImgSize{
        width: 100%;
    }
    .Size{
        width: 100%;
        height: 100%;
    }
    .viewer-video{
        display: flex;
        justify-content: center;
        align-items: center;
   }
    .viewer-doc{
        width: 100%;
        height: 100%;
    }
    .viewer-img{
        display: block;
        margin: 50px auto 50px auto;

    }
    .button {
        text-transform: lowercase;
        color: 'secondary'
    }
    .button:hover {
        animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
        transform: translate3d(0, 0, 0);
        perspective: 1000px;
    }
    @keyframes shake {
    10%, 90% {
        transform: translate3d(-1px, 0, 0);
    }
    20%, 80% {
        transform: translate3d(2px, 0, 0);
    }
    
    30%, 50%, 70% {
        transform: translate3d(-2px, 0, 0);
    }
    40%, 60% {
        transform: translate3d(2px, 0, 0);
    }
}
</style>