<template>
  <div>
    <v-list-item dense class="d-flex justify-center">
      <v-list-item-action class="mx-2">
        <v-btn color="primary" outlined small>
          {{ calificacion }}
        </v-btn>
      </v-list-item-action>
      
        <v-btn v-if="intentos.length > 0"  x-small text @click="verIntentos = true">
          {{ intentos.length }} intentos
        </v-btn>
    </v-list-item>

    <v-dialog
      v-if="intentos.length > 0"
      persistent
      v-model="verIntentos"
      max-width="400"
    >
      <v-card>
        <v-container>
          <v-list>
            <v-list-item v-for="intento in intentos" :key="intento._id">
              <v-list-item-avatar>
                <v-avatar color="secondary" size="36">
                  <span class="white--text text-strong">{{
                    intento.calificacion
                  }}</span>
                </v-avatar>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title
                  >Intento {{ intento.numero }}</v-list-item-title
                >
                <v-list-item-subtitle>{{ intento.observaciones }}</v-list-item-subtitle> 
                <v-list-item-subtitle>{{ intento.fecha }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-container>
        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" small outlined @click="verIntentos = false">
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { DateTime } from "luxon";

export default {
  name: "calInteractivoItem",
  props: {
    idCurso: { type: String || null, default: null },
    idActividad: { type: String || null, default: null },
    tipoActividad: { type: Number, default: 1 },
    alumno: { type: Object, default: () => {} },
    nombre: String,
  },
  components: {},
  computed: {
    ...mapGetters(["vistaSeguimiento"]),
    calificacion() {
      if (this.alumno.calificaciones.length > 0)
        return this.alumno.calificaciones[0].calificacion;
      return 0;
    },
    intentos() {
      let intentos = [];
      this.alumno.intentos.forEach((intento) => {
        intentos.push({
          calificacion: intento.calificacion,
          observaciones: intento.observaciones,
          numero: intento.numero,
          fecha: DateTime.fromISO(intento.fecha).toLocaleString(
            DateTime.DATE_FULL
          ),
        });
      });
      return intentos;
    },
  },
  data() {
    return {
      loading: false,
      verIntentos: false,
    };
  },
  methods: {},
};
</script>
