<template>
  <v-container>
    <v-card outlined>
      <v-toolbar dense color="#254b72" dark flat style="height: 45px;">
        <strong>Archivos: {{ archivosLocal.length }}</strong>
      </v-toolbar>

      <v-card outlined>
        <v-data-table
          :headers="headers"
          :items="archivosLocal"
          :items-per-page="archivosLocal.length"
          hide-default-footer
          class="elevation-0"
        >
          <template #body="{ items }">
            <tbody>
              <tr v-for="(item, index) in items" :key="index">
                <!-- ARCHIVO -->
                <td>
                  <v-list-item>
                    <v-list-item-avatar :color="item.color">
                      <v-icon color="white">{{ item.icon }}</v-icon>
                    </v-list-item-avatar>

                    <v-list-item-content style="width: 200px">
                      <v-list-item-title>
                        <v-tooltip left>
                          <template v-slot:activator="{ on }">
                            <v-icon
                              v-on="on"
                              small
                              @click="descargarArchivo(item)"
                              color="grey"
                              style="margin-right: 5px"
                              >mdi-download</v-icon
                            >
                          </template>
                          <span>Descargar</span>
                        </v-tooltip>
                        <a class="link" @click="mostrarArchivo(item)">{{
                          item.nombre
                        }}</a>
                      </v-list-item-title>

                      <v-list-item-subtitle>
                        Extensión:
                        <v-chip class="mx-1" x-small label>{{
                          item.extension
                        }}</v-chip>
                      </v-list-item-subtitle>
                      <v-list-item-subtitle
                        >Tamaño: {{ item.size }}</v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </v-list-item>
                </td>

                <!-- ENTREGADO -->
                <td class="text-center">
                  <div>Entregado {{ item.fechaEntrega }}</div>
                  <v-chip
                    v-if="item.entregaExt"
                    label
                    small
                    color="#F57C00"
                    outlined
                    >Entrega extemporánea.</v-chip
                  >
                </td>

                <!-- CALIFICACIÓN -->
                <td>
                  <v-chip
                    v-if="item.calificacion === null"
                    class="d-flex justify-center"
                    color="orange darken-2"
                    outlined
                    >Sin calificación</v-chip
                  >

                  <v-list-item v-else class="d-flex justify-center">
                    <v-chip
                      color="primary"
                      label
                      outlined
                      style="padding-right: 15px"
                      >{{ item.calificacion }}</v-chip
                    >
                    <v-card-text
                      v-if="item.observaciones"
                      style="margin-left: 0px"
                    >
                      <verMasComponent
                        :texto="item.observaciones"
                        :longitud="50"
                      />
                    </v-card-text>
                  </v-list-item>
                </td>

                <!-- REENVÍO -->
                <td>
                  <v-list-item v-if="item.index === 1" style="padding: 0px">
                    <v-list-item-content class="text-center">
                      <v-list-item-title
                        v-if="
                          item.calificacion !== null &&
                          item.calificacion !== 100
                        "
                        >Solicitar revisión y reenvío del
                        archivo</v-list-item-title
                      >
                      <v-list-item-subtitle v-if="item.calificacion === null"
                        ><i>
                          Para solicitar reenvío es necesario <br />
                          agregar una calificación
                        </i>
                      </v-list-item-subtitle>
                      <v-list-item-subtitle class="d-flex justify-center">
                        <v-switch
                          v-if="
                            item.calificacion != null &&
                            item.calificacion !== 100
                          "
                          v-model="item.reenvio"
                          inset
                          color="green"
                          @click="solicitarRevision(item)"
                        ></v-switch>
                        <i v-else
                          >No se puede solicitar reenvío cuando <br />
                          la calificación es de 100</i
                        >
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </v-card>
    </v-card>

    <viewerComponent
      v-if="verArchivo.mostrar"
      :mostrar="verArchivo.mostrar"
      :idRecurso="verArchivo.idRecurso"
      :nombreArchivo="verArchivo.filename"
      :url="verArchivo.url"
      :tipoArchivo="verArchivo.tipo"
      :permisos="verArchivo.permisos"
      @cerrar="cancelarVerArchivo"
    />
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment-timezone";
import numeral from "numeral";
import { getTypeFormatByName } from "../../../../../helpers/utilerias";

import viewerComponent from "../../../../widgets/fileViewer.vue";
import verMasComponent from "../../../../widgets/textSubtract.vue";

export default {
  name: "datosArchivos",
  props: {
    loading: { type: Boolean, default: false },
    archivos: { type: Array, default: () => null },
    disponible: { type: Object, default: () => {} },
  },

  components: {
    verMasComponent,
    viewerComponent,
  },

  data() {
    return {
      headers: [
        {
          text: "Archivo",
          value: "archivo",
          sortable: false,
          filterable: false,
          divider: true,
          align: "center",
          width: "150px",
        },
        {
          text: "Fecha subida",
          value: "entregado",
          sortable: false,
          filterable: false,
          divider: true,
          align: "center",
          width: "200px",
        },
        {
          text: "Calificación",
          value: "calificacion",
          sortable: false,
          filterable: false,
          divider: true,
          align: "center",
          width: "250px",
        },
        {
          text: "Reenvío",
          value: "reenvio",
          sortable: false,
          filterable: false,
          divider: true,
          align: "center",
          width: "100px",
        },
      ],
      verArchivo: {
        mostrar: false,
        idRecurso: null,
        filename: null,
        url: null,
        tipo: null,
        permisos: {
          descarga: true,
        },
      },
    };
  },
  computed: {
    ...mapGetters(["httpURL", "documentsURL", "timezone"]),
    archivosLocal() {
      let archivos = [];
      let contador = 1;
      // FALTA AGREGAR ENTREGA EXTEMPORANEA
      this.archivos.forEach((archivo) => {
        let item = {
          index: contador,
          _id: archivo._id,
          nombre: archivo.file.name,
          extension: getTypeFormatByName(archivo.file.name),
          fechaEntrega: moment(archivo.fecha).locale("es").fromNow(),
          size: numeral(archivo.file.size).format("0.00b"),
          url: archivo.file.path,
          calificacion: archivo.revision.calificacion,
          observaciones: archivo.revision.observaciones,
          reenvio: archivo.revision.reenvio,
          entregaExt: this.disponible.disponible
            ? false
            : moment.tz(archivo.fecha, this.timezone) >
              moment.tz(this.disponible.fin, this.timezone),
        };

        // ITEM
        let file = archivo.file.name.split(".");
        switch (file[file.length - 1].toLowerCase()) {
          case "pdf": {
            item.icon = "mdi-file-pdf-box";
            item.color = "red";
            item.tipo = "PDF";
            item.tipoArchivo = "application/pdf";
            break;
          }
          case "doc": {
            item.icon = "mdi-file-word";
            item.color = "light-blue darken-2";
            item.tipo = "Word";
            break;
          }
          case "docx": {
            item.icon = "mdi-file-word";
            item.color = "light-blue darken-2";
            item.tipo = "Word";
            break;
          }
          case "xls": {
            item.icon = "mdi-file-excel";
            item.color = "green darken-2";
            item.tipo = "Excel";
            break;
          }
          case "xlsx": {
            item.icon = "mdi-file-excel";
            item.color = "green darken-2";
            item.tipo = "Excel";
            break;
          }
          case "ppt": {
            item.icon = "mdi-file-powerpoint";
            item.color = "orange darken-3";
            item.tipo = "PPT";
            break;
          }
          case "pptx": {
            item.icon = "mdi-file-powerpoint";
            item.color = "orange darken-3";
            item.tipo = "PPT";
            break;
          }
          case "zip": {
            item.icon = "mdi-file-powerpoint";
            item.color = "yellow darken-2";
            item.tipo = "ZIP";
            break;
          }
          case "jpg": {
            item.icon = "mdi-file-image-outline";
            item.color = "yellow darken-2";
            item.tipo = "Imagen";
            item.tipoArchivo = "image";
            break;
          }
          case "jpeg": {
            item.icon = "mdi-file-image-outline";
            item.color = "indigo darken-2";
            item.tipo = "Imagen";
            break;
          }
          case "png": {
            item.icon = "mdi-file-image-outline";
            item.color = "indigo darken-2";
            item.tipo = "Imagen";
            break;
          }
          case "gif": {
            item.icon = "mdi-file-image-outline";
            item.color = "indigo darken-2";
            item.tipo = "Imagen";
            break;
          }
          default:
            item.icon = "mdi-file";
            item.color = "grey";
            item.tipo = "Otro";
            break;
        }

        //item.icon = 'mdi-file-alert';
        //item.color = 'grey darken-2';
        archivos.push(item);
        contador++;
      });

      return archivos;
    },
  },
  methods: {
    async descargarArchivo(item) {
      try {
        const url = `${this.documentsURL}/${item.url}`;
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "");
        link.click();
        link.remove();
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
    mostrarArchivo(item) {
      const url = `${this.documentsURL}/${item.url}`;
      this.verArchivo.mostrar = true;
      this.verArchivo.idRecurso = item._id;
      this.verArchivo.filename = item.nombre;
      this.verArchivo.url = url;
      this.verArchivo.tipo = item.tipoArchivo;
    },
    cancelarVerArchivo() {
      this.verArchivo.mostrar = false;
      this.verArchivo.idRecurso = null;
      this.verArchivo.filename = null;
      this.verArchivo.url = null;
      this.verArchivo.tipo = null;
    },
    solicitarRevision(item) {
      // #TODO: Preguntar sobre la validación de solo lectura
      this.$emit("solicitarRevision", { reenvio: item.reenvio });
    },
  },
};
</script>

<style scoped>
table tr > td {
  border-right: 1px solid #dddddd;
}
</style>
