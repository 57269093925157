import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{attrs:{"color":"#eceff1","flat":""}},[_c(VCardText,[(_vm.pagina.tipo === 'lectura')?_c('div',[_c(VToolbar,{staticClass:"mb-3",attrs:{"dense":"","color":"primary","dark":"","flat":"","shaped":""}},[_c(VToolbarTitle,[_vm._v("Lectura")])],1),_c('div',{staticClass:"ck-content mb-3",staticStyle:{"overflow":"hidden"},domProps:{"innerHTML":_vm._s(_vm.pagina.lectura)}})],1):_vm._e(),_c('div',[_c(VToolbar,{staticClass:"mb-3",attrs:{"dense":"","color":"secondary","dark":"","flat":"","shaped":""}},[_c(VToolbarTitle,[_vm._v("Reactivos")])],1),(_vm.loading)?_c('loading-reactivos'):(!_vm.reactivos.length)?_c('span',[_vm._v(" Página sin reactivos. ")]):_c('div',_vm._l((_vm.reactivos),function(reactivo){return _c('reactivo-aplicacion',{key:reactivo._id,staticClass:"mt-2",attrs:{"reactivo":reactivo,"soloLectura":_vm.soloLectura,"aplicacionFinalizada":_vm.aplicacionFinalizada,"busGuardado":_vm.busGuardado,"esAplicacionAlumno":_vm.esAplicacionAlumno}})}),1)],1),_c('div',{staticClass:"mt-3"},[_c(VBtn,{attrs:{"color":"primary","small":""},on:{"click":function($event){return _vm.$emit('siguientePagina')}}},[_c('span',[_vm._v("Siguiente")]),_c(VIcon,{staticClass:"ml-2",attrs:{"small":""}},[_vm._v("mdi-chevron-right")])],1)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }