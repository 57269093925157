<template>
  <v-dialog v-model="mostrar" width="40%" :fullscreen="showFullscreen" persistent>
    <v-card>

      <v-toolbar dense color="primary" dark flat>
          <v-btn v-if="!calificacionModificar && !esComentario" :disabled="disableBtnPrev" icon @click="btnPrev">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <strong v-if="!calificacionModificar"> Agregar calificación </strong>
          <strong v-else> Modificar calificación </strong>
          <v-spacer></v-spacer>
          <v-btn v-if="!calificacionModificar && !esComentario" :disabled="disableBtnNext" icon @click="btnNext">
            <v-icon>mdi-arrow-right</v-icon>
          </v-btn>
      </v-toolbar>

      
      <v-row no-gutters>
        
        <v-col :cols="showFullscreen ? 4 : 12">
          <div :class="{ 'cardFixed': showFullscreen }">
            <!-- DATOS DE USUARIOS -->
            <datosUsuariosComponent 
              :loading="loading"
              :alumno="alumno"
              :equipo="equipo"
            />

            <!-- DATOS CALIFICACIÓN -->
            <datosCalificacionComponent
              :loading="loading"
              :instrumento="instrumento"
              :instrumentoCalificacion="calificacionInstrumento.calificacion"
              :calificacionModificar="calificacionModificar"
              :limpiarDatos="alumno || equipo"
              @cancelar="$emit('cancelar')"
              @agregarCalificacion="agregarCalificacion"
            />
          </div>
        </v-col>

        <v-col v-if="showFullscreen" class="cardLeft" cols="8">
          <!-- INSTRUMENTO -->
          <v-container class="cardLeft">
            <v-card outlined>
              <v-toolbar dense color="#254b72" dark flat style="height: 45px;">
                <strong>Instrumento</strong>
              </v-toolbar>

              <listaCotejoActividadViewComponent
                v-if="tipoInstrumento === 'listaCotejo'"
                :esCalificacion="true"
                :instrumentoActividad="instrumento"
                :selectedCalificacion="calificacionInstrumento.indicadores"
                @calificacionActualizada="calificacionActualizada"
              />

              <rubricaActividadViewComponent 
                v-if="tipoInstrumento === 'rubrica'"
                :esCalificacion="true"
                :instrumentoActividad="instrumento"
                :selectedCalificacion="calificacionInstrumento.indicadores"
                @calificacionActualizada="calificacionActualizada"
              />
            
            </v-card>
          </v-container> 
        </v-col>

    </v-row>

    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import datosUsuariosComponent from "./datosUsuarios.vue";
import datosCalificacionComponent from "./datosCalificacion.vue";

import listaCotejoActividadViewComponent from "../../../../instrumentos/actividad/listaCotejoActividad.view.vue";
import rubricaActividadViewComponent from "../../../../instrumentos/actividad/rubricaActividad.view.vue";

import {
  agregarCalificacionDirectaService,
  modificarCalificacionDirectaService,
} from "../entregas.service";

export default {
  name: 'agregarCalificacionDirecta',

  props: {
    mostrar: { type: Boolean, default: false },
    idCurso: { type: String, default: null },
    idActividad: { type: String, default: null },
    instrumento: { type: String, default: null },
    tipoInstrumento: { type: String, default: null },
    esComentario: { type: Boolean, default: false },
    alumno: { type: Object, default: () => null },
    equipo: { type: Object, default: () => null },
    calificacionModificar: { type: Object, default: () => null },
    ultimoUsuario: { type: Number, default: 1 },
  },

  components: {
    datosCalificacionComponent,
    datosUsuariosComponent,
    listaCotejoActividadViewComponent,
    rubricaActividadViewComponent
  },

  computed: {
    ...mapGetters(["app"]),
    showFullscreen() {
      if (this.instrumento) return true;
      return false;
    },
    disableBtnPrev() {
      if (this.alumno && this.alumno.numero === 1) return true;
      if (this.equipo && this.equipo.numero === 1) return true;  
      return false;
    },
    disableBtnNext() {
      if (this.alumno && this.alumno.numero === this.ultimoUsuario) return true;
      if (this.equipo && this.equipo.numero === this.ultimoUsuario) return true;
      return false;
    }
  },

  watch: {
    alumno(value) {
      if (value && this.instrumento) this.limpiarCalificacionInstrumento();
    },
    equipo(value) {
      if (value && this.instrumento) this.limpiarCalificacionInstrumento();
    }
  },

  data () {
    return {
      loading: false,

      calificacionInstrumento: { // Guarda calificación de instrumento y muestra en datosCalificación
        calificacion: 0,
        indicadores: []
      },
    }
  },

  created() {
    if (this.instrumento) this.setDataCalificacionInstrumento();
  },

  methods: {
    setDataCalificacionInstrumento () {
        if (this.calificacionModificar) { // Valida si tiene calificacion disponible a modificar sino se limpia y deshabilita los botones
          this.calificacionInstrumento.calificacion = this.calificacionModificar.calificacion;
          this.calificacionInstrumento.indicadores = this.calificacionModificar.indicadores;
        }
    },
    // Solo aplica en caso de ser instrumento
    calificacionActualizada(data){
      this.calificacionInstrumento.calificacion = data.calificacion;
      this.calificacionInstrumento.indicadores = data.indicadores;
    },
    async agregarCalificacion(data) {
      try {
        
        let sendData = {
          calificacion: data.calificacion,
          observaciones: data.observaciones,
        };

        if (this.instrumento) sendData.indicadores = this.calificacionInstrumento.indicadores;

        if (this.alumno) sendData.idAlumno = this.alumno._id;
        if (this.equipo) sendData.idEquipo = this.equipo._id;

        this.loading = true;
        let serverResponse = null;

        if (!this.calificacionModificar) {
          serverResponse = await agregarCalificacionDirectaService(
            this.idCurso,
            this.idActividad,
            sendData
          );
        } else {
          serverResponse = await modificarCalificacionDirectaService(
            this.idCurso,
            this.idActividad,
            this.calificacionModificar.idCalificacion,
            sendData
          );
        }

        this.loading = false;
        if (!serverResponse.ok) 
          this.$systemErrorMessage(serverResponse.mensaje);
        else {
          if (!this.calificacionModificar)
            this.$emit("calificacionAgregada", serverResponse.calificacion);
          else
            this.$emit("calificacionModificada", serverResponse.calificacion);
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
    limpiarCalificacionInstrumento() {
      this.calificacionInstrumento.calificacion = 0;
      this.calificacionInstrumento.indicadores = [];
    },
    btnPrev() {
      let index = 0;
      if (this.alumno) index = this.alumno.numero - 1;
      if (this.equipo) index = this.equipo.numero - 1;
      this.$emit("btnCambio", index);
    },
    btnNext() {
      let index = 0;
      if (this.alumno) index = this.alumno.numero + 1;
      if (this.equipo) index = this.equipo.numero + 1;
      this.$emit("btnCambio", index);
    },
  },


}
</script>

<style>

.cardLeft {
 padding-left: 0px;
}

.disable-instrument {
  pointer-events: none;
  opacity: 0.6;
}

.cardFixed {
  position: fixed;
  width: 33%;
}

</style>