import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"persistent":"","width":"750"},model:{value:(_vm.mostrar),callback:function ($$v) {_vm.mostrar=$$v},expression:"mostrar"}},[_c(VCard,{attrs:{"loading":_vm.loading}},[_c(VToolbar,{attrs:{"dense":"","flat":"","color":"primary","dark":""}},[_c(VToolbarTitle,[_vm._v(_vm._s(_vm.pagina ? "Modificar página" : "Agregar página"))])],1),_c(VCardText,{staticClass:"pb-0 px-4 pt-3"},[_c(VRow,{attrs:{"dense":""}},[_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c(VTextField,{attrs:{"label":"Nombre","filled":"","counter":"20","error-messages":_vm.errors.nombre},on:{"input":function($event){return _vm.validarNombre()},"blur":function($event){return _vm.validarNombre()}},model:{value:(_vm.nombre),callback:function ($$v) {_vm.nombre=$$v},expression:"nombre"}})],1),_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c(VSelect,{attrs:{"items":_vm.tipos,"label":"Tipo de página","outlined":"","item-text":"text","item-value":"value"},model:{value:(_vm.tipo),callback:function ($$v) {_vm.tipo=$$v},expression:"tipo"}})],1),(_vm.tipo === 'lectura')?_c(VCol,{attrs:{"cols":"12"}},[(_vm.errors.lectura.length)?_c(VAlert,{attrs:{"type":"error","text":"","dense":""}},[_vm._v(_vm._s(_vm.errors.lectura[0]))]):_vm._e(),_c('editor-texto',{ref:"editor",attrs:{"texto":_vm.lectura,"contenido":"examen","idContenido":_vm.$route.params.idExamen},on:{"textoModificado":_vm.textoLecturaModificado}})],1):_vm._e()],1)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"secondary","small":"","outlined":"","disabled":_vm.loading},on:{"click":function($event){return _vm.$emit('cancelar')}}},[_vm._v("Cancelar")]),_c(VBtn,{attrs:{"color":"primary","small":"","disabled":_vm.loading || !_vm.esValido,"loading":_vm.loading},on:{"click":function($event){return _vm.submit()}}},[_vm._v("Guardar")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }