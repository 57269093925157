import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VSubheader } from 'vuetify/lib/components/VSubheader';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VRow,[_c(VCol,{attrs:{"md":"8","sm":"12","cols":"12"}},[_c(VCard,{staticClass:"my-2",attrs:{"outlined":""}},[_c(VSubheader,{staticClass:"font-weight-bold"},[_vm._v("Descripción")]),_c(VDivider,{staticClass:"mx-3"}),_c(VSkeletonLoader,{staticStyle:{"margin":"20px"},attrs:{"type":"paragraph@3"}})],1)],1),_c(VCol,{attrs:{"md":"4","sm":"12","cols":"12"}},[_c(VCard,{staticClass:"my-2",attrs:{"outlined":""}},[_c(VSubheader,{staticClass:"font-weight-bold"},[_vm._v("Evaluación")]),_c(VDivider,{staticClass:"mx-3"})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }