import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{class:{ 'pa-0': _vm.idPlaneacion }},[_c(VCard,{attrs:{"loading":_vm.loadingUpdate}},[_c(VToolbar,{staticClass:"text-h6",attrs:{"dense":"","color":_vm.idPlaneacion ? 'primary' : 'secondary',"dark":"","flat":""}},[(!_vm.idPlaneacion)?_c(VBtn,{attrs:{"icon":""},on:{"click":_vm.goBack}},[_c(VIcon,[_vm._v("mdi-arrow-left")])],1):_vm._e(),_c(VSpacer),_vm._v(_vm._s(_vm.planeacion ? _vm.planeacion.nombre : "")),_c(VSpacer),(_vm.planeacion)?_c('planeacionMenu',{attrs:{"planeacion":_vm.planeacion},on:{"planeacionSaved":_vm.planeacionSaved}}):_vm._e()],1),(_vm.loading)?_c('div',[_c(VContainer,[_c(VSkeletonLoader,{attrs:{"type":"article, article"}})],1)],1):(_vm.planeacion)?_c('div',[_c(VContainer,[_c('descripcionPlaneacion',{attrs:{"descripcion":_vm.planeacion.descripcion},on:{"actualizarPlaneacion":_vm.actualizarPlaneacion}}),_c('planeacionModulos',{staticClass:"mt-3",attrs:{"modulos":_vm.planeacion.modulos,"idPlaneacion":_vm.planeacion._id,"customGoToModulo":!!_vm.idPlaneacion},on:{"actualizarPlaneacion":_vm.actualizarPlaneacion,"goToModulo":_vm.goToModulo}}),_c('logs-planeacion',{attrs:{"logs":_vm.logs}})],1)],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }