<template>
  <v-card :loading="loading" flat>
    
    <div>
      <v-card flat class="mx-3 my-2">
        <v-row dense>
          <v-col cols="6">
            <v-list-item v-if="equipoUsuario && actividad.entrega > 1">
              <!-- Esta acción solo será posible si la actividad permite crear equipos -->
              <v-list-item-action v-if="actividad.formarEquipos && esAdmin ">
                <equipoMenu
                  :idCurso="idCurso"
                  :idActividad="actividad._id"
                  :idEquipo="equipoUsuario._id"
                  :cursoCampus="true"
                  @modificarEquipo="modificarEquipo"
                />
              </v-list-item-action>
              <!-- Esta acción solo será posible si la actividad permite crear equipos -->
              <v-list-item-content class="pt-2">
                <v-list-item-title>{{ equipoUsuario.nombre }}</v-list-item-title>
                <v-list-item-subtitle>Integrantes: {{ equipoUsuario.integrantes.length }} </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-else-if="!equipoUsuario && actividad.entrega > 1">
              <v-list-item-avatar><v-icon>mdi-alert-circle-outline</v-icon></v-list-item-avatar>
              <v-list-item-title>Sin equipo</v-list-item-title>
              <v-btn
                v-if="actividad.formarEquipos && !equipoUsuario && !loading"
                small
                color="primary"
                dark
                class="mr-8"
                @click="crearEquipo"
              >Crear equipo</v-btn>
            </v-list-item>
          </v-col>
          <v-divider vertical></v-divider>
          <v-col cols="6">
            <v-list v-if="!loading && equipoUsuario" >
              <v-list-item v-for="(integrante, index) in equipoUsuario.integrantes" :key="index">
                
                <v-list-item-avatar class="avatar-list">
                  <v-avatar color="primary" size="30">
                    <v-icon color="white" v-if="!integrante.avatar">mdi-account</v-icon>
                      <img
                        v-else
                        :src="`${imageURL}/avatar/${integrante.avatar}`"
                        alt="UserImage"
                      />
                  </v-avatar>
                </v-list-item-avatar>
                <v-list-item-content class="pt-0">
                  <v-list-item-title>{{ integrante.nombreCompleto }}</v-list-item-title>
                </v-list-item-content>

                <v-list-item-action v-if="integrante.admin" class="mt-0">
                  <v-chip 
                    outlined
                    color="yellow darken-2"
                    label
                    small
                    text-color="yellow darken-2"
                  >Administrador
                    <v-icon right>
                      mdi-star
                    </v-icon>
                  </v-chip>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-col>
        </v-row>
      </v-card>
    </div>
      
    <v-divider v-if="actividad.entrega > 1" class="mx-3"></v-divider>
    <!-- Crear equipo siendo ADMIN -->
    <v-subheader v-if="loading">
      <strong>Cargando calificación...</strong>
    </v-subheader>
    <template v-else>
      <calificacionDirectaAlumno
        v-if="actividad.tipo == 1 || (transversal && transversal.tipo == 1)"
        :idCurso="idCurso"
        :actividad="actividad"
        :calificaciones="calificaciones"
      />
      <calificacionDocumentoAlumno
        v-if="actividad.tipo == 2 || (transversal && transversal.tipo == 2)"
        :idCurso="idCurso"
        :actividad="actividad"
        :documentos="documentos"
        :maxFileSize="maxFileSize"
        :esAdmin="esAdmin"
        :permisoEscritura="permisoEscritura"
        :cursoArchivado="cursoArchivado"
        @documentoAgregado="documentoAgregado"
        @documentoEliminado="documentoEliminado"
      />
      <calififcacion-foro
        v-if="actividad.tipo == 3 "
        :calificaciones="calificaciones"
        :actividad="actividad"
        :esAdmin="esAdmin"
        :idEquipo="this.equipoUsuario ? this.equipoUsuario._id : null"
        :equipos="equipos"
        :cursoArchivado="cursoArchivado"
      ></calififcacion-foro>
      <calificacionInteractivoAlumno
        v-if="actividad.tipo == 4"
        :idCurso="idCurso"
        :actividad="actividad"
      />
    </template>

    <equipoForm
      v-if="editarEquipo.mostrar"
      :mostrar="editarEquipo.mostrar"
      :idCurso="idCurso"
      :idActividad="actividad._id"
      :tipoActividad="actividad.tipo"
      :entregaActividad="actividad.entrega"
      :alumnos="alumnos"
      :equipos="equipos"
      :idEquipo="editarEquipo.idEquipo"
      :usuarioCurso="editarEquipo.idUsuario"
      @cancelar="
        editarEquipo.idEquipo = null;
        editarEquipo.idUsuario = null;
        editarEquipo.mostrar = false;
      "
      @equipoAgregado="equipoAgregado"
      @equipoModificado="equipoModificado"
    />

  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import { calificacionAlumnoActividadService } from "./calificacionesAlumno.service";
import { ordenarObjetosAsc } from '../../../../helpers/ordenador';

import calificacionDirectaAlumno from "./calificacionDirectaAlumno.vue";
import calificacionDocumentoAlumno from "./calificacionDocumentoAlumno.vue";
import calificacionInteractivoAlumno from "./calificacionInteractivoAlumno.vue";
import equipoMenu from "../listadoEntregas/equipoMenu.vue";
import equipoForm from "../listadoEntregas/equipoForm.vue";

export default {
  name: "calificacionAlumno",
  props: {
    idCurso: { type: String, default: null },
    actividad: { type: Object, default: () => ({}) },
    idUsuario: { type: String, default: null },
    permisoEscritura: { type: Boolean, default: false },
    transversal: { type: Object, default: null },
  },
  components: {
    calificacionDirectaAlumno,
    calificacionDocumentoAlumno,
    calificacionInteractivoAlumno,
    "calififcacion-foro": () => import("./CalificacionForo.vue"),
    equipoForm,
    equipoMenu
  },
  computed: {
    ...mapGetters(["userId", "imageURL"]),
    esAdmin() {
      let esAdmin = false;
      if((this.actividad.entrega == 1 && !this.transversal) || (this.transversal && this.transversal.entrega == 1)) {
        esAdmin = true;
      } else {
        if(this.equipoUsuario && this.equipoUsuario.integrantes.length > 0) {
          const admin = this.equipoUsuario.integrantes.find(integrante => integrante.admin);
          if(admin.usuario == this.userId) return true;
          return false;
        } else {
          return false;
        }
      }
      return esAdmin;
    },
  },
  data() {
    return {
      loading: true,
      maxFileSize: 1,
      editarEquipo: {
        mostrar: false,
        idEquipo: null,
        idUsuario: null
      },
      calificaciones: [],
      documentos: [],
      alumnos: [],
      equipos: [],
      equipoUsuario: null,
      cursoArchivado: false,
    };
  },
  watch: {
    equipos() {
      if(((this.actividad.entrega > 1 && !this.transversal) || (this.transversal && this.transversal.entrega > 1)) && this.equipos) this.setEquipoUsuario();
    },
  },
  mounted() {
    this.cargarCalificaciones();
  },
  methods: {
    async cargarCalificaciones() {
      try {
        this.loading = true;
        const serverResponse = await calificacionAlumnoActividadService(
          this.idCurso,
          this.actividad._id
        );
        this.loading = false;
        if (!serverResponse.ok) {
          this.$systemErrorMessage(serverResponse.mensaje);
        } else {
          this.maxFileSize = serverResponse.maxFileSize || null;
          this.calificaciones = serverResponse.calificaciones;
          this.documentos = serverResponse.documentos;
          this.cursoArchivado = serverResponse.cursoArchivado;
          if (serverResponse.alumnos.length > 0)
            this.alumnos = ordenarObjetosAsc(serverResponse.alumnos, "usuario");
          this.equipos = serverResponse.equipos;
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
    documentoAgregado(documento) {
      this.documentos.unshift(documento);
    },
    documentoEliminado(idDocumento) {
      const index = this.documentos.findIndex((x) => x._id == idDocumento);
      if (index >= 0) this.documentos.splice(index, 1);
    },
    crearEquipo() {
      this.editarEquipo.idUsuario = this.userId;
      this.editarEquipo.mostrar = true;
    },
    modificarEquipo(idEquipo) {
      this.editarEquipo.idEquipo = idEquipo;
      this.editarEquipo.idUsuario = this.userId;
      this.editarEquipo.mostrar = true;
    },
    equipoAgregado(equipo) {
      this.editarEquipo.idEquipo = null;
      this.editarEquipo.idUsuario = null;
      this.editarEquipo.mostrar = false;
      this.equipos.push(equipo);
      this.equipoUsuario = equipo;
      this.setAlumnosEquipo();
    },
    equipoModificado(equipo) {
      this.editarEquipo.idEquipo = null;
      this.editarEquipo.idUsuario = null;
      this.editarEquipo.mostrar = false;
      const index = this.equipos.findIndex((x) => x._id == equipo._id);
      if (index >= 0) {
        this.equipos[index].nombre = equipo.nombre;
        this.equipos[index].integrantes = equipo.integrantes;
      }
      this.equipoUsuario = this.equipos[index];
      this.setAlumnosEquipo();
    },
    setEquipoUsuario() {
      this.equipoUsuario = this.equipos.find((equipo) => {
        if (equipo.integrantes.find((integrante) => integrante.usuario === this.userId))
          return equipo;
      });
      this.setAlumnosEquipo();
    },
    setAlumnosEquipo() {
      if(this.equipoUsuario) {
        this.equipoUsuario.integrantes.forEach((integrante) => {
          let { nombreCompleto, avatar } = this.alumnos.find((alumno) => alumno._id == integrante.usuario)
            integrante.nombreCompleto = nombreCompleto;
            integrante.avatar = avatar;
          }
        );
      }
    }
  },
};
</script>

<style>
.avatar-list {
  margin-right: 5px !important;
  vertical-align: middle;
}
</style>
