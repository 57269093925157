import { render, staticRenderFns } from "./editorInteractivo.vue?vue&type=template&id=acb54e38&scoped=true"
import script from "./editorInteractivo.vue?vue&type=script&lang=js"
export * from "./editorInteractivo.vue?vue&type=script&lang=js"
import style0 from "./editorInteractivo.vue?vue&type=style&index=0&id=acb54e38&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "acb54e38",
  null
  
)

export default component.exports