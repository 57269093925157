<template>
  <v-list dense>
    <div v-for="(item, index) of answers" :key="item._id">
      <v-list-item two-lines>
        <v-list-item-icon>
          <v-icon v-if="item.correct" color="green darken-1"
            >mdi-check-circle
          </v-icon>
          <v-icon v-else color="red darken-1">mdi-close-circle</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-subtitle>Respuesta:</v-list-item-subtitle>
          <v-list-item-title>{{ item.text }}</v-list-item-title>
        </v-list-item-content>
        <v-list-item-action v-if="permisoEscritura">
          <v-menu offset-y left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon small v-bind="attrs" v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list dense>
              <v-list-item
                v-if="!item.correct"
                @click="
                  $emit('modificarRespuestaCorrecta', {
                    idRespuesta: item._id,
                    value: true,
                  })
                "
              >
                <v-list-item-title>Establecer correcta</v-list-item-title>
                <v-list-item-icon
                  ><v-icon color="green darken-1" small
                    >mdi-check-circle</v-icon
                  ></v-list-item-icon
                >
              </v-list-item>
              <v-list-item
                v-else
                @click="
                  $emit('modificarRespuestaCorrecta', {
                    idRespuesta: item._id,
                    value: false,
                  })
                "
              >
                <v-list-item-title>Establecer incorrecta</v-list-item-title>
                <v-list-item-icon
                  ><v-icon color="red darken-1" small
                    >mdi-close-circle</v-icon
                  ></v-list-item-icon
                >
              </v-list-item>
              <v-list-item @click="$emit('modificarRespuesta', item)">
                <v-list-item-title>Modificar respuesta</v-list-item-title>
                <v-list-item-icon
                  ><v-icon color="primary" small
                    >mdi-pencil</v-icon
                  ></v-list-item-icon
                >
              </v-list-item>
              <v-list-item @click="$emit('eliminarRespuesta', item)">
                <v-list-item-title>Eliminar respuesta</v-list-item-title>
                <v-list-item-icon
                  ><v-icon small color="red darken-1"
                    >mdi-trash-can</v-icon
                  ></v-list-item-icon
                >
              </v-list-item>
            </v-list>
          </v-menu>
        </v-list-item-action>
      </v-list-item>

      <v-list-item v-if="item.tipsAndFeedback.tip" two-lines>
        <v-list-item-icon>
          <v-icon color="gray">mdi-lightbulb-outline </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-subtitle>Pista:</v-list-item-subtitle>
          <v-list-item-title>{{ item.tipsAndFeedback.tip }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-if="item.tipsAndFeedback.chosenFeedback" two-lines>
        <v-list-item-icon>
          <v-icon color="gray">mdi-information-box-outline </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-subtitle
            >Retroalimentación seleccionado:</v-list-item-subtitle
          >
          <v-list-item-title>{{
            item.tipsAndFeedback.chosenFeedback
          }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-if="item.tipsAndFeedback.notChosenFeedback" two-lines>
        <v-list-item-icon>
          <v-icon color="gray">mdi-information-box-outline </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-subtitle
            >Retroalimentación no seleccionado:</v-list-item-subtitle
          >
          <v-list-item-title>{{
            item.tipsAndFeedback.notChosenFeedback
          }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider v-if="index < answers.length - 1" class="mx-3"></v-divider>
    </div>
  </v-list>
</template>

<script>
export default {
  props: {
    answers: { type: Array, default: () => [] },
    permisoEscritura: { type: Boolean, default: false },
  },
  data() {
    return {
      loading: false,
    };
  },
};
</script>
