import { VCard } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VList } from 'vuetify/lib/components/VList';
import { VSubheader } from 'vuetify/lib/components/VSubheader';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{attrs:{"shaped":""}},[_c(VContainer,[_c(VList,[_c(VSubheader,[_vm._v("Configuración")]),_c(VDivider,{staticClass:"mx-3"}),_c('tipoSesionItemComponent'),_c('fechaRegistroItemComponent',{attrs:{"registro":_vm.registro}}),_c('zonaHorariaItemComponent'),_c('empresasItemComponent'),_c('escuelasItemComponent'),_c('controlParentalItemComponent')],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }