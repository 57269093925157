import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"max-width":"500","persistent":""},model:{value:(_vm.mostrar),callback:function ($$v) {_vm.mostrar=$$v},expression:"mostrar"}},[_c(VCard,{staticClass:"elevation-12"},[_c(VToolbar,{attrs:{"dense":"","flat":""}},[_c('h3',[_vm._v("Cuenta activada correctamente 😎")])]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"type":"submit","color":"primary","dark":"","small":""},on:{"click":function($event){return _vm.$emit('redireccionar')}}},[_vm._v("Aceptar")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }