import { VAlert } from 'vuetify/lib/components/VAlert';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{staticClass:"pb-1 text-center"},[_c(VAlert,{staticClass:"pa-0 mb-3",attrs:{"border":"top","colored-border":"","color":"secondary"}}),_c(VCard,{staticClass:"ma-1",attrs:{"outlined":""}},[_c(VIcon,{staticClass:"mt-2",attrs:{"color":"primary","size":"30"}},[_vm._v(_vm._s(_vm.descripcion.icon))]),_c(VCardSubtitle,{staticClass:"pa-0"},[_vm._v(_vm._s(_vm.descripcion.subtitle))]),_c(VSkeletonLoader,{attrs:{"type":"list-item-avatar-three-line"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }