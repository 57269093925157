<template>
  <div>
    <v-container v-if="loading">
      <v-skeleton-loader type="article"></v-skeleton-loader>
    </v-container>
    <v-container
      v-else-if="!!modulo && !!planeacion"
      :class="{ 'pa-0': idModulo }"
    >
      <v-card>
        <v-toolbar
          dense
          :color="idModulo ? 'primary' : 'secondary'"
          dark
          flat
          class="text-h6"
        >
          <v-btn icon @click="goBack()"><v-icon>mdi-arrow-left</v-icon></v-btn>
          <v-spacer></v-spacer>
          <span>{{ planeacion.nombre }}</span>
          <v-spacer></v-spacer>
          <planeacionModuloMenu
            :idPlaneacion="planeacion._id"
            :modulo="modulo"
            :modificarDescripcion="true"
            @moduloSaved="moduloSaved"
          ></planeacionModuloMenu>
        </v-toolbar>
        <v-card-title>
          <v-btn
            icon
            color="primary"
            :disabled="!previousModulo"
            @click="changeModulo(previousModulo)"
          >
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <span>{{ modulo.nombre }}</span>
          <v-spacer></v-spacer>
          <v-btn
            icon
            color="primary"
            :disabled="!nextModulo"
            @click="changeModulo(nextModulo)"
          >
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="mx-3"></v-divider>
        <v-card-text>
          <div v-if="modulo.descripcion">
            <p v-html="modulo.descripcion"></p>
          </div>
          <div class="mt-4">
            <moduloElementos
              :elementos="modulo.elementos"
              :idPlaneacion="idPlaneacion"
              :idModulo="idModulo"
              :planeacion="planeacion"
            ></moduloElementos>
          </div>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { obtenerModulo } from "../planeacionModulos.service";
import planeacionModuloElementos from "./planeacionModuloElementos.vue";
import planeacionModuloMenu from "./planeacionModuloMenu.vue";

export default {
  props: {
    idPlaneacion: {
      type: String,
    },
    idModulo: {
      type: String,
    },
  },

  components: {
    planeacionModuloMenu,
    moduloElementos: planeacionModuloElementos,
  },

  data() {
    return {
      loading: true,
      planeacion: null,
      modulo: null,
    };
  },

  computed: {
    ...mapGetters(["sessionToken"]),

    moduloIndex() {
      if (!this.planeacion || !this.modulo) return null;
      return this.planeacion.modulos.findIndex((m) => m == this.modulo._id);
    },

    nextModulo() {
      return this.moduloIndex != -1
        ? this.planeacion.modulos[this.moduloIndex + 1]
        : null;
    },

    previousModulo() {
      return this.moduloIndex != -1
        ? this.planeacion.modulos[this.moduloIndex - 1]
        : null;
    },
  },

  watch: {
    sessionToken(v) {
      !!v && this.cargarModulo();
    },

    $route() {
      this.cargarModulo();
    },
  },

  mounted() {
    !!this.sessionToken && this.cargarModulo();
  },

  methods: {
    async cargarModulo() {
      this.loading = true;

      try {
        const serverResponse = await obtenerModulo({
          idPlaneacion: this.$route.params.idPlaneacion || this.idPlaneacion,
          idModulo: this.$route.params.idModulo || this.idModulo,
        });

        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else {
          this.modulo = serverResponse.modulo;
          this.planeacion = serverResponse.planeacion;
        }
      } catch (error) {
        console.error(error);
        this.$appErrorMessage();
      } finally {
        this.loading = false;
      }
    },

    moduloSaved(modulo) {
      this.modulo = modulo;
    },

    changeModulo(idModulo) {
      if (this.idPlaneacion) {
        this.$emit("changeModulo", idModulo);
        return;
      }
      this.$router.replace({
        name: this.$route.name,
        params: {
          idModulo,
        },
      });
    },

    goBack() {
      if (this.idModulo) {
        this.$emit("goToPlaneacion");
        return;
      }
      this.$router.push({
        path: `/planeaciones/planeacion/${this.planeacion._id}`,
        query: {
          fromModulo: true,
        },
      });
    },
  },
};
</script>
