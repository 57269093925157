import {
    postServerRequest,
    patchServerRequest,
    deleteServerRequest,
} from "@/components/globals/services/serverRequest.service";
import { store } from "../../../../../store/store";
import { putServerRequest } from "../../../services/serverRequest.service";

const httpURL = store.getters.httpURL;

export const modificarDTWTextoService = async (idDTW, texto) => {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/interactivo/dragthewords/${idDTW}/texto`;
    return await patchServerRequest(url, { texto }, config);
};

export const agregarDTWDistractorService = async (idDTW, distractor) => {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/interactivo/dragthewords/${idDTW}/distractor`;
    return await putServerRequest(url, { distractor }, config);
};


export const modificarDTWDistractorService = async (idDTW, data) => {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/interactivo/dragthewords/${idDTW}/distractor`;
    return await patchServerRequest(url, data, config);
};

export const eliminarDTWDistractorService = async (idDTW, index) => {
    const token = store.getters.sessionToken;
    const config = {
        headers: { token },
        data: { index }
    };
    const url = `${httpURL}/interactivo/dragthewords/${idDTW}/distractor`;
    return await deleteServerRequest(url, config);
};
