import { VAlert } from 'vuetify/lib/components/VAlert';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
import { VVirtualScroll } from 'vuetify/lib/components/VVirtualScroll';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{attrs:{"loading":_vm.loading,"elevation":"2","height":!_vm.loading && _vm.avisosOrdenados.length != 0 ? '380' : '160'}},[_c(VAlert,{staticClass:"pa-0 mb-3",attrs:{"border":"top","colored-border":"","color":"secondary"}}),_c(VToolbar,{staticClass:"mx-1",attrs:{"elevation":"0","outlined":"","dense":""}},[_c(VIcon,{staticClass:"mr-3",attrs:{"color":"primary","size":"30"}},[_vm._v("mdi-school")]),_c(VToolbarTitle,{staticStyle:{"color":"#0d3863"}},[_vm._v("Avisos escuela")]),_c(VSpacer),_c(VChip,{staticClass:"elevation-1",attrs:{"color":"primary","small":""}},[_c(VIcon,{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("mdi-bell-alert")]),_vm._v(_vm._s(_vm.avisosOrdenados.length))],1)],1),(_vm.avisosOrdenados.length === 0 && !_vm.loading)?_c(VCardSubtitle,[_vm._v("No hay avisos disponibles")]):_vm._e(),(_vm.loading)?_c(VCard,{staticClass:"ma-1",attrs:{"outlined":""}},[_c(VSkeletonLoader,{attrs:{"type":"list-item-avatar-three-line"}})],1):_c(VVirtualScroll,{attrs:{"bench":15,"items":_vm.avisosOrdenados,"item-height":130,"height":300},scopedSlots:_vm._u([{key:"default",fn:function({ item }){return [_c(VCard,{staticClass:"ma-1",attrs:{"outlined":"","elevation":"0"}},[_c(VCardTitle,[_c(VIcon,{staticClass:"mr-2",attrs:{"small":""}},[_vm._v(" mdi-clock ")]),_c('span',{staticClass:"text-caption"},[_vm._v(_vm._s(_vm.moment(item.fechaCreacion).locale('es').format('DD [de] MMMM [de] YYYY')))]),_c(VSpacer),_c(VChip,{attrs:{"color":"#90CAF9","small":""}},[_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-bullhorn")])],1)],1),_c(VCardText,[_c('div',{staticClass:"textTitle"},[_vm._v(_vm._s(item.titulo))]),_c('div',{staticClass:"textAviso"},[_vm._v(_vm._s(item.descripcion))])])],1)]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }