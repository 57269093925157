<template>
  <v-card outlined>
    <v-container>
      <v-radio-group
        v-model="tipoRespuestasLocal"
        column
        :disabled="loading || totalCorrectas > 1"
        @change="modificarPropiedad('tipoRespuestas')"
      >
        <v-radio label="Sólo una respuesta correcta" value="single"></v-radio>
        <v-radio
          label="Múltiples respuestas correctas"
          value="multiple"
        ></v-radio>
      </v-radio-group>
      <v-checkbox
        v-model="respuestasAleatoriasLocal"
        :disabled="loading"
        label="Mostrar respuestas aleatorias"
        @change="modificarPropiedad('respuestasAleatorias')"
      ></v-checkbox>
    </v-container>
  </v-card>
</template>

<script>
import { modificarInteractivoService } from "../interactivos.service";

export default {
  props: {
    idInteractivo: { type: String },
    tipoInteractivo: { type: String, default: null },
    permisoEscritura: { type: Boolean, default: false },
    tipoRespuestas: { type: String, default: "single" },
    respuestasAleatorias: { type: Boolean, default: false },
    totalCorrectas: { type: Number, default: 0 },
  },

  mounted() {
    this.tipoRespuestasLocal = this.tipoRespuestas;
    this.respuestasAleatoriasLocal = this.respuestasAleatorias;
  },
  data() {
    return {
      loading: false,
      tipoRespuestasLocal: "single",
      respuestasAleatoriasLocal: false,
    };
  },
  methods: {
    async modificarPropiedad(campo) {
      this.loading = true;
      try {
        const value =
          campo === "tipoRespuestas"
            ? this.tipoRespuestasLocal
            : this.respuestasAleatoriasLocal;
        const serverResponse = await modificarInteractivoService(
          this.tipoInteractivo,
          value,
          this.idInteractivo,
          campo
        );
        this.loading = false;
        this.$validateResponse(serverResponse);
        if (!serverResponse.ok) {
          this.$systemErrorMessage(serverResponse.mensaje);
          return;
        } else {
          this.$emit(`${campo}Modificado`, {
            value,
            nuevoLog: serverResponse.nuevoLog,
          });
          this.modificar = false;
        }
      } catch (error) {
        console.log(error);
        this.loading = false;
        this.$appErrorMessage();
      }
    },

    itemModificado(payload) {
      this.$emit("logAgregado", payload.nuevoLog);
      const crosswordItem = payload.crosswordItem;
      const index = this.answers.findIndex((x) => x._id === crosswordItem._id);
      if (index >= 0) this.answers.splice(index, 1, crosswordItem);
      this.mostrarAgregarModificar = false;
    },
    eliminarItemAction(item) {
      this.itemEliminar = item;
      this.eliminarItem = true;
    },
    itemEliminado(payload) {
      this.$emit("logAgregado", payload.nuevoLog);
      const index = this.answers.findIndex(
        (x) => x._id === this.itemEliminar._id
      );
      if (index >= 0) this.answers.splice(index, 1);
      this.eliminarItem = false;
    },
  },
};
</script>
