import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VListItem,[_c(VListItemIcon,[_c(VIcon,{attrs:{"color":"primary"}},[_vm._v("mdi-book-open-outline")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(_vm.detallePlantilla.nombre))]),_c(VListItemSubtitle,[_vm._v("Plantilla, "+_vm._s(_vm.origenPlantilla))])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }