<template>
<div>
  <datatable
    :loading="loading"
    :datos="recursosList"
    :pagination="pagination"
    :headers="headers"
    :page="pagination.page"
    :totalItems="pagination.itemsLength"
    :itemsPerPage="pagination.itemsPerPage"
    hideSearch
    @update-page="updatePageTable"
    @pagina-change="paginaCambio"  
  >
    <template #item-recurso="{ value }" >
    <v-list-item>
      <v-list-item-avatar>
      <v-icon v-if="value.tipoRecurso == 'archivo'" color="primary" small >{{value.icono}}</v-icon>
      <v-icon v-else color="primary" small>mdi-link-variant</v-icon>
      </v-list-item-avatar>
      <v-list-item-content>
      <v-tooltip v-if="value.tipoRecurso == 'archivo'" bottom>
        <template v-slot:activator="{ on, attrs }">
          <a v-bind="attrs" v-on="on" @click="viewerItem(value)" class="link">{{ value.nombre }}</a> 
        </template>
        <span>Visualizar</span>
      </v-tooltip>

      <a v-else :href="value.url" class="link" target="_blank">{{ value.nombre }} </a>
      <ver-mas :texto="value.descripcion" :longitud="80" />            
      </v-list-item-content>
    </v-list-item>
  
    </template>
    <template #item-restringir="{ value }">
      <v-chip v-if="value" color="primary" outlined label small>
        Restringido
        <v-icon right small>mdi-lock</v-icon>
      </v-chip>
      <div v-else></div>
    </template>
    <template #item-tamanio="{ value }" >
      <v-list-item v-if="value">
            <v-list-item-subtitle style="text-align: center;"><i>{{ value }}</i></v-list-item-subtitle>
      </v-list-item>
    </template>
      <template #item-usuario="{ value }">
        <h5 style="text-align: center">{{ value }}</h5>
      </template>
      <template #item-edicion="{value}">
        <v-menu left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              small
              style="margin-left: 10px"
              icon
              color="primary"
              :disabled="!esAdmin || loading"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon color="grey">mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item @click="modificarRecurso(value)">
              <v-list-item-title>Modificar recurso</v-list-item-title>
              <v-list-item-icon
                ><v-icon small>mdi-pencil</v-icon></v-list-item-icon
              >
            </v-list-item>
            <v-divider class="mx-3"></v-divider>
            <v-list-item @click="eliminarRecurso(value)">
              <v-list-item-title
                ><font color="#D32F2F">
                  Eliminar recurso
                </font></v-list-item-title
              >
              <v-list-item-icon
                ><v-icon color="red darken-2" small
                  >mdi-trash-can-outline</v-icon
                ></v-list-item-icon
              >
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </datatable>

    <editorRecurso
      v-if="abrirEditorRecurso"
      :mostrar="abrirEditorRecurso"
      :idSubarea="modificarRecursoObject.subarea"
      :idArea="modificarRecursoObject.area"
      :editarRecurso="modificarRecursoObject"
      :tipo="tipo"
      @cancelar="(abrirEditorRecurso = false), (modificarRecursoObject = null)"
      @recursoModificado="recursoModificado"
    />

    <eliminarRecurso
      v-if="eliminarRecursoObject.mostrar"
      :mostrar="eliminarRecursoObject.mostrar"
      :recurso="eliminarRecursoObject.recurso"
      @cancelar="
        (eliminarRecursoObject.mostrar = false),
          (eliminarRecursoObject.recurso = null)
      "
      @recursoEliminado="$emit('getRecursos')"
    />
    <viewer
      v-if="verArchivo.mostrar"
      :mostrar="verArchivo.mostrar"
      :idRecurso="verArchivo.idRecurso"
      :nombreArchivo="verArchivo.nombre"
      :url="verArchivo.url"
      :tipoArchivo="verArchivo.tipo"
      :restringir="verArchivo.restringir"
      @cerrar="verArchivo.mostrar = false"
    />

    <noFile
      v-if="sinArchivo.mostrar"
      :mostrar="sinArchivo.mostrar"
      :nombreArchivo="sinArchivo.nombre"
      @cerrar="sinArchivo.mostrar = false"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import datatable from "../widgets/datatable.vue";
import EditorRecurso from "./editorRecurso.vue";
import EliminarRecurso from "./eliminarRecurso.vue";
import VerMas from "../widgets/textSubtract.vue";
import viewer from "../widgets/fileViewer.vue";
import noFile from "../widgets/noFile.vue";

import { validaExistenciaService } from "./recursos.service";
export default {
  name: 'tablaRecursos',
  components: {
    datatable,
    EditorRecurso,
    EliminarRecurso,
    VerMas,
    viewer,
    noFile,
  },
  props: {
    loading: { type: Boolean, default: () => false },
    recursos: { type: Array, default: () => [] },
    pagination: { type: Object, default: () => {} },
  },
  computed: {
    ...mapGetters(["sessionToken", "role", "documentsURL", "app"]),
    esAdmin() {
      return (
        this.app == "campus" || this.role == "admin" || this.role == "profesor" || this.role == "master"
      );
    },
    recursosList() {
      let lista = [];
      if (this.recursos.length) {
        this.recursos.forEach((x) => {
          let obj = { ...x };
          if (obj.recurso.tipoArchivo) {
            if (obj.recurso.tipoArchivo.includes("application/pdf"))
              x.recurso.icono = "fa-light fa-file-pdf";
            else if (obj.recurso.tipoArchivo.includes("image"))
              x.recurso.icono = "fa-regular fa-image";
            else if (obj.recurso.tipoArchivo.includes("spreadsheet"))
              x.recurso.icono = "fa-thin fa-table";
            else if (
              obj.recurso.tipoArchivo.includes("wordprocessingml") ||
              obj.recurso.tipoArchivo.includes("text")
            )
              x.recurso.icono = "mdi-file-document";
            else if (obj.recurso.tipoArchivo.includes("presentation"))
              x.recurso.icono = "mdi-presentation";
            else if (obj.recurso.tipoArchivo.includes("video"))
              x.recurso.icono = "fa-light fa-video";
            else x.recurso.icono = "mdi-folder";
          } else x.recurso.icono = "mdi-link-variant";
          lista.push(obj);
        });
      }
      return lista;
    },
  },
  data() {
    return {        
      tipo:null,
      pageLocal:1,
      verArchivo:{ mostrar:false, idRecurso: null, nombre:null, url:null, tipo:null, restringir: null },
      sinArchivo:{ mostrar:false, nombre:null },       
      headers: [
        {
          text: "",
          value: "numero",
          sortable: false,
          filterable: false,
          width: "30px",
        },
        {
          text: "Nombre",
          value: "recurso",
          sortable: false,
          filterable: false,
          width: "80%",
        },
        {
          text: "",
          value: "restringir",
          sortable: false,
          filterable: false,
          with:"90%"
        },
        {
          text: "Tamaño",
          value: "tamanio",
          sortable: false,
          filterable: false,
          align: "center",
        },
        {
          text: "Usuario",
          value: "usuario",
          sortable: false,
          filterable: false,
          align: "center",
          width: "20%",
        },
        {
          text: "",
          value: "edicion",
          sortable: false,
          filterable: false,
          width: "50px",
        },
      ],
      abrirEditorRecurso: false,
      modificarRecursoObject: null,
      eliminarRecursoObject: {
        mostrar: false,
        recurso: null,
      },
    };
  },
  methods: {
    async viewerItem(archivo) {
      try {
        const serverResponse = await validaExistenciaService({
          url: archivo.ruta,
        });
        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else if (serverResponse.existe) {
          this.verArchivo = {
            mostrar: true,
            idRecurso: archivo._id,
            nombre: archivo.nombre,
            url: archivo.url,
            tipo:archivo.tipoArchivo,
            restringir: archivo.restringir ? archivo.restringir : false
          }
        }
        else this.sinArchivo = { mostrar:true, nombre: archivo.nombre }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
    recursoModificado() {
      this.$emit("limpiarPagination");
      this.$emit("getRecursos");
      this.modificarRecursoObject = null;
    },
    updatePageTable(value) {
      this.$emit("updatePageTable", value);
    },
    paginaCambio(pagina) {
      this.$emit("paginaCambio", pagina);
    },
    modificarRecurso(recurso) {
      this.abrirEditorRecurso = true;
      this.modificarRecursoObject = recurso;
      this.tipo = this.modificarRecursoObject.tipoRecurso;
    },
    eliminarRecurso(recurso) {
      this.eliminarRecursoObject.mostrar = true;
      this.eliminarRecursoObject.recurso = recurso;
    },
  },
};
</script>
