import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VListItem,{staticClass:"d-flex justify-center",attrs:{"dense":""}},[_c(VListItemAction,{staticClass:"mx-2"},[_c(VBtn,{attrs:{"color":"primary","outlined":"","small":""}},[_vm._v(" "+_vm._s(_vm.calificacion)+" ")])],1),(_vm.intentos.length > 0)?_c(VBtn,{attrs:{"x-small":"","text":""},on:{"click":function($event){_vm.verIntentos = true}}},[_vm._v(" "+_vm._s(_vm.intentos.length)+" intentos ")]):_vm._e()],1),(_vm.intentos.length > 0)?_c(VDialog,{attrs:{"persistent":"","max-width":"400"},model:{value:(_vm.verIntentos),callback:function ($$v) {_vm.verIntentos=$$v},expression:"verIntentos"}},[_c(VCard,[_c(VContainer,[_c(VList,_vm._l((_vm.intentos),function(intento){return _c(VListItem,{key:intento._id},[_c(VListItemAvatar,[_c(VAvatar,{attrs:{"color":"secondary","size":"36"}},[_c('span',{staticClass:"white--text text-strong"},[_vm._v(_vm._s(intento.calificacion))])])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v("Intento "+_vm._s(intento.numero))]),_c(VListItemSubtitle,[_vm._v(_vm._s(intento.observaciones))]),_c(VListItemSubtitle,[_vm._v(_vm._s(intento.fecha))])],1)],1)}),1)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","small":"","outlined":""},on:{"click":function($event){_vm.verIntentos = false}}},[_vm._v(" Cerrar ")])],1)],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }