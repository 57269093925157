<template>
  <v-card outlined>
    <v-toolbar dense flat>
      <v-subheader>Distractores</v-subheader>
      <v-spacer />
      <v-btn
        v-if="permisoEscritura"
        small
        icon
        color="primary"
        style="margin-right: 5px;"
        :disabled="agregarDistractor || modificarDistractor.mostrar"
        @click="agregarDistractor = true"
        ><v-icon>mdi-playlist-plus</v-icon></v-btn
      >
    </v-toolbar>
    <v-divider class="mx-3" />
    <v-list>
      <!-- -------- MENU DISTRACTOR -------- -->
      <v-list-item
        v-for="(distractor, index) in distractores"
        :key="`dist-${index}`"
      >
        <v-list-item-action
          v-if="permisoEscritura && !modificarDistractor.mostrar"
        >
          <v-menu offset-y :disabled="loading">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" x-small dark icon v-bind="attrs" v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list dense>
              <v-list-item
                one-line
                @click="mostrarModificarDistractor(index, distractor)"
              >
                <v-list-item-title>Modificar</v-list-item-title>
              </v-list-item>
              <v-list-item one-line @click="eliminarDistractor(index)">
                <v-list-item-title>Eliminar</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-list-item-action>
        <template v-if="modificarDistractor.index !== index">
          <v-list-item-title>
            {{ distractor }}
          </v-list-item-title>
        </template>
        <template v-else-if="modificarDistractor.index === index">
          <v-list-item-title>
            <v-text-field
              v-model="modificarDistractor.distractor"
              outlined
              dense
              :disabled="loading"
            ></v-text-field>
          </v-list-item-title>
          <v-list-item-action>
            <v-btn-toggle style="margin-top: -25px;">
              <v-btn
                outlined
                color="grey"
                small
                :disabled="loading"
                @click="
                  modificarDistractor.mostrar = false;
                  modificarDistractor.index = -1;
                "
                ><v-icon color="red darken-2">mdi-close</v-icon></v-btn
              >
              <v-btn
                outlined
                color="grey"
                small
                :loading="loading"
                @click="guardarModificarDistractor()"
                ><v-icon color="green darken-3">mdi-check</v-icon></v-btn
              >
            </v-btn-toggle>
          </v-list-item-action>
        </template>
      </v-list-item>

      <!-- -------- AGREGAR DISTRACTOR -------- -->
      <v-list-item v-if="agregarDistractor">
        <v-list-item-title>
          <v-text-field v-model="nuevoDistractor" outlined dense></v-text-field>
        </v-list-item-title>
        <v-list-item-action>
          <v-btn-toggle style="margin-top: -25px;">
            <v-btn
              outlined
              color="grey"
              small
              :disabled="loading"
              @click="agregarDistractor = false"
              ><v-icon color="red darken-2">mdi-close</v-icon></v-btn
            >
            <v-btn
              outlined
              color="grey"
              small
              :loading="loading"
              @click="guardarNuevoDistractor()"
              ><v-icon color="green darken-3">mdi-check</v-icon></v-btn
            >
          </v-btn-toggle>
        </v-list-item-action>
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import {
  agregarDTWDistractorService,
  modificarDTWDistractorService,
  eliminarDTWDistractorService,
} from "./dragthewords.service.js";

export default {
  components: {},
  props: {
    idInteractivo: { type: String, default: null },
    tipoInteractivo: { type: String, default: null },
    permisoEscritura: { type: Boolean, default: false },
    distractores: { type: Array, default: () => [] },
  },
  computed: {
    ...mapGetters(["sessionToken", "app", "role", "menu"]),
  },

  watch: {
    distractores(value) {
      this.distractoresLocal = value;
    },
  },

  data() {
    return {
      loading: false,
      preview: false,
      distractoresLocal: "",
      agregarDistractor: false,
      nuevoDistractor: null,
      modificarDistractor: {
        index: -1,
        mostrar: false,
        distractor: null,
      },
    };
  },
  methods: {
    distractorModificado(payload) {
      this.$emit("distractorModificado", payload);
    },
    async guardarNuevoDistractor() {
      try {
        this.loading = true;
        const serverResponse = await agregarDTWDistractorService(
          this.idInteractivo,
          this.nuevoDistractor
        );
        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else {
          const emitData = {
            distractor: serverResponse.distractor,
            log: serverResponse.nuevoLog,
          };
          this.$emit("distractorAgregado", emitData);
          this.nuevoDistractor = null;
          this.agregarDistractor = false;
        }
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
    mostrarModificarDistractor(index, distractor) {
      this.modificarDistractor.index = index;
      this.modificarDistractor.mostrar = true;
      this.modificarDistractor.distractor = distractor;
    },
    async guardarModificarDistractor() {
      try {
        this.loading = true;
        const serverResponse = await modificarDTWDistractorService(
          this.idInteractivo,
          this.modificarDistractor
        );
        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else {
          const emitData = {
            index: this.modificarDistractor.index,
            distractor: serverResponse.distractor,
            log: serverResponse.nuevoLog,
          };
          this.$emit("distractorModificado", emitData);
          this.modificarDistractor.index = -1;
          this.modificarDistractor.mostrar = false;
        }
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
    async eliminarDistractor(index) {
      try {
        this.loading = true;
        const serverResponse = await eliminarDTWDistractorService(
          this.idInteractivo,
          index
        );
        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else {
          const emitData = {
            index,
            log: serverResponse.nuevoLog,
          };
          this.$emit("distractorEliminado", emitData);
        }
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
  },
};
</script>
