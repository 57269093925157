<template>
  <v-container>
    <v-card flat>
      <v-toolbar dense color="secondary" dark flat class="text-h6">
        <v-spacer></v-spacer> Módulos <v-spacer></v-spacer>
        <v-tooltip bottom v-if="permisoEscritura && !vistaSeguimiento">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="white" icon v-bind="attrs" v-on="on">
              <v-icon @click="crearModulo()">
                mdi-text-box-plus
              </v-icon>
            </v-btn>
          </template>
          <span>Agregar módulo</span>
        </v-tooltip>
      </v-toolbar>
      <v-row v-if="localModulos || moduloTransversal">
        <v-list style="width: 100%">
          <draggable
            v-model="modulos"
            element="div"
            v-bind="dragOptions"
            ghost-class="ghost"
            handle=".handle"
            @change="cambiarOrden"
          >
            <v-col class="col-12" v-for="modulo in modulos" :key="modulo">
              <moduloItemComponent
                :tipoCurso="tipoCurso"
                :idModulo="modulo"
                :idCurso="idCurso"
                :tipoModulo="tipo"
                :avanceAutomatico="avanceAutomatico"
                :permisoEscritura="permisoEscritura"
                @moduloEliminado="moduloEliminado"
                @calcularAvanceCurso="calcularAvanceCurso"
              />
            </v-col>
          </draggable>
          <v-col v-if="moduloTransversal" class="col-12">
              <moduloItemComponent
                :tipoCurso="tipoCurso"
                :idModulo="moduloTransversal"
                :idCurso="idCurso"
                :tipoModulo="tipo"
                :avanceAutomatico="avanceAutomatico"
                :permisoEscritura="permisoEscritura"
              />
            </v-col>
        </v-list>
        <v-col v-if="mostrarBotones && sesionCursoAlumno && diplomasInfo.length > 0" class="col-12">
          <div v-for="diploma in diplomasInfo" :key="diploma._id">
            <v-btn style="margin: 5px" color="primary" dark small @click="obtenerDiploma(diploma._id)">
              {{diploma.nombre}}
            </v-btn>
          </div>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col md="12" sm="12">
          <v-card outlined class="my-2" style="text-align: center">
            No hay módulos registrados
          </v-card>
        </v-col>
      </v-row>
    </v-card>

    <agregarModuloComponent
      v-if="agregarModulo"
      :mostrar="agregarModulo"
      :tipoCurso="tipoCurso"
      :idCurso="idCurso"
      :tipo="tipo"
      :editarModulo="editarModulo"
      @cancelar="(agregarModulo = false), (editarModulo = null)"
      @moduloRegistrado="moduloRegistrado"
    />

    <v-dialog persistent v-model="loadingFile" max-width="300">
      <v-card>
        <v-toolbar dense color="primary" dark
          >Cargando archivo...
        </v-toolbar>
        <v-progress-linear color="white" indeterminate></v-progress-linear>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import draggable from "vuedraggable";
import { mapGetters } from "vuex";
import agregarModuloComponent from "./agregarModulo.vue";
import moduloItemComponent from "./moduloItem.vue";
import { modificarOrdenModuloService, obtenerIdsModulosService } from "./modulos.service";
import { getDiplomasCursoService, getDiplomaFileService } from "../../diplomas/diplomas.service";

export default {
  name: "modulosTab",
  props: {
    tipoCurso: String,
    idCurso: String,
    permisoEscritura: { type: Boolean, default: false },
    diplomas: Array,
  },
  components: {
    moduloItemComponent,
    agregarModuloComponent,
    draggable,
  },
  computed: {
    ...mapGetters(["role", "app", "vistaSeguimiento", "sesionCursoAlumno"]),
    localModulos() {
      let x = false;
      if (this.modulos && this.modulos.length > 0) {
        x = true;
      }
      return x;
    }
  },

  data() {
    return {
      loading: true,
      tipo: "modulo",
      agregarModulo: false,
      editarModulo: null,
      dragOptions: { animation: 100 },
      modulos: [],
      avanceAutomatico: false,
      mostrarBotones: false,
      avanceModulos: [],
      loadingFile: false,
      moduloTransversal: null,
    };
  },
  created(){
    this.avanceModulos = [];
    this.getIdsModulos();
  },
  methods: {
    crearModulo() {
      this.agregarModulo = true;
    },
    moduloRegistrado(curso) {
      this.modulos.push(curso);
    },
    moduloEliminado(modulo) {
      const index = this.modulos.findIndex((idModulo) => idModulo == modulo);
      if (index >= 0) this.modulos.splice(index,1)
    },
    async cambiarOrden(event) {
      const ids = { idCurso: this.idCurso, idModulo: event.moved.element };
      const data = { previo: event.moved.oldIndex, nuevo: event.moved.newIndex };
      try {
        this.loading = true;
        const serverResponse = await modificarOrdenModuloService( data, this.tipoCurso, ids);
        this.loading = false;
        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
      } catch (error) {
        this.$appErrorMessage();
      }
    },
    async getIdsModulos() {
      try {
        this.loading = true;
        const serverResponse = await obtenerIdsModulosService(this.tipoCurso, this.idCurso);
        this.loading = false;

        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);

        this.modulos = serverResponse.modulos;
        this.avanceAutomatico = serverResponse.avanceAutomatico;
        this.moduloTransversal = serverResponse.moduloTransversal;
      } catch (error) {
        this.$appErrorMessage();
      }
    },
    calcularAvanceCurso(avanceModulo) {
      if(avanceModulo == 100) this.avanceModulos.push(avanceModulo);
      if(this.avanceModulos.length == this.modulos.length && this.diplomas.length > 0 && this.sesionCursoAlumno) this.cargarDiplomas();
    },
    async cargarDiplomas() {
      try {
        this.loading = true;
        const data = { diplomas: this.diplomas};
        const serverResponse = await getDiplomasCursoService(data);
        this.loading = false;

        if (!serverResponse.ok) {
          this.$systemErrorMessage(serverResponse.mensaje);
        } else {
          this.diplomasInfo = serverResponse.diplomas;
          this.mostrarBotones = true;
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
    async obtenerDiploma(idDiploma) {
      try {
        this.loadingFile = true;
        const serverResponse = await getDiplomaFileService(idDiploma, {origen: 'curso', idCurso: this.idCurso});
        const blob = new Blob([serverResponse], {type: 'application/pdf'});
        const blobURL = URL.createObjectURL(blob);
        window.open(blobURL);
        this.loadingFile = false;

      } catch (error) {
        this.loadingFile = false;
        this.$appErrorMessage();
      }
    },
  },
};
</script>
